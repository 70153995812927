import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import CommonAPIErrorPopup from "./Utils/CommonAPIErrorPopup.js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function Portal() {
  const dispatch = useDispatch();
  const { apiError } = useSelector((state) => state?.ApiErrorReducer);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    apiError && setShowModal(true);
  }, [apiError]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setShowModal(false);
      dispatch({ type: "API_ERROR", payload: null });
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showModal &&
        createPortal(
          <CommonAPIErrorPopup
            onClose={() => {
              dispatch({ type: "API_ERROR", payload: null });
              setShowModal(false);
            }}
            apiError={apiError}
          />,
          document.body
        )}
    </>
  );
}
