import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import DsbpActionDialog from "./DsbpActionDialog";
import CustomizeView from "../common/CustomizeView";
import filter from "../../assets/images/filter.svg";
import BlueFilterIcon from "../../assets/images/BlueFilterIcon.svg";
import searchMyProjectsClicked from "../../assets/images/searchMyProjectsClicked.svg";
import searchMyProjects from "../../assets/images/searchMyProjects.svg";
import { handleConfirmFullScopeIn } from "../../apis/dsbpApi";
import { getMyProject } from "../../store/actions/ProjectActions";
import "primeicons/primeicons.css";
import { isArray, isEmpty } from "lodash";
import { ExportSelectedRows } from "../ExportCSV";
import DsbpCommonPopup from "./DsbpCommonPopup";
import _ from "lodash";
import { dsbpConstant } from "./constant";
import { saveWarningMessage } from "../../constants";
import { AWM_Biocide, AWM_AISE, AWM_Sellable } from "../ACP/constant";
import { UnsavedChangesDialog } from "../common/NavigationPrompt";
import { CustomBreadCrum } from "../common/CustomBreadcrum";

const ArtworkHeader = ({
  accessType = "Write",
  label,
  headerName,
  selected,
  onActionSubmit,
  actionDialog,
  setActionDialog,
  dsbpPmpData,
  setTableRender,
  tableRender,
  customizeViewFields,
  setCustomizeViewFields,
  isDependencyMapping,
  setLoader,
  dependencyMappingData,
  CDPTPageData,
  IQData,
  RDTData,
  GABriefData,
  columnNames,
  filteredDependencyMappingData,
  onSearchClick,
  onClickClearFilter,
  isFilterActivatedInDependencyMapping,
  updateDropDownData,
  onSubmit,
  handleNewGaBrief,
  isSubmitEnable,
  submittedData,
  setSubmittedData,
  filteredDsbpDataLength,
  hyperLink,
  projectData,
  filename,
  loader,
  filteredDsbpData,
  ownerList,
  dependencyTableDatas,
  Assignee,
  isProjectComplete = false,
  totalNumRecords,
  pageEdited,
  setPageEdited,
  buWiseSortedColumnNames,
  setResetKey,
  onHideReset,
}) => {
  const navigate = useNavigate();
  let { ProjectID } = useParams();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [toggleButtons, setToggleButtons] = useState("Tabular");
  const [actionHeader, setActionHeader] = useState("");
  const [errorDialog, setErrorDialog] = useState(false);
  const [operation, setOperation] = useState({});
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(false);
  const locationPath = location?.pathname;

  const { DropDownValuesData, loading } = useSelector((state) => state.DropDownValuesReducer);

  const { userInformation } = useSelector((state) => state.UserReducer);
  const { projectState } = useSelector((state) => state.ProjectPlanReducer);
  const { myProject } = useSelector((state) => state.myProject);
  const currentUrl = useLocation().pathname.split("/");
  // const accessSecurityMatrix = usePriorityNew(currentUrl[1]);
  // const isUserAccess = Assignee === userInformation?.userid;
  // const finalPermission = isUserAccess ? isUserAccess : accessSecurityMatrix;
  const finalPermission = accessType === "Write" ? true : false;

  const handleClick = () => {
    setIsClicked(!isClicked);
    onSearchClick(); // Call the prop function
  };

  const user_group = useSelector((state) => state.UserDetailsReducer.userDetails.UserGroup);
  const payLoad = {
    ArtworkAgilityPage: {
      PM: userInformation.userid,
      UserGroup: user_group,
    },
  };
  const [showDialog, setShowDialog] = useState(false);

  const cancelNavigation = () => {
    setShowDialog(false);
    setShowWarningDialog(false);
    // hiding warning on cancelNavigation click
  };

  // check whether project is from home care or baby care
  let isBUHomeCare = false;
  if (projectData?.BU === "Home Care") {
    isBUHomeCare = true;
  }
  let actionNameObject = [];

  const awmCicMatrixRequestedGroupName = dependencyMappingData
    ?.filter((item) => item?.AWM_CIC_Matrix_Requested === "Yes" && item?.AWM_CIC_Matrix)
    .map((item) => item.AWM_CIC_Matrix);

  headerName !== "Dependency Mapping"
    ? (actionNameObject = [
        {
          value: "Mass Update",
          key: "option-1",
          header: "Mass Update",
        },
        {
          value: "Create POAA",
          key: "option-2",
          header: "Are you sure you want to create POAs for below PMPs in RTA ?",
        },
        {
          value: "Group PMPs",
          key: "option-3",
          header: "Are you sure you want to group these PMPs ?",
        },
        {
          value: "Add to Project",
          key: "option-4",
          header: "Are you sure you want to add these PMP to Project ?",
        },
      ])
    : (actionNameObject = [
        {
          value: "Add CIC Matrix",
          key: "option-2",
          header: "Are you sure you want to link these PMPs to CIC Matrix ?",
        },
        {
          value: "Request CIC/CIC Matrix",
          key: "option-3",
          header: "Please confirm you want to request CIC/CIC Matrix for following CICs?",
        },
      ]);

  let title;

  switch (operation) {
    case "notCIC":
      title = dsbpConstant.not_cic;
      break;
    case "fullkitNotReady":
      title = dsbpConstant.fullkit_not_ready;
      break;
    case "CICRequestSent":
      title = dsbpConstant.cic_request_sent;
      break;
    case "CICRequestSentAndNoCIC":
      title = dsbpConstant.cic_request_sent_no_cic;
      break;
    case "CICMatrixAdded":
      title = dsbpConstant.cic_matrix_added;
      break;
    default:
      title = dsbpConstant.unknown_operation;
      break;
  }

  const handleAction = (e) => {
    setActionHeader(e);
    let selectedItemSaved = [];
    let selectedItemCICSaved = true;

    if (headerName === "Dependency Mapping") {
      selectedItemSaved = dependencyTableDatas?.filter((item) =>
        selected?.some((data) => {
          return (
            data?.DSBP_PMP_PIMaterialNumber === item?.DSBP_PMP_PIMaterialNumber &&
            data?.DSBP_PMP_PIMaterialID === item?.DSBP_PMP_PIMaterialID &&
            data?.DSBP_InitiativeID === item?.DSBP_InitiativeID
          );
          // return data?.DSBP_PMP_PIMaterialNumber === item?.DSBP_PMP_PIMaterialNumber;
        })
      );
      const areCICValuesDifferent =
        selected?.some((currentItem, index) => {
          let current_DSBP_PMP_PIMaterialNumber = currentItem?.DSBP_PMP_PIMaterialNumber;
          let current_DSBP_PMP_PIMaterialID = currentItem?.DSBP_PMP_PIMaterialID;
          let current_DSBP_InitiativeID = currentItem?.DSBP_InitiativeID;

          const backendItem = selectedItemSaved?.find((item) => {
            return (
              item?.DSBP_InitiativeID === current_DSBP_InitiativeID &&
              item?.DSBP_PMP_PIMaterialID === current_DSBP_PMP_PIMaterialID &&
              item?.DSBP_PMP_PIMaterialNumber === current_DSBP_PMP_PIMaterialNumber
            );
          });
          // const backendItem = selectedItemSaved[index];
          return backendItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Needed !== currentItem?.AWM_CIC_Needed;
        }) && selected?.every((item) => item?.AWM_CIC_Needed !== "");

      const otherValuesDifferent = selected?.some((currentItem, index) => {
        let current_DSBP_PMP_PIMaterialNumber = currentItem?.DSBP_PMP_PIMaterialNumber;
        let current_DSBP_PMP_PIMaterialID = currentItem?.DSBP_PMP_PIMaterialID;
        let current_DSBP_InitiativeID = currentItem?.DSBP_InitiativeID;

        const backendItem = selectedItemSaved?.find((item) => {
          return (
            item?.DSBP_InitiativeID === current_DSBP_InitiativeID &&
            item?.DSBP_PMP_PIMaterialID === current_DSBP_PMP_PIMaterialID &&
            item?.DSBP_PMP_PIMaterialNumber === current_DSBP_PMP_PIMaterialNumber
          );
        });
        // const backendItem = selectedItemSaved[index];

        return (
          backendItem?.Preselected_GABrief_Page[0]?.AWM_GABrief !== currentItem?.AWM_GA_Brief ||
          backendItem?.AWM_CIC_Page?.[0]?.AWM_Supporting_PMP_Design !==
            currentItem?.AWM_Supporting_PMP_Design ||
          backendItem?.AWM_CIC_Page?.[0]?.AWM_Supporting_PMP_Layout !==
            currentItem?.AWM_Supporting_PMP_Layout ||
          backendItem?.AWM_CIC_Page?.[0]?.AWM_Other_Reference !== currentItem?.AWM_Other_Reference
        );
      });

      if (
        (selectedItemSaved?.some(
          (item) => item?.AWM_CIC_Page?.[0]?.AWM_CIC_Needed === "" || !item?.AWM_CIC_Page?.[0]
        ) &&
          selected?.every((item) => item?.AWM_CIC_Needed !== "")) ||
        areCICValuesDifferent ||
        otherValuesDifferent
      ) {
        selectedItemCICSaved = false;
      } else {
        selectedItemCICSaved = true;
      }
    }
    if (headerName !== "Dependency Mapping" || e === "Mass Update") {
      setActionDialog(true);
    } else {
      const selectedNoCICs = selected.some((item) => item.AWM_CIC_Needed !== "Yes");
      const selectedCICRequestSent = selected.every(
        //commented the below code  as part of 3319 and 3341
        // (item) => item?.AWM_CIC_Matrix_Requested1 !== "" || item?.AWM_CIC_Requested !== ""
        (item) => item?.AWM_CIC_Requested !== ""
      );

      const selectedCICRequestSentAndNoCIC = selected.every(
        (item) =>
          //commented the below code as part of 3319 and 3341
          // (item?.AWM_CIC_Matrix_Requested1 !== "" || item?.AWM_CIC_Requested !== "") &&
          (item?.AWM_CIC_Requested !== "" && item?.AWM_CIC_Needed === "Yes") ||
          item?.AWM_CIC_Needed !== "Yes"
      );

      // it will change NO to YES

      const isCICRequestFullKitSatisfied = selected.every(
        (item) => item.FullKit_Condition_Checking_Page?.[0]?.CIC_FullKit_Satisfied !== "Yes"
      );

      // it will change NO to YES

      const isCICMatrixRequestFullKitSatisfied = selected.every(
        (item) => item.FullKit_Condition_Checking_Page?.[1]?.CICMatrix_FullKit_Satisfied !== "Yes"
      );

      const isCICMatrixAdded = selected.some((item) => !isEmpty(item.AWM_CIC_Matrix));

      const selectedCICAwaiting = selected.some((item) =>item?.Copy_XML_Request_Status?.toLowerCase() === dsbpConstant?.xml_awaiting_response);

      if (!e.includes("CIC/CIC Matrix")) {
        if (!selectedItemCICSaved) {
          setShowDialog(true);
        } else {
          setShowDialog(false);
          if (
            selectedNoCICs ||
            selectedCICRequestSent ||
            selectedCICRequestSentAndNoCIC ||
            isCICMatrixAdded ||
            selectedCICAwaiting
          ) {
            setOperation(
              selectedNoCICs
                ? "notCIC"
                :(selectedCICRequestSent || selectedCICAwaiting)
                ? "CICRequestSent"
                : isCICMatrixAdded
                ? "CICMatrixAdded"
                : "CICRequestSentAndNoCIC"
            );
            setErrorDialog(true);
          } else {
            setActionDialog(true);
          }
        }
      } else {
        if (!selectedItemCICSaved) {
          setShowDialog(true);
        } else {
          setShowDialog(false);
          if (isCICRequestFullKitSatisfied && isCICMatrixRequestFullKitSatisfied) {
            setOperation("fullkitNotReady");
            setErrorDialog(true);
          } else if (selectedNoCICs || selectedCICRequestSent || selectedCICRequestSentAndNoCIC || selectedCICAwaiting) {
            setOperation(
              selectedNoCICs
                ? "notCIC"
                : (selectedCICRequestSent || selectedCICAwaiting)
                ? "CICRequestSent"
                : "CICRequestSentAndNoCIC"
            );
            setErrorDialog(true);
          } else {
            setActionDialog(true);
          }
        }
      }
    }
  };

  const url = locationPath?.split("/");
  const mytasks = url[1];
  const [showApproveDialogCPPFA, setShowApproveDialogCPPFA] = useState(false);
  const [actionDropDownValues, setActionDropDownValues] = useState([]);
  const [aiseList, setAISEList] = useState([]);
  const [assemblyMechanismList, setAssemblyMechanismList] = useState([]);
  const [confirmFullScopeEnable, setConfirmFullScopeEnable] = useState(false);
  const [availableFields, setAvailableFields] = useState([]);
  // let jsonColumnWidth = localStorage.getItem("columnWidthDSBPArtwork");
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [tempHandleActionClick, setTempHandleActionClick] = useState("");

  let jsonColumnWidth = isBUHomeCare
    ? localStorage.getItem("columnWidthDSBPArtworkHomeCare")
    : localStorage.getItem("columnWidthDSBPArtworkBabyCare");

  let allColumns = buWiseSortedColumnNames;
  let isFilterActivated = [];

  if (allColumns) {
    isFilterActivated = allColumns.filter((ele) => {
      if (
        ele.freeze === true ||
        ele.sortAtoZ === true ||
        ele.sortZtoA === true ||
        ele.width !== 250 ||
        ele?.reorder === true
      ) {
        return ele;
      }
    });
  }

  const breadcrumb = (
    <div>
      <nav className="p-breadcrumb p-component ProjectPlanBreadCrum" aria-label="Breadcrumb">
        <ul>
          <li className="">
            <NavLink to={`/${url[1]}`} className="p-menuitem-link">
              <span className="p-menuitem-text">{_.startCase(url[1])}</span>
            </NavLink>
          </li>
          <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"></li>
          <li className="">
            <a href="#" className="p-menuitem-link">
              <span className="p-menuitem-text">{headerName}</span>
            </a>
          </li>
          <li>
            <NavLink to={hyperLink}>
              <div className="project-name">{projectData?.Project_Name}</div>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );

  // needs to pass this data in Custom Breadcrum
  const items = [
    { label: _.startCase(url[1]), url: `/${url[1]}` },
    { label: headerName, url: "" },
    { label: !loader && projectData?.Project_Name, url: hyperLink },
  ];

  useEffect(() => {
    let availableField = dsbpPmpData
      ? Object.keys(dsbpPmpData[0] || {})
          .filter((key) => allColumns?.every((item) => item?.Field_Name !== key))
          .map((fieldName) => ({
            Field_Name: fieldName,
          }))
      : [];

    if (
      !isBUHomeCare &&
      label === "Artwork Scope Alignment" &&
      availableField &&
      availableField?.length
    ) {
      let availableFieldsUpdated = [];
      availableField?.forEach((field) => {
        if (
          field?.Field_Name !== AWM_Biocide &&
          field?.Field_Name !== AWM_AISE &&
          field?.Field_Name !== AWM_Sellable
        ) {
          availableFieldsUpdated.push(field);
        }
      });
      availableField = availableFieldsUpdated?.length > 0 && availableFieldsUpdated;
    }

    setAvailableFields(availableField);
  }, [dsbpPmpData, buWiseSortedColumnNames]); //buWiseSortedColumnNames added in dependency to fix catching issue

  useEffect(() => {
    if (myProject) {
      let projectData =
        isArray(myProject) && myProject.find((project) => project.Project_ID === ProjectID);
      setConfirmFullScopeEnable(projectData?.Estimated_No_Of_POAs > 1);
    }
  }, [myProject]);

  useEffect(() => {
    if (DropDownValuesData) {
      setActionDropDownValues(DropDownValuesData?.ArtworkAgilityTasksPage.Artwork_Alignment || []);
    }
  }, [DropDownValuesData]);

  useEffect(() => {
    if (actionDropDownValues !== undefined && actionDropDownValues.length !== 0) {
      setAISEList(actionDropDownValues.AISE);
      setAssemblyMechanismList(actionDropDownValues.Assembly_Mechanism);
    }
  }, [actionDropDownValues]);

  //this is not required as 2805 is implemented
  // useEffect(() => {
  //   if (headerName === "Artwork Scope Alignment") {
  //     if (customizeViewFields !== null && customizeViewFields !== undefined && !isEmpty(customizeViewFields) && customizeViewFields?.length !== 0 && buWiseSortedColumnNames?.length > 0 && availableFields?.length > 0) {
  //       const customizeViewFieldValue = JSON.parse(customizeViewFields)
  //       const selectedFeildsVal = customizeViewFieldValue?.selectedFields?.fieldsData;
  //       const aviaFeildsVal = customizeViewFieldValue?.availableFields?.fieldsData;
  //       const freezedVal = customizeViewFieldValue?.freezedColumns?.fieldsData;

  //       const allCustomizeviewFieldsValue = [...selectedFeildsVal, ...aviaFeildsVal, ...freezedVal]

  //       const totalColCus = selectedFeildsVal?.length + aviaFeildsVal?.length + freezedVal?.length;
  //       const totalColTable = buWiseSortedColumnNames?.length + availableFields?.length;

  //       const updatedSelectedFeildsVal = buWiseSortedColumnNames?.map((item) => { return item.Field_Name })
  //       const updatedAvaiFeildsVal = availableFields?.map((item) => { return item.Field_Name })
  //       const totalFeildsValue = [...updatedSelectedFeildsVal, ...updatedAvaiFeildsVal]

  //       if (totalColTable !== totalColCus && !_.isEqual(totalFeildsValue, allCustomizeviewFieldsValue)) //when number of columns in table doesnot match with customizeview, when shifting from homecare to babycare
  //       {
  //         const updatedCustomizeView = formCustomizeObject(updatedAvaiFeildsVal, [], updatedSelectedFeildsVal)
  //         setCustomizeViewFields(JSON.stringify(updatedCustomizeView))
  //         localStorage.setItem("customizeViewFields", JSON.stringify(updatedCustomizeView));
  //       }
  //     }
  //   }
  // }, [customizeViewFields, buWiseSortedColumnNames, availableFields]);

  const onConfirmFullScopeIn = async () => {
    setLoader(true);
    await handleConfirmFullScopeIn(ProjectID);
    await dispatch(getMyProject(payLoad));
    setLoader(false);
  };

  // const propertyNamesArray = dsbpPmpData && Object.keys(dsbpPmpData?.[0]);
  // const propertyNamesArray1 = allColumns.map(field => field.Field_Name);

  const alterColumnsforExport = () => {
    let jsonValue =
      customizeViewFields && customizeViewFields?.length > 0
        ? JSON.parse(customizeViewFields)
        : null;
    const allSelectedData = jsonValue?.freezedColumns?.fieldsData
      ? (jsonValue?.freezedColumns?.fieldsData).concat(jsonValue?.selectedFields?.fieldsData)
      : jsonValue?.selectedFields?.fieldsData;
    let selectedData = allSelectedData || columnNames;
    if (label === "Artwork Scope Alignment") {
      const columnsToExcludeForBabyCare = ["AWM_Biocide", "AWM_AISE", "AWM_Sellable"];
      selectedData = selectedData?.filter((column) => {
        return !(!isBUHomeCare && columnsToExcludeForBabyCare.includes(column));
      });
    }
    return selectedData;
  };
  columnNames = alterColumnsforExport();

  // this will be called on action click if page is not edited
  // it will work like earlier with else block, but
  // if page is edited then we will emprorily save action data
  // into a variable setTempHandleActionClick and show the warning msg
  // if a user click yes then will call confirmNavigation
  const handleActionClick = (e) => {
    if (pageEdited) {
      setTempHandleActionClick(e);
      setShowWarningDialog(pageEdited);
    } else {
      handleAction(e);
    }
  };

  // on confirming yes to warning this blcok will be used by using tempHandleActionClick variable value
  const confirmNavigation = () => {
    handleAction(tempHandleActionClick);
    setTempHandleActionClick("");
    setShowWarningDialog(false);
  };

  const onHide = (name, value) => {
    if (name === "dsbp") {
      setActionDialog(value);
    }
    if (name === "error") {
      setErrorDialog(value);
    }
    if (name === "warning") {
      setShowDialog(value);
    }
    if (pageEdited && onHideReset) {
      onHideReset();
    }
  };

  return (
    <div>
      {showApproveDialogCPPFA && (
        <CustomizeView
          onClose={() => setShowApproveDialogCPPFA(!showApproveDialogCPPFA)}
          showTaskDialog={showApproveDialogCPPFA}
          setCustomizeViewFields={setCustomizeViewFields}
          customizeViewFields={customizeViewFields}
          dependencyMappingData={dependencyMappingData}
          headerName={headerName}
          CDPTPageData={CDPTPageData}
          IQData={IQData}
          RDTData={RDTData}
          allColumns={allColumns}
          availableFields={availableFields}
          setTableRender={setTableRender}
          tableRender={tableRender}
          BU={projectData?.BU}
          region={projectData?.Region}
          setResetKey={setResetKey}
        />
      )}
      <div className="actions">
        <div>
          {/* {breadcrumb} */}
          <CustomBreadCrum
            items={items}
            iconColorBlack={true}
            lastChildSecondChild={false}
            lastLabel={"artworkAlignment"}
          />
        </div>
        <div className="header-buttons">
          {!isDependencyMapping && (
            <div style={{ top: 30 }}>
              {filteredDsbpDataLength ? (
                <img
                  src={BlueFilterIcon}
                  alt="filter logo"
                  onClick={() => onClickClearFilter()}
                  className="header-icons"
                />
              ) : (
                <img
                  src={filter}
                  alt="filter logo"
                  // onClick={() => clearColumnWiseFilter()}
                  className="header-icons"
                />
              )}
              <span style={{ marginLeft: 20, marginRight: 25 }}>
                <ExportSelectedRows
                  allData={dsbpPmpData}
                  selectedRows={filteredDsbpData}
                  headers={columnNames}
                  filename={filename}
                />
              </span>
            </div>
          )}
          {isDependencyMapping ? (
            <>
              {filteredDependencyMappingData?.length ? (
                <img
                  src={BlueFilterIcon}
                  alt="filter logo"
                  onClick={() => onClickClearFilter()}
                  className="header-icons"
                />
              ) : (
                <img
                  src={filter}
                  alt="filter logo"
                  // onClick={() => clearColumnWiseFilter()}
                  className="header-icons"
                />
              )}
              <div style={{ marginLeft: 10 }}>
                <img
                  src={isClicked ? searchMyProjects : searchMyProjectsClicked}
                  alt="search field"
                  onClick={handleClick}
                  className="header-icons"
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                <ExportSelectedRows
                  allData={dsbpPmpData}
                  selectedRows={filteredDependencyMappingData}
                  headers={columnNames}
                  filename={filename}
                />
              </div>
              {/* <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <div className="col projectPlanButtons">
                  <label
                    className={` btn border border-secondary ${toggleButtons === "Tabular"
                      ? "ganttChartTabular active"
                      : ""
                      }`}
                    onClick={() => setToggleButtons("Tabular")}
                  >
                    Tabular
                  </label>
                  <label
                    className={` btn border border-secondary ${toggleButtons === "Visual"
                      ? "ganttChartTabular active"
                      : ""
                      }`}
                    onClick={() => setToggleButtons("Visual")}
                  >
                    Visual
                  </label>
                </div>
              </div> */}
            </>
          ) : (
            <button
              type="button"
              disabled={
                confirmFullScopeEnable ||
                projectState === "Draft" ||
                !finalPermission ||
                isProjectComplete
              }
              className="btn btn-secondary"
              onClick={onConfirmFullScopeIn}
            >
              Confirm Full Scope in
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowApproveDialogCPPFA(true)}
            disabled={totalNumRecords == 0}
          >
            Customize View
          </button>
          <DropdownButton
            className="dropdown-button-custom"
            title="Actions"
            align="end"
            // onSelect={handleAction}
            onSelect={(e) => {
              handleActionClick(e);
            }}
            disabled={selected?.length === 0 || !finalPermission || isProjectComplete}
          >
            {actionNameObject?.map((item) => {
              return (
                <Dropdown.Item value={item.value} eventKey={item.header}>
                  {item.value}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </div>
      </div>
      {actionDialog && (
        <DsbpActionDialog
          actionHeader={actionHeader}
          actionDialog={actionDialog}
          setPageEdited={setPageEdited}
          pageEdited={pageEdited}
          setActionDialog={(value) => onHide("dsbp", value)}
          selected={selected}
          actionNameObject={actionNameObject}
          onActionSubmit={headerName !== "Dependency Mapping" ? onActionSubmit : onSubmit}
          aiseList={aiseList}
          assemblyMechanismList={assemblyMechanismList}
          headerName={headerName}
          CDPTPageData={CDPTPageData}
          IQData={IQData}
          RDTData={RDTData}
          GABriefData={GABriefData}
          updateDropDownData={updateDropDownData}
          handleNewGaBrief={handleNewGaBrief}
          isSubmitEnable={isSubmitEnable}
          setSubmittedData={setSubmittedData}
          submittedData={submittedData}
          awmCicMatrixRequestedGroupName={awmCicMatrixRequestedGroupName}
          pmpData={dependencyMappingData}
          loader={loader}
          projectData={projectData}
          ownersList={ownerList}
        />
      )}
      {errorDialog && (
        <DsbpCommonPopup
          actionHeader={title}
          dasbpDialog={errorDialog}
          setDasbpDialog={(value) => onHide("error", value)}
          okButtonShow={true}
          poaCreated={true}
          widthSize="610px"
        />
      )}
      {showDialog && (
        <DsbpCommonPopup
          actionHeader={saveWarningMessage}
          dasbpDialog={showDialog}
          setDasbpDialog={(value) => onHide("warning", value)}
          okButtonShow={true}
          poaCreated={true}
          widthSize="602px"
        />
      )}
      {/* this block is being used to show warning on same page header page */}
      <UnsavedChangesDialog
        visible={showWarningDialog}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    </div>
  );
};

export default ArtworkHeader;
