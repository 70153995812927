import { DownloadAnnotation } from "../components/ProofScope/ExportXFDFGeneration";
import { replaceSpecialChars } from "../utils";

export const triggerXfdfUpload = (env, ProjectID, userInformation, tasks,toast, page1, page2, pageType, navigate) => {   
    const taskFolder = tasks[0]?.Design_Job_ID.split('_DJob')[0].toUpperCase() === "DT" 
                      ? "RDT"
                      : tasks[0]?.Design_Job_ID.split('_DJob')[0].toUpperCase();
    const filename = tasks[0]?.FileMetaDataList?.[0]?.Filename?.split('.')
      .slice(0, -1)
      .join('.');
    const fileExtension = tasks[0]?.FileMetaDataList?.[0]?.Filename.split('.').pop();
    const sanitizeFile = replaceSpecialChars(filename);
    // Generate the paths for PDF and XFDF
    const pdfPath = `cloudflow://awm/${env}/${tasks[0]?.BU}/${taskFolder}/${ProjectID}/${tasks[0]?.Design_Job_ID}/${tasks[0]?.FileMetaDataList[0].Version}/${sanitizeFile}.${fileExtension}`;
    const XFDFPath = `cloudflow://awm/${env}/${tasks[0]?.BU}/${taskFolder}/${ProjectID}/${tasks[0]?.Design_Job_ID}/${tasks[0]?.FileMetaDataList[0].Version}/${sanitizeFile}.XFDF`;

    const xfdfVersion = (tasks[0]?.XfdfFileList?.length && tasks[0]?.XfdfFileList[0].Version !== "" && tasks[0]?.XfdfFileList[0].Version !== "NaN") ? (parseInt(tasks[0]?.XfdfFileList[0].Version)+1).toString() : "1"
    //AWM-3059: removed projectName and DeignJobName and added ProjectId and DesignJobId instead
    const XFDFAzureFolderStructure = `${tasks[0]?.BU}/${tasks[0]?.Region}/${ProjectID}/${taskFolder}/${tasks[0]?.Design_Job_ID}/XFDF/${xfdfVersion}/`; //instead of version it will be xfdfVersion extracted from GET API response of Projectplan or TaskDetails
    //map on tasks to create payload for all approval tasks
    const storeApproveFileDetailsFormData = {"FileList":[]}
    tasks.map((task)=>{
      storeApproveFileDetailsFormData.FileList.push({
        "Filename": `${sanitizeFile}.XFDF`,
        "Version": xfdfVersion,           
        "AWMTaskID": task?.AWM_Task_ID,
        "Size": "",
        "Filepath": "",
        "Full_Name": `${userInformation.firstName} ${userInformation.lastName}`,
        "Email": `${userInformation.userid}@pg.com`,
        "JobID": task?.Design_Job_ID,
        "JobName": task?.Design_Job_Name,
        "TypeOfFile": "xfdf",
        "ViewPFURL": ""
      });        
    })
    //AWM-3059: removed unwanted params
    DownloadAnnotation(pdfPath, XFDFPath,`${sanitizeFile}.XFDF`, XFDFAzureFolderStructure, "Rework", storeApproveFileDetailsFormData, ProjectID, toast, page1, page2, pageType, navigate);
  }