import React, { useState, useEffect, useRef } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import _, { cloneDeep, isArray, isEqual } from "lodash";
import ArtworkHeader from "./ArtworkHeader";
import SelectDsbpId from "./SelectDsbpId";
import ProjectNameHeader from "./ProjectNameHeader";
import DsbpCommonPopup from "./DsbpCommonPopup";
import AgilityList from "./AgilityList";
import { getDSBPDropdownData } from "../../store/actions/DSBPActions";
import Footer from "../common/tableFooter/tableFooter";
import {
  multiFilterChange,
  formCustomizeObject,
  redirectUnallocatedLinksToArtworkStatus,
  isRoleMatch,
} from "../../utils";
import { Toast } from "primereact/toast";
import {
  addDsbpToProject,
  deleteDsbpFromProject,
  getDsbpPMPDetails,
  onSubmitDsbpAction,
  onSubmitCreatePOAA,
} from "../../apis/dsbpApi";
import { useDispatch, useSelector } from "react-redux";
import FooterButtons from "../AWMJobs/DesignJobs/FooterButtons";
import "./index.scss";
import { AcpService } from "../../service/ACPService";
import { AcpCommonService } from "../../service/AcpCommonService";
import { getAttributesForBU, overrideFilteredData ,pgbuildDefaultColumns} from "./utils";
import { showBackgroundProcess } from "../../Utils/helpers";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../common/NavigationPrompt";
import { SpecialProjectAction } from "../../store/actions/ProjectSetupActions";
import { setColumnsOrder, getColumnsOrder } from "../../apis/commonApi";
import UnAuthorizedComponent from "../UnAuthorized/UnAuthorizedComponent";

const ArtworkAlignment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { ProjectID, assignee } = useParams();
  const currentUrl = location.pathname;
  let currentUrlBasePage = currentUrl?.split("/")[1];
  const [accessState,setAccessState]=useState({
    checkReadWriteAccess:true,
    status:""
  })
  const [accessType, setAccessType] = useState(null);
  const [dropdownlist, setDropdownList] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const DropDownData = useSelector((state) => state.DSBPDropdownReducer);
  const [dsbpPmpData, setDsbpPmpData] = useState(null);
  const [initdsbpPmpData, setInitdsbpPmpData] = useState(null);
  const [originalDsbpPmpData, setOriginalDsbpPmpData] = useState(null);
  const [selectedFields, setSelectedFields] = useState({});
  const [filteredDsbpData, setFilteredDsbpData] = useState(null);
  const [totalNoOfDsbpId, setTotalNoOfDsbpId] = useState(0);
  const [totalNoOfPMP, setTotalNoOfPMP] = useState(0);
  const [totalNoOfPOA, setTotalNoOfPOA] = useState(0);
  const [totalNoOfPMPLocked, setTotalNoOfPMPLocked] = useState(0);
  const [totalNoOfAddedProject, setTotalNoOfAddedProject] = useState(0);
  const [actionDialog, setActionDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [buWiseSortedColumnNames, setBuWiseSortedColumnNames] = useState(null);
  const [listOfInitiativeId, setListOfInitiativeId] = useState([]);
  const [addSavedData, setSavedData] = useState([]);
  const [handleYesAddToPRoject, setHandleYesAddToPRoject] = useState(false);
  const [rejectDialog, setRejectDialog] = useState(false);
  const [poaaAcknowledgDialog, setPoaaAcknowledgDialog] = useState(false);
  const [poaaResponse, setPoaaResponse] = useState(false);
  const [tableRender, setTableRender] = useState(false);
  const [selectedReason, setSelectedReason] = useState(false);
  const projectSetup = useSelector((state) => state.ProjectSetupReducer);
  const selectedProjectDetails = projectSetup.selectedProject;
  const [mappedPOAS, setMappedPOAS] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [mappedPOARequestedIds, setMappedPOARequestedIds] = useState([]);
  const [cicMappedIds, setCICMappedIds] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [clearAllFilter, setClearAllFilter] = useState(false);
  const [conditionMatched, setConditionMatched] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [customizeViewFields, setCustomizeViewFields] = useState();
  const [projectData, setProjectData] = useState([]);
  const [selectedOwnerMatch, setSelectedOwnerMatch] = useState(true);
  const [resizeMode, setResizeMode] = useState("expand");
  const filterArray = useRef([]);
  const selectedColumnList = useRef([]);
  const colAllSelected = useRef([]);
  const selectedFieldsRef = useRef([]);
  const allBUAttributesData = useSelector((state) => state.DropDownValuesReducer);
  const allBUAttributes = allBUAttributesData.DropDownValuesData;
  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [projectState, setProjectState] = useState("");
  const [isSelectAllDisabled, setisSelectAllDisbaled] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [initiativeIdsWithPoaCreated, setInitiativeIdsWithPoaCreated] = useState([]);
  const [resetCustomInputs, setResetCustomInputs] = useState(false);
  const [pgDefaultColumns,setPgDefaultColumns]=useState({})
  const [editingInProgress, setEditingInProgress] = useState(false);
  
  // const Task = "SAA_";
  // let taskData = isArray(projectPlanDesign) && projectPlanDesign?.find(
  //   (task) => task?.AWM_Task_ID?.includes(Task)
  // );

  const {
    userDetails: { AccessGroup, UserGroup },
  } = useSelector((state) => state?.UserDetailsReducer);
  const User = useSelector((state) => state?.UserReducer);
  const userInformation = User.userInformation;
  const toast = useRef(null);

  const userAccessPayLoad = {
    ArtworkAgilityPage: {
      UserId: userInformation?.userid,
      ProjectID: ProjectID,
      UserGroup: UserGroup,
    },
  };

  const showPyMessage = (msg) => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: msg,
      sticky: true,
    });
  };
  useEffect(() => {
    setProjectData(projectData);
    localStorage.setItem("projectData", JSON.stringify(projectData));
  }, [projectData]);


  const breadcrumb = [
    { label: "My Tasks", url: "/myTasks" },
    { label: "Approve Regional Design Template" },
  ];

  const dispatch = useDispatch();
  const headerName = "Artwork Scope Alignment";
  // Function to remove specific fields from the `fieldsData` arrays
const removeFields=(obj, fieldsToRemove)=> {
    const newObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const currentItem = obj[key];
        newObj[key] = {
          ...currentItem,
        fieldsData: currentItem.fieldsData.filter(item => !fieldsToRemove.includes(item))
        };
      }
    }

    return newObj;
}
  const setCustomizeViewPega = async () => {
    let customizeViewColumnFromPega = [];
    if (
      projectData?.BU != null &&
      projectData?.Region != null &&
      projectData?.BU !== "" &&
      projectData?.Region !== ""
    ) {
      customizeViewColumnFromPega = await getColumnsOrder(
        userInformation?.userid,
        "customizeViewFields",
        projectData?.BU,
        projectData?.Region
      );
      const fieldsToRemove = [
        "AWM_SnapChange_CIC",
        "AWM_SnapChange_Editable",
        "AWM_SnapChange_FA",
        "AWM_SnapChange_POACreate",
        "AWM_SnapChange_POAUpdate"
      ];
      if (customizeViewColumnFromPega != null && customizeViewColumnFromPega != "") {
        // new object with the fields removed
        const updatedObj = removeFields(customizeViewColumnFromPega, fieldsToRemove);
        setCustomizeViewFields(updatedObj);
      }
    } else {
      setCustomizeViewFields(null); //if CV is not there in pega null will set to Initial customizeview
    }
  };

  useEffect(() => {
    const fetchDataAndDispatch = async () => {
      await fetchData();
      // dispatch(getDSBPDropdownData(BU, Region, ProjectID));
    };

    const isUserAccess = isRoleMatch(AccessGroup, [
      "CapacityManager",
      "ProjectManager",
      "TaskOwner",
    ]);
    setAccessState({
      ...accessState,
      checkReadWriteAccess:isUserAccess
    })
    if (isUserAccess) {
      fetchDataAndDispatch();
    }
  }, [AccessGroup]);

  useEffect(() => {
    if (accessType === "Hidden") {
      setAccessState({
        ...accessState,
        checkReadWriteAccess:false
      })
    } else if (accessType === "Read") {
      setAccessState({
        ...accessState,
        checkReadWriteAccess:true
      })
    } else if (accessType === "Write") {
      setAccessState({
        ...accessState,
        checkReadWriteAccess:true
      })
    }
  }, [accessType]);

  useEffect(() => {
    if (userInformation?.userid != undefined) 
    setCustomizeViewPega();
    findAndSortBuWiseColumnNames();
  }, [dsbpPmpData, ProjectID, userInformation]);

  useEffect(() => {
    // Initialize originalDsbpPmpData when dsbpPmpData changes
    if (dsbpPmpData) {
      const idsWithPoaCreated = [];
      dsbpPmpData?.forEach((pmp) => {
        // if (pmp?.AWM_AWJStatus === "Created") {
        if (pmp?.AWMPOARequest === "AWM POA Requested" || pmp?.AWMPOACreate === "AWM POA Created") {
          idsWithPoaCreated.indexOf(pmp?.DSBP_InitiativeID) === -1 &&
            idsWithPoaCreated.push(pmp?.DSBP_InitiativeID);
        }
      });
      setInitiativeIdsWithPoaCreated(idsWithPoaCreated);
      // setOriginalDsbpPmpData(cloneDeep(dsbpPmpData));
    }
    let areObjectsEqualData = true;
    if (sortData?.length) {
      const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(
        initdsbpPmpData,
        sortData?.[0],
        sortData?.[1],
        "artworkalign"
      );
      areObjectsEqualData = isEqual(sortedDataSet, dsbpPmpData);
    } else {
      areObjectsEqualData = isEqual(originalDsbpPmpData, dsbpPmpData);
    }
    if (!areObjectsEqualData) {
      setPageEdited(true);
      markDirty();
    } else {
      setPageEdited(false);
      markClean();
    }
  }, [dsbpPmpData]);

  // this block is being used for show warning on sidebar click if page is altered
  const { markDirty, markClean } = useUnsavedChangesWarning();
  // useEffect(() => {
  //   let checkLength = addSavedData?.length;
  //   if (checkLength > 0  || !selectedOwnerMatch) {
  //     markDirty();
  //   } else {
  //     markClean();
  //   }
  // }, [addSavedData?.length,selectedOwnerMatch]);

  useEffect(() => {
    markClean();
    setPageEdited(false);
  }, []);

  const findAndSortBuWiseColumnNames = async () => {
    if (projectData?.BU && projectData?.Region) {
      let regionData = getAttributesForBU(
        allBUAttributes?.ArtWorkProjectSetupPage,
        projectData?.Region,
        projectData?.BU
      );
      let attributeList = [...regionData];
      let sortedData = [];
      if (attributeList && attributeList.length) {
        sortedData = [...attributeList].sort((a, b) => {
          return a.Sequence - b.Sequence;
        });
      }
      setBuWiseSortedColumnNames(sortedData);
      let columnNames = sortedData?.map((item) => item.Field_Name);
      setColumnNames(columnNames);
      if (sortedData && sortedData.length) {
        sortedData.map((list) => {
          list["width"] = 250;
          list["freeze"] = false;
          list["sortAtoZ"] = false;
          list["sortZtoA"] = false;
          list["reorder"] = false;
        });
      }
      setPgDefaultColumns(pgbuildDefaultColumns(projectData,dsbpPmpData,allBUAttributes));
      //await setColumnsOrder(userInformation?.userid, "artworkAlignmentAllColumns", projectData?.BU, projectData?.Region, false, JSON.stringify(sortedData));
      localStorage.setItem("artworkAlignmentAllColumns", JSON.stringify(sortedData)); //to use in drag and drop
      localStorage.setItem("columnWidthDSBPArtworkHomeCare", JSON.stringify(sortedData));
      localStorage.setItem("columnWidthDSBPArtworkBabyCare", JSON.stringify(sortedData));
    }
  };

  async function fetchData() {
    setTableLoader(true);
    const {
      resp,
      mappedInitiativeIDs,
      projectData,
      ownersList,
      specialProjectFlag,
      accessType,
      projectState,
      status
    } = await getDsbpPMPDetails(ProjectID, userAccessPayLoad);
    setAccessState({
      ...accessState,
      status: status
    })
    setAccessType(accessType);
    setProjectData(projectData);
    setListOfInitiativeId(mappedInitiativeIDs);
    setOwnerList(ownersList);
    setProjectState(projectState);
    dispatch(SpecialProjectAction(specialProjectFlag));
    redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate);
    if (!resp) {
      setDsbpPmpData(null);
      setInitdsbpPmpData(null);
      setOriginalDsbpPmpData(null);
    }
    if (resp && resp?.length !== 0) {
      const transformedArray = resp?.flatMap((item) =>
        item.DSBP_PMP_PIMaterialIDPage?.map((person) => ({
          DSBP_InitiativeID: item.DSBP_InitiativeID,
          ...person,
        }))
      );

      const filteredIds = Array.from(
        new Set(
          transformedArray
            .filter((item) => item.RTA_POANumber !== "")
            .map((item) => item.DSBP_InitiativeID)
        )
      );
      setMappedPOAS(filteredIds);

      const filteredPOARequestedIds = Array.from(
        new Set(
          transformedArray
            .filter((item) => item.AWM_POARequested === "Yes")
            .map((item) => item.DSBP_InitiativeID)
        )
      );
      setMappedPOARequestedIds(filteredPOARequestedIds);

      const filteredCICMappedIds = Array.from(
        new Set(
          transformedArray
            .filter((item) => item?.RTA_POANumber === "" && item?.AWM_CICNeeded === "Yes")
            .map((item) => item?.DSBP_InitiativeID)
        )
      );
      setCICMappedIds(filteredCICMappedIds);

      if (filteredDsbpData && filteredDsbpData.length) {
        const uniqueArrayValues = new Set(filteredDsbpData?.map(obj => `${obj.DSBP_PMP_PIMaterialNumber}-${obj.DSBP_PMP_PIMaterialID}-${obj.DSBP_InitiativeID}`));

        // // Filter transformedArray based on uniqueArrayValues
        const filteredTransformedArray = transformedArray.filter((obj) =>
          uniqueArrayValues.has(
            `${obj.DSBP_PMP_PIMaterialNumber}-${obj.DSBP_PMP_PIMaterialID}-${obj.DSBP_InitiativeID}`
          )
        );

        setFilteredDsbpData([...filteredTransformedArray]); //set the filtered data back.AWM-3510,AWM-3562
        setInitdsbpPmpData([...transformedArray]);
        setDsbpPmpData([...transformedArray]);
        setOriginalDsbpPmpData([...transformedArray])
      } else {
        const flattenedArray = transformedArray.map((item) => {
          const newItem = { ...item };
          const FPCStagingPage = newItem.FPCStagingPage?.[0];
          const formulaCard = FPCStagingPage?.FormulaCardStagingPage?.[0];

          delete newItem.FPCStagingPage;

          return {
            ...newItem,
            ...FPCStagingPage,
            ...formulaCard,
          };
        });
        const newflatterArray = flattenedArray.map((item) => {
          const newItem = { ...item };
          delete newItem.FormulaCardStagingPage;
          return {
            ...newItem,
          };
        });
        setDsbpPmpData(newflatterArray);
        setInitdsbpPmpData(_.cloneDeep(newflatterArray));
        setOriginalDsbpPmpData(_.cloneDeep(newflatterArray));
      }

      // setOriginalDsbpPmpData(cloneDeep(transformedArray));
      setTotalNoOfPMP(transformedArray.length);

      const initiativeIDs = transformedArray.map((task) => task.DSBP_InitiativeID);
      const uniqueIDs = [...new Set(initiativeIDs)];
      uniqueIDs.sort((a, b) => a - b);

      const count = transformedArray.reduce((acc, obj) => {
        if (obj?.RTA_POANumber) {
          return acc + 1;
        }
        return acc;
      }, 0);

      setTotalNoOfPOA(count);
      const noOfAddedProject = transformedArray.reduce((acc, obj) => {
        if (obj?.AWM_AddedToProject === "Yes") {
          return acc + 1;
        }
        return acc;
      }, 0);
      setTotalNoOfAddedProject(noOfAddedProject);

      const notOfPMPLocked = transformedArray.reduce((acc, obj) => {
        if (obj?.DSBP_PMP_AWReadinessGateStatus === "LOCKED") {
          return acc + 1;
        }
        return acc;
      }, 0);

      setTotalNoOfPMPLocked(notOfPMPLocked);
    } else {
      setDsbpPmpData(null);
      setOriginalDsbpPmpData(null);
      setTotalNoOfPOA(0);
      setTotalNoOfPMP(0);
      setInitdsbpPmpData(null);
      setTotalNoOfPMPLocked(0);
      setTotalNoOfAddedProject(0);
    }
    setTotalNoOfDsbpId(resp?.length || 0);
    await dispatch(
      getDSBPDropdownData(projectData?.BU, projectData?.Region, ProjectID, userAccessPayLoad)
    );
    setSortData([])
    setTableLoader(false);
  }

  const fetchDataAfterFilter = async () => {
    const { resp, mappedInitiativeIDs, projectData } = await getDsbpPMPDetails(
      ProjectID,
      userAccessPayLoad
    );
    setProjectData(projectData);
    setListOfInitiativeId(mappedInitiativeIDs);

    if (!resp) {
      setDsbpPmpData(null);
      setInitdsbpPmpData(null);
      setOriginalDsbpPmpData(null);
    }
    if (resp && resp?.length !== 0) {
      const transformedArray = resp?.flatMap((item) =>
        item.DSBP_PMP_PIMaterialIDPage?.map((person) => ({
          DSBP_InitiativeID: item.DSBP_InitiativeID,
          ...person,
        }))
      );

      const flattenedArray = transformedArray.map((item) => {
        const newItem = { ...item };
        const FPCStagingPage = newItem.FPCStagingPage?.[0];
        const formulaCard = FPCStagingPage?.FormulaCardStagingPage?.[0];

        delete newItem.FPCStagingPage;

        return {
          ...newItem,
          ...FPCStagingPage,
          ...formulaCard,
        };
      });
      const newflatterArray = flattenedArray.map((item) => {
        const newItem = { ...item };
        delete newItem.FormulaCardStagingPage;
        return {
          ...newItem,
        };
      });
      setDsbpPmpData(_.cloneDeep(newflatterArray));
      setInitdsbpPmpData(_.cloneDeep(newflatterArray));
    }
  };

  useEffect(() => {
    updateDropdownList(listOfInitiativeId);
  }, [DropDownData]);

  const updateDropdownList = (selectedID) => {
    setListOfInitiativeId(selectedID);
    let listOfInitiativeId = selectedID.sort((a, b) => a - b);
    let { ArtworkAgilityTasks, AssignedListofDSBPIDs } = DropDownData?.DSBPDropdownData;
    let mappedListOfDSBPId = [];
    let unMappedListOfDSBPId = [];
    let alreadyAssignedListOfDSBPId = [];
    if (listOfInitiativeId && listOfInitiativeId?.length) {
      mappedListOfDSBPId =
        ArtworkAgilityTasks?.filter((item) => listOfInitiativeId.includes(item.InitiativeID))
          .map((item) => ({
            ...item,
            sequence: 1,
          }))
          .sort((a, b) => a.InitiativeID - b.InitiativeID) || [];
    }

    unMappedListOfDSBPId =
      ArtworkAgilityTasks?.filter((item) => !listOfInitiativeId?.includes(item.InitiativeID))
        .map((item) => ({
          ...item,
          sequence: 2,
        }))
        .sort((a, b) => a.InitiativeID - b.InitiativeID) || [];

    alreadyAssignedListOfDSBPId =
      AssignedListofDSBPIDs?.map((item) => ({
        ...item,
        sequence: 3,
      })).sort((a, b) => a.InitiativeID - b.InitiativeID) || [];

    let fullDropDownData = [
      ...mappedListOfDSBPId,
      ...unMappedListOfDSBPId,
      ...alreadyAssignedListOfDSBPId,
    ];
    setDropdownList(fullDropDownData);
    setFieldUpdated(!fieldUpdated);
  };

  const addDSBPIntoProject = async (InitiativeID, operation) => {
    setTableLoader(true);
    if (operation === "add") {
      let checkRes = await addDsbpToProject(ProjectID, InitiativeID);
    }
    if (operation === "delete") {
      let checkRes = await deleteDsbpFromProject(ProjectID, InitiativeID);
    }
    // fetch dsbp project data after delete / add
    await fetchData();
    setTableLoader(false);
    if (operation === "delete") {
      showBackgroundProcess(toast);
    }
  };

  const onSort = (column, direction, filterData) => {
    const dsbpPmpDataFinal = dsbpPmpData?.length > 0 ? dsbpPmpData : [];
    const sortedDataSet = AcpService.handleSortDataOnDemand(column, direction, filterData, filteredDsbpData, dsbpPmpDataFinal, "artworkalign" )
    setSortData([column, direction]);
    const finalSortedData = _.cloneDeep(sortedDataSet);
    localStorage.setItem("sortingDataArtworkAlignment", JSON.stringify(true));
    if (filteredDsbpData?.length || filterData?.length) {
      setFilteredDsbpData(finalSortedData);
      selectedColumnList.current = finalSortedData;
    } else {
      setDsbpPmpData(finalSortedData);
    }
  };

  const handleSelect = (item) => {
    if (selected?.some((element) => element.DSBP_PMP_PIMaterialID === item.DSBP_PMP_PIMaterialID)) {
      setSelected(selected.filter((i) => i.DSBP_PMP_PIMaterialID !== item.DSBP_PMP_PIMaterialID));
    } else {
      if (selected.length === 0) {
        const selectedList = [];
        selectedList.push(item);
        setSelected(selectedList);
      } else {
        setSelected([...selected, item]);
      }
    }
    if (selected?.length !== dsbpPmpData?.length || selected?.length !== filteredDsbpData?.length) {
      setSelectAllChecked(false);
    } else {
      setSelectAllChecked(true);
    }
  };

  // 3185 - disabled select all checkbox when neither project nor anytask is assigned to logged in user
  useEffect(() => {
    if (filteredDsbpData?.length || dsbpPmpData?.length) {
      const isProjectOwner =
        projectData?.Assignee === userInformation?.userid || accessType === "Write";
      const dataset = filteredDsbpData?.length ? filteredDsbpData : dsbpPmpData;
      const filteredDataSet = dataset?.some((item) => item?.Owner === userInformation?.userid);
      if (isProjectOwner || filteredDataSet) {
        setisSelectAllDisbaled(false);
      } else {
        setisSelectAllDisbaled(true);
        setSelectAllChecked(false);
      }
    }
  }, [filteredDsbpData, dsbpPmpData]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const dataSet = filteredDsbpData?.length ? filteredDsbpData : dsbpPmpData;
      updateSelectedforOwner(dataSet);
    } else {
      setSelectAllChecked(false);
      setSelected([]);
    }
  };
  // 3185 - update selected records based on logged in user and taskowner/project owner
  const updateSelectedforOwner = (dataset) => {
    const isProjectOwner =
      projectData?.Assignee === userInformation?.userid || accessType === "Write";
    if (isProjectOwner) {
      setSelected(dataset);
      setSelectAllChecked(true);
    } else {
      const filteredDataSet = dataset?.filter((item) => item?.Owner === userInformation?.userid);
      setSelected(filteredDataSet);
      setSelectAllChecked(true);
    }
  };

  const onSubmit = async () => {
    setLoader(true);
    setPageEdited(false);
    // if submit btn click then new data will come hence reseting pageedit as false
    if (addSavedData && addSavedData.length) {
      const updatedPmpDetails = { ArtworkAgilityPMPs: addSavedData };
      let submitDsbpResponse = await onSubmitDsbpAction(updatedPmpDetails);
      await fetchData();
      markClean();
      setPageEdited(false);
      if (submitDsbpResponse?.data?.pyMessage) showPyMessage(submitDsbpResponse?.data?.pyMessage);
    }
    setSavedData([]);
    setLoader(false);
    showBackgroundProcess(toast);
    setSelected([]); //Added for awm-3720 
  };

  const onActionSubmit = async (formData, data) => {
    markClean();
    setLoader(true);
    setPageEdited(false);
    // if onActionSubmit btn called then new data will come hence reseting pageedit as false
    let updatedData = {};
    let updatedDataList = [];
    let selectionData = data ? data : selected;

    if (formData?.POAPackageName !== undefined) {
      updatedDataList = selectionData?.map((pmpDetails) => {
        updatedData = {
          DSBP_InitiativeID: pmpDetails.DSBP_InitiativeID,
          DSBP_PMP_PIMaterialID: pmpDetails.DSBP_PMP_PIMaterialID,
        };
        return updatedData;
      });
    } else {
      if (formData === "AddToProject") {
        selectionData = selectionData?.filter((data) => data?.AWM_AddedToProject !== "Yes");
      }
      updatedDataList = selectionData?.map((pmpDetails) => {
        updatedData = {
          DSBP_InitiativeID: pmpDetails?.DSBP_InitiativeID,
          DSBP_PMP_PIMaterialID: pmpDetails?.DSBP_PMP_PIMaterialID,
          DSBP_PMP_PIMaterialNumber: pmpDetails?.DSBP_PMP_PIMaterialNumber,
          FK_AWMProjectID: ProjectID,
          AWM_AddedToProject: pmpDetails?.AWM_AddedToProject || "",
          AWM_AISE: pmpDetails?.AWM_AISE || "",
          AWM_AssemblyMechanism: pmpDetails?.AWM_AssemblyMechanism || "",
          AWM_Biocide: pmpDetails?.AWM_Biocide || "",
          AWM_GroupPMP: pmpDetails?.AWM_GroupPMP || "",
          AWM_Sellable: pmpDetails?.AWM_Sellable || "",
          ReasonforRejection: pmpDetails?.ReasonforRejection || "",
          RejectionComment: pmpDetails?.RejectionComment || "",
          Owner: pmpDetails?.Owner || "",
          Assignee: projectData?.Assignee,
          LoggedUser: userInformation?.userid,
        };
        if (formData === "AddToProject") {
          updatedData.AWM_AddedToProject = "Yes";
          setHandleYesAddToPRoject(false);
        }
        if (formData.AWM_AISE !== undefined) {
          updatedData.AWM_AISE = formData?.AWM_AISE;
        }
        if (formData?.AWM_AssemblyMechanism !== undefined) {
          updatedData.AWM_AssemblyMechanism = formData?.AWM_AssemblyMechanism;
        }
        if (formData?.AWM_Biocide !== undefined) {
          updatedData.AWM_Biocide = formData?.AWM_Biocide;
        }
        if (formData?.AWM_GroupPMP !== undefined) {
          updatedData.AWM_GroupPMP = formData?.AWM_GroupPMP;
        }
        if (formData?.ReasonforRejection !== undefined) {
          updatedData.AWM_AddedToProject = "Reject";
          updatedData.ReasonforRejection = formData?.ReasonforRejection;
        }
        if (formData?.RejectionComment !== undefined) {
          updatedData.RejectionComment = formData?.RejectionComment;
        }
        if (formData?.AWM_Sellable !== undefined) {
          updatedData.AWM_Sellable = formData?.AWM_Sellable;
        }
        if (formData?.Owner !== undefined) {
          updatedData.Owner = formData?.Owner?.Person;
        }
        setRejectDialog(false);
        setSelectedReason(false);
        return updatedData;
      });
    }
    let updatedPmpDetails = {};
    let submitDsbpResponse = {};
    if (formData === "CreatePOAA") {
      updatedPmpDetails = {
        ArtworkAgilityPage: {
          AWM_Project_ID: ProjectID,
          Assignee: assignee !== undefined || assignee !== "" ? assignee : projectData?.Assignee,
          POAPackageName: formData?.POAPackageName,
          LoggedUser: userInformation?.userid,
        },
        ArtworkAgilityPMPs: updatedDataList,
      };
      let res = await onSubmitCreatePOAA(updatedPmpDetails);
      setPoaaResponse(res?.some((item) => item.POACreationStatus?.includes("Failed")));
      //Added null check for awm-3721
      if (res?.[0]?.pyMessage) showPyMessage(res?.[0]?.pyMessage);
       if(res && res?.length)
        {
          setPoaaAcknowledgDialog(true);
        }
    } else {
      updatedPmpDetails = { ArtworkAgilityPMPs: updatedDataList };
      submitDsbpResponse = await onSubmitDsbpAction(updatedPmpDetails);
    }
    setActionDialog(false);
    dispatch(
      getDSBPDropdownData(projectData?.BU, projectData?.Region, ProjectID, userAccessPayLoad)
    );
    await fetchData();
    setPageEdited(false);
    markClean();
    setSelected([]);
    setSelectAllChecked(false);
    setLoader(false);
    if (submitDsbpResponse?.data?.pyMessage) showPyMessage(submitDsbpResponse?.data?.pyMessage);
    showBackgroundProcess(toast);
  };

  const resetTableData = () => {
    if (originalDsbpPmpData) {
      markClean();
      setSavedData([]);
      setPageEdited(false);
      const updatedFilteredData = overrideFilteredData(
        _.cloneDeep(originalDsbpPmpData),
        _.cloneDeep(filteredDsbpData)
      );
      if (filteredDsbpData?.length && sortData?.length) {
        setFilteredDsbpData(updatedFilteredData);
      } else if (filteredDsbpData?.length) {
        setFilteredDsbpData(updatedFilteredData);
      } else if (sortData?.length) {
        const updatedFilteredData = overrideFilteredData(
          _.cloneDeep(originalDsbpPmpData),
          _.cloneDeep(dsbpPmpData)
        );
        setInitdsbpPmpData(cloneDeep(updatedFilteredData));
        setDsbpPmpData(cloneDeep(updatedFilteredData));
      } else {
        setDsbpPmpData(cloneDeep(originalDsbpPmpData));
        setInitdsbpPmpData(cloneDeep(originalDsbpPmpData));
      }
    }
  };

  const handleCancel = () => {
    setLoader(true);
    setResetCustomInputs(true);
    resetTableData();
    setLoader(false);
    setSelected([]);
    setSelectAllChecked(false);
  };

  const handleCancelClick = () => {
    if (pageEdited) {
      setShowDialog(true);
    } else {
      handleCancel();
    }
  };

  const confirmNavigation = () => {
    setShowDialog(false);
    handleCancel();
  };

  const cancelNavigation = () => {
    setShowDialog(false);
  };
 //Function when reset to default is called
  const resetToPgDefault=()=>{
    setResetKey((prev) => prev + 1);
    findAndSortBuWiseColumnNames();
  }
  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      dsbpPmpData,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef = (selectedFieldsRef?.current || [])?.map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentArtworkAlignment");
    }
    if (filterArray.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        const filterData = AcpService.filterDataByfilters(
          dsbpPmpData,
          _selectedFieldsRefWithUniqueObjects,
          setConditionMatched,
          "ArtworkAlignment"
        );
        const convertedObj = AcpCommonService.convertArrayToObject(
          _selectedFieldsRefWithoutEmptyArray
        );
        const uniqueObj = AcpCommonService.getUniqueValuesObject(convertedObj);
        setSelectedFields(uniqueObj);
        if (filterData?.length && sortData?.length) {
          onSort(sortData[0], sortData[1], filterData);
        } else {
          setFilteredDsbpData(filterData);
          selectedColumnList.current = filterData;
        }
        if (selectAllChecked === true && filterData && filterData?.length) {
          // 3185 - update selected records based on logged in user and taskowner/project owner
          updateSelectedforOwner(filterData);
        } else if (selected && filterData) {
          const itemsInFiltered = selected?.some((item) => filterData?.includes(item));
          if (itemsInFiltered) {
            const newSelected = selected?.filter((item) => filterData?.includes(item));
            setSelected(newSelected);
          }
          setSelectAllChecked(false);
        }
      } else {
        setFilteredDsbpData([]);
        selectedColumnList.current = [];
        selectedFieldsRef.current = [];
        setSelectedFields({});
      }
    } else {
      setFilteredDsbpData([]);
      selectedColumnList.current = [];
      selectedFieldsRef.current = [];
      setSelectedFields({});
    }
  };

  const onClickClearFilter = () => {
    // let isBUHomeCare = false;
    // if (projectData?.BU === "Home Care") {
    //   isBUHomeCare = true;
    // }
    // buWiseSortedColumnNames.map((ele) => {
    //   if (ele) {
    //     ele["sortZtoA"] = false;
    //     ele["sortAtoZ"] = false;
    //     ele["freeze"] = false;
    //     ele["width"] = 250;
    //     ele["reorder"] = false;
    //   }
    // });
    // isBUHomeCare
    //   ? localStorage.setItem(
    //       "columnWidthDSBPArtworkHomeCare",
    //       JSON.stringify(buWiseSortedColumnNames)
    //     )
    //   : localStorage.setItem(
    //       "columnWidthDSBPArtworkBabyCare",
    //       JSON.stringify(buWiseSortedColumnNames)
    //     );
    // setBuWiseSortedColumnNames(buWiseSortedColumnNames);
    // setInitdsbpPmpData(_.cloneDeep(dsbpPmpData));
    selectedFieldsRef.current = [];
    setFieldUpdated(!fieldUpdated);
    // setTableRender(!tableRender);
    setDsbpPmpData(dsbpPmpData);
    setSelectAllChecked(false);
    setFilteredDsbpData(null);
    setResizeMode("expand");
    setSelectedFields({});
    setSelected([]);
    // setSortData([]);
  };

  const saveSettings = () => {
    localStorage.setItem("sortingDataArtworkAlignment", JSON.stringify(sortData));
  };

  const parseJSONSafely = (jsonString) => {
    try {
      return jsonString ? JSON.parse(jsonString) : null;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null; // Return null if parsing fails
    }
  };

  const clearColumnWiseFilter = () => {
    // let jsonFrozenItem = localStorage.getItem("frozenDataArtworkAlignment");
    // const frozenItem = parseJSONSafely(jsonFrozenItem);
     let _selectedColumnName = selectedColumnName;

    // if (frozenItem && frozenItem?.length && frozenItem?.includes(_selectedColumnName)) {
    //   const index = frozenItem?.indexOf(_selectedColumnName);
    //   frozenItem?.splice(index, 1);
    //   localStorage.setItem("frozenDataArtworkAlignment", JSON.stringify(frozenItem));
    // }
    const frozenCoulmns = parseJSONSafely(customizeViewFields)
      ? customizeViewFields?.freezedColumns?.fieldsData
      : [];
    if (frozenCoulmns?.includes(_selectedColumnName)) {
      const index = frozenCoulmns?.indexOf(_selectedColumnName);
      frozenCoulmns?.splice(index, 1);
      const availableColumnNamesData = parseJSONSafely(customizeViewFields)
        ? customizeViewFields?.availableFields?.fieldsData
        : [];
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = parseJSONSafely(customizeViewFields)
        ? customizeViewFields?.selectedFields?.fieldsData?.filter((el) => el !== "SelectAll")
        : [];
      let selectedFieldsNamesData = [...selectedFieldsNames, _selectedColumnName];
      const customizeViewFieldsUpdated = formCustomizeObject(
        availableColumnNamesData,
        freezedColumnsNamesData,
        selectedFieldsNamesData
      );
      setCustomizeViewFields(JSON.stringify(customizeViewFieldsUpdated));
    }

    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortingDataArtworkAlignment");
      //setClearAllFilter(true);
      setSortData([]);
    }
    const selectedFields2 = _.cloneDeep(selectedFields);
    const uniqueObj = AcpCommonService.getUniqueValuesObject(selectedFields2);
    const _selectedFields = AcpService.emptyProperty(uniqueObj, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRef.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentDependancyMapping");
    }
    let data = [...(dsbpPmpData || [])];
    Object.keys(__selectedFields).forEach((el) => {
      if (__selectedFields[el]?.length) {
        data = data.filter((ele) => __selectedFields[el].includes(ele[el]));
      }
    });
    if (data?.length && __selectedFields && Object.keys(__selectedFields)?.length) {
      const customColumn = sortData?.length && sortData?.[0] !== _selectedColumnName ? sortData?.[0] : "DSBP_InitiativeID";
      const direction = sortData?.length && sortData?.[0] !== _selectedColumnName ? sortData?.[1] : "asc";
      // if there is sort and filter both applied on seperate columns,
      // then on clearing filtercolumn wise, we need data should be sorted but
      // with DSBP_InitiativeID as it remains on initial page load
      data = AcpService.handleSortDataOnDemand(customColumn, direction, data, filteredDsbpData, dsbpPmpData, "artworkalign");
      setFilteredDsbpData(data);
    } else if (sortData?.length && sortData[0] !== _selectedColumnName) {
      selectedColumnList.current = dsbpPmpData;
      setFilteredDsbpData([]);
      onSort(sortData[0], sortData[1], dsbpPmpData);
    } else {
      // const finalInitData = _.cloneDeep(originalDsbpPmpData);
      const finalInitData = _.cloneDeep(dsbpPmpData);
      selectedColumnList.current = finalInitData;
      selectedFieldsRef.current = [];
      setDsbpPmpData(finalInitData);
      setFilteredDsbpData([]);
      setSelectedFields({});
    }
    setConditionMatched(false);
  };

  let checkLength = addSavedData.length;
  let formValid = "";
  if (!checkLength || !selectedOwnerMatch) {
    formValid = true;
  } else {
    formValid = false;
  }

  const FooterComponent = () => (
    <Footer
      className="footer"
      selected={selected}
      totalObjects={filteredDsbpData?.length ? filteredDsbpData?.length : dsbpPmpData?.length}
      isSelected={true}
    />
  );

  return (
    <div className="artwork-dsbp myProjectAnddAllProjectList data-table-checkbox-column">
      <Toast ref={toast} />
      {loader || totalNoOfDsbpId === null ? (
        <LoadingOverlay active={true} spinner text=""  className="overlay-loader"/>
      ) : accessState?.checkReadWriteAccess ? (
        <>
          <ArtworkHeader
            accessType={accessType}
            breadcrumb={breadcrumb}
            headerName={headerName}
            selected={selected}
            pageEdited={pageEdited}
            setPageEdited={setPageEdited}
            onActionSubmit={onActionSubmit}
            label="Artwork Scope Alignment"
            hyperLink={`/${currentUrlBasePage}/projectPlan/${ProjectID}`}
            actionDialog={actionDialog}
            setActionDialog={setActionDialog}
            isProjectComplete={projectState === "Complete"}
            setFieldUpdated={setFieldUpdated}
            fieldUpdated={fieldUpdated}
            buWiseSortedColumnNames={buWiseSortedColumnNames}
            setBuWiseSortedColumnNames={setBuWiseSortedColumnNames}
            setDsbpPmpData={setDsbpPmpData}
            dsbpPmpData={dsbpPmpData}
            setTableRender={setTableRender}
            tableRender={tableRender}
            selectedProjectDetails={selectedProjectDetails}
            customizeViewFields={customizeViewFields}
            setCustomizeViewFields={setCustomizeViewFields}
            setLoader={setLoader}
            onClickClearFilter={onClickClearFilter}
            filteredDsbpDataLength={filteredDsbpData?.length}
            projectData={projectData}
            onHideReset={handleCancel}
            filename="Artwork_Alignment_Records"
            columnNames={columnNames}
            filteredDsbpData={filteredDsbpData}
            ownerList={ownerList}
            Assignee={assignee ? assignee : projectData?.Assignee}
            totalNumRecords={
              filteredDsbpData?.length ? filteredDsbpData?.length : dsbpPmpData?.length
            }
            setResetKey={setResetKey}
            pgDefaultColumns={pgDefaultColumns}
            resetToPgDefault={resetToPgDefault}
          />
          <SelectDsbpId
            onHideReset={handleCancel}
            accessType={accessType}
            dropdownlist={dropdownlist}
            pageEdited={pageEdited}
            setPageEdited={setPageEdited}
            isProjectComplete={projectState === "Complete"}
            addDSBPIntoProject={addDSBPIntoProject}
            totalNoOfDsbpId={totalNoOfDsbpId}
            totalNoOfPMP={totalNoOfPMP}
            totalNoOfPOA={totalNoOfPOA}
            totalNoOfAddedProject={totalNoOfAddedProject}
            totalNoOfPMPLocked={totalNoOfPMPLocked}
            cicMappedIds={cicMappedIds}
            listOfInitiativeId={listOfInitiativeId}
            mappedPOAS={mappedPOAS}
            mappedPOARequestedIds={mappedPOARequestedIds}
            updateDropdownList={updateDropdownList}
            projectData={projectData}
            tableLoader={tableLoader}
            initiativeIdsWithPoaCreated={initiativeIdsWithPoaCreated}
          />
          {tableLoader ? (
            <LoadingOverlay active={true} spinner text="" className="overlay-loader" />
          ) : (
            <AgilityList
              accessType={accessType}
              selected={selected}
              pageEdited={pageEdited}
              setPageEdited={setPageEdited}
              setSelected={setSelected}
              selectAllChecked={selectAllChecked}
              handleSelect={handleSelect}
              handleSelectAll={handleSelectAll}
              dsbpPmpData={dsbpPmpData}
              initdsbpPmpData={initdsbpPmpData}
              filteredDsbpData={filteredDsbpData}
              clearColumnWiseFilter={clearColumnWiseFilter}
              onSort={onSort}
              onGlobalFilterChange={onGlobalFilterChange}
              selectedFields={selectedFields}
              setDsbpPmpData={setDsbpPmpData}
              onActionSubmit={onActionSubmit}
              buWiseSortedColumnNames={buWiseSortedColumnNames}
              setFieldUpdated={setFieldUpdated}
              fieldUpdated={fieldUpdated}
              setSavedData={setSavedData}
              addSavedData={addSavedData}
              Assignee={projectData?.Assignee}
              handleYesAddToPRoject={handleYesAddToPRoject}
              setHandleYesAddToPRoject={setHandleYesAddToPRoject}
              rejectDialog={rejectDialog}
              setRejectDialog={setRejectDialog}
              tableRender={tableRender}
              setTableRender={setTableRender}
              customizeViewFields={customizeViewFields}
              setCustomizeViewFields={setCustomizeViewFields}
              selectedReason={selectedReason}
              setSelectedReason={setSelectedReason}
              projectData={projectData}
              ownerList={ownerList}
              ownerMatch={setSelectedOwnerMatch}
              resizeMode={resizeMode}
              setResizeMode={setResizeMode}
              onClickClearFilter={onClickClearFilter}
              sortData={sortData}
              setSortData={setSortData}
              saveSettings={saveSettings}
              selectedColumnName={selectedColumnName}
              setSelectedColumnName={setSelectedColumnName}
              conditionMatched={conditionMatched}
              clearAllFilter={clearAllFilter}
              setFilteredDsbpData={setFilteredDsbpData}
              isProjectComplete={projectState === "Complete"}
              isSelectAllDisabled={isSelectAllDisabled}
              resetKey={resetKey}
              resetCustomInputs={resetCustomInputs}
              setResetCustomInputs={setResetCustomInputs}
              selectedFieldsRef={selectedFieldsRef}
              Status={accessState?.status}
              setEditingInProgress={setEditingInProgress}
            />
          )}
          <FooterButtons
            accessType={accessType}
            handleCancel={handleCancelClick}
            hideSaveButton={true}
            onSubmit={onSubmit}
            formValid={!dsbpPmpData?.length || !pageEdited || editingInProgress}
            // formValid={formValid}
            checkReadWriteAccess={true}
            submitAndSave="Save"
            Assignee={projectData?.Assignee}
            FooterComponent={!loader && dsbpPmpData && FooterComponent}
            isCancelDisabled={!dsbpPmpData?.length || (!pageEdited && !selected?.length)}
          />
        </>
      ) : (
        AccessGroup && AccessGroup.length && <UnAuthorizedComponent nowrapper={true} />
      )}
      {poaaAcknowledgDialog && (
        <DsbpCommonPopup
          actionHeader="POAA Acknowledgement"
          dasbpDialog={poaaAcknowledgDialog}
          setDasbpDialog={setPoaaAcknowledgDialog}
          poaaResponse={poaaResponse}
          okButtonShow={true}
          deleteButtonShow={false}
          showCancel={true}
          submitButtonShow={false}
          yesButtonShow={true}
          disconnectButtonShow={true}
        >
          {poaaResponse ? (
            <>
              POA Creation failed, your request was not received by Enovia and POA will not be
              created. Please try again, if problem persists, please open a ticket.
            </>
          ) : (
            <>POA Creation request submitted to Enovia.</>
          )}
        </DsbpCommonPopup>
      )}
      {/* UnsavedChangesDialog popup*/}
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </div>
  );
};

export default ArtworkAlignment;
