import "../index.scss";
import _ ,{isEmpty} from "lodash";
import { Panel } from "primereact/panel";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Link, useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import CustomizeView from "../../common/CustomizeView";
import { AcpService } from "../../../service/ACPService";
import { CapacityToDemandAlertsExternal } from "../constant";
import { ProjectService } from "../../../service/PegaService";
import ACPBookingHeaderColumn from "./ACPDashBoardHeaderColumn";
import React, { useEffect, useState, useCallback } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";
import { acpBookingFrozenColumn } from "../../../Utils/acpBookingFrozenColumn";
import { multiFilterChange } from "../../../utils";
import { addGroupInfo, sortByGroup } from "../../../service/DashboardService";
import { AcpCommonService } from "../../../service/AcpCommonService";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import { onACPColumnResizeEnd,storeACPReorderedColumns } from "../utils";


function PanelBottom({
  ref,
  reset,
  loader,
  tabName,
  Headers,
  isSearch,
  rowGroups,
  fetchData,
  editedRow,
  expandAll,
  setLoader,
  setHeaders,
  actionType,
  isSearchSet,
  selectedRows,
  setExpandAll,
  setEditedRow,
  setActionFlag,
  allEditedRows,
  setActionType,
  setBookingData,
  clearAllFilter,
  setSelectedRows,
  dashboardAlerts,
  setAllEditedRows,
  bookingTableData,
  setClearAllFilter,
  setNoOfActiveTasks,
  updatedBookingData,
  ShowCustomizedView,
  bookingTableFilters,
  setBookingTableData,
  customizeViewFields,
  checkReadWriteAccess,
  editedRowPanelBottom,
  setShowCustomizedView,
  setCustomizeViewFields,
  selectedRowsPanelBottom,
  setEditedRowPanelBottom,
  allEditedRowsPanelBottom,
  setSelectedRowsPanelBottom,
  setAllEditedRowsPanelBottom,
  selectedDropdownValue,
  resetKey,
  dashboardPageEdited,
  setDashboardPageEdited,
}) {
  const dt = useRef(null);
  const op = useRef(null);
  const filterArray = useRef([]);
  const panelRef2 = useRef(null);
  const navigate = useNavigate();
  const colAllSelected = useRef([]);
  const selectedFieldsRef = useRef([]);
  const selectedColumnList = useRef([]);
  const [filters, setFilters] = useState([]);
  const [selected, setSelected] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ColumnsList2, setColumnsList2] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [tableRender, setTableRender] = useState(false);
  const [groupWiseData, setGroupWiseData] = useState([]);
  const [selectedFields, setSelectedFields] = useState({});
  const [allColumnNames, setAllColumnNames] = useState([]);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [initialPegaData, setInitialPegaData] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [projectColumnName, setProjectColumnNames] = useState([]);
  const [isReorderedColumn, setReorderedColumn] = useState(false);
  const [conditionMatched, setConditionMatched] = useState(false);
  const [frozenCoulmns, setFrozenColumn] = useState(["SelectAll"]);
  const [availableColumnNames, setAvailableColumnNames] = useState([]);
  const [selectedColumnName, setSelectedColumnName] = useState("SelectAll");
  const baseUrl = "/capacityManagement/planning";
  const baseUrl2 = "/capacityManagement/booking";
  const [initialTableData, setInitialTableData] = useState([]);

  const [panelBottomLoader, setPanelBottomLoader] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const [showDialog, setShowDialog] = useState(false);
  const [tempOptions, setTempOptions] = useState({});
  const [tempField, setTempField] = useState("");

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      tableData?.map((el) => (el.isSelected = true));
      setSelectAllChecked(true);
      setSelected(tableData);
    } else {
      tableData?.map((el) => (el.isSelected = false));
      setSelectAllChecked(false);
      setSelected([]);
    }
  };

  const handleSelect = useCallback(
    (item) => {
      if (selected?.includes(item)) {
        item.isSelected = false;
        setSelectAllChecked(false);
        setSelected(selected.filter((i) => i !== item));
      } else {
        item.isSelected = true;
        if (selected.length === 0) {
          const selectedList = [];
          selectedList.push(item);
          setSelected(selectedList);
        } else {
          const selectedList = [...selected, item];
          setSelectAllChecked(tableData?.length === selectedList?.length);
          setSelected([...selectedList]);
        }
      }
    },
    [selected]
  );

  useEffect(() => {
    const columnNames2 = ProjectService.getAvailableSubDashboardColumnNames();
    localStorage.setItem("NewStartDatesSaved", JSON.stringify([]));
    setColumnsList2(columnNames2);
  }, []);

  useEffect(() => {
    if (customizeViewFields && JSON.parse(customizeViewFields)?.selectedFields?.fieldsData) {
      const updatedHeaders = JSON?.parse(customizeViewFields)?.selectedFields?.fieldsData;
      const latestHeaders = updatedHeaders.filter((item) => item !== "SelectAll");
      const frozenData = JSON?.parse(customizeViewFields)?.freezedColumns?.fieldsData;
      if (!frozenData.includes("SelectAll")) {
        frozenData.unshift("SelectAll");
      }
      setFrozenColumn(frozenData);
      const finalColumn = [...frozenData, ...latestHeaders];
      setProjectColumnNames(finalColumn);

      setProjectFrozen(!ProjectFrozen);
      projectColumnName &&
        acpBookingFrozenColumn(
          frozenData,
          setReorderedColumn,
          setProjectColumnNames,
          setTableRender,
          op,
          finalColumn,
          "booking"
        );
      setTableData(tableData);
      // setPanelBottomLoader(true);
      setTimeout(() => {
        headerTemplate(false);
        setPanelBottomLoader(false);
      }, 1000);
    } else {
      const columnNames = ProjectService.getAvailableBookingColumnNames();
      if (!columnNames.includes("SelectAll")) {
        columnNames.unshift("SelectAll");
      }
      setProjectColumnNames(columnNames);
    }
  }, [customizeViewFields]);

  useEffect(() => {
    let localDashboardAllColumns = JSON.parse(localStorage.getItem("ACPSubDasboardAllColumnNames"));
    const availableBookingColumnNames = ProjectService.getAvailableSubDashboardColumnNames();
    let availableBookingColumnNamesUpdated =[];
    if(isEmpty(localDashboardAllColumns)){
    availableBookingColumnNamesUpdated = availableBookingColumnNames.map((el) => {
      return {
        width: 250,
        Sequence: "",
        freeze: false,
        Field_Name: el,
        reorder: false,
        Column_Name: el,
        sortAtoZ: false,
        sortZtoA: false,
        Attribute_Type: "Free Text",
      };
    });} else{
      availableBookingColumnNamesUpdated=localDashboardAllColumns; 
    }
    const bookingColumnNames = ProjectService.getBookingColumnNames();
    const bookingColumnNamesUpdated = bookingColumnNames.map((el) => {
      return {
        Field_Name: el,
      };
    });
    let availableFieldsToSelect = bookingColumnNamesUpdated.filter(function (objFromA) {
      return !availableBookingColumnNamesUpdated.find(function (objFromB) {
        return objFromA.Field_Name === objFromB.Field_Name;
      });
    });

    const updatedHeaders = AcpService.reorderColumns(availableBookingColumnNames).filter(
      (el) => el !== "SelectAll"
    );

    setHeaders(updatedHeaders);
    setAvailableColumnNames(availableFieldsToSelect);
    setAllColumnNames(availableBookingColumnNamesUpdated);
    if (tableData?.length !== 0 && loader) {
      (async () => {
        try {
          const ProjectData = _.cloneDeep(tableData);
          if (ProjectData.length) {
            if (!initialPegaData) {
              setInitialPegaData(ProjectData);
            }
            let filteredPegaDataJson = localStorage.getItem("bookingColumnWiseFilterData");
            const filteredPegaData = JSON.parse(filteredPegaDataJson);
            if (filteredPegaData && filteredPegaData.length) {
              setTableData(ProjectData);
              setFilters(filteredPegaData);
              setSelectedFields(filteredPegaData);
            } else {
              setTableData(ProjectData);
            }
          }

          let columnNamesJson = localStorage.getItem("bookingNamesbookings");
          const columnNames = JSON.parse(columnNamesJson);

          if (columnNames != null && columnNames.length) {
            setProjectColumnNames(columnNames);
          } else {
            if (
              customizeViewFields &&
              JSON.parse(customizeViewFields)?.selectedFields?.fieldsData
            ) {
              const updatedHeaders = JSON?.parse(customizeViewFields)?.selectedFields?.fieldsData;
              const latestHeaders = updatedHeaders.filter((item) => item !== "SelectAll");
              const frozenData = JSON?.parse(customizeViewFields)?.freezedColumns?.fieldsData;
              if (!frozenData.includes("SelectAll")) {
                frozenData.push("SelectAll");
              }
              const updatedColumn = [...frozenData, ...latestHeaders];
              const finalColumn = updatedColumn.filter((item) => {
                return item.toLowerCase() !== "selectall";
              });
              const finalColumn1 = [...finalColumn];
              if (!finalColumn.includes("SelectAll")) {
                finalColumn1.unshift("SelectAll");
              }
              // setProjectColumnNames(updatedColumn);
              setProjectFrozen(!ProjectFrozen);
              projectColumnName &&
                acpBookingFrozenColumn(
                  frozenCoulmns,
                  setReorderedColumn,
                  setProjectColumnNames,
                  setTableRender,
                  op,
                  finalColumn1,
                  "booking"
                );
            } else {
              const columnNames = ProjectService.getAvailableBookingColumnNames();
              if (!columnNames.includes("SelectAll")) {
                columnNames.unshift("SelectAll");
              }
              // setProjectColumnNames(columnNames);
            }
          }
          let jsonSortingData1 = localStorage.getItem("subDashboardSortingData");
          const sortingData = JSON.parse(jsonSortingData1);

          if (sortingData && sortingData.length) {
             const sortField = sortData[0];  // field
             const sortOrder = sortData[1];  // 'desc' or 'asc'
          
            // // Ensure sortField exists in ProjectData objects
            // const sortedData = [...ProjectData].sort((a, b) => {
            //   // Check if sortOrder is descending or ascending
            //   if (sortOrder === "desc") {
            //     return a[sortField] > b[sortField] ? -1 : 1;  // Descending order
            //   } else {
            //     return a[sortField] > b[sortField] ? 1 : -1;  // Ascending order
            //   }
            // });
            // console.log(sortedData,"sortedDatasortedDatasortedData")
            // setTableData(sortedData);
            onSort(sortField,sortOrder)
          }

          let jsonFrozenrData1 = localStorage.getItem("bookingFrozenData");
          const frozenData = JSON.parse(jsonFrozenrData1);
          if (frozenData && frozenData.length) {
            setFrozenColumn(frozenData);
          }
        } catch (err) {
          console.log("error", err);
        }
      })();
    }
  }, [tableData]);

  useEffect(() => {
    let toBeExpanded = [];
    if (expandAll === true) {
      panelRef2?.current?.expand();
      (rowGroups || [])?.forEach((grp) => {
        if (tableData?.length) {
          let filteredData = tableData?.filter((data) => {
            return data.Group === grp.id;
          });
          filteredData?.length && toBeExpanded.push(filteredData[0]);
        }
      });
      setExpandedRows(toBeExpanded);
    } else if (expandAll === false) {
      panelRef2?.current?.collapse();
      setExpandedRows([]);
    }
  }, [expandAll, tableData, bookingTableFilters]);

  useEffect(() => {
    let temp = [];
    if (tableData?.length) {
      tableData?.forEach((data) => {
        temp.push(data.Group);
      });
    }
    const currentRowGroups = _.uniq(temp);
    if (expandedRows?.length < 1 && expandAll) {
      setExpandAll(true);
    } else if (expandedRows?.length < 1) {
      setExpandAll(false);
    } else if (expandedRows?.length === currentRowGroups?.length) {
      setExpandAll(true);
    } else {
      setExpandAll(true);
    }
  }, [expandedRows]);

  useEffect(() => {
    setAllEditedRowsPanelBottom([]);
    setSelectedRowsPanelBottom([]);
    filterTable();
  }, [reset]);

  useEffect(() => {
    setInitialPegaData(_.cloneDeep(dashboardAlerts));
    filterTable();
    if (
      Object.keys(selectedDropdownValue)?.length > 0 &&
      Object.entries(dashboardAlerts)?.length === 0
    ) {
      setPanelBottomLoader(true);
      setTimeout(()=>{
        setPanelBottomLoader(false)
      },5000);
    } else if (Object.entries(dashboardAlerts)?.length) {
      setPanelBottomLoader(false);
    }
  }, [bookingTableFilters, dashboardAlerts]);

  useEffect(() => {
    setSelectedRowsPanelBottom(selected);
    if (setActionFlag) {
      setActionFlag(selected);
    } else {
      setActionFlag(!selected);
    }
  }, [selected]);

  useEffect(() => {
    filterTable();
  }, [updatedBookingData]);

  const filterTable = () => {
    const tbldata = _.cloneDeep(dashboardAlerts);
    const modifiedTbldata = addGroupInfo(tbldata);
    if (modifiedTbldata?.length) {
      const updatedData = modifiedTbldata?.map((el) => {
        if (el.Group === "BookingAlerts2weeks") {
          el.DateRange = [el?.StartDate];
        } else {
          el.DateRange = [el?.StartDate, el?.EndDate];
        }
        el.Task = el?.TaskName;
        el.Studio = el?.Supplier;
        el.Capacity = el?.Capacity === 0 ? "0" : el?.Capacity;
        return el;
      });

      const finalData = sortByGroup(updatedData);
      const filteredByDate = finalData?.map((el) => {
        let Date = el?.DateRange?.[0] && AcpService.convertToShortDateFormat(el?.DateRange[0]);
        if (el?.DateRange?.[1]) {
          Date = Date + " to " + AcpService.convertToShortDateFormat(el?.DateRange[1]);
        }
        return {
          ...el,
          Date: Date,
        };
      });
      const activeCount = filteredByDate?.filter((item) => item?.Status === "Active")?.length;
      const GroupedData = AcpService.countTasksByGroup(filteredByDate);
      const SortedData = AcpService.addRefinedOrder(GroupedData);
      setNoOfActiveTasks && setNoOfActiveTasks(activeCount);
      setGroupWiseData(SortedData);
      setInitialTableData(filteredByDate);
      setTableData(filteredByDate);
    } else {
      setTableData([]);
      setInitialTableData([]);
    }
  };

  const handleToggleExpand = (data) => {
    let flag = false;
    let _local = [];
    if (expandedRows?.length === 0) {
      setExpandedRows([data]);
    }
    expandedRows?.forEach((el) => {
      if (el.Group === data.Group) {
        _local = expandedRows.filter((el) => el.Group !== data.Group);
        flag = true;
      }
    });
    if (flag) setExpandedRows(_local);
    else setExpandedRows([...expandedRows, data]);
  };

  const headerTemplate = (data) => {
    if (!data) return;
    const expanded = expandedRows?.length;
    const row = document.querySelectorAll("tr.p-rowgroup-header");
    if (row && ColumnsList2?.length > 6) {
      document
        .querySelectorAll(`tr > td[colspan="${ColumnsList2?.length}"`)
        .forEach((td) => td.setAttribute("colspan", 3));

      const finalLength = ColumnsList2?.length - 3;

      for (let i = 0; i < row.length; i++) {
        let td1 = document.createElement("td");
        td1.setAttribute("style", "border:0");
        td1.setAttribute("id", `row_${i}`);
        td1.setAttribute("colspan", finalLength);
        row[i].append(td1);
      }
      // for (let i = 0; i < row?.length; i++) {
      //   window.$(`tr > td[id="row_${i}"]`).not(":first").remove();
      // }
      for (let i = 0; i < row?.length; i++) {
        let cells = document.querySelectorAll(`tr > td[id="row_${i}"]`);
        cells?.forEach((cell, index) => {
          if (index !== 0) {
            cell.remove();
          }
        });
      }
    }

    let rowgrp = rowGroups?.filter((obj) => obj.id === data.Group);
    const GroupedData = tableData?.filter((el) => el.GroupOrder === rowgrp?.[0]?.order);
    const position = Object.values(groupWiseData)?.filter((el) => el.key === data.Group)?.[0]
      ?.refinedOrder;

    const isIncluded = expandedRows?.some((item) => item?.Group === rowgrp?.[0]?.id);
    const iconClassName = `p-treetable-toggler-icon pi pi-fw ${isIncluded ? "pi-angle-up" : "pi-angle-down"
      }`;

    return (
      <React.Fragment>
        <div className="group-wrapper">
          <div
            onClick={() => handleToggleExpand(data)}
            className={`group-header vertical-align-middle ml-2 font-bold line-height-3 ${rowgrp?.[0]?.name} top-${position}`}
          >
            <span>
              <span>{rowgrp?.[0]?.name}</span>
              <span className="group-length">({GroupedData?.length})</span>
            </span>
            <span className="p-treetable-toggler p-link">
              <span className={iconClassName} aria-hidden="true"></span>
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
      const frozenCoulmns1 = frozenCoulmns.filter((item) => item !== "SelectAll");
      if (!frozenCoulmns1.includes("SelectAll")) {
        frozenCoulmns1.push("SelectAll");
      }
      setFrozenColumn(frozenCoulmns1);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = JSON.parse(
        customizeViewFields
      )?.selectedFields?.fieldsData?.filter((el) => el !== "SelectAll");
      setFrozenColumn(freezedColumnsNamesData);
      let selectedFieldsNamesData = [...selectedFieldsNames, name];
      const finalData = {
        availableFields: {
          fieldsData: availableColumnNames,
          id: "availableFields",
          title: "Available Fields",
        },
        freezedColumns: {
          fieldsData: freezedColumnsNamesData,
          id: "freezedColumns",
          title: "Freezed Fields",
        },
        selectedFields: {
          fieldsData: selectedFieldsNamesData,
          id: "selectedFields",
          title: "Selected Fields",
        },
      };
      setCustomizeViewFields(JSON.stringify(finalData));
      localStorage.setItem("customizeViewFieldsBooking", JSON.stringify(finalData));
    }
  };

  const handleTaskName = (options, field) => {
    navigate(
      `${
        options?.Group === "BookingAlerts2weeks" ? baseUrl2 : baseUrl
      }?TaskType=${encodeURIComponent(options?.[field])}&Supplier=${encodeURIComponent(
        options?.["Studio"]
      )}&StartDate=${encodeURIComponent(options?.["DateRange"]?.[0])}&EndDate=${
        options?.["DateRange"]?.[1]
          ? encodeURIComponent(options?.["DateRange"]?.[1])
          : encodeURIComponent(options?.["DateRange"]?.[0])
      }&Demand=${encodeURIComponent(options?.["Demand"])}&Capacity=${encodeURIComponent(
        options?.["Capacity"]
      )}&DateType=${encodeURIComponent("Daily")}`
    );
  };

  const handleTaskNameClick = (options, field) =>{
    if(dashboardPageEdited){
      setTempOptions(options);
      setTempField(field);
      setShowDialog(true);
    }else{
      handleTaskName(options, field);
    }
  }

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  }
  // by doing setShowDialog(false) and will call handleUserNotifications ;
  const confirmNavigation = () => {
    handleTaskName(tempOptions,tempField);
    setDashboardPageEdited(false);
    setTempOptions({});
    setTempField("");
    markClean();
    setShowDialog(false);
  }

  const fullKitReadinessBody = (options, rowData) => {
    let field = rowData.field;
    return (
      <>
        {/* {field === "SelectAll" && (
          <div className="flex align-items-center">
            <input
              type="checkbox"
              checked={selected?.includes(options)}
              onChange={() => handleSelect(options)}
              className="p-checkbox-box p-highlight"
              disabled={options?.RequestStatus === "Awaiting Response"}
            />
          </div>
        )} */}
        {field === "Task" && (
          <div
            className={`task-link`}
            onClick={()=>handleTaskNameClick(options, field)}
          >
            {options[field]}
          </div>
        )}
        {field === "Studio" && options["Studio"]}
        {field === "Demand" && options["Demand"]}
        {field === "Capacity" && options["Capacity"]}
        {field === "Date" && options[field]}

        {field !== "Task" &&
          field !== "Date" &&
          field !== "Studio" &&
          field !== "Demand" &&
          field !== "Capacity" &&
          field !== "SelectAll" &&
          field !== "StartDate" &&
          options[field]}
      </>
    );
  };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    let _options = options;
    if (options === "ProjectID") _options = "AWMProjectID";
    if (options === "TaskDuration") _options = "Duration";
    if (options === "Art@PrinterDate") _options = "EstimatedAWPrinter";
    setSelectedColumnName(_options);
  };
  const onColumnResizeEnd = (event) => {
       const _allColumns = onACPColumnResizeEnd(event,"ACPSubDasboardAllColumnNames",allColumnNames)
       setAllColumnNames(_allColumns)
  
    };
    const storeReorderedColumns = (e) => {
       const projectColumnName=storeACPReorderedColumns(e,"ACPSubDasboardAllColumnNames",allColumnNames)
       setAllColumnNames(projectColumnName);
    };
 
  const onSort = (column, direction, filterData) => {
    const newArray = [];
    const sortedData = AcpService.groupBy(
      filterData?.length ? filterData : filters?.length ? filters : tableData,
      "Group"
    );
    if (column === "Date") {
      for (let key in sortedData) {
        const sortedTasksAscending = AcpCommonService.sortTasksByDate(sortedData[key], direction);
        newArray.push(sortedTasksAscending);
      }
    } else {
      for (const [key, value] of Object.entries(sortedData)) {
        const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(value, column, direction);
        newArray.push(sortedDataSet);
      }
    }
    let updatedData = newArray?.flat();
    setSortData([column, direction]);
    localStorage.setItem("sortedColumnDashboardPanelBottom", JSON.stringify(true));
    if (filterData?.length || filters?.length) {
      setFilters(updatedData);
      selectedColumnList.current = updatedData;
    } else {
      setTableData(updatedData);
    }
    localStorage.setItem("subDashboardSortingData", JSON.stringify(updatedData));

  };

  const saveSettings = () => {
    if (frozenCoulmns?.includes(selectedColumnName)) {
      const availableColumnNamesData = JSON.parse(customizeViewFields)?.availableFields?.fieldsData;
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = JSON.parse(customizeViewFields)
        ?.selectedFields?.fieldsData?.filter((el) => el !== selectedColumnName)
        ?.filter((el) => el != "SelectAll");
      let selectedFieldsNamesData = selectedFieldsNames?.filter(function (objFromA) {
        return !frozenCoulmns?.find(function (objFromB) {
          return objFromA === objFromB;
        });
      });

      const selectedData = allColumnNames
        ?.map((el) => el.Field_Name)
        ?.filter((el) => el !== selectedColumnName)
        ?.filter((el) => el !== "SelectAll")
        ?.filter(function (objFromA) {
          return !frozenCoulmns?.find(function (objFromB) {
            return objFromA === objFromB;
          });
        });
      setFrozenColumn(freezedColumnsNamesData);
      setAvailableColumnNames(availableColumnNames);

      const finalData = {
        availableFields: {
          fieldsData:
            availableColumnNames?.map((el) => el.Field_Name) || availableColumnNamesData || [],
          id: "availableFields",
          title: "Available Fields",
        },
        freezedColumns: {
          fieldsData: freezedColumnsNamesData,
          id: "freezedColumns",
          title: "Freezed Fields",
        },
        selectedFields: {
          fieldsData: selectedData || selectedFieldsNamesData,
          id: "selectedFields",
          title: "Selected Fields",
        },
      };
      setCustomizeViewFields(JSON.stringify(finalData));
      localStorage.setItem("customizeViewFieldsBooking", JSON.stringify(finalData));
    }
  };

  const clearColumnWiseFilter = () => {
    let jsonFrozenItem = localStorage.getItem("bookingColumnFrozenData");
    const frozenItem = JSON.parse(jsonFrozenItem);
    let _selectedColumnName = selectedColumnName;

    if (frozenItem && frozenItem?.length && frozenItem.includes(_selectedColumnName)) {
      const index = frozenItem.indexOf(_selectedColumnName);
      frozenItem.splice(index, 1);
      setFrozenColumn(frozenItem);
    }
    if (frozenCoulmns.includes(_selectedColumnName)) {
      const index = frozenCoulmns.indexOf(_selectedColumnName);
      frozenCoulmns.splice(index, 1);
      setFrozenColumn(frozenCoulmns);
      setProjectFrozen(!ProjectFrozen);
    }
    const selectedFields2 = _.cloneDeep(selectedFields);
    const _selectedFields = AcpService.emptyProperty(selectedFields2, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRef.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      setActionType(!actionType);
      localStorage.removeItem("_selectedFieldsRefCurrentDashboardPanelBottom");
    }
    let data = [...(tableData || [])];
    Object.keys(__selectedFields).forEach((el) => {
      if (__selectedFields[el]?.length) {
        data = tableData.filter((ele) => __selectedFields[el].includes(ele[el]));
      }
    });
    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortedColumnDashboardPanelBottom");
      setSortData([]);
      if (Object.keys(__selectedFields)?.length === 0) {
        setFilters([]);
        // setClearAllFilter(true);
        selectedFieldsRef.current = [];
        setTableData(initialTableData);
        selectedColumnList.current = initialTableData;
      }
    }

    if (data?.length && Object.keys(__selectedFields)?.length) {
      setFilters(data);
    } else if (sortData?.length && sortData[0] !== _selectedColumnName) {
      selectedColumnList.current = tableData;
      setFilters([]);
      onSort(sortData[0], sortData[1], tableData);
    }else {
      setFilters([]);
      selectedFieldsRef.current = [];
      setTableData(initialTableData);
      selectedColumnList.current = initialTableData;
    }
    setConditionMatched(false);
  };

  const dynamicColumns = () => {
    const tblData = filters?.length ? filters : tableData;
    const allSelected = selectAllChecked || tblData?.length === selected?.length;
    return [
      allColumnNames?.map((col, i) => {
        return (
          <Column
            filter
            key={col.Field_Name}
            field={col.Field_Name}
            filterField={col.Field_Name}
            alignFrozen="left"
            columnKey={col.Field_Name || i}
            showFilterMenu={false}
            filterPlaceholder={col.Field_Name}
            body={fullKitReadinessBody}
            frozen={frozenCoulmns?.includes(col.Field_Name)}
            className={frozenCoulmns?.includes(col.Field_Name) ? "font-bold" : ""}
            header={
              <ACPBookingHeaderColumn
                op={op}
                col={col.Field_Name}
                onSort={onSort}
                filters={filters}
                sortData={sortData}
                tableData={tableData}
                setFilters={setFilters}
                allSelected={allSelected}
                tableRender={tableRender}
                setSortData={setSortData}
                saveSettings={saveSettings}
                ProjectFrozen={ProjectFrozen}
                frozenCoulmns={frozenCoulmns}
                allColumnNames={allColumnNames}
                selectedFields={selectedFields}
                setTableRender={setTableRender}
                handleSelectAll={handleSelectAll}
                setFrozenColumn={setFrozenColumn}
                setProjectFrozen={setProjectFrozen}
                addFrozenColumns={addFrozenColumns}
                selectAllChecked={selectAllChecked}
                projectColumnName={projectColumnName}
                isReorderedColumn={isReorderedColumn}
                projectNameOnClick={projectNameOnClick}
                setReorderedColumn={setReorderedColumn}
                selectedColumnName={selectedColumnName}
                availableColumnNames={availableColumnNames}
                onGlobalFilterChange={onGlobalFilterChange}
                setProjectColumnNames={setProjectColumnNames}
                setSelectedColumnName={setSelectedColumnName}
                clearColumnWiseFilter={clearColumnWiseFilter}
                acpBookingFrozenColumn={acpBookingFrozenColumn}
              />
            }
            // style={{
            //   width:
            //     checkWidth?.length && checkWidth.includes(col) ? columnWidthMyProject[col] : "",
            //   textOverflow: "ellipsis",
            // }}
            style={{
              width: col?.freeze
              ? (col?.Field_Name === "SelectAll" 
                  ? "50px" 
                  : col?.Field_Name === "Project Name"
                    ? "200px"
                    : col?.width)
              : col?.width,
              textOverflow: "ellipsis",
              maxWidth: col?.width,
              minWidth:"80px",
            }}
          />
        );
      }),
    ];
  };

  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      tableData,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef = selectedFieldsRef?.current.map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      setActionType(!actionType);
      localStorage.removeItem("_selectedFieldsRefCurrentDashboardPanelBottom");
    }
    if (filterArray.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        const filterData = AcpService.filterDataByfilters(
          tableData,
          _selectedFieldsRefWithUniqueObjects,
          setConditionMatched,
          "DashboardPanelBottom"
        );
        if (filterData?.length && sortData?.length) {
          onSort(sortData[0], sortData[1], filterData);
        } else {
          setFilters(filterData);
          selectedColumnList.current = filterData;
        }
        setActionType(!actionType);
      } else {
        selectedColumnList.current = [];
        setSelectedFields({});
        setFilters([]);
      }
    } else {
      selectedColumnList.current = [];
      setSelectedFields({});
      setFilters([]);
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        clear() {
          clearFilters();
        },
      };
    },
    []
  );

  const clearFilters = () => {
    setFilters([]);
    isSearchSet(false);
    setSelectedFields({});
  };

  useEffect(() => {
    if (loader) {
      setSelected([]);
    }
  }, [loader]);

  useEffect(() => {
    if (clearAllFilter) {
      clearFilters();
      setClearAllFilter(false);
    }
  }, [clearAllFilter]);

  const headerTemplatePanel = (options, text, ref) => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <>
        <div className={className}>
          <div className="flex align-items-center gap-2">
            <span className="font-bold">{text}</span>
            <span className="p-treetable-toggler p-link">
              <span
                aria-hidden="true"
                onClick={() => ref.current.toggle()}
                className={`p-treetable-toggler-icon pi pi-fw ${options?.collapsed ? "pi-angle-down" : "pi-angle-up"
                  }`}
              ></span>
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {ShowCustomizedView && (
        <CustomizeView
          allColumns={allColumnNames}
          showTaskDialog={ShowCustomizedView}
          headerName={"Artwork Scope Alignment"}
          availableFields={availableColumnNames}
          customizeViewFields={customizeViewFields}
          setCustomizeViewFields={setCustomizeViewFields}
          onClose={() => setShowCustomizedView(!ShowCustomizedView)}
        />
      )}
      <div className="acp-booking-table">
        <Panel
          toggleable
          ref={panelRef2}
          className="panel2"
          headerTemplate={(el) =>
            headerTemplatePanel(el, CapacityToDemandAlertsExternal, panelRef2)
          }
        >
          {projectColumnName?.length < 2 ? null : (
            <DataTable
              ref={dt}
              rows={100}
              scrollable
              stripedRows
              showGridlines
              size={"small"}
              resizableColumns
              reorderableColumns
              groupRowsBy="Group"
              expandableRowGroups
              dataKey="Project_ID"
              rowGroupMode="subheader"
              responsiveLayout="scroll"
              columnResizeMode="expand"
              paginatorPosition="bottom"
              className="mt-3 acpBooking"
              expandedRows={expandedRows}
              loading={panelBottomLoader}
              filterDisplay={isSearch && "row"}
              selection={selectedRowsPanelBottom}
              rowGroupHeaderTemplate={headerTemplate}
              onSelectionChange={(e) => {
                setSelectedRowsPanelBottom(e?.value);
              }}
              onColumnResizeEnd={onColumnResizeEnd}
              onColReorder={storeReorderedColumns}
              onResize={(e) => console.log("resize", e)}
              onResizeCapture={(e) => console.log("e", e)}
              key={tableRender ? `"Project_ID" + timestamp+ ${resetKey}` : `${resetKey}`}
              tableStyle={{ width: "max-content", minWidth: "100%" }}
              emptyMessage={panelBottomLoader ? " " : "No records found"}
              value={conditionMatched ? [] : filters?.length ? filters : tableData}
              rowClassName={(rowData) => (rowData.isSelected ? "highlight-row" : "normal-row")}
            >
              {dynamicColumns()}
            </DataTable>
          )}
        </Panel>
      </div>
      {/* UnsavedChangesDialog popup*/}   
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </>
  );
}

export default forwardRef(PanelBottom);
