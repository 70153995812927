// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.owner-field {
  width: 80%;
  font-size: 12;
}

.owner-field-group {
  text-align: "-webkit-center";
}

.AISE-field-group {
  text-align: "-webkit-center";
}

.AddToProjectColumn {
  text-align: "-webkit-center";
  display: flex;
}

.drag-arrow {
  position: absolute;
  top: 15%;
  left: 2px;
  font-size: 25px;
  font-weight: bold;
  color: #000;
  z-index: 99;
  transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/components/DSBP/AgilityList.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACI,4BAAA;EACA,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,WAAA;EACA,2BAAA;AACJ","sourcesContent":[".owner-field {\n    width: 80%;\n    font-size: 12;\n}\n\n.owner-field-group {\n    text-align: '-webkit-center'\n}\n\n.AISE-field-group {\n    text-align: '-webkit-center'\n}\n\n.AddToProjectColumn {\n    text-align: \"-webkit-center\";\n    display: flex;\n}\n\n.drag-arrow {\n    position: absolute;\n    top: 15%;\n    left: 2px;\n    font-size: 25px;\n    font-weight: bold;\n    color: #000;\n    z-index: 99;\n    transform: translateY(-50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
