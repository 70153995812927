import { CreateSession } from "./useSession";
import { DEVURL } from "../../apis/envUrl";
import { uploadtoAzureStrContainer } from "../../apis/uploadAzuerContainer";
import { getProjectPlan, storeApproveFileDetails } from "../../apis/projectPlanApi";
import { fetchExtractedAnnotations, redirectToProjectPlanOrTaskPage } from "../../utils";
import { AzureContainerFail, AzureContainerSuccess } from "../../Utils/constants";

export const DownloadAnnotation = async (pdfPath, strXFDFPath, filename, XFDFAzureFolderStructure, designJobStatus, storeApproveFileDetailsFormData, ProjectID,toast,page1,page2,pageType,navigate, item, XfdfFileList, approvalStatus) => {

  let xfdfContent = "";
  try {
    const sessionCreate = await CreateSession(
      `${DEVURL}/createProofscopeSession`,
      "admin",
      "admin"
    );
    const strSession = JSON.parse(JSON.stringify(sessionCreate)).session;

    const json = {
      method: "hub.process_from_whitepaper_with_variables",
      whitepaper_name: "ExportXFDF",
      input_name: "get_xfdf_file",
      variables: {
        file_url: pdfPath,
        xfdf_url: strXFDFPath,
      },
      session: strSession,
    };

    const url = new URL(`${DEVURL}/exportXfdf`);
    const conn = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Content-Language": "en-US",
      },
      body: JSON.stringify(json),
    });

    if (conn.ok) {
      const response = await conn.json();
      if(response?.status === 500){
        return xfdfContent = response
      }else{
        const xfdfFilePath = response?.xfdfFilePath;
        const lines = xfdfFilePath?.lines; // Extract lines from the response
        if (lines?.length > 0) {
          xfdfContent = lines[0];    
          //AWM-3059: initiate xfdf file download and upload in case of rework of Upload tasks
          const extractedAnnotations = fetchExtractedAnnotations(xfdfContent);
          if(designJobStatus==="Rework"){
            if(extractedAnnotations.length>0){
              await initiateDownload(lines, filename, XFDFAzureFolderStructure, storeApproveFileDetailsFormData, ProjectID,toast,page1,page2,pageType,navigate);
            }else{
              redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
            }
          }
        }
      }      
    }
  } catch (error) {
    console.error("Error:", error.message);
  }

  return xfdfContent; // Return empty string if download or fetching fails
};

const initiateDownload = async (lines, filename, XFDFAzureFolderStructure, storeApproveFileDetailsFormData, ProjectID,toast,page1,page2,pageType,navigate) => {
  try {
    // Ensure the filename has .xfdf extension
    if (!filename.endsWith(".XFDF")) {
      filename += ".XFDF";
    }

    const xmlContent = lines.join("\n");
    const file = new Blob([xmlContent], { type: "text/xml" });
    file.name = filename; // Set the name property of the Blob
    await initiateUploadToAzure(file, XFDFAzureFolderStructure, storeApproveFileDetailsFormData, ProjectID,toast,page1,page2,pageType,navigate );
  } catch (error) {
    console.error("Error initiating download:", error.message);
  }
};

const initiateUploadToAzure = async (file, XFDFAzureFolderStructure, storeApproveFileDetailsFormData, ProjectID,toast,page1,page2,pageType,navigate ) => {
  
  
  try {
    // Upload the file to Azure Storage with the specified folder structure
    let azureRes = await uploadtoAzureStrContainer(file, XFDFAzureFolderStructure);
    if(azureRes){
      if (toast.current) {
        toast.current.show(AzureContainerSuccess);
      }
    } else {
      if (toast.current) {
        toast.current.show(AzureContainerFail);
      }
    }
    const storedFileDetails = await storeApproveFileDetails(storeApproveFileDetailsFormData, ProjectID);
    if(storedFileDetails.status === 200){
      // await getProjectPlan(ProjectID);
      localStorage.setItem("Approval_Task_Submitted",false);
      redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
      
    }
    
  } catch (error) {
    console.error("Error uploading file to Azure Storage:", error.message);
  }
};

// DownloadAnnotation('path/to/pdf', 'path/to/xfdf', 'desired_filename');
