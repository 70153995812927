import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const useAzureUploadFolderStructure = (subFolder, version, toSendWithVersion = true,groupName) => {
  const { TaskDetailsData } = useSelector((state) => state.TaskDetailsReducer);
  let { ProjectID } = useParams();
  if(!ProjectID){
    ProjectID = TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID;
  }

  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
  const jobName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.Design_Job_ID
    ? TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.Design_Job_ID
    : subFolder;

  const regionName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region;
  const Project = ProjectID?.toUpperCase();
  let folderStructure;
  if (subFolder === "GA Brief") {
    folderStructure = `${BU}/${regionName}/${Project}/${subFolder}/${groupName}`;
  } else {
    folderStructure = `${BU}/${regionName}/${Project}/${subFolder}/${jobName}`;
  }

  if (toSendWithVersion) {
    return `${folderStructure}/${version}/`;
  }

  return folderStructure;
};

export default useAzureUploadFolderStructure;
