import React, { useEffect, useState, useMemo, useRef } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { TabView, TabPanel } from "primereact/tabview";
import { Form } from "react-bootstrap";
import "./index.scss";
import { Accordion } from "react-bootstrap";
import {
  checkIfGreyIcon,
  checkIfRedIcon,
  createPMPObject,
  transformDependencyData,
} from "../utils";
import { DMTabValuesAction } from "../../../store/actions/DMTabValuesActions";
import {
  onSubmitDependencyMappingAction,
  getDependencyMappingDetails,
  createNewGaBriefTask,
  requestCICAndRequestCicMatrix,
  getDependencyMappingTabDetails,
} from "../../../apis/dsbpApi";
import FooterButtons from "../../AWMJobs/DesignJobs/FooterButtons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomHeader from "./CustomHeader";
import toggleOff from "../../../assets/images/toggleOff.svg";
import toggleOn from "../../../assets/images/toggleOn.svg";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import { generateTaskName, getRequestText } from "../utils";
import { Button } from "primereact/button";
import { useLocation, useParams } from "react-router-dom";
import editName from "../../../assets/images/editName.svg";
import { Tooltip } from "primereact/tooltip";
import ManageDependency from "../../ManageDependency";
import { getProjectValues } from "../../../store/actions/ProjectSetupActions";
import AlertIcon from "../../common/AlertIcon/AlertIcon";
import { dsbpConstant } from "../constant";
import CICInfo from "../../../assets/images/CICInfo.svg";
import DsbpCommonPopup from "../DsbpCommonPopup";
import { MultiSelect } from "primereact/multiselect";
import ShowMoreList from "../../common/ShowMoreLess/ShowMoreList";
import Showmore from "../../common/ShowMoreLess/ShowMore";
import _ from "lodash";
import { useUnsavedChangesWarning,UnsavedChangesDialog } from "../../common/NavigationPrompt";
import { usePriorityNew } from "../../../Utils/GetRolePriority";
import { rearrangeByStatusOrderAndSortAlphabetically, showBackgroundProcess } from "../../../Utils/helpers";
import { Toast } from "primereact/toast";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus, isRoleMatch } from "../../../utils";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { isEqual, isObject } from "lodash";
import CustomInput from "../../common/CustomInput";
import { Checkbox } from "primereact/checkbox";
import { createManageDependencySaveDataDMTabByTab } from "../../../service/DMService";
import { CIC_DEPENDENT, NOT_NEEDED } from "../../../Utils/constants";

const errorMessage = "Valid PMP# is 8 digits";

const DMPMPSpecificTabView = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { ProjectID, assignee } = useParams();
  const initialState = {
    poaaAcknowledgDialog: false,
    poaaResponseData: false,
  };
  const [requestedCicAcknowledgement, setRequestedCicAcknowledgement] = useState(initialState);
  const { dmTabValuesData } = useSelector((state) => state.DMTabValuesReducer);
  // const { closelectedProject } = useSelector((state) => state.ProjectSetupReducer);
  const [storesTabList, setStoresTabDataList] = useState(dmTabValuesData);
  const [filteredDataList, setFilteredDataList] = useState(dmTabValuesData);
  const [tabPanelList, setTabPanelList] = useState(1);
  const [cdpt, setCDPT] = useState([]);
  const [rdt, setRDT] = useState([]);
  const [iq, setIQ] = useState([]);
  const [cdptCheckboxValue, setCDPTCheckboxValue] = useState(false);
  const [rdtCheckboxValue, setRDTCheckboxValue] = useState(false);
  const [iqCheckboxValue, setIQCheckboxValue] = useState(false);
  const [cicNeeded, setCICNeeded] = useState("");
  const [pmpDesign, setPMPDesign] = useState("");
  const [pmpLayout, setPMPLayout] = useState("");
  const [otherRef, setOtherRef] = useState("");
  const [gaBrief, setGaBrief] = useState("");
  const [cicMatrix, setCICMatrix] = useState("");
  const [formData, setFormData] = useState({});
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [isToggleClick, setToggleClick] = useState(false);
  const [selectedTab, setSelectedTabData] = useState({});
  const [loader, setLoader] = useState(false);
  const [initialFormData, setInitialFormData] = useState({});
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { dmTabAttributesData } = useSelector((state) => state.DMTabValuesReducer);
  const [dmTabData, setDmTabData] = useState(dmTabAttributesData);
  const [showManageDependency, setShowManageDependency] = useState(false);
  const [accessSecurityMatrix, setSecurityMatrix] = useState(false);
  const [checkReadWriteAccess, setUserAccess] = useState(false);
  const [accessType, setAccessType] = useState(false);

  const [selectedProjectDetails, setSelectedProjectDetails] = useState([]);
  const projectSetup = useSelector((state) => state.ProjectSetupReducer);
  const [changedData, setChangedData] = useState([]);

  // const {CDPTPageData,IQData,RDTData,GABriefData} = JSON.parse(localStorage.getItem("DMTabAttributesActionData"))  
  const { isDirty, markDirty, markClean } = useUnsavedChangesWarning();
  const {
    userDetails: { UserGroup, AccessGroup },
  } = useSelector((state) => state?.UserDetailsReducer);

  const { userInformation } = useSelector((state) => state.UserReducer);
  const _currentUrl = useLocation().pathname;
  const currentUrl = useLocation().pathname.split("/");
  const finalPermission = accessSecurityMatrix;

  const dropdownMI = dmTabAttributesData?.DMMappingData?.map((item) => item?.DSBP_PMP_PIMaterialID);
  const dropdownInitiateID = dmTabAttributesData?.DMMappingData?.map(
    (item) => item?.DSBP_InitiativeID
  );
  const specialProjectFlag = projectSetup?.projectData?.SpecialProject;
  const fetchProjectData = useMemo(() => {
    if (projectSetup?.status === 200) {
      return projectSetup.projectData?.ArtworkAgilityProjects || {};
    }
    return {};
  }, [projectSetup?.status, projectSetup?.projectData?.ArtworkAgilityProjects]);

  useEffect(() => {
    if (fetchProjectData) {
      setSelectedProjectDetails(fetchProjectData?.[0] || []);
    }
  }, [fetchProjectData]);

  useEffect(() => {
    setSelectedTabData(dmTabValuesData[tabPanelList]);
    const isUserAccess = isRoleMatch(AccessGroup, [
      "CapacityManager",
      "ProjectManager",
      "TaskOwner",
    ]);
    setUserAccess(isUserAccess);
    if (tabPanelList && isUserAccess) {
      fetchDependencyMappingTabDetails(
        dmTabValuesData?.[tabPanelList]?.description?.DSBP_InitiativeID,
        dmTabValuesData?.[tabPanelList]?.description?.DSBP_PMP_PIMaterialID,
        dmTabValuesData?.[tabPanelList]?.description?.DSBP_PMP_PIMaterialNumber,
        ProjectID
      );
    }
  }, [tabPanelList]);

  useEffect(() => {
    if (accessType === "Hidden") {
      setUserAccess(false);
    } else if (accessType === "Read") {
      setUserAccess(true);
      setSecurityMatrix(false);
    } else if (accessType === "Write") {
      setUserAccess(true);
      setSecurityMatrix(true);
    }
  }, [accessType]);

  // const selectedProjectDetails = projectSetup.selectedProject;
  const CICs = selectedProjectDetails?.CICs;

  let { Artwork_Brand, Artwork_Category, Project_Name, Artwork_SMO, Project_State } =
    selectedProjectDetails;

  const task_Name = generateTaskName(Artwork_Brand, Artwork_Category, Project_Name, Artwork_SMO);

  const navigateToDSBP = () => {
    navigate(`/${currentUrl?.[1]}/dependencyMapping/${ProjectID}`);
  };

  const userAccessPayLoad = {
    ArtworkAgilityPage: {
      UserId: userInformation?.userid,
      ProjectID: ProjectID,
      UserGroup: UserGroup,
    },
  };
  useEffect(() => {
    //Added payload for url access for issue 3109
    if (ProjectID) dispatch(getProjectValues(ProjectID, userAccessPayLoad));
  }, [ProjectID]);

  useEffect(() => {
    if (ProjectID) {
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(_currentUrl, specialProjectFlag, navigate);
    }
  }, [specialProjectFlag]);

  useEffect(() => {
    setFilteredDataList(dmTabValuesData);
  }, [dmTabValuesData]);

  useEffect(() => {
    setTabPanelList(dmTabValuesData?.length - 1);
    dispatch(DMTabValuesAction(dmTabValuesData));
  }, []);

  useEffect(() => {
    if (tabPanelList >= storesTabList?.length) {
      setTabPanelList(storesTabList.length - 1);
    }
    storesTabList !== undefined && dispatch(DMTabValuesAction(storesTabList));
    setSelectedTabData(dmTabValuesData[tabPanelList]);
    if (dmTabValuesData[tabPanelList]) {
      const selectedTabData = dmTabValuesData[tabPanelList];
      if (selectedTabData?.description !== undefined) {
        setCDPT(selectedTabData?.description?.Color_Development_and_Print_Trial);
        setRDT(selectedTabData?.description?.Regional_Design_Template);
        setIQ(selectedTabData?.description?.Ink_Qualification);
        setCDPTCheckboxValue(selectedTabData?.description?.Color_Development_and_Print_Trial?.filter((obj)=>obj.AWM_Design_Job_ID.includes("_None"))?.length>0);
        setRDTCheckboxValue(selectedTabData?.description?.Regional_Design_Template?.filter((obj)=>obj.AWM_Design_Job_ID.includes("_None"))?.length>0);
        setIQCheckboxValue(selectedTabData?.description?.Ink_Qualification?.filter((obj)=>obj.AWM_Design_Job_ID.includes("_None"))?.length>0);
        setCICNeeded(selectedTabData?.description?.AWM_CIC_Needed);
        setPMPDesign(selectedTabData?.description?.AWM_Supporting_PMP_Design);
        setPMPLayout(selectedTabData?.description?.AWM_Supporting_PMP_Layout);
        setOtherRef(selectedTabData?.description?.AWM_Other_Reference);
        setGaBrief(selectedTabData?.description?.AWM_GA_Brief);
        setCICMatrix(selectedTabData?.description?.AWM_CIC_Matrix_Toggle || "");
        setFormData({
          DSBP_InitiativeID: selectedTabData?.description?.DSBP_InitiativeID,
          DSBP_PMP_PIMaterialID: selectedTabData?.description?.DSBP_PMP_PIMaterialID,
          DSBP_PMP_PIMaterialNumber: selectedTabData?.description?.DSBP_PMP_PIMaterialNumber,
          AWM_CICNeeded: selectedTabData?.description?.AWM_CIC_Needed,
          //converted to object
          AWM_SupportingPMPLayoutPage:
            typeof selectedTabData?.description?.AWM_Supporting_PMP_Layout === "object"
              ? selectedTabData?.description?.AWM_Supporting_PMP_Layout
              : createPMPObject(selectedTabData?.description?.AWM_Supporting_PMP_Layout),

          AWM_SupportingPMPDesignPage:
            typeof selectedTabData?.description?.AWM_Supporting_PMP_Design === "object"
              ? selectedTabData?.description?.AWM_Supporting_PMP_Design
              : createPMPObject(selectedTabData?.description?.AWM_Supporting_PMP_Design),

          AWM_OtherReference: selectedTabData?.description?.AWM_Other_Reference,
          AWM_GABrief: selectedTabData?.description?.AWM_GA_Brief || "",
          AWM_CIC_Matrix_Toggle: selectedTabData?.description?.AWM_CIC_Matrix_Toggle || "",
          AWM_CICDetails: selectedTabData?.description?.CICDetails || {},
          // updated:false,
          Regional_Design_Template: selectedTabData?.description?.Regional_Design_Template,
          Color_Development_and_Print_Trial: selectedTabData?.description?.Color_Development_and_Print_Trial,
          Ink_Qualification: selectedTabData?.description?.Ink_Qualification,
        });

        setInitialFormData({
          DSBP_InitiativeID: selectedTabData?.description?.DSBP_InitiativeID,
          DSBP_PMP_PIMaterialID: selectedTabData?.description?.DSBP_PMP_PIMaterialID,
          DSBP_PMP_PIMaterialNumber: selectedTabData?.description?.DSBP_PMP_PIMaterialNumber,
          AWM_CICNeeded: selectedTabData?.description?.AWM_CIC_Needed,
          AWM_SupportingPMPLayoutPage:
            typeof selectedTabData?.description?.AWM_Supporting_PMP_Layout === "object"
              ? selectedTabData?.description?.AWM_Supporting_PMP_Layout
              : createPMPObject(selectedTabData?.description?.AWM_Supporting_PMP_Layout),

          AWM_SupportingPMPDesignPage:
            typeof selectedTabData?.description?.AWM_Supporting_PMP_Design === "object"
              ? selectedTabData?.description?.AWM_Supporting_PMP_Design
              : createPMPObject(selectedTabData?.description?.AWM_Supporting_PMP_Design),
          AWM_OtherReference: selectedTabData?.description?.AWM_Other_Reference,
          AWM_GABrief: selectedTabData?.description?.AWM_GA_Brief || " ",
          AWM_CIC_Matrix_Toggle: selectedTabData?.description?.AWM_CIC_Matrix_Toggle || "",
          AWM_CICDetails: selectedTabData?.description?.CICDetails || {},
          // updated:false,
          Regional_Design_Template: selectedTabData?.description?.Regional_Design_Template,
          Color_Development_and_Print_Trial: selectedTabData?.description?.Color_Development_and_Print_Trial,
          Ink_Qualification: selectedTabData?.description?.Ink_Qualification,
        });
      }
    }
    // setFormData({});
  }, [storesTabList, tabPanelList]);

  const checkSupportingPMPValue = (obj) => {
    let isEmpty = true;
    if (isObject(obj)) {
      let keys = Object.keys(obj);
      keys.forEach((key) => {
        if (obj[key] !== "") {
          isEmpty = false;
        }
      });
    } else {
      if (obj !== "") {
        isEmpty = false;
      }
    }

    if (isEmpty === true) {
      return "";
    } else {
      return "not_empty";
    }
  };

  useEffect(() => {
    const areObjectsEqualData = isEqual(initialFormData, formData);
    let filteredData =
      // save should be disable if all the three fields(AWM_SupportingPMPLayout,AWM_SupportingPMPDesign,AWM_OtherReference) are empty when cic needed selected is No
      (
        formData?.AWM_CICNeeded === CIC_DEPENDENT &&
        checkSupportingPMPValue(formData?.AWM_SupportingPMPLayoutPage) === "" &&
        checkSupportingPMPValue(formData?.AWM_SupportingPMPDesignPage) === "" &&
        (formData?.AWM_OtherReference === "" ||
          (formData?.AWM_OtherReference !== "" && formData?.AWM_OtherReference.length < 8))) ||
      (formData?.AWM_CICNeeded === CIC_DEPENDENT &&
        (checkSupportingPMPValue(formData?.AWM_SupportingPMPLayoutPage) !== "" ||
          checkSupportingPMPValue(formData?.AWM_SupportingPMPDesignPage) !== "") &&
        formData?.AWM_OtherReference !== "" &&
        formData?.AWM_OtherReference.length < 8) ||
      (formData?.AWM_CICNeeded === NOT_NEEDED &&
        formData?.AWM_OtherReference !== "" &&
        formData?.AWM_OtherReference?.length < 8) ||
      (formData?.AWM_CICNeeded === "Yes" &&
        (
          (formData?.Regional_Design_Template && !formData?.Regional_Design_Template?.length) ||
          (formData?.Color_Development_and_Print_Trial && !formData?.Color_Development_and_Print_Trial?.length) ||
          (formData?.Ink_Qualification && !formData?.Ink_Qualification?.length)
        )
      )

    let setSubmitEnable = filteredData ? true : false;
    if (areObjectsEqualData) {
      setInternalPageEdited(false);
      markClean();
      setIsSubmitEnabled(setSubmitEnable);
    } else {
      markDirty();
      setInternalPageEdited(true);
      setIsSubmitEnabled(setSubmitEnable);
    }
  }, [formData]);

  useEffect(() => {
    setSelectedTabData(dmTabValuesData[tabPanelList]);
    if (dmTabValuesData[tabPanelList]) {
      const selectedTabData = dmTabValuesData[tabPanelList];
      if (selectedTabData?.description !== undefined) {
        setCDPT(selectedTabData?.description?.Color_Development_and_Print_Trial);
        setRDT(selectedTabData?.description?.Regional_Design_Template);
        setIQ(selectedTabData?.description?.Ink_Qualification);
        setCDPTCheckboxValue(selectedTabData?.description?.Color_Development_and_Print_Trial?.filter((obj)=>obj.AWM_Design_Job_ID.includes("_None"))?.length>0);
        setRDTCheckboxValue(selectedTabData?.description?.Regional_Design_Template?.filter((obj)=>obj.AWM_Design_Job_ID.includes("_None"))?.length>0);
        setIQCheckboxValue(selectedTabData?.description?.Ink_Qualification?.filter((obj)=>obj.AWM_Design_Job_ID.includes("_None"))?.length>0);
        setCICNeeded(selectedTabData?.description?.AWM_CIC_Needed);
        setPMPDesign(selectedTabData?.description?.AWM_Supporting_PMP_Design);
        setPMPLayout(selectedTabData?.description?.AWM_Supporting_PMP_Layout);
        setOtherRef(selectedTabData?.description?.AWM_Other_Reference);
        setGaBrief(selectedTabData?.description?.AWM_GA_Brief);
        setCICMatrix(selectedTabData?.description?.AWM_CIC_Matrix_Toggle || "");
        setFormData({
          DSBP_InitiativeID: selectedTabData?.description?.DSBP_InitiativeID,
          DSBP_PMP_PIMaterialID: selectedTabData?.description?.DSBP_PMP_PIMaterialID,
          DSBP_PMP_PIMaterialNumber: selectedTabData?.description?.DSBP_PMP_PIMaterialNumber,
          AWM_CICNeeded: selectedTabData?.description?.AWM_CIC_Needed,
          AWM_SupportingPMPLayoutPage:
            typeof selectedTabData?.description?.AWM_Supporting_PMP_Layout === "object"
              ? selectedTabData?.description?.AWM_Supporting_PMP_Layout
              : createPMPObject(selectedTabData?.description?.AWM_Supporting_PMP_Layout),
          AWM_SupportingPMPDesignPage:
            typeof selectedTabData?.description?.AWM_Supporting_PMP_Design === "object"
              ? selectedTabData?.description?.AWM_Supporting_PMP_Design
              : createPMPObject(selectedTabData?.description?.AWM_Supporting_PMP_Design),
          AWM_OtherReference: selectedTabData?.description?.AWM_Other_Reference,
          AWM_GABrief: selectedTabData?.description?.AWM_GA_Brief || "",
          AWM_CIC_Matrix_Toggle: selectedTabData?.description?.AWM_CIC_Matrix_Toggle || "",
          AWM_CICDetails: selectedTabData?.description?.CICDetails || {},
          Regional_Design_Template: selectedTabData?.description?.Regional_Design_Template,
          Color_Development_and_Print_Trial: selectedTabData?.description?.Color_Development_and_Print_Trial,
          Ink_Qualification: selectedTabData?.description?.Ink_Qualification,
          // AWM_CIC_Matrix:selectedTabData?.description?.AWM_CIC_Matrix,
        });
        setInitialFormData({
          DSBP_InitiativeID: selectedTabData?.description?.DSBP_InitiativeID,
          DSBP_PMP_PIMaterialID: selectedTabData?.description?.DSBP_PMP_PIMaterialID,
          DSBP_PMP_PIMaterialNumber: selectedTabData?.description?.DSBP_PMP_PIMaterialNumber,
          AWM_CICNeeded: selectedTabData?.description?.AWM_CIC_Needed,
          AWM_SupportingPMPLayoutPage:
            typeof selectedTabData?.description?.AWM_Supporting_PMP_Layout === "object"
              ? selectedTabData?.description?.AWM_Supporting_PMP_Layout
              : createPMPObject(selectedTabData?.description?.AWM_Supporting_PMP_Layout),
          AWM_SupportingPMPDesignPage:
            typeof selectedTabData?.description?.AWM_Supporting_PMP_Design === "object"
              ? selectedTabData?.description?.AWM_Supporting_PMP_Design
              : createPMPObject(selectedTabData?.description?.AWM_Supporting_PMP_Design),
          AWM_OtherReference: selectedTabData?.description?.AWM_Other_Reference,
          AWM_GABrief: selectedTabData?.description?.AWM_GA_Brief || "",
          AWM_CIC_Matrix_Toggle: selectedTabData?.description?.AWM_CIC_Matrix_Toggle || "",
          AWM_CICDetails: selectedTabData?.description?.CICDetails || {},
          Regional_Design_Template: selectedTabData?.description?.Regional_Design_Template,
          Color_Development_and_Print_Trial: selectedTabData?.description?.Color_Development_and_Print_Trial,
          Ink_Qualification: selectedTabData?.description?.Ink_Qualification,
          // AWM_CIC_Matrix:selectedTabData?.description?.AWM_CIC_Matrix,
        });
      }
    }
    // setFormData({});
  }, [dmTabValuesData]);

  useEffect(() => {
    if (dmTabValuesData) {
      setStoresTabDataList(dmTabValuesData || []);
    }
  }, [dmTabValuesData]);

  useEffect(() => {
    if (dmTabValuesData) {
      const filteredDmTabValuesData = dmTabValuesData?.filter((obj) => {
        return obj?.projectId === ProjectID || obj?.projectId === "Project Id";
      });
      setStoresTabDataList(filteredDmTabValuesData || []);
    }
  }, [ProjectID]);

  const unsavedChanges = JSON.parse(localStorage.getItem("unsavedChanges"));
  const [internalPageEdited, setInternalPageEdited] = useState(false);
  const [tempTabIndex, setTempTabIndex] = useState("");
  const [actionType, setActionType] = useState("");
  const [tempObj, setTempObj] = useState({});

  useEffect(() => {
    setInternalPageEdited(false);
  }, []);

  // useEffect(() => {
  //   if (unsavedChanges || internalPageEdited) {
  //     markDirty();
  //     // unsavedChanges is motre reliable hence using that
  //     // because isSubmitEnabled based on several condition,
  //     // but if we altered anything on page then warning should come
  //   } else {
  //     markClean();
  //   }
  // }, [unsavedChanges, internalPageEdited]);
  // removing from here used below

  const handleNewGaBrief = async () => {
    let temp = {
      NewGABTask: "Yes",
      AWM_Project_ID: ProjectID,
      AWM_Task_ID: "",
      Project_Name: selectedProjectDetails?.Project_Name,
      BU: selectedProjectDetails?.BU,
      Region: selectedProjectDetails?.Project_region,
      DSBP_PMP_PIMaterialID: selectedTab?.description?.DSBP_PMP_PIMaterialID,
      DSBP_PMP_PIMaterialNumber: selectedTab?.description?.DSBP_PMP_PIMaterialNumber,
      DSBP_InitiativeID: selectedTab?.description?.DSBP_InitiativeID,
    };
    let formData = {
      RequestPageForGABList: [temp],
    };
    let res = await createNewGaBriefTask(formData);
  };

  const handleCDPTChange = (e) => {
    setCDPT(e.target.value);
    setCDPTCheckboxValue(false);
    let newFormData = _.cloneDeep(formData);
    newFormData.Color_Development_and_Print_Trial = e.target.value;
    setFormData(newFormData);
  };

  const handleRDTchange = (e) => {
    setRDT(e.target.value);
    setRDTCheckboxValue(false);
    let newFormData = _.cloneDeep(formData);
    newFormData.Regional_Design_Template = e.target.value;
    setFormData(newFormData);
  };

  const handleIQChange = (e) => {
    setIQ(e.target.value);
    setIQCheckboxValue(false);
    let newFormData = _.cloneDeep(formData);
    newFormData.Ink_Qualification = e.target.value;
    setFormData(newFormData);
  };

  const handleCICNeededChange = (e) => {
    const { value } = e.target;
    setCICNeeded(value);
    if (value !== CIC_DEPENDENT) {
      setPMPDesign("");
      setPMPLayout("");
    }
    let newFormData = _.cloneDeep(formData);
    newFormData.AWM_CICNeeded = value;
    newFormData.AWM_SupportingPMPDesignPage = createPMPObject(null);
    newFormData.AWM_SupportingPMPLayoutPage = createPMPObject(null);
    setFormData(newFormData);
  };

  const handlePmpDesignChange = (e) => {
    // on any edit on page marking dirty to alert on sidebar click
    // if we click on upper tab then it will show alert
    const selectedOption = e.target.selectedOptions[0];
    const dropdownMI = selectedOption.getAttribute("data-dropdownMI");
    const dropdownInitiateID = selectedOption.getAttribute("data-dropdownInitiateID");
    setPMPDesign(e.target.value);

    let newFormData = _.cloneDeep(formData);
    newFormData.AWM_SupportingPMPLayoutPage =
      typeof formData?.AWM_SupportingPMPLayoutPage === "object"
      ? formData?.AWM_SupportingPMPLayoutPage
      : createPMPObject(formData?.AWM_SupportingPMPLayoutPage);
    newFormData.AWM_SupportingPMPDesignPage =
      e.target.value === ""
      ? ""
      : createPMPObject(`${e.target.value}-${dropdownMI}-${dropdownInitiateID}`);

      setFormData(newFormData);
  };
  const handlePmpLayoutChange = (e) => {
    // on any edit on page marking dirty to alert on sidebar click
    // if we click on upper tab then it will show alert
    const selectedOption = e.target.selectedOptions[0];
    const dropdownMI = selectedOption.getAttribute("data-dropdownMI");
    const dropdownInitiateID = selectedOption.getAttribute("data-dropdownInitiateID");
    setPMPLayout(e.target.value);

    let newFormData = _.cloneDeep(formData);
    newFormData.AWM_SupportingPMPDesignPage =
      typeof formData?.AWM_SupportingPMPDesignPage === "object"
      ? formData?.AWM_SupportingPMPDesignPage
      : createPMPObject(formData?.AWM_SupportingPMPDesignPage);
    newFormData.AWM_SupportingPMPLayoutPage =
      e.target.value === ""
      ? ""
      : createPMPObject(`${e.target.value}-${dropdownMI}-${dropdownInitiateID}`);

      setFormData(newFormData);
  };
  const handleOtherRefChange = (e) => {
    // on any edit on page marking dirty to alert on sidebar click
    // if we click on upper tab then it will show alert
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    // Limit the input to 8 characters
    if (inputValue.length <= 8) {
      setOtherRef(inputValue);
      let newFormData = _.cloneDeep(formData);
      newFormData.AWM_OtherReference = inputValue;
      setFormData(newFormData);
    }
  };

  const handleGABriefChange = async (e) => {
    const { value } = e.target;
    setGaBrief(value);

    let newFormData = _.cloneDeep(formData);
    newFormData.AWM_GABrief = e.target.value;
    setFormData(newFormData);
  };

  const handleCancel = () => {
    return navigateToDSBP();
  };

  const handleCancelClick = () => {
    if (internalPageEdited) {
      setShowDialog(true);
      setActionType("cancel");
    } else {
      handleCancel();
    }
  };

  const updateMappingTabValuesData = (updatedNewData) => {
    let submittionData = {};
    submittionData = {
      tabHeader: selectedTab.tabHeader,
      description: updatedNewData && updatedNewData[0],
      groupCIC: selectedTab?.groupCIC,
    };
    const indexToUpdate = dmTabValuesData.findIndex(
      (tab) => tab.tabHeader === submittionData.tabHeader
    );
    if (indexToUpdate !== -1) {
      // Create a copy of the dmTabValuesData array
      const updateMappingTabValuesData = [...dmTabValuesData];

      // Replace the element at the index with the selectedTab object
      updateMappingTabValuesData[indexToUpdate] = submittionData;

      // Update the state with the updated array
      setStoresTabDataList(updateMappingTabValuesData);
    }
  };

  const saveCICAndCicMatrixRequest = async (formData, reqId) => {
    setLoader(true);
    let selectedData = selectedTab?.description;
    let requestFormData = null;
    const POACreated = selectedData?.RTA_POANumber !== "" ? true : false;
    const POAData = POACreated
      ? `_${selectedData?.RTA_POANumber}_${selectedData?.RTA_POADescription}`
      : ``;
    if (reqId === 2) {
      const generatedTaskName = `CIC_${selectedData?.DSBP_PMP_PIMaterialNumber}${POAData}`;

      requestFormData = {
        AWMProjectID: ProjectID,
        RequestCICList: [
          {
            DSBP_InitiativeID: selectedData?.DSBP_InitiativeID,
            DSBP_PMP_PIMaterialID: selectedData?.DSBP_PMP_PIMaterialID,
            DSBP_PMP_PIMaterialNumber: selectedData?.DSBP_PMP_PIMaterialNumber,
            Task_Name: generatedTaskName,
            POANumber: selectedData?.RTA_POANumber,
            Assignee: selectedData?.CICTaskAssignee,
            AWM_CIC_Matrix: selectedData?.AWM_CIC_Matrix

          },
        ],
      };
    }
    if (reqId === 1) {
      let groupName = selectedData?.AWM_CIC_Matrix;

      let filterMappingDataGroupWise = dmTabData.DMMappingData.filter(
        (ele) => ele.AWM_CIC_Matrix === groupName && ele
      );

      let requestCICList = [];
      filterMappingDataGroupWise.map((group) => {
        const generatedTaskName = `CICM_${groupName}_${task_Name}${
          selectedData?.DSM_PMP_PMPPackagingComponentType
            ? "_" + selectedData?.DSM_PMP_PMPPackagingComponentType
            : ""
        }`;
        const POACreated = group?.RTA_POANumber !== "" ? true : false;
        const POAData = POACreated ? `_${group?.RTA_POANumber}_${group?.RTA_POADescription}` : ``;
        let UCIC_TaskName = `CIC_${group?.DSBP_PMP_PIMaterialNumber}${POAData}`;
        let ObjectForGroup = {};
        ObjectForGroup["DSBP_InitiativeID"] = group?.DSBP_InitiativeID;
        ObjectForGroup["DSBP_PMP_PIMaterialID"] = group?.DSBP_PMP_PIMaterialID;
        ObjectForGroup["DSBP_PMP_PIMaterialNumber"] = group?.DSBP_PMP_PIMaterialNumber;
        ObjectForGroup["Task_Name"] = generatedTaskName;
        ObjectForGroup["Assignee"] = group?.CICTaskAssignee;
        ObjectForGroup["POANumber"] = group?.RTA_POANumber;
        ObjectForGroup["UCIC_TaskName"] = UCIC_TaskName;
        ObjectForGroup["AWM_CIC_Matrix"] = group?.AWM_CIC_Matrix;

        requestCICList.push(ObjectForGroup);
      });

      requestFormData = {
        AWMProjectID: ProjectID,
        RequestCICList: requestCICList,
      };
    }
    let resp = await requestCICAndRequestCicMatrix(requestFormData);
    const message = resp?.data?.RequestCICList?.[0];
    if (message) {
      setRequestedCicAcknowledgement({
        ...requestedCicAcknowledgement,
        poaaAcknowledgDialog: true,
        poaaResponseData: message?.CopyXMLCreationStatus?.includes(
          dsbpConstant.xml_Success_response
        ),
      });
    }
    // Call GET API of dependency mapping
    const { dependencyTableData, isRDTData, isIQData, isCDPTData, isGABrifData } =
      await getDependencyMappingDetails(ProjectID, userAccessPayLoad);

    const tableData = fetchData(dependencyTableData, isRDTData, isIQData, isCDPTData, isGABrifData);

    let updatedNewTabData = tableData?.filter(
      (data) => data.DSBP_PMP_PIMaterialID === selectedTab.description.DSBP_PMP_PIMaterialID
    );

    updateMappingTabValuesData(updatedNewTabData);
    // setFormData({});
    setLoader(false);
    showBackgroundProcess(toast);
  };

  const fetchDependencyMappingTabDetails = async (
    InitiativeID,
    PIMaterialID,
    PIMaterialNumber,
    projectId,
    reqId
  ) => {
    setLoader(true);
    const { response, AccessType } = await getDependencyMappingTabDetails(
      InitiativeID,
      PIMaterialID,
      PIMaterialNumber,
      projectId,
      userAccessPayLoad
    );
    setAccessType(AccessType);

    if (response) {
      const data = [
        ...(response?.FieldsList ?? []),
        ...(response?.PMPFPCData?.[0]?.FieldsList ?? []),
        ...(response?.PMPFPCData?.[0]?.PMPFPCFormulaData?.[0]?.FieldsList ?? []),
      ];

      let isChangedData = data
        .map((obj) => {
          if (obj["IsChanged"] == "True") {
            return obj;
          }
        })
        .filter((ele) => ele != null);

      setChangedData(isChangedData);
    }
    setLoader(false);
  };

  //return DSBP_PMP_PIMaterialID
  const getPIMaterialID = (materialNumber) => {
    // Loop through the array of objects

    for (const item of dmTabAttributesData?.DMMappingData) {
      // Check if the DSBP_PMP_PIMaterialNumber matches the provided material number
      if (item.DSBP_PMP_PIMaterialNumber === materialNumber) {
        return item.DSBP_PMP_PIMaterialID;
      }
    }
    return null;
  };
  const generatePMPObject = (value, comparisonValue, initiativeID) => {
    if (value === comparisonValue) {
      return null;
    }
    return `${value}-${getPIMaterialID(value)}-${initiativeID}`;
  };
  const getGABriefID = (gaBrief) => {
    if (gaBrief && gaBrief !== "Add GA Brief" && gaBrief !== "N/A") {
      const matchedBrief = dmTabData?.GABriefData?.find(
        (data) => data?.File_Name?.trim()?.toLowerCase() === gaBrief?.trim()?.toLowerCase()
      );
      return matchedBrief?.AWM_Task_ID || null; // Return Task ID or null
    }
    return null;
  };
  const onSubmit = async () => {
    //here function essentially searches for objects in the groupCIC array where any of these three fields (AWM_Supporting_PMP_Layout, AWM_Supporting_PMP_Design, or AWM_Other_Reference) match the DSBP_PMP_PIMaterialNumber from the description of the current tab panel. AWM-3427
    const otherCicMatchDatas = dmTabValuesData[tabPanelList]?.groupCIC?.filter(
      (item) =>
        item.AWM_Supporting_PMP_Layout ===
          dmTabValuesData[tabPanelList].description.DSBP_PMP_PIMaterialNumber ||
        item.AWM_Supporting_PMP_Design ===
          dmTabValuesData[tabPanelList].description.DSBP_PMP_PIMaterialNumber ||
        item.AWM_Other_Reference ===
          dmTabValuesData[tabPanelList].description.DSBP_PMP_PIMaterialNumber
    );
    setDeleteFlag(false);
    setLoader(true);
    let selectedData = selectedTab?.description?.AWM_CIC_Matrix;
    formData.AWM_GABrief = formData?.AWM_GABrief?.length
      ? formData.AWM_GABrief === "Add GA Brief"
        ? ""
        : formData.AWM_GABrief
      : "";
    //Added AWM_GABrief_ID property if its not Add GA Brief or N/A
    if (
      formData?.AWM_GABrief &&
      formData?.AWM_GABrief !== "Add GA Brief" &&
      formData?.AWM_GABrief !== "N/A"
    ) {
      formData.AWM_GABrief_ID = getGABriefID(formData?.AWM_GABrief);
    }

    setFormData(formData);

    formData["Task_Name"] = "Request_CIC_" + selectedTab?.description?.DSBP_PMP_PIMaterialNumber;
    formData["AWMProjectID"] = ProjectID;

    // group name
    formData["AWM_CICMatrix"] = selectedTab?.description?.AWM_CIC_Matrix || "";

    if (cicNeeded === "Yes") {
      formData["AWM_OtherReference"] = "";
    }
    if (cicMatrix === "CIC MATRIX ONLY") {
      //cic matrix type
      formData["CICMatrix"] = cicMatrix;
      formData["Action"] = "";
      formData["Task_Name"] = "Request_CIC_" + selectedTab?.description?.DSBP_PMP_PIMaterialNumber;
    }

    if (cicMatrix === "CIC MATRIX AND CIC") {
      formData["CICMatrix"] = cicMatrix;
      formData["Action"] = "";
      formData["Task_Name"] = `CICM_${selectedTab?.description?.AWM_CIC_Matrix}_${task_Name}${
        selectedTab?.description?.DSM_PMP_PMPPackagingComponentType
          ? "_" + selectedTab?.description?.DSM_PMP_PMPPackagingComponentType
          : ""
      }`;
    }
    if (isToggleClick) {
      delete formData.Task_Name;
    }

    if (deleteFlag) {
      formData["CICMatrix"] = "";
      formData["AWM_CICMatrix"] = "";
      formData["Action"] = "DELETE";
      formData["Task_Name"] = "Request_CIC_" + selectedTab?.description?.DSBP_PMP_PIMaterialNumber;
    }
    formData["AWM_CICNeeded"]= 
              formData["AWM_CICNeeded"] === CIC_DEPENDENT ? "No" :
              formData["AWM_CICNeeded"] === NOT_NEEDED ? "N/A" :
              formData["AWM_CICNeeded"];
    const { Regional_Design_Template, Color_Development_and_Print_Trial, Ink_Qualification, ...newObj } = formData;
    let payLoadData = [newObj];
    //The code is processing an array otherCicMatchDatas to create a new array otherCicDatasNew by mapping over the matched data and transforming each item into a new format. Awm-3427
    let otherCicDatasNew = [];
    if (otherCicMatchDatas) {
      otherCicDatasNew = otherCicMatchDatas?.map((item) => {
        let filteredItem = {
          AWMProjectID: ProjectID,
          AWM_CICMatrix: item?.AWM_CIC_Matrix,
          AWM_CICNeeded: 
              item?.AWM_CIC_Needed === CIC_DEPENDENT ? "No" :
              item?.AWM_CIC_Needed === NOT_NEEDED ? "N/A" :
              item?.AWM_CIC_Needed,
          AWM_GABrief_ID: getGABriefID(item?.AWM_GA_Brief),
          AWM_GABrief: item?.AWM_GA_Brief?.length
            ? item.AWM_GA_Brief === "Add GA Brief"
              ? ""
              : item.AWM_GA_Brief
            : "",
          AWM_OtherReference: item?.AWM_Other_Reference,

          AWM_SupportingPMPDesignPage: createPMPObject(
            generatePMPObject(
              item?.AWM_Supporting_PMP_Design,
              dmTabValuesData[tabPanelList].description.DSBP_PMP_PIMaterialNumber,
              dmTabValuesData[tabPanelList].description?.DSBP_InitiativeID
            )
          ), //updated to object format

          AWM_SupportingPMPLayoutPage: createPMPObject(
            generatePMPObject(
              item?.AWM_Supporting_PMP_Layout,
              dmTabValuesData[tabPanelList].description.DSBP_PMP_PIMaterialNumber,
              dmTabValuesData[tabPanelList].description?.DSBP_InitiativeID
            )
          ), //updated to object format

          CICMatrix: cicMatrix,
          DSBP_InitiativeID: item?.DSBP_InitiativeID,
          DSBP_PMP_PIMaterialID: item?.DSBP_PMP_PIMaterialID,
          DSBP_PMP_PIMaterialNumber: item?.DSBP_PMP_PIMaterialNumber,
          updated: true,
          Task_Name: "Request_CIC_" + item?.DSBP_PMP_PIMaterialNumber,
        };
        return filteredItem;
      });
      const { Regional_Design_Template, Color_Development_and_Print_Trial, Ink_Qualification, ...newObj } = formData;
      payLoadData = [newObj, ...otherCicDatasNew];
    }

    // let otherCicDatas = [];
    // if (isToggleClick) {
    //   otherCicDatas = dmTabValuesData[tabPanelList]?.groupCIC?.filter(
    //     (item) => item?.DSBP_PMP_PIMaterialNumber !== formData?.DSBP_PMP_PIMaterialNumber
    //   );
    //   otherCicDatas = otherCicDatas?.map((item) => {
    //     let filteredItem = {
    //       AWMProjectID: ProjectID,
    //       AWM_CICMatrix: item?.AWM_CIC_Matrix,
    //       AWM_CICNeeded: item?.AWM_CIC_Needed,
    //       AWM_GABrief: item?.AWM_GA_Brief?.length
    //         ? item.AWM_GA_Brief === "Add GA Brief"
    //           ? ""
    //           : item.AWM_GA_Brief
    //         : "",
    //       AWM_GABrief_ID: getGABriefID(item?.AWM_GA_Brief),
    //       AWM_OtherReference: item?.AWM_Other_Reference,
    //       AWM_SupportingPMPDesign: createPMPObject(item?.AWM_Supporting_PMP_Design), //updated to object format
    //       AWM_SupportingPMPLayout: createPMPObject(item?.AWM_Supporting_PMP_Layout), //updated to object format
    //       Action: "",
    //       CICMatrix: cicMatrix,
    //       DSBP_InitiativeID: item?.DSBP_InitiativeID,
    //       DSBP_PMP_PIMaterialID: item?.DSBP_PMP_PIMaterialID,
    //       DSBP_PMP_PIMaterialNumber: item?.DSBP_PMP_PIMaterialNumber,
    //       updated: true,
    //       Task_Name: "Request_CIC_" + item?.DSBP_PMP_PIMaterialNumber,
    //     };

    //     return filteredItem;
    //   });
    //   payLoadData = [formData, ...otherCicDatas];
    // }
    const submitData = createManageDependencySaveDataDMTabByTab(selectedTab, rdt, cdpt, iq)
    const updatedPmpDetails = { 
      DSBPValues: payLoadData,
      CICDependencyPage: submitData?.CICDependencyPage,
      SuccessorPredecessor: submitData?.SuccessorPredecessor,
    };

    // Call POST API of create new GA Brief
    if (gaBrief === "Add GA Brief") {
      handleNewGaBrief();
    }

    // Call POST API to save tab data
    await onSubmitDependencyMappingAction(updatedPmpDetails, ProjectID);

    // Call GET API of dependency mapping
    // const {
    //   dependencyTableData,
    //   isRDTData,
    //   isIQData,
    //   isCDPTData,
    //   isGABrifData,
    // } = await getDependencyMappingDetails(selectedProject?.Project_ID);

    // const tableData = fetchData(
    //   dependencyTableData,
    //   isRDTData,
    //   isIQData,
    //   isCDPTData,
    //   isGABrifData
    // );

    // let updatedNewTabData = tableData?.filter(
    //   (data) =>
    //     data.DSBP_PMP_PIMaterialID ===
    //     selectedTab.description.DSBP_PMP_PIMaterialID
    // );

    // updateMappingTabValuesData(updatedNewTabData);
    // setFormData({});
    updateDMPMPTabData();
    setLoader(false);
    markClean();
    setInternalPageEdited(false);
  };

  const updateDMPMPTabData = async () => {
    const { dependencyTableData, isRDTData, isIQData, isCDPTData, isGABrifData } =
      await getDependencyMappingDetails(ProjectID, userAccessPayLoad);

    const tableData = fetchData(dependencyTableData, isRDTData, isIQData, isCDPTData, isGABrifData);

    let updatedNewTabData = tableData?.filter(
      (data) => data.DSBP_PMP_PIMaterialID === selectedTab.description.DSBP_PMP_PIMaterialID
    );

    updateMappingTabValuesData(updatedNewTabData);
  };

  const fetchData = (dependencyTableData, isRDTData, isIQData, isCDPTData, isGABrifData) => {
    if (dependencyTableData && dependencyTableData.length) {
      //code fix 3355 : Refactored the code to display the column names in both tabular and tab by tab view

      const transformedData = dependencyTableData?.map((item) =>
        transformDependencyData(item, isRDTData, isCDPTData, isIQData, isGABrifData)
      );
      let columnNames = Object.keys(transformedData[0]);
      return transformedData;
    }
  };

  // const isChangedValue = (value) => {
  //   const hasMatch = Boolean(
  //     changedData.find((el) => {
  //       return el.FieldName == value;
  //     })
  //   );
  //   return hasMatch;
  // };

  const getLatestChangedValues = (changedData, value, item) => {
    const isFound = changedData?.some(
      (el) => el.FieldName?.includes(value) && el.FieldName !== "RTA_RTAPOAStatus"
    );
    return (
      <span>
        {isFound ? (
          <span className="text-red">{getLatestValue(changedData, value)}</span>
        ) : (
          <span>{item?.[value]}</span>
        )}
      </span>
    );
  };

  const isChangedValue = (value) => {
    const hasMatch = changedData?.map((el) => el?.FieldName).includes(value);
    return hasMatch;
  };

  const getLatestValue = (changedData, value) => {
    const data = changedData?.filter((el) => {
      return el?.FieldName?.includes(value);
    });
    return data[0]?.FieldValue;
  };
  const dependencyOptionTemplate = (option) => {
    return (
      <div className="dependency-options" onClick={(e) => e.stopPropagation()}>
        <div
          className="option-name"
          onClick={(e) => e.stopPropagation()}
        >
          {option?.AWM_Design_Job_Name}
        </div>
      </div>
    );
  };

  const handleEditIconClick = () => {
    if (internalPageEdited) {
      setShowDialog(true);
      setActionType("edit");
    } else {
      setShowManageDependency(true);
    }
  };

  const renderData = (tabData, group) => {
    let dependencyColumnNames1 = dmTabData.DMColumnNames;

    const dependencyColumnNames2 = dmTabData?.CDPTPageData?.length
      ? dependencyColumnNames1
      : dependencyColumnNames1.filter((item) => item.field !== "Color_Development_and_Print_Trial");

    const dependencyColumnNames3 = dmTabData?.RDTData?.length
      ? dependencyColumnNames2
      : dependencyColumnNames2.filter((item) => item.field !== "Regional_Design_Template");

    const dependencyColumnNames4 = dmTabData?.IQData?.length
      ? dependencyColumnNames3
      : dependencyColumnNames3.filter((item) => item.field !== "Ink_Qualification");

    let allColumns = dependencyColumnNames4;

    if (CICs === false) {
      allColumns = dependencyColumnNames4.filter(
        (item) =>
          item.field !== "AWM_CIC_Needed" &&
          item.field !== "AWM_GA_Brief" &&
          item.field !== "AWM_Supporting_PMP_Layout" &&
          item.field !== "AWM_Supporting_PMP_Design" &&
          item.field !== "AWM_Other_Reference" &&
          item.field !== "AWM_CIC_Matrix" &&
          item.field !== "AWM_CIC_Matrix_Requested"
      );
    }

    const groupOneCols = allColumns.filter((col) => col.group === 1);
    const groupTwoCols = allColumns.filter((col) => col.group === 2);
    const groupThreeCols = allColumns.filter((col) => col.group === 3);
    const convertedInObject = [tabData];

    const groupColumns =
      group === "groupOne"
        ? groupOneCols
        : group === "groupTwo"
        ? groupTwoCols
        : group === "groupThree"
        ? groupThreeCols
        : allColumns;
    if (groupColumns && groupColumns.length) {
      return groupColumns.map((field, index) => {
        const value = field?.field;
        // const filteredItems = convertedInObject?.filter(
        //   (item) => item && item[value] !== undefined
        // );
        let field1 = field?.field;

        if (value === "AWM_Supporting_PMP_Design" || value === "AWM_Other_Reference") {
          field1 = value + "_" + "(optional)";
        }
        //updated the label as per tabular view
        if (value === "AWM_CIC_Matrix_Requested") {
          field1 = "AWM Request CIC/CIC Matrix";
        }
        let splittedCol = field1.split("_").join(" ");

        let selectedData = selectedTab?.description;
        let isCICMatrix = selectedData?.AWM_CIC_Matrix !== "" ? true : false;

        let isalreadyRequested =
          selectedData?.AWM_CIC_Matrix_Requested1 === "Yes" ||
          selectedData?.AWM_CIC_Requested === "Yes";

        let disableCICdropdown =
          selectedData?.AWM_CIC_Matrix_Requested1 === "Yes" ||
          selectedData?.AWM_CIC_Requested === "Yes" ||
          selectedData?.AWM_CIC_Matrix_Toggle;

        let disableBasedOnStatus=selectedData?.Copy_XML_Request_Status?.toLowerCase() === dsbpConstant?.xml_awaiting_response;

        // change this condition for development to check fullkit is ready or not

        let isCICRequestFullKitSatisfied =
          selectedData?.FullKit_Condition_Checking_Page?.[0]?.CIC_FullKit_Satisfied === "Yes" ||
          selectedData?.FullKit_Condition_Checking_Page?.[1]?.CIC_FullKit_Satisfied === "Yes"
            ? true
            : false;
        // change this condition for development to check fullkit is ready or not
        let isCICMatrixRequestFullKitSatisfied =
          selectedData?.FullKit_Condition_Checking_Page?.[1]?.CICMatrix_FullKit_Satisfied ===
            "Yes" ||
          selectedData?.FullKit_Condition_Checking_Page?.[0]?.CICMatrix_FullKit_Satisfied === "Yes"
            ? true
            : false;

        let xmlRequestStatus =
          selectedData?.Copy_XML_Request_Status?.toLowerCase() ===
          dsbpConstant?.xml_awaiting_response;
        let resp = getRequestText(
          cicNeeded,
          isCICMatrixRequestFullKitSatisfied,
          isCICRequestFullKitSatisfied,
          isCICMatrix,
          isalreadyRequested,
          xmlRequestStatus
        );

        let buttonLabel = resp.name;

        const handleCheckboxChange = (e, field) => {
          if(field.field === 'Regional_Design_Template'){
            handleRDTchange({target:{
              value:
                e.checked ?
                [{
                  AWM_Design_Job_ID : "DT_None",
                  AWM_Design_Job_Name : "None",
                  Design_Job_Status: "None"
                }] :
                []
            }})
            setRDTCheckboxValue(e.checked)
          }
          if(field.field === 'Ink_Qualification'){
            handleIQChange({target:{
              value:
                e.checked ?
                [{
                  AWM_Design_Job_ID : "IQ_None",
                  AWM_Design_Job_Name : "None",
                  Design_Job_Status: "None"
                }] :
                []
            }})
            setIQCheckboxValue(e.checked)
          }
          if(field.field === 'Color_Development_and_Print_Trial'){
            handleCDPTChange({target:{
              value:
                e.checked ? 
                [{
                  AWM_Design_Job_ID : "NPF_None",
                  AWM_Design_Job_Name : "None",
                  Design_Job_Status: "None"
                }] :
                []
            }})
            setCDPTCheckboxValue(e.checked)
          } 

        }

        const handleToggle = (e, fieldType, options, rowData, removeUpdated, InitialValue) => {
          if (selectedData?.AWM_CIC_Matrix_Requested1 !== "Yes") {
            let fieldValue = "CIC MATRIX ONLY";
            if (cicMatrix === "CIC MATRIX ONLY") {
              fieldValue = "CIC MATRIX AND CIC";
            }

            if (cicMatrix === "CIC MATRIX AND CIC") {
              fieldValue = "CIC MATRIX ONLY";
            }
            setCICMatrix(fieldValue);
            setToggleClick(true);
            setInternalPageEdited(true);
            setFormData({
              ...formData,
              AWM_CIC_Matrix_Toggle: fieldValue,
              // updated: InitialValue === fieldValue ? false : true,
            });
          }
        };

        return convertedInObject.map((item) => {
          const showRedIcon = checkIfRedIcon(item);
          const showGreyIcon = checkIfGreyIcon(item);
          const showAlertIcon = showRedIcon || showGreyIcon;
          let isalreadyRequested =
            item?.AWM_CIC_Matrix_Requested1 === "Yes" || item?.AWM_CIC_Requested === "Yes";

          return (
            <tr key={item[value]}>
              <td className="columnWidth">
                {splittedCol?.replaceAll("_", " ")}
                {(field.field === "AWM_Supporting_PMP_Layout" ||
                  field.field === "AWM_Supporting_PMP_Design" ||
                  field.field === "AWM_Other_Reference") && (
                  <span>
                    <img src={CICInfo} className="cicinfoicontab" alt="" />
                    <Tooltip
                      position="top"
                      target=".cicinfoicontab"
                      className="tooltip-white-cic"
                      content={dsbpConstant?.cicTooltipMessage}
                    />
                  </span>
                )}
              </td>
              <td>
                {(field.field === "Regional_Design_Template" || field.field === "Ink_Qualification" || field.field === "Color_Development_and_Print_Trial") && (
                  <div className="d-flex justify-content-between">
                    <div className='table-row' 
                      disabled={
                        !finalPermission || 
                        cicNeeded !== "Yes" ||
                        isalreadyRequested ||
                        Project_State === "Complete" ||
                        disableBasedOnStatus
                      }
                    >
                      <div className="none_rdt_iq_cd">
                        <div>
                          <Checkbox
                            className={
                              cicNeeded === "Yes" && 
                              ((field.field === 'Regional_Design_Template' && rdt?.length===0)
                              || (field.field === 'Ink_Qualification' && iq?.length===0)
                              || (field.field === 'Color_Development_and_Print_Trial' && cdpt?.length===0))
                              ? "checkbox-border-red" : ""
                            }
                            onChange={(e) => {  
                              handleCheckboxChange(e, field)
                            }}
                            checked={field.field === 'Regional_Design_Template' ? rdtCheckboxValue : field.field === 'Ink_Qualification' ? iqCheckboxValue : field.field === 'Color_Development_and_Print_Trial' ? cdptCheckboxValue : false}
                            disabled={
                              !finalPermission || 
                              cicNeeded !== "Yes" ||
                              isalreadyRequested ||
                              Project_State === "Complete" ||
                              disableBasedOnStatus
                            }
                          ></Checkbox>
                        </div>
                        <span
                          className={
                            (!finalPermission || 
                              cicNeeded !== "Yes" ||
                              isalreadyRequested ||
                              Project_State === "Complete" ||
                              disableBasedOnStatus) &&
                            "disabled-text label-header-name"
                          }
                        >
                          None
                        </span>
                      </div>
                      <MultiSelect
                        className={
                          cicNeeded === "Yes" && 
                          ((field.field === 'Regional_Design_Template' && rdt?.length===0)
                          || (field.field === 'Ink_Qualification' && iq?.length===0)
                          || (field.field === 'Color_Development_and_Print_Trial' && cdpt?.length===0))
                          ? "border-red predecessor-select" : "predecessor-select"
                        }
                        value={
                          field.field === 'Regional_Design_Template' 
                          ? rdt.filter((obj)=>!obj.AWM_Design_Job_ID.includes("_None"))
                          : field.field === 'Ink_Qualification' 
                          ? iq.filter((obj)=>!obj.AWM_Design_Job_ID.includes("_None"))
                          : field.field === 'Color_Development_and_Print_Trial' 
                          ? cdpt.filter((obj)=>!obj.AWM_Design_Job_ID.includes("_None"))
                          : []
                        }
                        onChange={(e)=>{
                            const values_selected = e.target.value.filter((obj)=>{
                              return !obj.AWM_Design_Job_ID.includes("_None")
                            })
                            if(field.field === 'Regional_Design_Template'){
                              handleRDTchange({target:{value:values_selected}})
                            }
                            if(field.field === 'Ink_Qualification'){
                              handleIQChange({target:{value:values_selected}})
                            }
                            if(field.field === 'Color_Development_and_Print_Trial'){
                              handleCDPTChange({target:{value:values_selected}})
                            }
                          }
                        }
                        
                        options={field.field === 'Regional_Design_Template' ? dmTabData?.RDTData : field.field === 'Ink_Qualification' ? dmTabData?.IQData : field.field === 'Color_Development_and_Print_Trial' ? dmTabData?.CDPTPageData : {} }
                        optionLabel="AWM_Design_Job_Name"
                        placeholder="Please Select"
                        filter
                        itemTemplate={(option) => dependencyOptionTemplate(option)}
                        maxSelectedLabels={false}
                        panelClassName="dm-multiselect-dropdown"
                        disabled={
                          !finalPermission || 
                          cicNeeded !== "Yes" ||
                          isalreadyRequested ||
                          Project_State === "Complete" ||
                          disableBasedOnStatus
                        }
                      />
                      <ShowMoreList
                        data = {rearrangeByStatusOrderAndSortAlphabetically(
                          (field.field === 'Regional_Design_Template' ? rdt : field.field === 'Ink_Qualification' ? iq : field.field === 'Color_Development_and_Print_Trial' ? cdpt : [])?.filter((obj)=>{
                            return !obj.AWM_Design_Job_ID.includes("_None")
                          })
                        )}
                        propertyName="AWM_Design_Job_Name" 
                        sliceIndex={2} 
                        disabled={
                          !finalPermission || 
                          cicNeeded !== "Yes" ||
                          isalreadyRequested ||
                          Project_State === "Complete" ||
                          disableBasedOnStatus
                        }
                      />
                    </div>
                  </div>
                )}
                {field.field === "AWM_CIC_Needed" && (
                  <Form.Group
                    controlId="groupName.ControlInput1"
                    disabled={Project_State === "Complete" || !accessSecurityMatrix}
                  >
                    <Form.Select
                      placeholder="Select"
                      value={cicNeeded}
                      onChange={handleCICNeededChange}
                      style={{ width: "80%", fontSize: 12 }}
                      disabled={
                        disableCICdropdown ||
                        disableBasedOnStatus ||
                        item.AWM_CIC_Matrix_Requested1 === "Yes" ||
                        Project_State === "Complete"
                      }
                    >
                      <option className="hidden-option" value="" default selected>
                        Select
                      </option>
                      {dmTabData.cicNeededOptionList?.map((data) => (
                        <option key={data.code} value={data.name}>
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
                {field.field === "AWM_Supporting_PMP_Design" &&
                  (cicNeeded === "" || !cicNeeded || cicNeeded === "Yes" || cicNeeded === NOT_NEEDED ? (
                    " "
                  ) : (
                    <Form.Group
                      controlId="groupName.ControlInput1"
                      disabled={Project_State === "Complete" || !accessSecurityMatrix}
                      // style={{ textAlign: "-webkit-center" }}
                    >
                      <Form.Select
                        placeholder="Select"
                        value={pmpDesign}
                        onChange={handlePmpDesignChange}
                        className={
                          pmpDesign === "" && pmpLayout === "" && otherRef === ""
                            ? "border-red"
                            : "border-normal"
                        }
                        style={{ width: "80%", fontSize: 12 }}
                      >
                        {/* Defect 3417: Removed 'Select' to maintain consistency between the tabular view and tab-by-tab view */}
                        <option className="hidden-option" value="" default selected>
                          Select
                        </option>
                        {dmTabData?.SPMPDesignData?.map(
                          (data, index) =>
                            data !== formData?.DSBP_PMP_PIMaterialNumber && (
                              <option // filter out the opened pmp from the dropdown option
                                key={data.code}
                                data-dropdownMI={dropdownMI[index]}
                                data-dropdownInitiateID={dropdownInitiateID[index]}
                                value={data}
                              >
                                {data}
                              </option>
                            )
                        )}
                      </Form.Select>
                    </Form.Group>
                  ))}
                {field.field === "AWM_Supporting_PMP_Layout" &&
                  (cicNeeded === "" || !cicNeeded || cicNeeded === "Yes" || cicNeeded === NOT_NEEDED ? (
                    " "
                  ) : (
                    <Form.Group
                      controlId="groupName.ControlInput1"
                      disabled={Project_State === "Complete" || !accessSecurityMatrix}
                    >
                      <Form.Select
                        placeholder="Select"
                        value={pmpLayout}
                        onChange={handlePmpLayoutChange}
                        // className={
                        //   formData?.AWM_CICNeeded === 'No' &&
                        //   (formData?.AWM_SupportingPMPLayout === ' ' ||
                        //     formData?.AWM_SupportingPMPLayout === '')
                        //     ? 'border-color'
                        //     : ''
                        // }
                        className={
                          pmpDesign === "" && pmpLayout === "" && otherRef === ""
                            ? "border-red"
                            : "border-normal"
                        }
                        style={{ width: "80%", fontSize: 12 }}
                      >
                        {/* Defect 3417: Removed 'Select' to maintain consistency between the tabular view and tab-by-tab view */}
                        <option className="hidden-option" value="" default selected>
                          Select
                        </option>
                        {dmTabData?.SPMPLayoutData?.map(
                          (data, index) =>
                            data !== formData?.DSBP_PMP_PIMaterialNumber && (
                              <option // filter out the opened pmp from the dropdown option
                                key={data.code}
                                value={data}
                                data-dropdownMI={dropdownMI[index]}
                                data-dropdownInitiateID={dropdownInitiateID[index]}
                              >
                                {data}
                              </option>
                            )
                        )}
                      </Form.Select>
                    </Form.Group>
                  ))}
                {field.field === "AWM_Other_Reference" &&
                  (cicNeeded === "" || !cicNeeded || cicNeeded === "Yes" ? (
                    " "
                  ) : (
                    <Form.Group
                      disabled={Project_State === "Complete" || !accessSecurityMatrix}
                      controlId="groupName.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        maxLength={8}
                        value={otherRef}
                        onChange={handleOtherRefChange}
                        className={
                          cicNeeded === CIC_DEPENDENT &&
                          pmpDesign === "" &&
                          pmpLayout === "" &&
                          otherRef === ""
                            ? "border-red"
                            : "border-normal"
                        }
                        style={{ width: "24%", fontSize: 12, height: "50%" }}
                      ></Form.Control>
                      {otherRef?.length < 8 && otherRef?.length !== 0 && (
                        <div className="text-red">{errorMessage}</div>
                      )}
                    </Form.Group>
                  ))}
                {field.field === "AWM_GA_Brief" && (
                  <div>
                    <Form.Group
                      controlId="groupName.ControlInput1"
                      disabled={Project_State === "Complete" || !accessSecurityMatrix}
                      // style={{ textAlign: "-webkit-center" }}
                    >
                      <Form.Select
                        placeholder="Select"
                        value={gaBrief}
                        onChange={handleGABriefChange}
                        style={{ width: "80%", fontSize: 12 }}
                        // className={
                        //   formData?.AWM_CICNeeded === "Yes" &&
                        //   (formData.AWM_GABrief === " " || formData.AWM_GABrief === "")
                        //     ? "border-color"
                        //     : ""
                        // }
                        disabled={
                          item.AWM_CIC_Matrix_Requested1 === "Yes" ||
                          Project_State === "Complete" ||
                          item?.AWM_CIC_Requested === "Yes" ||
                          disableBasedOnStatus
                        }
                      >
                        <option className="hidden-option" value="" default selected>
                          Select
                        </option>
                        {dmTabData.GABriefData?.map((data) => (
                          <option key={data.File_Name} value={data.File_Name}>
                            {data.File_Name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                )}

                {field.field === "AWM_CIC_Matrix_Requested" &&
                  cicNeeded === "Yes" &&
                  deleteFlag !== true &&
                  (cicNeeded === "" ? (
                    ""
                  ) : (
                    <>
                      <Tooltip
                        className="xml-tooltip"
                        target=".xml-tooltip-target"
                        position="left"
                      />
                      <div
                        className={`cic-button-alignment ${
                          xmlRequestStatus ? "xml-tooltip-target" : ""
                        }`}
                        data-pr-tooltip={dsbpConstant?.xml_tooltip_value}
                      >
                        <Button
                          style={{ fontSize: 12, height: 23, lineHeight: 13 }}
                          severity={resp?.severity}
                          disabled={
                            resp?.disabled || Project_State === "Complete" || !accessSecurityMatrix
                          }
                          onClick={() => saveCICAndCicMatrixRequest(item, resp?.reqId)}
                          className={resp?.type === "text" ? "p-button-text" : ""}
                        >
                          {buttonLabel}
                        </Button>
                        {/* <Button style={{height:25, fontSize:12}} severity="success">Request CIC Matrix</Button>
                        <Button  style={{height:25, fontSize:12}} severity="secondary" disabled>Request CIC </Button> */}
                      </div>
                    </>
                  ))}

                {field.field === "AWM_CIC_Matrix" &&
                  item.AWM_CIC_Matrix !== "" &&
                  (cicNeeded === "" || !cicNeeded || cicNeeded === CIC_DEPENDENT || cicNeeded === NOT_NEEDED ? (
                    ""
                  ) : (
                    <div>
                      <span>
                        <img
                          src={deleteIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isalreadyRequested || xmlRequestStatus || !accessSecurityMatrix) {
                              //AWM-3495: condition added
                              return false; // Returning false if button is disabled
                            }
                            if (!isalreadyRequested) {
                              setDeleteFlag(true);
                            }
                          }}
                          alt="filter logo"
                          disabled={
                            isalreadyRequested ||
                            xmlRequestStatus || //AWM-3495: condition added
                            Project_State === "Complete" ||
                            !accessSecurityMatrix
                          }
                          style={{ height: 16 }}
                          className="cursor-pointer"
                        />
                      </span>
                      <span> {selectedData?.AWM_CIC_Matrix} </span>
                      <span
                        className={
                          item[value] === true ? "cic-matrix-text-on" : "cic-matrix-text-off"
                        }
                      >
                        CIC Matrix Only
                      </span>
                      {/* <img
                        src={
                          cicMatrix === "CIC MATRIX ONLY" || cicMatrix === "" ? toggleOff : toggleOn
                        }
                        className="add-new-design-intent-icon"
                        style={{ height: 20 }}
                        alt="Add role button"
                        disabled={
                          selectedData?.AWM_CIC_Matrix_Requested === "Yes" ||
                          Project_State === "Complete" ||
                          !accessSecurityMatrix
                        }
                        onClick={(e) => {
                          if (selectedData?.AWM_CIC_Matrix_Requested !== "Yes") {
                            let fieldValue = "CIC MATRIX ONLY";
                            if (cicMatrix === "CIC MATRIX ONLY") {
                              fieldValue = "CIC MATRIX AND CIC";
                            }

                            if (cicMatrix === "CIC MATRIX AND CIC") {
                              fieldValue = "CIC MATRIX ONLY";
                            }
                            setCICMatrix(fieldValue);
                            setToggleClick(true);
                            setInternalPageEdited(true);
                            setFormData({
                              ...formData,
                              AWM_CICMatrix: fieldValue,
                              updated: true,
                            });
                          }
                        }}
                      /> */}
                      <CustomInput
                        type={"Toggle"}
                        field={"Toggle"}
                        value={cicMatrix}
                        alt="Add role button"
                        style={{ height: 20 }}
                        onChange={handleToggle}
                        fieldType={field?.field}
                        innerClassName="add-new-design-intent-icon"
                        disabled={
                          selectedData?.AWM_CIC_Matrix_Requested1 === "Yes" ||
                          xmlRequestStatus ||
                          Project_State === "Complete" ||
                          !accessSecurityMatrix
                        }
                        src={
                          cicMatrix === "CIC MATRIX ONLY" || cicMatrix === "" ? toggleOff : toggleOn
                        }
                      />
                      <span
                        className={
                          item[value] === false ? "cic-matrix-text-on" : "cic-matrix-text-off"
                        }
                      >
                        CIC Matrix & CIC's
                      </span>
                    </div>
                  ))}
                {/* {field.field === "AWM_CIC_Matrix_Toggle" && <>{item["AWM_CIC_Matrix_Toggle"]}</>} */}

                {field.field === "DSBP_PMP_PIMaterialNumber" && (
                  <>
                    <span>
                      {isChangedValue("DSBP_PMP_PIMaterialNumber") ? (
                        <>
                          <span className="text-option text-red">
                            {getLatestValue(changedData, "DSBP_PMP_PIMaterialNumber", item)}{" "}
                          </span>
                          <AlertIcon
                            iconType="error"
                            showAlertIcon={showAlertIcon}
                            // onClick={() => {
                            //   navigate(
                            //     `/${currentUrl?.[1]}/DSBP/tab/dependencyMapping/ChangeNotification/${ProjectID}`,
                            //     {
                            //       state: {
                            //         from: "dependencyTabPage",
                            //         assignee: assignee,
                            //         InitiativeID: item.DSBP_InitiativeID,
                            //         PIMaterialID: item.DSBP_PMP_PIMaterialID,
                            //         PIMaterialNumber: item.DSBP_PMP_PIMaterialNumber,
                            //       },
                            //     }
                            //   );
                            // }}
                            onClick={() =>
                              handleNavigateToUrlClick(item, "DSBP_PMP_PIMaterialNumber_Alert")
                            }
                            editable={showGreyIcon}
                            tooltipClass="tooltip-white"
                            tooltipText={
                              showGreyIcon
                                ? dsbpConstant.xml_awaiting_response_enovia
                                : dsbpConstant.xml_view_changes
                            }
                            tooltipPosition={"top"}
                          />
                        </>
                      ) : (
                        <>
                          <span className="text-option">{item[value]} </span>
                          <AlertIcon
                            iconType="error"
                            showAlertIcon={showAlertIcon}
                            // onClick={() => {
                            //   navigate(
                            //     `/${currentUrl?.[1]}/DSBP/tab/dependencyMapping/ChangeNotification/${ProjectID}`,
                            //     {
                            //       state: {
                            //         from: "dependencyTabPage",
                            //         assignee: assignee,
                            //         InitiativeID: item.DSBP_InitiativeID,
                            //         PIMaterialID: item.DSBP_PMP_PIMaterialID,
                            //         PIMaterialNumber: item.DSBP_PMP_PIMaterialNumber,
                            //       },
                            //     }
                            //   );
                            // }}
                            onClick={() =>
                              handleNavigateToUrlClick(item, "DSBP_PMP_PIMaterialNumber_Alert")
                            }
                            editable={showGreyIcon}
                            tooltipClass="tooltip-white"
                            tooltipText={
                              showGreyIcon
                                ? dsbpConstant.xml_awaiting_response_enovia
                                : dsbpConstant.xml_view_changes
                            }
                            tooltipPosition={"top"}
                          />
                        </>
                      )}
                    </span>
                  </>
                )}

                {field.field !== "Color_Development_and_Print_Trial" &&
                  field.field !== "Regional_Design_Template" &&
                  field.field !== "Ink_Qualification" &&
                  field.field !== "AWM_CIC_Needed" &&
                  field.field !== "AWM_Supporting_PMP_Design" &&
                  field.field !== "AWM_Supporting_PMP_Layout" &&
                  field.field !== "AWM_Other_Reference" &&
                  field.field !== "AWM_GA_Brief" &&
                  field.field !== "AWM_CIC_Matrix_Requested" &&
                  field.field !== "AWM_CIC_Matrix" &&
                  field.field !== "DSBP_PMP_PIMaterialNumber" && (
                    <>
                      <span>
                        {isChangedValue(field?.field) ? (
                          <span className="text-red">{getLatestChangedValues(changedData, field?.field, item)}</span>
                        ) : (
                          <span>{item[value]}</span>
                          // <span><Showmore text={item[value]} sliceIndex={100} /></span>
                        )}
                      </span>
                    </>
                  )}
              </td>
            </tr>
          );
        });
      });
    }
    return null;
  };

  const handleNavigateToUrlClick = (obj, type) => {
    if (internalPageEdited) {
      setShowDialog(true);
      setActionType(type);
      setTempObj(obj);
    } else {
      handleNavigateToUrl(obj, type);
    }
  };

  const handleNavigateToUrl = (obj, type) => {
    navigate(`/${currentUrl?.[1]}/DSBP/tab/dependencyMapping/ChangeNotification/${ProjectID}`, {
      state: {
        from: "dependencyTabPage",
        assignee: assignee,
        InitiativeID:
          type === "tabsCompo" ? obj?.description?.DSBP_InitiativeID : obj?.DSBP_InitiativeID,
        PIMaterialID:
          type === "tabsCompo"
            ? obj?.description?.DSBP_PMP_PIMaterialID
            : obj?.DSBP_PMP_PIMaterialID,
        PIMaterialNumber:
          type === "tabsCompo"
            ? obj?.description?.DSBP_PMP_PIMaterialNumber
            : obj?.DSBP_PMP_PIMaterialNumber,
      },
    });
  };

  const tabsCompo = (obj) => {
    const showGreyIcon = checkIfGreyIcon(obj?.description);
    const showRedIcon = checkIfRedIcon(obj?.description);
    const showAlertIcon = showRedIcon || showGreyIcon;
    return (
      <div className="tabsCompo">
        <div className="btn-right-wrapper">
          {showAlertIcon ? (
            <button
              className={`btn btn-right`}
              // onClick={() => {
              //   navigate(
              //     `/${currentUrl?.[1]}/DSBP/tab/dependencyMapping/ChangeNotification/${ProjectID}`,
              //     {
              //       state: {
              //         from: "dependencyTabPage",
              //         assignee: assignee,
              //         InitiativeID: obj?.description?.DSBP_InitiativeID,
              //         PIMaterialID: obj?.description?.DSBP_PMP_PIMaterialID,
              //         PIMaterialNumber: obj?.description?.DSBP_PMP_PIMaterialNumber,
              //       },
              //     }
              //   );
              // }}
              onClick={() => handleNavigateToUrlClick(obj, "tabsCompo")}
            >
              {dsbpConstant.xml_view_changes_btn}
            </button>
          ) : null}
        </div>
        <div className="tabsCompoMainDM">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="pmp-details">PMP Details</Accordion.Header>
              <Accordion.Body className="pmp-details">
                <table className="table table-sm table-hover">
                  <tbody>{renderData(obj?.description, "groupOne")}</tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="mapping">Mapping</Accordion.Header>
              <Accordion.Body className="mapping">
                <table className="table table-sm table-hover">
                  <tbody>{renderData(obj?.description, "groupTwo")}</tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="dsm-fields">DSM Fields</Accordion.Header>
              <Accordion.Body className="dsm-fields">
                <table className="table table-sm table-hover">
                  <tbody>{renderData(obj?.description, "groupThree")}</tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    );
  };

  const handleDelete = (index) => {
    // if page is edited we will temprorily save setTempTabIndex, and showWarning,
    // on Click of confirmation via yes, we will call confirmAction
    // which will execute pending action based on actiontype
    if (internalPageEdited) {
      setShowDialog(true);
      setTempTabIndex(index);
      setActionType("delete");
    } else {
      // else blcok will execute as earlier with this reusable function, if no internalPage edited
      onDelete(index);
    }
  };

  // making a reusable function for earlier delete function
  const onDelete = (index) => {
    let updatedDataList = [];
    if (Array.isArray(storesTabList) && storesTabList.length > 0) {
      updatedDataList = [...storesTabList];
    }
    updatedDataList.splice(index, 1);
    setStoresTabDataList(updatedDataList);
    if (tabPanelList >= storesTabList.length) {
      setTabPanelList(storesTabList.length - 1); // Select the last tab if the active tab is deleted
    }
  };

  const CustomTabHeader = ({ tabHeader, index }) => {
    return (
      <div className="custom-tab-header">
        <span className="p-tabview-title">{tabHeader}</span>
        {index !== 0 && (
          <button className="close-button" onClick={() => handleDelete(index)}>
            &#x2715;
          </button>
        )}
      </div>
    );
  };
  const onTabChange = (index) => {
    if (internalPageEdited) {
      setTempTabIndex(index);
      setShowDialog(true);
    } else {
      setTabPanelList(index);
      if (index === 0) {
        return navigateToDSBP();
      }
    }
  };

  // if a warning has been shown and clicked yes, that you confiremed to loose your changes
  // then confirmNavigation will be used to remove sidebar warning with markClean();
  // set temp index with setTabPanelList(tempTabIndex); and redirect to required place
  const confirmNavigation = () => {
    markClean();
    setInternalPageEdited(false);
    setShowDialog(false);
    if (actionType === "delete") {
      onDelete(tempTabIndex);
    } else if (actionType === "edit") {
      setShowManageDependency(true);
      setActionType("");
    } else if (actionType === "tabsCompo" || actionType === "DSBP_PMP_PIMaterialNumber_Alert") {
      handleNavigateToUrl(tempObj, actionType);
    } else if (actionType === "cancel") {
      handleCancel();
      setActionType("");
    } else {
      setTabPanelList(tempTabIndex);
      if (tempTabIndex === 0) {
        return navigateToDSBP();
      }
      setTempTabIndex(0);
    }
  };

  const cancelNavigation = () => {
    setShowDialog(false);
    setActionType("");
  };

  const onHide = (value) => {
    const areObjectsEqualData = isEqual(initialFormData, formData);
    if (!areObjectsEqualData) {
      const deepData = { ...initialFormData };
      if (deepData.AWM_CICNeeded === CIC_DEPENDENT) {
        setPMPDesign(deepData.AWM_SupportingPMPDesignPage.PIMaterialNumber);
        setPMPLayout(deepData.AWM_SupportingPMPLayoutPage.PIMaterialNumber);
        setOtherRef(deepData.AWM_OtherReference);
      }
      if (deepData.AWM_CICNeeded !== CIC_DEPENDENT) {
        setPMPDesign("");
        setPMPLayout("");
        if (deepData.AWM_CICNeeded === "Yes") {
          setOtherRef("");
        }
      }
      if (deepData.AWM_CICNeeded === NOT_NEEDED) {
        setOtherRef(deepData.AWM_OtherReference);
      }
      setGaBrief(deepData.AWM_GABrief);
      setCICMatrix(deepData.AWM_CIC_Matrix_Toggle || "");

      if (deepData.AWM_CICNeeded !== cicNeeded) {
        setCICNeeded(deepData.AWM_CICNeeded);
      }

      setFormData(deepData);
    }
    setShowManageDependency(value);
  };

  const renderTabs = () => {
    return filteredDataList.map((obj, index) => (
      <TabPanel
        key={index}
        header={<CustomHeader tabHeaderDetails={obj} index={index} handleDelete={handleDelete} />}
        scrollable
      >
        <>
          {loader ? (
            <LoadingOverlay active={true} spinner text=""  className="overlay-loader"/>
          ) : index !== 0 && checkReadWriteAccess ? (
            tabsCompo(obj)
          ) : (
            AccessGroup && AccessGroup.length && <UnAuthorizedComponent nowrapper={true} />
          )}
        </>
      </TabPanel>
    ));
  };

  return (
    <div className="dependency-mapping-tab-view">
      <Toast ref={toast} />
      {dmTabValuesData?.length > 1 && tabPanelList !== 0 ? (
        <TabView
          activeIndex={tabPanelList}
          scrollable={dmTabValuesData?.length > 3 ? true : false}
          onTabChange={(e) => onTabChange(e.index)}
        >
          {renderTabs()}
        </TabView>
      ) : (
        navigateToDSBP()
      )}
      {checkReadWriteAccess && (
        <FooterButtons
          handleCancel={handleCancelClick}
          hideSaveButton={true}
          onSubmit={onSubmit}
          formValid={
            internalPageEdited
              ? isSubmitEnabled || Project_State === "Complete"
              : !(isSubmitEnabled || Project_State === "Complete")
          }
          checkReadWriteAccess={!false}
          submitAndSave="Save"
          Assignee={assignee}
          accessType={accessType}
          loader={loader}
        />
      )}
      <ManageDependency
        showManageDependency={showManageDependency}
        setShowManageDependency={onHide}
        ProjectID={ProjectID}
        setPageEdited={setInternalPageEdited}
        setInternalPageEdited={setInternalPageEdited}
        getApiParentPage={updateDMPMPTabData}
        finalPermission={finalPermission}
        isProjectComplete={Project_State === "Complete"}
      />
      {requestedCicAcknowledgement.poaaAcknowledgDialog && (
        <DsbpCommonPopup
          actionHeader={dsbpConstant.requestedCicAcknowledgementHeader}
          dasbpDialog={requestedCicAcknowledgement.poaaAcknowledgDialog}
          setDasbpDialog={(value) =>
            setRequestedCicAcknowledgement({
              ...requestedCicAcknowledgement,
              poaaAcknowledgDialog: value,
            })
          }
          okButtonShow={true}
          deleteButtonShow={false}
          showCancel={true}
          submitButtonShow={false}
          yesButtonShow={true}
          disconnectButtonShow={true}
        >
          {requestedCicAcknowledgement.poaaResponseData ? (
            <>{dsbpConstant.requestedCicAcknowledgementSuccessMessage}</>
          ) : (
            <>{dsbpConstant.requestedCicAcknowledgementErrorMessage}</>
          )}
        </DsbpCommonPopup>
      )}
      {deleteFlag && (
        <DsbpCommonPopup
          actionHeader={dsbpConstant?.deleteCicMatrixMessage}
          dasbpDialog={deleteFlag}
          setDasbpDialog={setDeleteFlag}
          yesButtonShow={false}
          cancelButtonShow={true}
          submitButtonShow={true}
          onSubmit={onSubmit}
        ></DsbpCommonPopup>
      )}
      <UnsavedChangesDialog
        visible={showDialog}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    </div>
  );
};

export default DMPMPSpecificTabView;
