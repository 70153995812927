import { ArtWorkTabValuesAction } from "../../../store/actions/ArtWorkTabValuesActions";

import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef, Suspense } from "react";
import { useDispatch } from "react-redux";
import CalendarIcon from "../../common/CalendarIcon/CalendarIcon";
import messageIcon from "../../../assets/images/Message.svg";
import hyphen from "../../../assets/images/hyphen.svg";
import { changeDateFormat, bufferToWork } from "../../../utils";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import { dipsalyStateValues, formatIntoDatePicker } from "./util";
import { AcpService } from "../../../service/ACPService";
import { SuggestionsMatch } from "../../../Utils/helpers";

const ElementTemplate = ({
  options,
  rowData,
  ProjectID,
  isPmAccess,
  dropdownRef,
  width,
  handleDialog,
  handleInfoIcon,
  RolesOwners,
  updateFields,
  setDropDownMatch
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const [messageContent, setMessageContent] = useState([]);
  const op = useRef(null);

  const field = rowData.field;
  const optionsData = options.data;
  const currentUrl = location.pathname;
  let currentUrlLastSeg = currentUrl?.split("/")[3];
  let currentUrlBasePage = currentUrl?.split("/")[1];
  const code = options?.code;
  const keyCode = code?.split("_");
  const locaiton = window.location.pathname;
  const url = `${locaiton.split("/")[1]}/${locaiton.split("/")[2]}/${
    keyCode?.[0]
  }/${code}/${currentUrlLastSeg}`;
  const daysLeftStyle =
    optionsData["Days_Left"] === 0 || optionsData["Days_Left"] === "N/A" ? "" : "red";
  const cursorStyle =
    optionsData["GroupName"]?.includes("Final Art") || optionsData["Task_ID"]?.includes("CPC_")
      ? "default"
      : "";

  const clickHandler = () => {
    if (field && field.length && keyCode[0] === "CPPFA") {
      handleDialog(options);
    } else if (
      optionsData["Task_ID"]?.includes("SAA_") ||
      optionsData["Task_ID"]?.includes("TPOA_")
    ) {
      dispatch(ArtWorkTabValuesAction([]));
      navigate(`/${currentUrlBasePage}/artworkAlignment/${optionsData["Assignee"]}/${ProjectID}`);
    } else if (
      optionsData["Task_ID"]?.includes("DM_") ||
      optionsData["Task_ID"]?.includes("RCIC_") ||
      optionsData["Task_ID"]?.includes("RCICMatrix_")
    ) {
      navigate(`/${currentUrlBasePage}/dependencyMapping/${ProjectID}`);
    } else if (optionsData["Task_ID"]?.includes("UBD_")) {
      navigate(`/${currentUrlBasePage}/projectPlan/UBD/${options.code}/${ProjectID}`);
    } else if (
      optionsData["Task"] !== "Design" &&
      optionsData["Task"] !== "Input" &&
      !optionsData["GroupName"]?.includes("Final Art") &&
      !optionsData["Task_ID"]?.includes("CPC_") &&
      optionsData["Task"] !== "Final Art" &&
      !optionsData["Task"]?.includes("(X")
    ) {
      (options.redirect === true || optionsData.Task) &&
        optionsData.State !== "Awaiting" &&
        navigate(`../${url}`, { replace: true });
    }
  };

  const formatHelpNeeded = (rowData) => {
    let className = "";
    let helpNeeded = rowData["Help_Needed"];
    switch (helpNeeded) {
      case "Yes, In Process":
        className = "helpneeded_inprocess";
        helpNeeded = "Yes, In Process";
        rowData["Help_Needed"] = "Yes, In Process";
        return (
          <>
            <span
              className="helpneeded_inprocess"
              onClick={() =>
                handleInfoIcon(`${rowData["Comments"]}`, `${rowData["Help_Needed_Reason"]}`)
              }
            >
              <img
                className="message_logo"
                src={messageIcon}
                alt="message logo"
                onClick={() =>
                  handleInfoIcon(`${rowData["Comments"]}`, `${rowData["Help_Needed_Reason"]}`)
                }
              />
              {rowData?.Help_Needed}
            </span>
          </>
        );
      case "Yes, Done":
        className = "helpneeded_done";
        helpNeeded = "Yes, Done";
        break;
      case "N/A":
        className = "helpneeded_no";
        helpNeeded = "N/A";
        break;
      case "No":
        className = "helpneeded_no";
        helpNeeded = "No";
        break;
      default:
        break;
    }
    return <span className={className}>{helpNeeded}</span>;
  };

  const durationEnabled = (State) => {
    if (State === "In-Progress" || State === "Awaiting") {
      return false;
    } else {
      return true;
    }
  };

  const onDropdownChange = (rowData, { value }, ele) => {
    updateFields(rowData, value, ele);
  };

  const onDDTyped = (rowData, val, ele) => {
    let value = val.value;
    if (typeof value === "string") {
      if (ele === "Role") {
        if (rowData.data["Role"] !== value) {
          rowData.data["Assignee"] = "";
        }
      }
      if (ele === "Owner") {
        rowData.data["Assignee"] = value;
      } else {
        rowData.data[ele] = value;
      }
    }
  };

  const onDurationChange = (rowData, { value }, ele) => {
    rowData.data[ele] = value < 1 ? "0" : value?.toString();
    updateFields(rowData, value, ele);
  };

  const onDate = (rowData, { value }, ele) => {
    rowData.data["Start_Date"] = changeDateFormat(value);
    updateFields(rowData, value, ele);
  };

  const getRoleSuggestions = (RolesOwners, field, optionsData) => {
    return RolesOwners && field === "Role"
    ? RolesOwners?.filter((el) => el.TaskID == optionsData?.Sequence)?.[0]
        ?.RolesList
    : []
  }

  const getOwnerSuggestions = (RolesOwners, field, optionsData) => {
    return optionsData && field === "Role"
    ? optionsData?.["RoleOptions"]
    : optionsData && (field === "Owner" || field === "Assignee")
    ? RolesOwners &&
      RolesOwners?.filter((el) => el.TaskID == optionsData?.Sequence)?.[0]?.[
        "RolesList"
      ]?.find((obj) => optionsData?.["Role"] == obj?.Role)?.OwnerList
    : []
  }

  return (
    <>
      {field === "Task" && (
        <span
          className={`${
            optionsData?.State === "Awaiting"
              ? ""
              : options?.children?.length === 0
              ? "task-link"
              : "task"
          } ${field === "Task" && "custom-toggler-wrapper"}`}
          style={{ cursor: cursorStyle }}
          onClick={clickHandler}
          title={optionsData?.[field]}
        >
          {optionsData[field]}
        </span>
      )}
      {options?.children && options?.children?.length > 0 ? (
        <>
          {(field === "Role" ||
            field === "Owner" ||
            field === "Assignee" ||
            field === "State" ||
            field === "TaskDuration" ||
            field === "StartDate" ||
            field === "TaskID" ||
            field === "Dependency" ||
            field === "Project_TaskID" ||
            field === "Project_PredecessorID" ||
            field === "DaysLeft" ||
            field === "EndDate") && (
            <img
              src={hyphen}
              alt="Hyphen"
              onClick={(e) => {
                op.current.toggle(e);
              }}
            />
          )}
        </>
      ) : (
        <>
          {field === "Project_TaskID" && (
            <span
              title={optionsData?.["Project_TaskID"]}
              className={`${
                optionsData?.State === "Awaiting"
                  ? ""
                  : options?.children?.length === 0
                  ? "task-link"
                  : "task"
              }`}
              style={{ cursor: cursorStyle }}
              onClick={clickHandler}
            >
              {optionsData?.["Project_TaskID"]}
            </span>
          )}
          {field === "Role" && (
            <div className="d-flex" ref={dropdownRef}>
              <CommonAutocomplete
                field="Role"
                columnName="Role"
                width={width}
                value={options.data[field]}
                placeholder={`Select ${field}`}
                onChange={(e) => {
                  onDDTyped(options, e, field);
                }}
                onSelect={(e) => {
                  onDropdownChange(options, e, field);
                }}
                disabled={
                  optionsData?.GroupName === "Final Art" ||
                  optionsData?.State?.includes?.("Complete") ||
                  optionsData?.["Task_ID"]?.includes("CPC_") ||
                  !isPmAccess
                }
                suggestions={getRoleSuggestions(RolesOwners, field, optionsData)}
                setDropDownMatch={setDropDownMatch}
              />
            </div>
          )}
          {(field === "Owner" || field === "Assignee") && (
            <div className="d-flex" ref={dropdownRef}>
              <CommonAutocomplete
                width={width}
                field="Person"
                columnName="Owner"
                placeholder={`Select ${field}`}
                onChange={(e) => {
                  onDDTyped(options, e, field);
                }}
                onSelect={(e) => {
                  onDropdownChange(options, e, field);
                }}
                disabled={
                  optionsData?.GroupName === "Final Art" ||
                  optionsData?.State?.includes?.("Complete") ||
                  optionsData?.["Task_ID"]?.includes("CPC_") ||
                  !isPmAccess
                }
                value={options.data[field === "Owner" ? "Assignee" : field]}
                suggestions={getOwnerSuggestions(RolesOwners, field, optionsData)}
                setDropDownMatch={setDropDownMatch}
              />
              {/* {optionsData &&
                      (field === "Owner" || field === "Assignee") &&
                      optionsData?.Task_ID === "DDI_Task-1596" &&
                      console.log(
                        "optionsData, OwnerData",
                        "OwnerData?.ArtworkAgilityProjects?.filter(el => el.Role===optionsData?.Role",
                        optionsData,
                        OwnerData?.ArtworkAgilityProjects,
                        OwnerData?.ArtworkAgilityProjects?.filter(
                          (el) => el.TaskID == optionsData?.Sequence
                        )?.[0]
                      )} */}
            </div>
          )}
          {field === "State" && dipsalyStateValues(optionsData?.[field])}

          {field === "TaskDuration" && options?.data?.[field] !== "" && (
            <InputNumber
              className="input-duration"
              inputId="integeronly"
              value={options?.data?.[field]}
              onValueChange={(e) => onDurationChange(options, e, field)}
              disabled={durationEnabled(optionsData?.State) || !isPmAccess}
              min={1}
            />
          )}

          {field === "Start_Date" && (
            <div className="projectplan-calender">
              <Calendar
                value={
                  !AcpService.isUBN(optionsData?.["Start_Date"])
                    ? formatIntoDatePicker(optionsData?.["Start_Date"])
                    : null
                }
                onChange={(e) => {
                  onDate(options, e, field);
                }}
                dateFormat="d-M-y"
                showIcon={true}
                minDate={new Date()}
                disabled={optionsData.State !== "Awaiting" || !isPmAccess}
                icon={<CalendarIcon />}
              />
            </div>
          )}

          {field === "End_Date" && (
            <span>
              {optionsData?.["End_Date"]?.length !== undefined ? optionsData?.["End_Date"] : ""}
            </span>
          )}
          {field === "Days_Left" && optionsData?.["TaskDuration"] !== "" && (
            <span style={{ color: daysLeftStyle }}>{optionsData?.["Days_Left"]}</span>
          )}
          {field === "Project_PredecessorID" && (
            <span title={optionsData?.[field]}> {optionsData?.[field]} </span>
          )}
          {field === "Remaining_Work" && optionsData?.["Remaining_Work"]}
          {field === "Buffer" && optionsData?.["Buffer"]}
          {field === "Buffer_To_Work" &&
            options?.[field] !== "" &&
            bufferToWork(optionsData?.["Buffer_To_Work"])}
        </>
      )}

      {field === "Help_Needed" && formatHelpNeeded(optionsData)}

      {field !== "Task" &&
        field !== "Task_ID" &&
        field !== "Role" &&
        field !== "Owner" &&
        field !== "Assignee" &&
        field !== "State" &&
        field !== "Start_Date" &&
        field !== "End_Date" &&
        field !== "TaskDuration" &&
        field !== "Help_Needed" &&
        field !== "Buffer" &&
        field !== "Dependency" &&
        field !== "Project_TaskID" &&
        field !== "Project_PredecessorID" &&
        field !== "Remaining_Work" &&
        field !== "Buffer_To_Work" &&
        field !== "Days_Left" && <>{optionsData[field]}</>}
    </>
  );
};

export default ElementTemplate;
