import { Dialog } from "primereact/dialog";
import React from "react";

function CommonAPIErrorPopup({ onClose, apiError }) {
  let errorMessage = apiError?.message; // Default error message
  const is429Error = apiError?.response?.status === 429;

  // If the status code is 429, extract the error message from response.data
  if (is429Error && apiError?.response?.data) {
    try {
      const match = apiError.response.data.match(/:\s*"({.*})"/);
      if (match && match[1]) {
        const parsedError = JSON.parse(match[1]);
        errorMessage = parsedError?.ErrorDescription || errorMessage;
      }
    } catch (parseError) {
      console.error("Error parsing API error data:", parseError);
    }
  }

  return (
    <Dialog
      visible={true}
      className="common-api-error-dialog"
      onHide={onClose}
      header={<div className="p-dialog-md">Error Message</div>}
    >
      {/* Show URL only if status is not 429 */}
      {!is429Error && <ul>{apiError?.config?.url}</ul>}

      <ul>{errorMessage}</ul>
    </Dialog>
  );
}

export default CommonAPIErrorPopup;
