import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

function CustomInput({ type, value, options, field, placeholder, disabled, onChange }) {
  const [InputValue, setInputValue] = useState("");
  const [InputChanged, setInputChanged] = useState(false);
  
  const handleChange = (options, e, field) => {
    setInputValue(e?.target?.value);
    onChange(options, e, field);
    if (value === e?.target?.value) {
      setInputChanged(false);
    } else {
      setInputChanged(true);
    }
  };

  return (
    <div>
      <Form.Group controlId="groupName.ControlInput1" style={{ textAlign: "-webkit-center" }}>
        <Form.Control
          onChange={(e) => handleChange(options, e, field)}
          value={InputChanged ? InputValue : value}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
        />
      </Form.Group>
    </div>
  );
}

export default CustomInput;
