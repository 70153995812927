import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { warningMessage, confirmLeaveMessage, rejectionMessage, rdtrejectMessage } from "../../../constants";
const STORAGE_KEY = "unsavedChanges";


export const UnsavedChangesDialog = ({ visible, onConfirm, onCancel, rejected, taskName }) => (
  <Dialog
        visible={visible}
        className="acp-dialog acpCloseDialogSubContainer headerIcon"
        onHide={onCancel}
        header={<div className="p-dialog-acp">{taskName === "RDT" && rejected ? rdtrejectMessage : rejected ? rejectionMessage : warningMessage}</div>}
      >
        <div className="col-sm-12 ">
          <Row className="justify-content-end ">
            <Button   variant="secondary" onClick={onCancel}>
             {rejected ? "Ok" : "No"}
            </Button>
            {rejected ? null :
              <Button
                onClick={onConfirm}
                disabled={null}
                className="ms-3"
              >
                Yes
              </Button>
            }
          </Row>
        </div>
      </Dialog>
);

export const useUnsavedChangesWarning = (
  message = confirmLeaveMessage
) => {
  const [isDirty, setIsDirty] = useState(() => {
    // Initialize isDirty state based on local storage
    const storedValue = localStorage.getItem(STORAGE_KEY);
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(()=>{
    localStorage.setItem("RejectedCase", false);
  },[])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    window.addEventListener("onpopstate", handleBeforeUnload);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("onpopstate", handleBeforeUnload);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty, message]);

  const markDirty = () => {
    setIsDirty(true);
    localStorage.setItem(STORAGE_KEY, true);
  };

  const markClean = () => {
    setIsDirty(false);
    localStorage.setItem(STORAGE_KEY, false);
  };

  const toggleRejected = (value) =>{
    localStorage.setItem("RejectedCase", JSON.stringify(value));
  }

  return { isDirty, markDirty, markClean, toggleRejected };
};
