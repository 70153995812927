import "../index.scss";
import _, { isObject, uniq } from "lodash";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Link, useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import filter from "../../../assets/images/filter.svg";
import CustomizeView from "../../common/CustomizeView";
import acpInfo from "../../../assets/images/acpInfo.svg";
import { AcpService } from "../../../service/ACPService";
import acpError from "../../../assets/images/acpError.svg";
import { ProjectService } from "../../../service/PegaService";
import ACPBookingHeaderColumn from "./ACPBookingHeaderColumn";
import React, { useEffect, useState, useCallback } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";
import { acpBookingFrozenColumn } from "../../../Utils/acpBookingFrozenColumn";
import { changeDateFormat, multiFilterChange } from "../../../utils";
import { AcpCommonService } from "../../../service/AcpCommonService";
import CalendarIcon from "../../common/CalendarIcon/CalendarIcon";
import { InputText } from "primereact/inputtext";
import { removeObjectsWithNullStartDate } from "../../../service/DashboardService";

function AcpBookingDatatable(
  {
    reset,
    loader,
    tabName,
    Headers,
    isSearch,
    rowGroups,
    fetchData,
    isBVEloop,
    editedRow,
    expandAll,
    setLoader,
    actionType,
    setHeaders,
    isSearchSet,
    backlogData,
    selectedRows,
    setExpandAll,
    setChartData,
    chartClicked,
    setEditedRow,
    setSuppliers,
    setActionType,
    setActionFlag,
    allEditedRows,
    setBookingData,
    clearAllFilter,
    setSelectedRows,
    setAllEditedRows,
    bookingTableData,
    resetMultiFilter,
    setClearAllFilter,
    setNoOfActiveTasks,
    updatedBookingData,
    ShowCustomizedView,
    bookingTableFilters,
    setResetMultiFilter,
    setBookingTableData,
    customizeViewFields,
    checkReadWriteAccess,
    setShowCustomizedView,
    setCustomizeViewFields,
    onSave,
    resetKey,
    setDateChangeValue,
    chartData,
  },
  ref
) {
  const dt = useRef(null);
  const op = useRef(null);
  const filterArray = useRef([]);
  const location = useLocation();
  const colAllSelected = useRef([]);
  // const currentUrl = location.pathname;
  const selectedFieldsRef = useRef([]);
  const selectedColumnList = useRef([]);
  const [filters, setFilters] = useState([]);
  const [selected, setSelected] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchInfo, setSearchInfo] = useState("");
  const [searchHeader, setSearchHeader] = useState();
  const [expandedRows, setExpandedRows] = useState([]);
  // let currentUrlBasePage = currentUrl?.split("/")?.[1];
  const [tableRender, setTableRender] = useState(false);
  const [groupWiseData, setGroupWiseData] = useState([]);
  const [selectedFields, setSelectedFields] = useState({});
  const [allColumnNames, setAllColumnNames] = useState([]);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [initialPegaData, setInitialPegaData] = useState(null);
  const [InitialTableData, setInitialTableData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [projectColumnName, setProjectColumnNames] = useState([]);
  const [isReorderedColumn, setReorderedColumn] = useState(false);
  const accessSecurityMatrix = checkReadWriteAccess; //usePriorityNew(currentUrlBasePage);
  const [conditionMatched, setConditionMatched] = useState(false);
  const [frozenCoulmns, setFrozenColumn] = useState(["SelectAll"]);
  const [availableColumnNames, setAvailableColumnNames] = useState([]);
  const [selectedColumnName, setSelectedColumnName] = useState("SelectAll");
  const [resizeMode, setResizeMode] = useState("expand");
  const [localRowGroups, setLocalRowGroups] = useState([...rowGroups]);
  const [localPreviousRowGroups, setLocalPreviousRowGroups] = useState([]);
  const baseUrl = "/allProjects/artworkStatus"; //TODO: Need to change to dynamicurl

  // const User = useSelector((state) => state.UserReducer);
  // const userInformation = User.userInformation;
  // const [access, setAccess] = useState([]);
  // const [userAccessDisabled, setUserAccessDisabled] = useState(false);

  // const userAccessGroup = useSelector((state) => state.UserDetailsReducer.userDetails.AccessGroup);

  // useEffect(()=>{
  //   let temp =[];
  //   userAccessGroup.map((obj)=>{
  //     if(obj.AccessGroupNames.includes("CapacityManager")){
  //       temp.push("write");
  //     }else {
  //         temp.push("read")
  //     }
  //   })
  //   setAccess(temp)
  // },[userInformation])

  // useEffect(()=>{
  //   if(access.indexOf("write")===-1){
  //     setUserAccessDisabled(true)
  //   }else{
  //     setUserAccessDisabled(false)
  //   }
  // },[access])

  const handleSelectAll = (e) => {
    const data = filters?.length ? filters : tableData;
    const updatedData = data?.filter((el) => el?.RequestStatus !== "Awaiting Response");
    if (e.target.checked) {
      updatedData?.map((el) => (el.isSelected = true));
      setSelectAllChecked(true);
      setSelected(updatedData);
    } else {
      updatedData?.map((el) => (el.isSelected = false));
      setSelectAllChecked(false);
      setSelected([]);
    }
  };

  useEffect(() => {
    setSelected([]);
    setSelectAllChecked(false);
  }, [onSave]);

  const handleSelect = useCallback(
    (item) => {
      if (selected?.includes(item)) {
        item.isSelected = false;
        setSelectAllChecked(false);
        setSelected(selected.filter((i) => i !== item));
      } else {
        item.isSelected = true;
        if (selected.length === 0) {
          const selectedList = [];
          selectedList.push(item);
          setSelected(selectedList);
        } else {
          const selectedList = [...selected, item];
          setSelectAllChecked(
            (filters?.length ? filters : tableData)?.length === selectedList?.length
          );
          setSelected([...selectedList]);
        }
      }
    },
    [selected]
  );

  useEffect(() => {
    setFilters([]);
    setTableData([]);
    localStorage.setItem("NewStartDatesSaved", JSON.stringify([]));
  }, []);

  useEffect(() => {
    if (
      customizeViewFields &&
      customizeViewFields !== undefined &&
      customizeViewFields?.length !== 0
    ) {
      const updatedHeaders = JSON?.parse(customizeViewFields)?.selectedFields?.fieldsData;
      const latestHeaders = updatedHeaders?.filter((item) => item !== "SelectAll");
      const frozenData = JSON?.parse(customizeViewFields)?.freezedColumns?.fieldsData;
      if (!frozenData?.includes("SelectAll")) {
        frozenData?.unshift("SelectAll");
      }
      setFrozenColumn(frozenData);
      const finalColumn = [...(frozenData || []), ...(latestHeaders || [])];
      setProjectFrozen(!ProjectFrozen);
      projectColumnName &&
        acpBookingFrozenColumn(
          frozenData,
          setReorderedColumn,
          setProjectColumnNames,
          setTableRender,
          op,
          finalColumn,
          "booking"
        );
      setProjectColumnNames(finalColumn);
      setTableData(tableData);
      setLoader(true);
      setTimeout(() => {
        headerTemplate(false);
        setLoader(false);
      }, 1000);

      const totalColumns = [...(latestHeaders || []), ...(frozenData || [])];
      if (totalColumns.length < 8) {
        setResizeMode("fit");
      } else {
        setResizeMode("expand");
      }
    } else {
      const columnNames = ProjectService.getAvailableBookingColumnNames();
      if (!columnNames.includes("SelectAll")) {
        columnNames.unshift("SelectAll");
      }
      setProjectColumnNames(columnNames);
      setFrozenColumn(["SelectAll"]);
    }
  }, [customizeViewFields]);

  useEffect(() => {
    const availableBookingColumnNames = ProjectService.getAvailableBookingColumnNames();
    const availableBookingColumnNamesUpdated = AcpService.reorderColumns(
      availableBookingColumnNames
    ).map((el) => {
      return {
        width: 250,
        Sequence: "",
        freeze: false,
        Field_Name: el,
        reorder: false,
        Column_Name: el,
        sortAtoZ: false,
        sortZtoA: false,
        Attribute_Type: "Free Text",
      };
    });
    const bookingColumnNames = ProjectService.getBookingColumnNames();
    const bookingColumnNamesUpdated = bookingColumnNames.map((el) => {
      return {
        Field_Name: el,
      };
    });
    let availableFieldsToSelect = bookingColumnNamesUpdated.filter(function (objFromA) {
      return !availableBookingColumnNamesUpdated.find(function (objFromB) {
        return objFromA.Field_Name === objFromB.Field_Name;
      });
    });

    const updatedHeaders = AcpService.reorderColumns(availableBookingColumnNames).filter(
      (el) => el !== "SelectAll"
    );

    setHeaders(updatedHeaders);
    setAvailableColumnNames(availableFieldsToSelect);
    setAllColumnNames(availableBookingColumnNamesUpdated);
    if (tableData?.length !== 0 && loader) {
      (async () => {
        try {
          if (chartClicked) setTableData(updatedBookingData);
          const ProjectData = _.cloneDeep(tableData);
          if (ProjectData.length) {
            if (!initialPegaData) {
              setInitialPegaData(ProjectData);
            }
            let filteredPegaDataJson = localStorage.getItem("bookingColumnWiseFilterData");
            const filteredPegaData = JSON.parse(filteredPegaDataJson);
            if (filteredPegaData && filteredPegaData.length) {
              setTableData(ProjectData);
              setFilters(filteredPegaData);
              setSelectedFields(filteredPegaData);
            } else {
              setTableData(ProjectData);
            }
          }

          let columnNamesJson = localStorage.getItem("bookingNamesbookings");
          const columnNames = JSON.parse(columnNamesJson);

          if (columnNames != null && columnNames.length) {
            setProjectColumnNames(columnNames);
          } else {
            if (
              customizeViewFields &&
              JSON.parse(customizeViewFields)?.selectedFields?.fieldsData
            ) {
              const updatedHeaders = JSON?.parse(customizeViewFields)?.selectedFields?.fieldsData;
              const latestHeaders = updatedHeaders?.filter((item) => item !== "SelectAll");
              const frozenData = JSON?.parse(customizeViewFields)?.freezedColumns?.fieldsData;
              if (!frozenData.includes("SelectAll")) {
                frozenData.push("SelectAll");
              }
              const updatedColumn = [...frozenData, ...latestHeaders];
              const finalColumn = updatedColumn?.filter((item) => {
                return item.toLowerCase() !== "selectall";
              });
              const finalColumn1 = [...finalColumn];
              if (!finalColumn.includes("SelectAll")) {
                finalColumn1.unshift("SelectAll");
              }
              // setProjectColumnNames(updatedColumn);
              setProjectFrozen(!ProjectFrozen);
              projectColumnName &&
                acpBookingFrozenColumn(
                  frozenCoulmns,
                  setReorderedColumn,
                  setProjectColumnNames,
                  setTableRender,
                  op,
                  finalColumn1,
                  "booking"
                );
            } else {
              const columnNames = ProjectService.getAvailableBookingColumnNames();
              if (!columnNames.includes("SelectAll")) {
                columnNames.unshift("SelectAll");
              }
              // setProjectColumnNames(columnNames);
            }
          }

          let jsonSortingData1 = localStorage.getItem("bookingSortingData");
          const sortingData = JSON.parse(jsonSortingData1);

          if (sortingData && sortingData.length) {
            const sortedData = [...ProjectData].sort((a, b) => {
              if (sortingData[1] === "desc") {
                return a[sortingData[0]] > b[sortingData[0]] ? -1 : 1;
              } else {
                return a[sortingData[0]] > b[sortingData[0]] ? 1 : -1;
              }
            });
            setTableData(sortedData);
            setChartData(sortedData);
            setSortData([sortingData[0], sortingData[1]]);
          }

          let jsonFrozenrData1 = localStorage.getItem("bookingFrozenData");
          const frozenData = JSON.parse(jsonFrozenrData1);
          if (frozenData && frozenData.length) {
            setFrozenColumn(frozenData);
          }
        } catch (err) {
          console.log("error", err);
        }
      })();
    }
  }, [tableData]);

  useEffect(() => {
    if (tableData?.length || filters?.length) {
      //3182
      // setBookingData(filters?.length ? filters : tableData);

      // For 3093
      // The Chart Data state wasn't updated with new filtered data on adding filters. Now we check if filters are present add that or table data to the chart data state.
      if (setBookingData && !chartClicked) {
        setBookingData(filters?.length ? filters : tableData);
        const updatedDataWithoutBacklog = tableData?.filter((el) => el.Group !== "Backlog");
        const updatedDataWithoutBacklogUpdated = _.cloneDeep(updatedDataWithoutBacklog);
        // setChartData(filters?.length ? _.cloneDeep(filters) : updatedDataWithoutBacklogUpdated);
        // Backlog tasks UI
        setChartData(filters?.length ? _.cloneDeep(filters) : _.cloneDeep(tableData));
      }
    }
  }, [tableData, filters]);

  useEffect(() => {
    let toBeExpanded = [];
    if (expandAll === true) {
      rowGroups?.forEach((grp) => {
        let filteredData = tableData?.filter((data) => {
          return data.Group === grp.id;
        });
        filteredData?.length && toBeExpanded.push(filteredData[0]);
      });
      let _localRowGroups = [...localRowGroups];
      //The function iterates through a collection of row groups (_localRowGroups) to manage their expanded state based on a previously saved state (localPreviousRowGroups) Awm-3247
      _localRowGroups.forEach((el) => {
        const checkPrevSnapState = localPreviousRowGroups?.find((item) => item?.name === el?.name);
        el.expanded = checkPrevSnapState ? checkPrevSnapState?.expanded : true;
        if (!el.expanded) {
          toBeExpanded = toBeExpanded?.filter((item) => item?.Group !== el?.id);
        }
        // changes for Awm-3247
      });
      setLocalPreviousRowGroups([]);
      setLocalRowGroups([..._localRowGroups]);
      setExpandedRows(toBeExpanded);
    } else if (expandAll === false) {
      let _localRowGroups = [...localRowGroups];
      _localRowGroups.forEach((el) => {
        el.expanded = false;
      });
      setLocalRowGroups([..._localRowGroups]);
      setExpandedRows([]);
    }
  }, [expandAll, tableData, bookingTableFilters]);

  useEffect(() => {
    const temp = allEditedRows?.filter((row) => {
      return isObject(row) && row.UniqueID !== editedRow.UniqueID;
    });

    if (tabName === "booking") {
      // Object.keys(editedRow)?.length &&
      //   !AcpService.checkDateIsSameToFilterDate(editedRow.StartDate, bookingTableFilters?.Date) &&
      //   temp.push(editedRow); //don't push if task date is same as filter date

      if (
        Object.keys(editedRow)?.length &&
        actionType !== "massUpdate"
        // &&
        // !AcpService.checkDateIsSameToFilterDate(
        //   new Date(editedRow.StartDate),
        //   bookingTableFilters?.Date
        // )
      ) {
        temp.push(editedRow); //don't push if task date is same as filter date
      }
    } else if (tabName === "bveLoop") {
      let oldRow = bookingTableData?.filter((row) => {
        return row.TaskName === editedRow.TaskName;
      });
      Object.keys(editedRow)?.length &&
        !AcpService.checkDateIsSameToFilterDate(editedRow.EndDate, oldRow[0]?.EndDate) &&
        temp.push(editedRow); //don't push if task date is same as filter date
    }
    setAllEditedRows(temp);
    const editedTableData = filters?.length ? _.cloneDeep(filters) : _.cloneDeep(tableData);
    editedTableData?.forEach((rowData) => {
      if (rowData.TaskName === editedRow.TaskName) {
        rowData["edited"] = "edited"; //don't add this property if task date is same as filter date
      }
      if (tabName === "booking") {
        // if (checkDateIsSameToFilterDate(editedRow.StartDate, bookingTableFilters?.Date)) {
        //   delete rowData.edited;
        // }
      } else if (tabName === "bveLoop") {
        let oldRow = bookingTableData?.filter((row) => {
          return row.TaskName === editedRow.TaskName;
        });
        if (AcpService.checkDateIsSameToFilterDate(editedRow.EndDate, oldRow[0]?.EndDate)) {
          delete rowData.edited;
        }
      }
    });
    const updatedTableData = AcpCommonService.updateTableData(
      _.cloneDeep(tableData),
      _.cloneDeep(editedTableData),
      "UniqueID"
    );
    setTableData(updatedTableData);

    // 3246 - updating the bar chart
    let localChart = [...chartData];
    localChart.forEach((_chart) => {
      if (_chart?.["UniqueID"] === editedRow?.["UniqueID"]) {
        _chart["StartDate"] = AcpService.convertShortDateToGMT(editedRow["StartDate"]);
      }
    });

    setChartData(localChart);
  }, [editedRow]);

  useEffect(() => {
    let temp = [];
    tableData?.forEach((data) => {
      temp.push(data.Group);
    });
    const currentRowGroups = _.uniq(temp);
    if (expandedRows?.length < 1 && expandAll) {
      setExpandAll(true);
    } else if (expandedRows?.length < 1) {
      setExpandAll(false);
    } else if (expandedRows?.length === currentRowGroups?.length) {
      setExpandAll(true);
    } else {
      setExpandAll(true);
    }
  }, [expandedRows]);

  useEffect(() => {
    const activeCount = bookingTableData?.filter((item) => item.Status === "In-Progress")?.length;
    setNoOfActiveTasks && setNoOfActiveTasks(activeCount);
    setAllEditedRows([]);
    setSelectedRows([]);
    setEditedRow({});
    filterTable();
  }, [reset]);

  useEffect(() => {
    setInitialPegaData(_.cloneDeep(bookingTableData));
    setTableData([]);
    filterTable();
  }, [bookingTableFilters, bookingTableData, backlogData]);

  useEffect(() => {
    setSelectedRows(selected);
    if (setActionFlag) {
      setActionFlag(selected);
    } else {
      setActionFlag(!selected);
    }
  }, [selected]);

  useEffect(() => {
    if (chartClicked === true) {
      setTableData(updatedBookingData);
    } else {
      filterTable();
    }
  }, [chartClicked, updatedBookingData]);

  //3078
  const convertData = (data) => {
    return data?.map((el, i) => {
      return {
        ...el,
        UniqueID: i,
        Slack: AcpService.formatDuration(el?.Slack),
        Status: el?.Status !== "" ? el?.Status : "",
        Owner: el?.Assignee !== "" ? el?.Assignee : "",
        TaskName: el?.TaskName !== "" ? el?.TaskName : "",
        Supplier: el?.Supplier !== "" ? el?.Supplier : "",
        ProjectName: el?.ProjectName !== "" ? el?.ProjectName : "",
        ProjectID: el?.AWMProjectID !== "" ? el?.AWMProjectID : "",
        BufferToWork: el?.BufferToWork !== "" ? el?.BufferToWork : "",
        EndDate: !AcpService.isUBN(el?.EndDate) ? changeDateFormat(el?.EndDate) : "",
        Work: el?.AWMTaskRemainingWork !== "" ? el?.AWMTaskRemainingWork : "",
        StartDate: !AcpService.isUBN(el?.StartDate) ? changeDateFormat(el?.StartDate) : "",
        Buffer: el?.AWMTaskRemainingBuffer !== "" ? el?.AWMTaskRemainingBuffer : "",
        ArtPrinterDate: !AcpService.isUBN(el?.EstimatedAWPrinter)
          ? changeDateFormat(el?.EstimatedAWPrinter)
          : "",
        TaskDuration: el?.TaskDuration !== "" ? AcpService.formatDuration(el?.Duration) : "",
      };
    });
  };

  const filterTable = () => {
    let _backlogData = [];
    let _SupplierValue = [];
    if (backlogData?.length) {
      _SupplierValue = backlogData?.map((el) => {
        let val = el.Supplier.length > 0 ? el.Supplier : "NA";
        return val;
      });
    }
    if (backlogData?.length) {
      _backlogData = backlogData?.filter((data) => {
        if (bookingTableFilters?.FAValue && bookingTableFilters?.FAValue === "FA") {
          return data?.TaskType?.includes("ssembly");
        } else if (bookingTableFilters?.FAValue && bookingTableFilters?.FAValue.includes("CIC")) {
          return data?.TaskType?.includes("CIC");
        } else {
          return data;
        }
      });
      _backlogData = _backlogData?.filter((data) => {
        if (bookingTableFilters?.SupplierValue) {
          return data?.Supplier === bookingTableFilters?.SupplierValue;
        } else {
          return data;
        }
      });
      _backlogData = _backlogData?.map((el) => {
        return {
          ...el,
          GroupOrder: 3,
          Group: "Backlog",
        };
      });
    }

    if (AcpService.compareDates(bookingTableFilters.Date, bookingTableFilters.endDate)) {
      if (bookingTableFilters?.FAValue === "FA") {
        let uniqueSuppliers = uniq(_SupplierValue);
        setSuppliers(uniqueSuppliers);
        const __backlogData = convertData(_backlogData);
        setTableData(__backlogData);
        setInitialTableData(__backlogData);
      } else {
        setSuppliers([]);
        setTableData([]);
      }
    } else {
      const tbldata = _.cloneDeep(bookingTableData);
      if (tbldata?.length) {
        const filteredTasks = tbldata?.filter((data) => {
          if (bookingTableFilters?.FAValue && bookingTableFilters?.FAValue === "FA") {
            return data?.TaskType?.includes("ssembly");
          } else if (bookingTableFilters?.FAValue && bookingTableFilters?.FAValue.includes("CIC")) {
            return data?.TaskType?.includes("CIC");
          } else {
            return data;
          }
        });
        //3078
        let filteredSupplier = filteredTasks?.filter((data) => {
          if (bookingTableFilters?.SupplierValue && bookingTableFilters?.SupplierValue !== "NA") {
            return data?.Supplier === bookingTableFilters?.SupplierValue;
          } else if (bookingTableFilters?.SupplierValue === "NA") {
            return data?.Supplier === "";
          } else {
            return data;
          }
        });

        // due to change of 3078
        let SupplierValue = filteredTasks.map((el) => {
          let val = el.Supplier.length > 0 ? el.Supplier : "NA";
          return val;
        });
        let _SupplierValue = [];
        if (backlogData?.length && bookingTableFilters?.FAValue === "FA") {
          _SupplierValue = backlogData?.map((el) => {
            let val = el.Supplier.length > 0 ? el.Supplier : "NA";
            return val;
          });
        }
        const _finalSupplierValue = [...(SupplierValue || []), ...(_SupplierValue || [])];
        let uniqueSuppliers = uniq(_finalSupplierValue);
        //3078
        if (!uniqueSuppliers?.includes(bookingTableFilters?.SupplierValue)) {
          filteredSupplier = filteredTasks;
        }
        if (
          !filteredSupplier?.length &&
          bookingTableFilters?.SupplierValue &&
          !_SupplierValue?.length
        ) {
          setSuppliers([bookingTableFilters?.SupplierValue]);
        } else {
          setSuppliers(uniqueSuppliers);
        }

        let filteredByDate;
        if (isBVEloop) {
          filteredByDate = filteredSupplier;
        } else {
          filteredByDate = AcpService.filterObjectsByDate(
            filteredSupplier,
            AcpService.changeDateFromISTtoUTC(bookingTableFilters?.Date),
            AcpService.changeDateFromISTtoUTC(bookingTableFilters?.endDate)
          );
        }
        let _backlogData = [];
        if (backlogData?.length) {
          _backlogData = backlogData?.filter((data) => {
            if (bookingTableFilters?.FAValue && bookingTableFilters?.FAValue === "FA") {
              return data?.TaskType?.includes("ssembly");
            } else if (
              bookingTableFilters?.FAValue &&
              bookingTableFilters?.FAValue.includes("CIC")
            ) {
              return data?.TaskType?.includes("CIC");
            } else {
              return data;
            }
          });
          _backlogData = _backlogData?.filter((data) => {
            if (bookingTableFilters?.SupplierValue) {
              return data?.Supplier === bookingTableFilters?.SupplierValue;
            } else {
              return data;
            }
          });
          _backlogData = _backlogData?.map((el) => {
            return {
              ...el,
              GroupOrder: 3,
              Group: "Backlog",
            };
          });
        }
        const finalFilteredData = [...filteredByDate, ..._backlogData];
        let _filteredByDate = convertData(finalFilteredData);

        const newArray = [];
        const sortedData = AcpService.groupBy(_filteredByDate, "Group");
        for (const [key, value] of Object.entries(sortedData)) {
          const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(
            value,
            "StartDate",
            "asc"
          );
          newArray.push(sortedDataSet);
        }
        const activeCount = _filteredByDate?.filter((item) => item.Status === "Active")?.length;
        setNoOfActiveTasks && setNoOfActiveTasks(activeCount);
        const GroupedData = AcpService.countTasksByGroup(_filteredByDate);
        const SortedData = AcpService.addRefinedOrder(GroupedData);
        let updatedData = newArray?.flat();
        setGroupWiseData(SortedData);
        const updatedDataFinal = updatedData?.map((el, i) => {
          return {
            ...el,
            StartDate: el?.StartDate !== "" ? changeDateFormat(el?.StartDate) : null,
          };
        });
        setTableData(updatedDataFinal);
        setInitialTableData(updatedDataFinal);
        const updatedDataWithoutBacklog = updatedData?.filter((el) => el.Group !== "Backlog");
        setChartData(updatedDataFinal);
        setResetMultiFilter(true);
        const SavedFieldRefs = localStorage.getItem("_selectedFieldsRefCurrentBooking");
        const SavedFieldRefsParsed = SavedFieldRefs && JSON.parse(SavedFieldRefs);
        if (selectedFieldsRef.current?.length) {
          const filterData = AcpService.filterDataByfilters(
            updatedDataFinal,
            selectedFieldsRef.current,
            setConditionMatched,
            tabName
          );
          setFilters(filterData);
          selectedColumnList.current = filterData;
        } else if (SavedFieldRefsParsed?.length) {
          // const filterData = AcpService.filterDataByfilters(
          //   updatedData,
          //   SavedFieldRefsParsed,
          //   setConditionMatched,
          //   tabName
          // );
          // setFilters(filterData);
          // let _selectedFieldsRefCurrentBooking = {};
          // for (const ele of SavedFieldRefsParsed) {
          //   const values = Object.entries(ele)[0];
          //   const key = values[0];
          //   const value = values[1];
          //   _selectedFieldsRefCurrentBooking[key] = value;
          // }
          // selectedColumnList.current = filterData;
          // setSelectedFields(_selectedFieldsRefCurrentBooking);
          // selectedFieldsRef.current = _selectedFieldsRefCurrentBooking;
        }
      }
    }
  };

  const handleToggleExpand = (data, rowGroupdId) => {
    let _localRowGroups = [...localRowGroups];
    _localRowGroups.forEach((el) => {
      if (el.id === rowGroupdId) {
        el.expanded = !AcpService.isUBN(el.expanded) ? !el.expanded : true;
      }
    });
    setLocalRowGroups([..._localRowGroups]);
    let flag = false;
    let _local = [];
    if (expandedRows?.length === 0) {
      setExpandedRows([data]);
    }
    expandedRows?.forEach((el) => {
      if (el.Group === data.Group) {
        _local = expandedRows.filter((el) => el.Group !== data.Group);
        flag = true;
      }
    });
    if (flag) setExpandedRows(_local);
    else setExpandedRows([...expandedRows, data]);

    setLocalPreviousRowGroups(_.cloneDeep(_localRowGroups));
  };

  const headerTemplate = (data) => {
    if (!data) return false;
    const expanded = expandedRows?.length;
    let length = 0;
    const selectAllColumnLength = 1;
    const freezedColumns = customizeViewFields
      ? JSON.parse(customizeViewFields)?.freezedColumns?.fieldsData
      : [];
    const selectedColumns = customizeViewFields
      ? JSON.parse(customizeViewFields)?.selectedFields?.fieldsData
      : [];
    if (freezedColumns?.length) {
      length = (
        selectedColumns?.length +
        selectAllColumnLength +
        freezedColumns?.length
      ).toString();
    } else {
      const isSelectetAllColumnExists = selectedColumns?.includes("SelectAll");
      if (isSelectetAllColumnExists) {
        length = selectedColumns?.length ? (selectedColumns?.length).toString() : "15";
      } else {
        length = selectedColumns?.length
          ? (selectedColumns?.length + selectAllColumnLength).toString()
          : "15";
      }
    }
    document
      .querySelectorAll(`tr > td[colspan="${length}"`)
      .forEach((td) => td.setAttribute("colspan", 3));

    const row = document.querySelectorAll("tr.p-rowgroup-header");
    if (row) {
      const finalLength = length - 3;
      for (let i = 0; i < row?.length; i++) {
        let td1 = document.createElement("td");
        td1.setAttribute("style", "border:0");
        td1.setAttribute("id", `row_${i}`);
        td1.setAttribute("colspan", finalLength);
        row[i].append(td1);
      }
      // for (let i = 0; i < row?.length; i++) {
      //   window.$(`tr > td[id="row_${i}"]`).not(":first").remove();
      // }
      for (let i = 0; i < row?.length; i++) {
        let cells = document.querySelectorAll(`tr > td[id="row_${i}"]`);
        cells?.forEach((cell, index) => {
          if (index !== 0) {
            cell.remove();
          }
        });
      }
    }
    let rowgrp = rowGroups?.filter((obj) => obj.id === data.Group);
    const iconClassName = `p-treetable-toggler-icon pi pi-fw ${
      localRowGroups[rowgrp[0]?.order - 1]?.expanded ? "pi-angle-up" : "pi-angle-down"
    }`;
    const GroupedData = (filters?.length ? filters : tableData).filter(
      (el) => el.GroupOrder === rowgrp?.[0]?.order
    );
    const position = Object.values(groupWiseData).filter((el) => el.key === data.Group)?.[0]
      ?.refinedOrder;

    return (
      <React.Fragment>
        <div className="group-wrapper">
          <div
            onClick={() => handleToggleExpand(data, rowgrp?.[0]?.id)}
            className={`group-header vertical-align-middle ml-2 font-bold line-height-3 ${rowgrp?.[0]?.name} top-${position}`}
          >
            <span>
              <span>{rowgrp?.[0]?.name}</span>
              <span className="group-length">({GroupedData?.length})</span>
            </span>
            <span className="p-treetable-toggler p-link">
              <span className={iconClassName} aria-hidden="true"></span>
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
      const frozenCoulmns1 = frozenCoulmns.filter((item) => item !== "SelectAll");
      if (!frozenCoulmns1.includes("SelectAll")) {
        frozenCoulmns1.push("SelectAll");
      }
      setFrozenColumn(frozenCoulmns1);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
      //const availableColumnNamesData = JSON.parse(customizeViewFields)?.availableFields?.fieldsData;
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = JSON.parse(
        customizeViewFields
      )?.selectedFields?.fieldsData?.filter((el) => el !== "SelectAll");
      setFrozenColumn(freezedColumnsNamesData);
      let selectedFieldsNamesData = [...selectedFieldsNames, name];
      const finalData = {
        availableFields: {
          fieldsData: availableColumnNames,
          id: "availableFields",
          title: "Available Fields",
        },
        freezedColumns: {
          fieldsData: freezedColumnsNamesData,
          id: "freezedColumns",
          title: "freezed Columns",
        },
        selectedFields: {
          fieldsData: selectedFieldsNamesData,
          id: "selectedFields",
          title: "selected Fields",
        },
      };
      setCustomizeViewFields(JSON.stringify(finalData));
      localStorage.setItem("customizeViewFieldsBooking", JSON.stringify(finalData));
    }
  };

  const handleDateChange = (options, field, value, isSlack) => {
    if (value != null) {
      options.isSelected = true;
    } else {
      options.isSelected = false;
    }
    if (!options.tempStartDate) {
      options.tempStartDate = options?.StartDate;
    }
    const startDate =
      !AcpService.isUBN(options?.tempStartDate) &&
      !AcpService.checkIfValidDate(options?.tempStartDate)
        ? AcpService.convertToShortDateFormat(options?.tempStartDate, "DD-MMM-YYYY")
        : AcpService.convertToShortDateFormat(new Date(options?.tempStartDate), "DD-MMM-YYYY");
    const endDate = AcpService.convertToShortDateFormat(value, "DD-MMM-YYYY");
    const newStartDateGMT = AcpService.convertToShortDateFormat(value);
    options[field] = newStartDateGMT;
    const dateDifference = AcpService.calculateDateDifference(startDate, endDate);
    if (!options.existGroupOrder && options.existGroupOrder !== 0) {
      const newSlack = parseInt(options.Slack, 10);
      options.existGroupOrder = newSlack;
      const SlackResult = parseInt(options.existGroupOrder, 10) + dateDifference;
      options.Slack = String(SlackResult).padStart(2, "0");
    } else {
      const SlackResult = parseInt(options.existGroupOrder, 10) + dateDifference;
      options.Slack = String(SlackResult).padStart(2, "0");
    }

    let _options = { ...options };

    setEditedRow(_options);
    // if (isSlack !== "slackValue" || isSlack === undefined) {
    //   options[field] = AcpService.convertToGMT(value, options, tabName);
    // }
    // if (allEditedRows?.length) {
    //   allEditedRows?.forEach((row) => {
    //     if (row.TaskName === options.TaskName) {
    //       row[field] = AcpService.convertToGMT(value, options, tabName);
    //       // row["edited"] = true;
    //     }
    //   });
    // }
    setActionType("inlineUpdate");
  };
  const getMinDate = (options) => {
    const minDate = AcpService.getMinDate(options);
    return minDate;
  };

  const fullKitReadinessBody = (options, rowData) => {
    let field = rowData.field;
    return (
      <>
        {field === "Work" && (
          <>
            {AcpService.isNegative(options[field]) ? (
              <span className="text-danger">{options[field]}</span>
            ) : (
              <span>{options[field]}</span>
            )}
          </>
        )}

        {field === "Buffer" && (
          <>
            {AcpService.isNegative(options[field]) ? (
              <span className="text-danger">{options[field]}</span>
            ) : (
              <span>{options[field]}</span>
            )}
          </>
        )}

        {field === "SelectAll" && (
          <div className="flex align-items-center">
            <input
              type="checkbox"
              checked={selected?.includes(options)}
              onChange={() => handleSelect(options)}
              className="p-checkbox-box p-highlight"
              disabled={options?.RequestStatus === "Awaiting Response" || !accessSecurityMatrix}
            />
          </div>
        )}

        {field === "BufferToWork" && (
          <>
            {AcpService.isNegative(options[field]) ? (
              <span className="text-danger">{options[field]}</span>
            ) : (
              <span>{options[field]}</span>
            )}
          </>
        )}

        {field === "Owner" && options[field]}
        {field === "ProjectID" && (
          <Link className={`task-link`} to={`${baseUrl}/${options[field]}`}>
            {options[field]}
          </Link>
        )}
        {field === "EndDate" && tabName === "bveLoop" && (
          <>
            {options?.RequestStatus === "Awaiting Response" ? (
              <div className="start-date-awaiting">
                {AcpService.formatTableDate(options["UpdatedEndDate"]).toString()}
                <Tooltip target=".infoIcon" mouseTrack mouseTrackLeft={10} />
                <img
                  className="infoIcon"
                  data-pr-tooltip="Request sent to Enovia, awaiting response"
                  src={acpInfo}
                  alt="info"
                />
              </div>
            ) : options?.RequestStatus === "FAIL" ? (
              <>
                <Calendar
                  showIcon={true}
                  dateFormat="d-M-y"
                  disabledDays={[0, 6]}
                  icon={<CalendarIcon />}
                  className="acp-calendar"
                  placeholder="Select End Date"
                  value={AcpService.formatDate(options[field])}
                  onChange={(e) => {
                    // options.RequestStatus = true;
                    // options[field] = convertToGMT(e.target.value);
                    handleDateChange(options, field, e.target.value);
                  }}
                  minDate={AcpService.formatDate(options?.StartDate)}
                  disabled={!accessSecurityMatrix}
                  // maxDate={AcpService.formatDate(options.PlannedRelease)}
                />
                <Tooltip target=".errorIcon" mouseTrack mouseTrackLeft={10} />
                {!options.edited && (
                  <img
                    className="errorIcon"
                    // data-pr-tooltip={options.RTAStatusMessage || "Rejected"}
                    data-pr-tooltip={`Enovia update Failed. Error Code ${options?.StatusCode}.`}
                    src={acpError}
                    alt="error"
                  />
                )}
              </>
            ) : (
              <Calendar
                showIcon={true}
                dateFormat="d-M-y"
                icon={<CalendarIcon />}
                disabledDays={[0, 6]}
                className="acp-calendar"
                placeholder="Select End Date"
                disabled={!accessSecurityMatrix}
                value={AcpService.formatDate(options[field])}
                onChange={(e) => {
                  // options[field] = convertToGMT(e.target.value);
                  handleDateChange(options, field, e.target.value);
                }}
                minDate={AcpService.formatDate(options?.StartDate)}
                // maxDate={AcpService.formatDate(options.PlannedRelease)}
              />
            )}
          </>
        )}
        {field === "StartDate" && tabName !== "bveLoop" && (
          <>
            {options?.RequestStatus === "Awaiting Response" ? (
              <div className="start-date-awaiting">
                {AcpService.formatTableDate(options["UpdatedStartDate"]).toString()}
                <Tooltip target=".infoIcon" mouseTrack mouseTrackLeft={10} />
                <img
                  className="infoIcon"
                  data-pr-tooltip="Request sent to Enovia, awaiting response"
                  src={acpInfo}
                  alt="info"
                />
              </div>
            ) : options?.RequestStatus === "FAIL" ? (
              <div className={!options.edited ? "image-info-icon" : ""}>
                <Calendar
                  showIcon={true}
                  disabledDays={[0, 6]}
                  dateFormat="d-M-y"
                  icon={<CalendarIcon />}
                  minDate={getMinDate(options)}
                  className="acp-calendar"
                  placeholder="Select Start Date"
                  disabled={!accessSecurityMatrix}
                  // value={new Date(options["StartDate"])}
                  value={
                    !AcpService.isUBN(options[field]) &&
                    !AcpService.checkIfValidDate(options[field])
                      ? AcpService.formatDate(options[field])
                      : new Date(options[field])
                  }
                  onChange={(e) => {
                    // options.RequestStatus = true;
                    // options[field] = convertToGMT(e.target.value);
                    handleDateChange(options, field, e.target.value);
                  }}
                  // maxDate={AcpService.formatDate(options.PlannedRelease)}
                />
                <Tooltip target=".errorIcon" mouseTrack mouseTrackLeft={10} />
                {!options.edited && (
                  <img
                    className="errorIcon"
                    // data-pr-tooltip={options.RTAStatusMessage || "Rejected"}
                    data-pr-tooltip={`Enovia update Failed. Error Code ${options?.StatusCode}.`}
                    src={acpError}
                    alt="error"
                  />
                )}
              </div>
            ) : (
              <>
                <Calendar
                  showIcon={true}
                  dateFormat="d-M-y"
                  icon={<CalendarIcon />}
                  disabledDays={[0, 6]}
                  className="acp-calendar"
                  minDate={getMinDate(options)}
                  placeholder="Select Start Date"
                  disabled={!accessSecurityMatrix}
                  // value={new Date(options["StartDate"])}
                  value={
                    !AcpService.isUBN(options[field]) &&
                    !AcpService.checkIfValidDate(options[field])
                      ? AcpService.formatDate(options[field])
                      : !AcpService.isUBN(options[field])
                      ? new Date(options[field])
                      : ""
                  }
                  onChange={(e) => {
                    // options[field] = convertToGMT(e.target.value);
                    handleDateChange(options, field, e.target.value, "slackValue");
                  }}
                  // maxDate={AcpService.formatDate(options.PlannedRelease)}
                />
              </>
            )}
          </>
        )}

        {field === "BVETaskEndDate" && changeDateFormat(options[field])}
        {/* {field === "ArtPrinterDate" && changeDateFormat(options[field])} */}
        {field === "TaskDuration" && AcpService.formatDuration(options[field])}
        {field === "PlannedRelease" && AcpService.formatTableDate(options[field])}
        {/* {field === "EndDate" && tabName !== "bveLoop" && changeDateFormat(options[field])} */}
        {field === "EndDate" && tabName !== "bveLoop" && <>{options[field]}</>}

        {field === "ArtPrinterDate" && tabName !== "bveLoop" && <>{options[field]}</>}
        {field === "StartDate" && tabName === "bveLoop" && changeDateFormat(options[field])}

        {field !== "ArtPrinterDate" &&
          field !== "BVETaskEndDate" &&
          field !== "PlannedRelease" &&
          field !== "BufferToWork" &&
          field !== "TaskDuration" &&
          field !== "StartDate" &&
          field !== "ProjectID" &&
          field !== "SelectAll" &&
          field !== "EndDate" &&
          field !== "Buffer" &&
          field !== "Owner" &&
          field !== "Work" &&
          options[field]}
      </>
    );
  };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    let _options = options;
    setSelectedColumnName(_options);
  };

  const onSort = (column, direction, filterData) => {
    setSearchInfo("");
    const newArray = [];
    const sortedData = AcpService.groupBy(
      filterData?.length ? filterData : filters?.length ? filters : tableData,
      "Group"
    );
    for (const [key, value] of Object.entries(sortedData)) {
      const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(value, column, direction);
      newArray.push(sortedDataSet);
    }
    let updatedData = newArray?.flat();
    //3182
    const updatedSortedData = updatedData?.map((el) => {
      return {
        ...el,
        StartDate: AcpCommonService.convertFromGMTtoDDMMMYYDate(el?.StartDate),
      };
    });
    setSortData([column, direction]);
    localStorage.setItem("sortedColumnBooking", JSON.stringify(true));
    // due to 3076
    if (filters?.length || filterData?.length) {
      setFilters(updatedSortedData);
      selectedColumnList.current = updatedSortedData;
      //3182
    } else {
      setTableData(updatedSortedData);
      //3182
      // updatedData = updatedData?.filter((el) => el.Group !== "Backlog");
      setChartData(updatedData);
    }
    // localStorage.setItem("bookingSortingData", JSON.stringify(sortData));
  };

  const saveSettings = () => {
    if (frozenCoulmns?.includes(selectedColumnName)) {
      const availableColumnNamesData = JSON.parse(customizeViewFields)?.availableFields?.fieldsData;
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = JSON.parse(customizeViewFields)
        ?.selectedFields?.fieldsData?.filter((el) => el !== selectedColumnName)
        ?.filter((el) => el !== "SelectAll");
      let selectedFieldsNamesData = selectedFieldsNames?.filter(function (objFromA) {
        return !frozenCoulmns?.find(function (objFromB) {
          return objFromA === objFromB;
        });
      });

      const selectedData = allColumnNames
        ?.map((el) => el.Field_Name)
        ?.filter((el) => el !== selectedColumnName)
        ?.filter((el) => el !== "SelectAll")
        ?.filter(function (objFromA) {
          return !frozenCoulmns?.find(function (objFromB) {
            return objFromA === objFromB;
          });
        });
      setFrozenColumn(freezedColumnsNamesData);
      setAvailableColumnNames(availableColumnNames);

      const finalData = {
        availableFields: {
          fieldsData:
            availableColumnNames?.map((el) => el.Field_Name) || availableColumnNamesData || [],
          id: "availableFields",
          title: "Available Fields",
        },
        freezedColumns: {
          fieldsData: freezedColumnsNamesData,
          id: "freezedColumns",
          title: "freezed Columns",
        },
        selectedFields: {
          fieldsData: selectedData || selectedFieldsNamesData,
          id: "selectedFields",
          title: "selected Fields",
        },
      };
      setCustomizeViewFields(JSON.stringify(finalData));
      localStorage.setItem("customizeViewFieldsBooking", JSON.stringify(finalData));
    }
  };

  const clearColumnWiseFilter = () => {
    setSearchInfo("");
    let _selectedColumnName = selectedColumnName;
    let jsonFrozenItem = localStorage.getItem("bookingColumnFrozenData");
    const frozenItem = JSON.parse(jsonFrozenItem);

    if (frozenItem && frozenItem?.length && frozenItem.includes(_selectedColumnName)) {
      const index = frozenItem.indexOf(_selectedColumnName);
      frozenItem.splice(index, 1);
      setFrozenColumn(frozenItem);
    }
    if (frozenCoulmns.includes(_selectedColumnName)) {
      const index = frozenCoulmns.indexOf(_selectedColumnName);
      frozenCoulmns.splice(index, 1);
      setFrozenColumn(frozenCoulmns);
      setProjectFrozen(!ProjectFrozen);

      const updatedHeaders = JSON?.parse(customizeViewFields)?.selectedFields?.fieldsData;
      const latestHeaders = updatedHeaders?.filter((item) => item !== "SelectAll");
      const frozenData = frozenCoulmns;
      if (!frozenData?.includes("SelectAll")) {
        frozenData?.unshift("SelectAll");
      }
      setFrozenColumn(frozenData);
      const finalColumn = [...(frozenData || []), ...(latestHeaders || [])];
      setProjectColumnNames(finalColumn);

      acpBookingFrozenColumn(
        frozenData,
        setReorderedColumn,
        setProjectColumnNames,
        setTableRender,
        op,
        finalColumn,
        "booking"
      );

      const availableColumnNamesData = JSON.parse(customizeViewFields)?.availableFields?.fieldsData;
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = JSON.parse(
        customizeViewFields
      )?.selectedFields?.fieldsData?.filter((el) => el !== "SelectAll");
      let selectedFieldsNamesData = [...selectedFieldsNames, _selectedColumnName];
      const finalData = {
        availableFields: {
          fieldsData: availableColumnNamesData,
          id: "availableFields",
          title: "Available Fields",
        },
        freezedColumns: {
          fieldsData: freezedColumnsNamesData,
          id: "freezedColumns",
          title: "freezed Columns",
        },
        selectedFields: {
          fieldsData: selectedFieldsNamesData,
          id: "selectedFields",
          title: "selected Fields",
        },
      };
      setCustomizeViewFields(JSON.stringify(finalData));
      localStorage.setItem("customizeViewFieldsBooking", JSON.stringify(finalData));
    }
    const selectedFields2 = _.cloneDeep(selectedFields);
    const _selectedFields = AcpService.emptyProperty(selectedFields2, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    //3182
    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortedColumnBooking");
      setSortData([]);
      if (Object.keys(__selectedFields)?.length === 0) {
        setClearAllFilter(true);
      }
    }
    //3078
    let data = [];
    if (Object.keys(__selectedFields)?.length) {
      data = [...(tableData || [])];
      Object.keys(__selectedFields).forEach((el) => {
        if (__selectedFields[el]?.length) {
          data = data.filter((ele) => __selectedFields[el].includes(ele[el]));
        }
      });
    }
    if (data?.length) {
      setFilters(data);
    } else if (sortData?.length && sortData[0] !== _selectedColumnName) {
      selectedColumnList.current = tableData;
      setTableData(tableData);
      setFilters([]);
      //3182
    } else {
      setFilters([]);
      const finalInitialData = _.cloneDeep(InitialTableData);
      selectedColumnList.current = finalInitialData;
      setTableData(finalInitialData);
    }
    setConditionMatched(false);
    //3182
    setSelectedFields(__selectedFields);
    selectedFieldsRef.current = __selectedFields;
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentBooking");
    }
  };

  const dynamicColumns = () => {
    let jsonColumnWidthMyProject = localStorage.getItem("columnWidthbookingColumn");
    const columnWidthMyProject = JSON.parse(jsonColumnWidthMyProject);
    let checkWidth = [];
    if (columnWidthMyProject) {
      checkWidth = Object.keys(columnWidthMyProject);
    }
    const tblData = filters?.length ? filters : tableData;
    const allSelected = selectAllChecked || tblData?.length === selected?.length;
    return [
      projectColumnName?.map((col, i) => {
        return (
          <Column
            filter
            key={col}
            field={col}
            filterField={col}
            alignFrozen="left"
            columnKey={col || i}
            showFilterMenu={false}
            filterPlaceholder={col}
            filterMatchMode="contains"
            body={fullKitReadinessBody}
            frozen={frozenCoulmns?.includes(col)}
            className={frozenCoulmns?.includes(col) ? "font-bold" : ""}
            header={
              <>
                <ACPBookingHeaderColumn
                  op={op}
                  col={col}
                  onSort={onSort}
                  filters={filters}
                  sortData={sortData}
                  tableData={tableData}
                  setFilters={setFilters}
                  allSelected={allSelected}
                  tableRender={tableRender}
                  setSortData={setSortData}
                  saveSettings={saveSettings}
                  ProjectFrozen={ProjectFrozen}
                  accessSecurityMatrix={accessSecurityMatrix}
                  frozenCoulmns={frozenCoulmns}
                  allColumnNames={allColumnNames}
                  selectedFields={selectedFields}
                  setTableRender={setTableRender}
                  handleSelectAll={handleSelectAll}
                  setFrozenColumn={setFrozenColumn}
                  resetMultiFilter={resetMultiFilter}
                  setProjectFrozen={setProjectFrozen}
                  addFrozenColumns={addFrozenColumns}
                  selectAllChecked={selectAllChecked}
                  projectColumnName={projectColumnName}
                  isReorderedColumn={isReorderedColumn}
                  projectNameOnClick={projectNameOnClick}
                  setReorderedColumn={setReorderedColumn}
                  selectedColumnName={selectedColumnName}
                  availableColumnNames={availableColumnNames}
                  onGlobalFilterChange={onGlobalFilterChange}
                  setProjectColumnNames={setProjectColumnNames}
                  setSelectedColumnName={setSelectedColumnName}
                  clearColumnWiseFilter={clearColumnWiseFilter}
                  acpBookingFrozenColumn={acpBookingFrozenColumn}
                />
                {isSearch && col !== "SelectAll" && (
                  <div className="filter-wrapper">
                    <InputText
                      value={searchHeader?.[col]?.value}
                      onChange={(e) => onGlobalFilterChangeSearch(e, col)} // Trigger global filter change
                      placeholder={`Search ${col?.replace(/_/g, " ")}`} // Replace underscores with spaces in placeholder
                      className="p-inputtext p-component p-column-filter"
                    />
                    <div className="clear-search-wrapper">
                      {searchHeader?.[col]?.value && (
                        <button
                          class="p-column-filter-clear-button p-link clear-search-btn"
                          type="button"
                          aria-label="Clear"
                          onClick={() => clearFilterIndividual(col)}
                        >
                          <span class="pi pi-filter-slash" aria-hidden="true"></span>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </>
            }
            style={{
              width: col?.freeze
                ? col?.field_Name === "SelectAll"
                  ? "50px"
                  : "200px"
                : col?.field_Name === "ProjectName"
                ? "200px"
                : col?.width,
              maxWidth: "200px",
              minWidth: "150px",
              textOverflow: "ellipsis",
            }}
          />
        );
      }),
    ];
  };

  const onGlobalFilterChange = (e, colName) => {
    setSearchInfo("");
    const { value, multiplesFlag, artworkCategories, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      tableData,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef = selectedFieldsRef?.current.map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentBooking");
    }
    if (filterArray.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        const filterData = AcpService.filterDataByfilters(
          tableData,
          _selectedFieldsRefWithUniqueObjects,
          setConditionMatched,
          tabName
        );
        if (filterData?.length && sortData?.length) {
          onSort(sortData[0], sortData[1], filterData);
        } else {
          setFilters(filterData);
          selectedColumnList.current = filterData;
        }
      } else {
        selectedColumnList.current = [];
        setSelectedFields({});
        setFilters([]);
      }
    } else {
      selectedColumnList.current = [];
      setSelectedFields({});
      setFilters([]);
    }
  };

  // useEffect(() => {
  //   let temp = projectColumnName.reduce(
  //     (acc, curr) => ({
  //       ...acc,
  //       [curr]: {
  //         value: selectedFields[curr] ? selectedFields[curr] : null,
  //         // matchMode:FilterMatchMode.CONTAINS,
  //         matchMode: FilterMatchMode.IN,
  //       },
  //     }),
  //     {}
  //   );
  //   setSearchHeader(temp);
  // }, [selectedFields]);

  useImperativeHandle(
    ref,
    () => {
      return {
        clear() {
          clearFilters();
        },
      };
    },
    []
  );

  const clearFilters = () => {
    setFilters([]);
    setSortData([]);
    isSearchSet(false);
    setFrozenColumn([]);
    setSelectedFields({});
  };

  useEffect(() => {
    if (loader) {
      // setSelected([]);
    }
    setDateChangeValue([]);
  }, [loader]);
  // on change of clearAllFilter flag clearAllFilters should call and reset the states
  useEffect(() => {
    if (clearAllFilter) {
      clearAppliedFilters();
    }
  }, [clearAllFilter]);
  // Clear all applied filters and reset states
  const clearAppliedFilters = () => {
    setFilters([]);
    setSortData([]);
    setSelectedFields({});
    setClearAllFilter(false);
    setSelectAllChecked(false);
    setConditionMatched(false);
    selectedFieldsRef.current = {};
    selectedColumnList.current = [];
    localStorage.removeItem("sortedColumnBooking");
    localStorage.removeItem("_selectedFieldsRefCurrentBooking");
  };

  const onGlobalFilterChangeSearch = (e, colName) => {
    const value = e.target.value;
    setSearchInfo(value);
    const searchTerms = value?.split(" ")?.filter(Boolean);
    // Split by spaces and remove empty strings
    setSearchHeader((prevFilters) => {
      return {
        ...prevFilters,
        [colName]: {
          value, // Set the value of the specific column filter
          matchMode: FilterMatchMode.CONTAINS, // Ensure match mode is set
        },
      };
    });
  };

  const clearFilterIndividual = (column) => {
    // copy of the current filters state
    const updatedFilters = { ...searchHeader };
    // Clear filter value for specific column
    if (updatedFilters[column]) {
      updatedFilters[column].value = "";
      setSearchHeader(updatedFilters);
      setSearchInfo("");
    }
  };

  useEffect(() => {
    if ((filters?.length || tableData?.length) && !selectedRows?.length) {
      setSelectedColumnName("SelectAll");
      //check the array if StartDate null skip that value ,Awm-3217
      const updatedValues = removeObjectsWithNullStartDate(allEditedRows);
      setDateChangeValue(updatedValues);
      //here checking if filter?.length then filter else tableData
      // const updatedFilters = (filters?.length ? filters : tableData)?.map((el) => {
      //   return {
      //     ...el,
      //     StartDate:
      //       !AcpService.isUBN(el?.StartDate) && !AcpService.checkIfValidDate(el?.StartDate)
      //         ? changeDateFormat(el.StartDate)
      //         : el?.StartDate,
      //   };
      // });
      // filters?.length ? setFilters([...updatedFilters]) : setTableData([...updatedFilters]);
    }
  }, [selectedRows, allEditedRows]);

  return (
    <>
      <LoadingOverlay active={loader} spinner text="">
        {ShowCustomizedView && (
          <CustomizeView
            headerName={"ACPBooking"}
            allColumns={allColumnNames}
            setFrozenColumn={setFrozenColumn}
            showTaskDialog={ShowCustomizedView}
            availableFields={availableColumnNames}
            customizeViewFields={customizeViewFields}
            setCustomizeViewFields={setCustomizeViewFields}
            onClose={() => setShowCustomizedView(!ShowCustomizedView)}
          />
        )}

        <div className="acp-booking-table data-table-checkbox-column">
          {projectColumnName?.length < 2 ? null : (
            <DataTable
              ref={dt}
              scrollable
              rows={100}
              stripedRows
              showGridlines
              size={"small"}
              resizableColumns
              reorderableColumns
              groupRowsBy="Group"
              expandableRowGroups
              //accordion by group
              dataKey="Project_ID"
              // value={tableData}
              selection={selectedRows}
              rowGroupMode="subheader"
              filters={searchHeader}
              responsiveLayout="scroll"
              columnResizeMode={resizeMode}
              // rowClassName={rowClass}
              className="mt-3 acpBooking"
              expandedRows={expandedRows}
              // selectionMode="checkbox"
              onSelectionChange={(e) => {
                setSelectedRows(e?.value);
              }}
              paginatorPosition="both"
              // onRowToggle={(e) => {
              //   setExpandedRows(e.data);
              // }}
              // filterDisplay={isSearch && "row"}
              // onColReorder={storeReorderedColumns}
              rowGroupHeaderTemplate={headerTemplate}
              // key={generateUniqueKey("Project_ID")}
              // onColumnResizeEnd={onColumnResizeEnd}
              onResize={(e) => console.log("resize", e)}
              onResizeCapture={(e) => console.log("e", e)}
              // onValueChange={(obj) => {}}
              key={tableRender ? `"Project_ID" + timestamp+ ${resetKey}` : `${resetKey}`}
              // tableStyle={{ width: "max-content", minWidth: "100%" }}
              emptyMessage={loader ? " " : "No records found"}
              paginator={tableData?.length || filters?.length ? true : false}
              value={conditionMatched ? [] : filters?.length ? filters : tableData}
              rowClassName={(rowData) => (rowData.isSelected ? "highlight-row" : "normal-row")}
            >
              {dynamicColumns()}
            </DataTable>
          )}
        </div>
      </LoadingOverlay>
    </>
  );
}

export default forwardRef(AcpBookingDatatable);
