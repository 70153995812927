export const AzureContainerSuccess = {
    severity: "success",
    summary: "File Uploaded To Azure Container",
    detail: "Successfully!",
    life: 3000,
  }

  export const AzureContainerFail = {
    severity: "error",
    summary: "File Upload To Azure Container",
    detail: "Failed!",
    life: 3000,
  }

  export const AzureFileShareSuccess = {
    severity: "success",
    summary: "File Uploaded To Azure File Share",
    detail: "Successfully!",
    life: 3000,
  }

  export const AzureFileShareFail = {
    severity: "error",
    summary: "File Upload To Azure File Share",
    detail: "Failed!",
    life: 3000,
  }

  export const ContainerAndFileShareFailMsg = "Upload to both Azure File AWM Container and Azure Cloudflow File Share failed! Please try after some time."
  export const ContainerFailMsg = "Upload to Azure File AWM Container failed! Please try after some time."
  export const FileShareFailMsg = "Upload to Azure Cloudflow File Share failed! Please try after some time."

export const CIC_DEPENDENT = "CIC Dependent";
export const NOT_NEEDED = "Not Needed";
export const AWM_CICNeeded_values = [CIC_DEPENDENT, NOT_NEEDED];
