import "../index.scss";
import _, { isObject,isEmpty } from "lodash";
import { Panel } from "primereact/panel";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Link, useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import CustomizeView from "../../common/CustomizeView";
import filter from "../../../assets/images/filter.svg";
import { AcpService } from "../../../service/ACPService";
import { ProjectsRequiringPMAssignment } from "../constant";
import { ProjectService } from "../../../service/PegaService";
import ACPBookingHeaderColumn from "./ACPDashBoardHeaderColumn";
import React, { useEffect, useState, useCallback } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import { changeDateFormat, multiFilterChange } from "../../../utils";
import { acpBookingFrozenColumn } from "../../../Utils/acpBookingFrozenColumn";
import { AcpCommonService } from "../../../service/AcpCommonService";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import { onACPColumnResizeEnd,storeACPReorderedColumns } from "../utils";

function PanelTop({
  ref,
  reset,
  loader,
  tabName,
  Headers,
  isSearch,
  rowGroups,
  fetchData,
  editedRow,
  expandAll,
  setLoader,
  setHeaders,
  actionType,
  isSearchSet,
  selectedRows,
  setExpandAll,
  setEditedRow,
  setActionType,
  setActionFlag,
  allEditedRows,
  setBookingData,
  clearAllFilter,
  pmPicklistData,
  setSelectedRows,
  setAllEditedRows,
  bookingTableData,
  setClearAllFilter,
  setNoOfActiveTasks,
  updatedBookingData,
  ShowCustomizedView,
  bookingTableFilters,
  setBookingTableData,
  customizeViewFields,
  checkReadWriteAccess,
  setShowCustomizedView,
  setCustomizeViewFields,
  selectedDropdownValue,
  resetKey,
  dashboardPageEdited,
  setDashboardPageEdited,
}) {
  const dt = useRef(null);
  const op = useRef(null);
  const navigate = useNavigate();
  const filterArray = useRef([]);
  const panelRef1 = useRef(null);
  const colAllSelected = useRef([]);
  const selectedFieldsRef = useRef([]);
  const selectedColumnList = useRef([]);
  const [filters, setFilters] = useState([]);
  const [selected, setSelected] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ColumnsList1, setColumnsList1] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [tableRender, setTableRender] = useState(false);
  const [selectedFields, setSelectedFields] = useState({});
  const [allColumnNames, setAllColumnNames] = useState([]);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [initialPegaData, setInitialPegaData] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [projectColumnName, setProjectColumnNames] = useState([]);
  const [isReorderedColumn, setReorderedColumn] = useState(false);
  const [frozenCoulmns, setFrozenColumn] = useState(["SelectAll"]);
  const [availableColumnNames, setAvailableColumnNames] = useState([]);
  const [selectedColumnName, setSelectedColumnName] = useState("SelectAll");
  const baseUrl = "/allProjects/projectSetup"; //TODO: Need to change to dynamicurl
  const [conditionMatched, setConditionMatched] = useState(false);
  const [initialTableData, setInitialTableData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [pendingArg, setPendingArg] = useState(null);
  const { markDirty, markClean } = useUnsavedChangesWarning();

  const [panelTopLoader, setPanelTopLoader] = useState(false);
  const handleSelectAll = (e) => {
    if (e.target.checked && (tableData?.length || filters?.length)) {
      const data = filters?.length > 0 ? filters : tableData;
      data?.map((el) => (el.isSelected = true));
      setSelectAllChecked(true);
      setSelected(data);
    } else {
      const data = filters?.length > 0 ? filters : tableData;
      if (data) {
        tableData?.map((el) => (el.isSelected = false));
      }
      setSelectAllChecked(false);
      setSelected([]);
    }
  };

  const handleSelect = useCallback(
    (item) => {
      if (selected?.includes(item)) {
        item.isSelected = false;
        setSelectAllChecked(false);
        setSelected(selected.filter((i) => i !== item));
      } else {
        item.isSelected = true;
        if (selected.length === 0) {
          const selectedList = [];
          selectedList.push(item);
          setSelected(selectedList);
        } else {
          const selectedList = [...selected, item];
          const data = filters?.length > 0 ? filters : tableData;
          setSelectAllChecked(data?.length === selectedList?.length);
          setSelected([...selectedList]);
        }
      }
    },
    [selected]
  );

  useEffect(() => {
    const columnNames1 = ProjectService.getAvailableDashboardColumnNames();
    localStorage.setItem("PmNameSaved", JSON.stringify([]));
    setColumnsList1(columnNames1);
  }, []);

  useEffect(() => {
    if (customizeViewFields && JSON.parse(customizeViewFields)?.selectedFields?.fieldsData) {
      const updatedHeaders = JSON?.parse(customizeViewFields)?.selectedFields?.fieldsData;
      const latestHeaders = updatedHeaders.filter((item) => item !== "SelectAll");
      const frozenData = JSON?.parse(customizeViewFields)?.freezedColumns?.fieldsData;
      if (!frozenData.includes("SelectAll")) {
        frozenData.unshift("SelectAll");
      }
      setFrozenColumn(frozenData);
      const finalColumn = [...frozenData, ...latestHeaders];
      setProjectColumnNames(finalColumn);

      setProjectFrozen(!ProjectFrozen);
      projectColumnName &&
        acpBookingFrozenColumn(
          frozenData,
          setReorderedColumn,
          setProjectColumnNames,
          setTableRender,
          op,
          finalColumn,
          "booking"
        );
    } else {
      const columnNames = ProjectService.getAvailableDashboardColumnNames();
      if (!columnNames.includes("SelectAll")) {
        columnNames.unshift("SelectAll");
      }
      setProjectColumnNames(columnNames);
    }
  }, [customizeViewFields]);

  useEffect(() => {
    let localDashboardAllColumns = JSON.parse(localStorage.getItem("ACPDasboardAllColumnNames"));
    const availableBookingColumnNames = ProjectService.getAvailableDashboardColumnNames();
    let availableBookingColumnNamesUpdated = [];
    if(isEmpty(localDashboardAllColumns)){
      availableBookingColumnNamesUpdated=availableBookingColumnNames?.map((el) => {
      return {
        width: 250,
        Sequence: "",
        freeze: false,
        Field_Name: el,
        reorder: false,
        Column_Name: el,
        sortAtoZ: false,
        sortZtoA: false,
        Attribute_Type: "Free Text",
      };
    })
    } else{
      availableBookingColumnNamesUpdated=localDashboardAllColumns;
    }
    const bookingColumnNames = ProjectService.getDashboardColumnNames();
    const bookingColumnNamesUpdated = bookingColumnNames?.map((el) => {
      return {
        Field_Name: el,
      };
    });
    let availableFieldsToSelect = bookingColumnNamesUpdated?.filter(function (objFromA) {
      return !availableBookingColumnNamesUpdated?.find(function (objFromB) {
        return objFromA.Field_Name === objFromB.Field_Name;
      });
    });
    setHeaders(bookingColumnNames);
    setAvailableColumnNames(availableFieldsToSelect);
    setAllColumnNames(availableBookingColumnNamesUpdated);
    if (tableData?.length !== 0 && loader) {
      (async () => {
        try {
          const ProjectData = _.cloneDeep(tableData);
          if (ProjectData.length) {
            if (!initialPegaData) {
              setInitialPegaData(ProjectData);
            }
            let filteredPegaDataJson = localStorage.getItem("bookingColumnWiseFilterData");
            const filteredPegaData = JSON.parse(filteredPegaDataJson);
            if (filteredPegaData && filteredPegaData.length) {
              setTableData(ProjectData);
              setFilters(filteredPegaData);
              setSelectedFields(filteredPegaData);
            } else {            
                setTableData(ProjectData);
            }
          }
          let columnNamesJson = localStorage.getItem("bookingNamesbookings");
          const columnNames = JSON.parse(columnNamesJson);

          if (columnNames != null && columnNames.length) {
            setProjectColumnNames(columnNames);
          } else {
            if (
              customizeViewFields &&
              JSON.parse(customizeViewFields)?.selectedFields?.fieldsData
            ) {
              const updatedHeaders = JSON?.parse(customizeViewFields)?.selectedFields?.fieldsData;
              const latestHeaders = updatedHeaders.filter((item) => item !== "SelectAll");
              const frozenData = JSON?.parse(customizeViewFields)?.freezedColumns?.fieldsData;
              if (!frozenData.includes("SelectAll")) {
                frozenData.push("SelectAll");
              }
              const updatedColumn = [...frozenData, ...latestHeaders];
              const finalColumn = updatedColumn.filter((item) => {
                return item.toLowerCase() !== "selectall";
              });
              const finalColumn1 = [...finalColumn];
              if (!finalColumn.includes("SelectAll")) {
                finalColumn1.unshift("SelectAll");
              }
              // setProjectColumnNames(updatedColumn);
              setProjectFrozen(!ProjectFrozen);
              projectColumnName &&
                acpBookingFrozenColumn(
                  frozenCoulmns,
                  setReorderedColumn,
                  setProjectColumnNames,
                  setTableRender,
                  op,
                  finalColumn1,
                  "booking"
                );
            } else {
              const columnNames = ProjectService.getAvailableDashboardColumnNames();
              if (!columnNames.includes("SelectAll")) {
                columnNames.unshift("SelectAll");
              }
              // setProjectColumnNames(columnNames);
            }
          }

          let jsonSortingData1 = localStorage.getItem("DashboardSortingData");
          const sortingData = JSON.parse(jsonSortingData1);
          if (sortingData && sortingData.length) {
            const sortField = sortData[0];  // field
            const sortOrder = sortData[1];  // 'desc' or 'asc'
            onSort(sortField,sortOrder)

            // const sortedData = [...ProjectData].sort((a, b) => {
            //   if (sortData === "desc") {
            //     return a[sortData[0]] > b[sortData[0]] ? -1 : 1;
            //   } else {
            //     return a[sortData[0]] > b[sortData[0]] ? 1 : -1;
            //   }
            // });
            // setTableData(sortedData);

          }

          let jsonFrozenrData1 = localStorage.getItem("bookingFrozenData");
          const frozenData = JSON.parse(jsonFrozenrData1);
          if (frozenData && frozenData.length) {
            setFrozenColumn(frozenData);
          }
        } catch (err) {
          console.log("error", err);
        }
      })();
    }
    setSelected([]);
  }, [tableData]);

  useEffect(() => {
    if (tableData?.length || filter?.length) {
      setBookingData(filters?.length ? filters : tableData);
      if (setBookingData) setBookingData(filters?.length ? filters : tableData);
    }
  }, [tableData, filters]);

  useEffect(() => {
    const temp = allEditedRows?.filter((row) => {
      return isObject(row) && row.UniqueID !== editedRow.UniqueID;
    });
    if (Object.keys(editedRow)?.length && actionType !== "massUpdate") {
      temp.push(editedRow);
    }
    setDashboardPageEdited(temp?.length ? true : false);
    if (temp?.length) {
      markDirty();
      setDashboardPageEdited(true);
    } else {
      markClean();
      setDashboardPageEdited(false);
    }
    setAllEditedRows(temp);
    const updatedTableData = AcpCommonService.updateTableData(
      _.cloneDeep(tableData),
      _.cloneDeep(temp),
      "UniqueID"
    );
    setTableData(updatedTableData);
  }, [editedRow]);

  const convertData = (data) => {
    return data?.map((el, i) => {
      return {
        ...el,
        UniqueID: i,
        PmName: el?.PM,
        Owner: el?.Assignee !== "" ? el?.Assignee : null,
        ProjectName: el?.ProjectName !== "" ? el?.ProjectName : null,
        ArtPrinterDate:
          el?.EstimatedAWPrinter !== "" ? changeDateFormat(el?.EstimatedAWPrinter) : null,
        AwRedinessDate:
          el?.EstimatedAWReadiness !== "" ? changeDateFormat(el?.EstimatedAWReadiness) : null,
      };
    });
  };

  const resetFilter = (tbldata) => {
    let _selectedFieldsRef=[];
    if(selectedFieldsRef?.current?.length>0){
       _selectedFieldsRef = selectedFieldsRef?.current?.map((el) => {
        return el;
      });
    }

    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentDashboardPanelTop");
    }
    if (filterArray?.current?.length) {
      if (colAllSelected?.current?.length >= 1 && !clearAllFilter) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        const updatedObjects = _selectedFieldsRefWithUniqueObjects?.filter(
          (item) => !item.hasOwnProperty("PmName")
        );
        let _filteredByDate = convertData(tbldata);
        const filterData = AcpService.filterDataByfilters(
          _filteredByDate,
          updatedObjects,
          setConditionMatched,
          "DashboardPanelTop"
        );
        const convertedObj = AcpCommonService.convertArrayToObject(updatedObjects);
        const uniqueObj = AcpCommonService.getUniqueValuesObject(convertedObj);
        if (filterData?.length && sortData?.length) {
          onSort(sortData[0], sortData[1], filterData, updatedObjects);
        } else {
          setFilters(filterData);
          setSelectedFields(uniqueObj);
          selectedFieldsRef.current = uniqueObj;
          selectedColumnList.current = filterData;
        }
      } else {
        selectedColumnList.current = [];
        setSelectedFields({});
        setFilters([]);
      }
    } else {
      selectedColumnList.current = [];
      setSelectedFields({});
      setFilters([]);
    }
  };

  useEffect(() => {
      setTableData(_.cloneDeep(bookingTableData));
    if (filters?.length) {
      resetFilter(_.cloneDeep(bookingTableData));
    }
    setSelectedColumnName("SelectAll");
    setSelectedRows([]);
    filterTable();
  }, [reset]);

  useEffect(() => {
    setInitialPegaData(_.cloneDeep(bookingTableData));
    setTableData(_.cloneDeep(bookingTableData));
    setSelectAllChecked(false);
    filterTable();
    if (bookingTableData?.length) {
      setLoader(false);
    }
  }, [bookingTableFilters, bookingTableData]);
  //added useEffect for loader issue resolved Awm-3276
  useEffect(() => {
    // Check if the condition to show the loader is met
    if (Object.keys(selectedDropdownValue || {}).length > 0 && !pmPicklistData?.length) {
      setPanelTopLoader(true);
      // Automatically close the loader after 5 seconds
      setTimeout(() => {
        setPanelTopLoader(false);
      }, 5000);
    }
  }, [selectedDropdownValue, pmPicklistData]);

  useEffect(() => {
    setSelectedRows(selected);
    if (setActionFlag) {
      setActionFlag(selected);
    } else {
      setActionFlag(!selected);
    }
  }, [selected]);

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
      const frozenCoulmns1 = frozenCoulmns.filter((item) => item !== "SelectAll");
      if (!frozenCoulmns1.includes("SelectAll")) {
        frozenCoulmns1.push("SelectAll");
      }
      setFrozenColumn(frozenCoulmns1);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = JSON.parse(
        customizeViewFields
      )?.selectedFields?.fieldsData?.filter((el) => el !== "SelectAll");
      setFrozenColumn(freezedColumnsNamesData);
      let selectedFieldsNamesData = [...selectedFieldsNames, name];
      const finalData = {
        availableFields: {
          fieldsData: availableColumnNames,
          id: "availableFields",
          title: "Available Fields",
        },
        freezedColumns: {
          fieldsData: freezedColumnsNamesData,
          id: "freezedColumns",
          title: "Freezed Fields",
        },
        selectedFields: {
          fieldsData: selectedFieldsNamesData,
          id: "selectedFields",
          title: "Selected Fields",
        },
      };
      setCustomizeViewFields(JSON.stringify(finalData));
      localStorage.setItem("customizeViewFieldsBooking", JSON.stringify(finalData));
    }
  };

  const handleSelectedPmName = (options, field, value) => {
    setSelectedColumnName("SelectAll");
    if (pmPicklistData.includes(value)) {
      options["PM"] = value;
      options[field] = value?.name;
      options.isSelected = true;
      setActionType("inlineUpdate");
      setEditedRow(options);
      if (allEditedRows?.length) {
        allEditedRows?.forEach((row) => {
          if (row.UniqueID === options.UniqueID) {
            row["PM"] = value;
            row[field] = value?.name;
          }
        });
        const uniqueIdsInArray = new Set(allEditedRows?.map((obj) => obj?.UniqueID));
        const filteredSelectedData = selectedRows?.filter(
          (obj) => !uniqueIdsInArray.has(obj?.UniqueID)
        );
        setSelected(filteredSelectedData);
      }
    } else if (!pmPicklistData.includes(value)) {
      options.isSelected = false;
      setActionType("");
      if (!value) {
        allEditedRows = allEditedRows.filter((item) => item.UniqueID !== options.UniqueID);
        setAllEditedRows(allEditedRows);
      }
      options["PM"] = "";
      options[field] = {};
      setActionType("");
    }
  };

  const filterTable = () => {
    const tbldata = _.cloneDeep(bookingTableData);
    if (tbldata?.length) {
      let _filteredByDate = convertData(tbldata);
      setInitialTableData(_filteredByDate);
      if (allEditedRows && allEditedRows.length > 0) {
        const updatedTableData = _filteredByDate.map(item => {
            const editedItem = allEditedRows.find(edit => edit.AWMProjectID === item.AWMProjectID);
            
            if (editedItem) {
                return { ...item, ...editedItem };
            }
            return item;
        });
        setTableData(updatedTableData);
      } else{
        setTableData(_filteredByDate);
      }
    } else {
      setTableData([]);
      setInitialTableData([]);
    }
  };

  const handleProjectNameRedirect = (options) => {
    navigate(`${baseUrl}/${options?.["AWMProjectID"]}`);
  };

  const handleProjectNameClick = (options) => {
    if (dashboardPageEdited) {
      setShowDialog(true);
      setPendingArg(options);
    } else {
      handleProjectNameRedirect(options);
    }
  };

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  };
  // by doing setShowDialog(false) and will call handleUserNotifications ;
  const confirmNavigation = () => {
    markClean();
    setShowDialog(false);
    handleProjectNameRedirect(pendingArg);
    setPendingArg("");
  };

  const fullKitReadinessBody = (options, rowData) => {
    let field = rowData.field;
    return (
      <>
        {field === "SelectAll" && (
          <div className="flex align-items-center">
            <input
              type="checkbox"
              checked={selected?.includes(options)}
              onChange={() => handleSelect(options)}
              className="p-checkbox-box p-highlight"
              disabled={options?.RequestStatus === "Awaiting Response" || !checkReadWriteAccess}
            />
          </div>
        )}
        {field === "PmName" && (
          <>
            <div>
              <CommonAutocomplete
                field="name"
                optionLabel="name"
                className="w-full"
                value={options["PM"]}
                placeholder="Select PM"
                options={pmPicklistData}
                suggestions={pmPicklistData}
                disabled={!checkReadWriteAccess}
                onChange={(e) => {
                  handleSelectedPmName(options, field, e?.target?.value);
                }}
              />
            </div>
          </>
        )}
        {field === "ProjectName" && (
          <span className={`task-link`} onClick={() => handleProjectNameClick(options)}>
            {options[field]}
          </span>
        )}
        {field === "AwRedinessDate" && options[field]}
        {field === "ArtPrinterDate" && options[field]}

        {field !== "ArtPrinterDate" &&
          field !== "AwRedinessDate" &&
          field !== "ProjectName" &&
          field !== "StartDate" &&
          field !== "SelectAll" &&
          field !== "PmName" &&
          options[field]}
      </>
    );
  };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    let _options = options;
    setSelectedColumnName(_options);
  };

  const onSort = (column, direction, filterData, selectedFieldsRefObj) => {
    const newArray = [];
    const sortedData = AcpService.groupBy(
      filterData?.length ? filterData : filters?.length ? filters : tableData,
      "Group"
    );
    for (const [key, value] of Object.entries(sortedData)) {
      const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(value, column, direction);
      newArray.push(sortedDataSet);
    }
    setSortData([column, direction]);
    let updatedData = newArray?.flat();
    localStorage.setItem("sortedColumnDashboardPanelTop", JSON.stringify(true));

    if (selectedFieldsRefObj && Object.keys(selectedFieldsRefObj)?.length) {
      //3182
      selectedFieldsRef.current = selectedFieldsRefObj;
      setSelectedFields(selectedFieldsRefObj);
    }
    if (filterData?.length || filters?.length) {
      setFilters(updatedData);
      selectedColumnList.current = updatedData;
    } else {
      setTableData(updatedData);
    }
    localStorage.setItem("DashboardSortingData", JSON.stringify(updatedData));
  };

  const saveSettings = () => {
    if (frozenCoulmns?.includes(selectedColumnName)) {
      const availableColumnNamesData = JSON.parse(customizeViewFields)?.availableFields?.fieldsData;
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = JSON.parse(customizeViewFields)
        ?.selectedFields?.fieldsData?.filter((el) => el !== selectedColumnName)
        ?.filter((el) => el !== "SelectAll");
      let selectedFieldsNamesData = selectedFieldsNames?.filter(function (objFromA) {
        return !frozenCoulmns?.find(function (objFromB) {
          return objFromA === objFromB;
        });
      });

      const selectedData = allColumnNames
        ?.map((el) => el.Field_Name)
        ?.filter((el) => el !== selectedColumnName)
        ?.filter((el) => el !== "SelectAll")
        ?.filter(function (objFromA) {
          return !frozenCoulmns?.find(function (objFromB) {
            return objFromA === objFromB;
          });
        });
      setFrozenColumn(freezedColumnsNamesData);
      setAvailableColumnNames(availableColumnNames);

      const finalData = {
        availableFields: {
          fieldsData:
            availableColumnNames?.map((el) => el.Field_Name) || availableColumnNamesData || [],
          id: "availableFields",
          title: "Available Fields",
        },
        freezedColumns: {
          fieldsData: freezedColumnsNamesData,
          id: "freezedColumns",
          title: "Freezed Fields",
        },
        selectedFields: {
          fieldsData: selectedData || selectedFieldsNamesData,
          id: "selectedFields",
          title: "Selected Fields",
        },
      };
      setCustomizeViewFields(JSON.stringify(finalData));
      localStorage.setItem("customizeViewFieldsBooking", JSON.stringify(finalData));
    }
  };
  const clearColumnWiseFilter = () => {
    let jsonFrozenItem = localStorage.getItem("bookingColumnFrozenData");
    const frozenItem = JSON.parse(jsonFrozenItem);
    let _selectedColumnName = selectedColumnName;

    if (frozenItem && frozenItem?.length && frozenItem.includes(_selectedColumnName)) {
      const index = frozenItem.indexOf(_selectedColumnName);
      frozenItem.splice(index, 1);
      setFrozenColumn(frozenItem);
    }
    if (frozenCoulmns.includes(_selectedColumnName)) {
      const index = frozenCoulmns.indexOf(_selectedColumnName);
      frozenCoulmns.splice(index, 1);
      setFrozenColumn(frozenCoulmns);
      setProjectFrozen(!ProjectFrozen);
    }
    const selectedFields2 = _.cloneDeep(selectedFields);
    const _selectedFields = AcpService.emptyProperty(selectedFields2, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRef.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentDashboardPanelTop");
    }
    let data = [...(tableData || [])];
    Object.keys(__selectedFields).forEach((el) => {
      if (__selectedFields[el]?.length) {
        data = data.filter((ele) => __selectedFields[el].includes(ele[el]));
      }
    });

    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortedColumnDashboardPanelTop");
      setSortData([]);
      if (Object.keys(__selectedFields)?.length === 0) {
        setFilters([]);
        // setClearAllFilter(true);
        selectedFieldsRef.current = [];
        setTableData(initialTableData);
        selectedColumnList.current = initialTableData;
      }
    }

    if (data?.length  && Object.keys(__selectedFields)?.length) {
      setFilters(data);
    } else if (sortData?.length && sortData[0] !== _selectedColumnName) {
      selectedColumnList.current = tableData;
      setFilters([]);
      onSort(sortData[0], sortData[1], tableData);
    }else {
      setFilters([]);
      selectedFieldsRef.current = [];
      setTableData(initialTableData);
      selectedColumnList.current = initialTableData;
    }
    if (selectAllChecked) {
      setSelectAllChecked(false);
    }
    setConditionMatched(false);
  };
  const onColumnResizeEnd = (event) => {
     const _allColumns = onACPColumnResizeEnd(event,"ACPDasboardAllColumnNames",allColumnNames)
     setAllColumnNames(_allColumns)

  };
  const storeReorderedColumns = (e) => {
     const projectColumnName=storeACPReorderedColumns(e,"ACPDasboardAllColumnNames",allColumnNames)
     setAllColumnNames(projectColumnName);
  };

  const dynamicColumns = () => {
    const tblData = filters?.length ? filters : tableData;
    const allSelected = selectAllChecked || tblData?.length === selected?.length;
    return [
      allColumnNames?.map((col, i) => {
        return (
          <Column
            filter={col.Field_Name
              === "SelectAll" ? false : true}
            key={col.Field_Name
            }
            field={col.Field_Name
            }
            filterField={col.Field_Name
            }
            alignFrozen="left"
            columnKey={col.Field_Name
              || i}
            showFilterMenu={false}
            filterPlaceholder={col.Field_Name
            }
            body={fullKitReadinessBody}
            frozen={frozenCoulmns?.includes(col.Field_Name
            )}
            className={frozenCoulmns?.includes(col.Field_Name
            ) ? "font-bold" : ""}
            header={
              <ACPBookingHeaderColumn
                op={op}
                col={col.Field_Name
                }
                onSort={onSort}
                filters={filters}
                sortData={sortData}
                tableData={tableData}
                setFilters={setFilters}
                allSelected={allSelected}
                tableRender={tableRender}
                setSortData={setSortData}
                saveSettings={saveSettings}
                ProjectFrozen={ProjectFrozen}
                frozenCoulmns={frozenCoulmns}
                allColumnNames={allColumnNames}
                selectedFields={selectedFields}
                accessSecurityMatrix={checkReadWriteAccess}
                setTableRender={setTableRender}
                handleSelectAll={handleSelectAll}
                setFrozenColumn={setFrozenColumn}
                setProjectFrozen={setProjectFrozen}
                addFrozenColumns={addFrozenColumns}
                selectAllChecked={selectAllChecked}
                projectColumnName={projectColumnName}
                isReorderedColumn={isReorderedColumn}
                projectNameOnClick={projectNameOnClick}
                setReorderedColumn={setReorderedColumn}
                selectedColumnName={selectedColumnName}
                availableColumnNames={availableColumnNames}
                onGlobalFilterChange={onGlobalFilterChange}
                setProjectColumnNames={setProjectColumnNames}
                setSelectedColumnName={setSelectedColumnName}
                clearColumnWiseFilter={clearColumnWiseFilter}
                acpBookingFrozenColumn={acpBookingFrozenColumn}
              />
            }
            style={{
              width: col?.freeze
              ? (col?.Field_Name === "SelectAll" 
                  ? "50px" 
                  : col?.Field_Name === "Project Name"
                    ? "200px"
                    : col?.width)
              : col?.width,
              textOverflow: "ellipsis",
              maxWidth: col?.width,
              minWidth:"80px",
            }}
          />
        );
      }),
    ];
  };

  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      tableData,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef = selectedFieldsRef?.current.map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });

    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentDashboardPanelTop");
    }
    if (filterArray.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        const filterData = AcpService.filterDataByfilters(
          tableData,
          _selectedFieldsRefWithUniqueObjects,
          setConditionMatched,
          "DashboardPanelTop"
        );
        if (filterData?.length && sortData?.length) {
          onSort(sortData[0], sortData[1], filterData);
        } else {
          setFilters(filterData);
          selectedColumnList.current = filterData;
        }
      } else {
        selectedColumnList.current = [];
        setSelectedFields({});
        setFilters([]);
      }
    } else {
      selectedColumnList.current = [];
      setSelectedFields({});
      setFilters([]);
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        clear() {
          clearFilters();
        },
      };
    },
    []
  );

  const clearFilters = () => {
    setFilters([]);
    isSearchSet(false);
    setSelectedFields({});
  };

  useEffect(() => {
    if (clearAllFilter) {
      clearFilters();
      setClearAllFilter(false);
      if (selectAllChecked) {
        setSelectAllChecked(false);
      }
    }
  }, [clearAllFilter]);

  useEffect(() => {
    if (loader) {
      setSelected([]);
    }
  }, [loader]);

  const headerTemplatePanel = (options, text, ref) => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <>
        <div className={className}>
          <div className="flex align-items-center gap-2">
            <span className="font-bold">{text}</span>
            <span className="p-treetable-toggler p-link">
              <span
                aria-hidden="true"
                onClick={() => ref.current.toggle()}
                className={`p-treetable-toggler-icon pi pi-fw ${
                  options?.collapsed ? "pi-angle-down" : "pi-angle-up"
                }`}
              ></span>
            </span>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    let toBeExpanded = [];
    if (expandAll === true) {
      panelRef1?.current?.expand();
      (rowGroups || [])?.forEach((grp) => {
        if (tableData?.length) {
          let filteredData = tableData?.filter((data) => {
            return data?.Group === grp?.id;
          });
          filteredData?.length && toBeExpanded.push(filteredData[0]);
        }
      });
      setExpandedRows(toBeExpanded);
    } else if (expandAll === false) {
      panelRef1?.current?.collapse();
      setExpandedRows([]);
    }
  }, [expandAll, tableData, bookingTableFilters]);

  return (
    <>
      <LoadingOverlay active={loader} spinner text="">
        {ShowCustomizedView && (
          <CustomizeView
            allColumns={allColumnNames}
            showTaskDialog={ShowCustomizedView}
            headerName={"Artwork Scope Alignment"}
            availableFields={availableColumnNames}
            customizeViewFields={customizeViewFields}
            setCustomizeViewFields={setCustomizeViewFields}
            onClose={() => setShowCustomizedView(!ShowCustomizedView)}
          />
        )}
        <div className="acp-booking-table data-table-checkbox-column">
          <Panel
            toggleable
            ref={panelRef1}
            className="panel1"
            headerTemplate={(el) =>
              headerTemplatePanel(el, ProjectsRequiringPMAssignment, panelRef1)
            }
          >
            {projectColumnName?.length < 2 ? null : (
              <DataTable
                ref={dt}
                rows={100}
                scrollable
                stripedRows
                showGridlines
                size={"small"}
                resizableColumns
                reorderableColumns
                dataKey="Project_ID"
                selection={selectedRows}
                columnResizeMode="expand"
                scrollHeight="grid_height"
                className="mt-3 acpBooking"
                onSelectionChange={(e) => {
                  setSelectedRows(e?.value);
                }}
                onColumnResizeEnd={onColumnResizeEnd}
                onColReorder={storeReorderedColumns}
                paginatorPosition={"bottom"}
                filterDisplay={isSearch && "row"}
                loading={panelTopLoader}
                key={tableRender ? `"Project_ID" + timestamp+ ${resetKey}` : `${resetKey}`}
                // tableStyle={{ width: "max-content", minWidth: "100%" }}
                emptyMessage={loader ? " " : "No records found"}
                paginator={tableData?.length || filters?.length ? true : false}
                value={conditionMatched ? [] : filters?.length ? filters : tableData}
                rowClassName={(rowData) => (rowData.isSelected ? "highlight-row" : "normal-row")}
              >
                {dynamicColumns()}
              </DataTable>
            )}
          </Panel>
        </div>
      </LoadingOverlay>
      {/* UnsavedChangesDialog popup*/}
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </>
  );
}

export default forwardRef(PanelTop);
