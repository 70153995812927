import { ArtWorkTabValuesAction } from "../../../store/actions/ArtWorkTabValuesActions";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import CalendarIcon from "../../common/CalendarIcon/CalendarIcon";
import messageIcon from "../../../assets/images/Message.svg";
import hyphen from "../../../assets/images/hyphen.svg";
import { changeDateFormat, bufferToWork } from "../../../utils";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import { dipsalyStateValues, formatIntoDatePicker } from "./util";
import { AcpService } from "../../../service/ACPService";
import { UnsavedChangesDialog } from "../../common/NavigationPrompt";
import CustomInput from "../../common/CustomInput";
import { AcpCommonService } from "../../../service/AcpCommonService";
import _ from "lodash";

const ElementTemplate = ({
  options,
  rowData,
  ProjectID,
  isDisabled,
  dropdownRef,
  width,
  sortData,
  filterData,
  handleDialog,
  handleInfoIcon,
  RolesOwners,
  updateFields,
  setDropDownMatch,
  pageEdited,
  setOwnerValue,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const [messageContent, setMessageContent] = useState([]);
  const op = useRef(null);
  const field = rowData.field;
  const optionsData = options.data;
  const currentUrl = location.pathname;
  let currentUrlLastSeg = currentUrl?.split("/")[3];
  let currentUrlBasePage = currentUrl?.split("/")[1];
  const code = options?.code;
  const keyCode = code?.split("_");
  const locaiton = window.location.pathname;
  const url = `${locaiton.split("/")[1]}/${locaiton.split("/")[2]}/${keyCode?.[0]
    }/${code}/${currentUrlLastSeg}`;
  const daysLeftStyle =
    optionsData["Days_Left"] === 0 || optionsData["Days_Left"] === "N/A" ? "" : "red";
  const cursorStyle =
    optionsData["GroupName"]?.includes("Final Art") || optionsData["Task_ID"]?.includes("CPC_")
      ? "default"
      : "";
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  const clickHandler = () => {
    if (field && field.length && keyCode[0] === "CPPFA") {
      handleDialog(options);
    } else if (
      optionsData["Task_ID"]?.includes("SAA_") ||
      optionsData["Task_ID"]?.includes("TPOA_")
    ) {
      dispatch(ArtWorkTabValuesAction([]));
      navigate(`/${currentUrlBasePage}/artworkAlignment/${optionsData["Assignee"]}/${ProjectID}`);
    } else if (
      optionsData["Task_ID"]?.includes("DM_") ||
      optionsData["Task_ID"]?.includes("RCIC_") ||
      optionsData["Task_ID"]?.includes("RCICMatrix_")
    ) {
      navigate(`/${currentUrlBasePage}/dependencyMapping/${ProjectID}`);
    } else if (optionsData["Task_ID"]?.includes("UBD_")) {
      navigate(`/${currentUrlBasePage}/projectPlan/UBD/${options.code}/${ProjectID}`);
    } else if (
      optionsData["Task"] !== "Design" &&
      optionsData["Task"] !== "Input" &&
      !optionsData["GroupName"]?.includes("Final Art") &&
      !optionsData["Task_ID"]?.includes("CPC_") &&
      optionsData["Task"] !== "Final Art" &&
      !optionsData["Task"]?.includes("(X")
    ) {
      (options.redirect === true || optionsData.Task) &&
        optionsData.State !== "Awaiting" &&
        navigate(`../${url}`, { replace: true });
    }
  };

  // it will be called on Task or TaskId click
  // if no pageEdited will work like earlier with else block, but
  // if there is a alteration in the page then, will show the warning dialog
  // after click yes, confirmNavigation will execute pending action

  const handleClickHandler = () => {
    if (pageEdited) {
      setShowWarningDialog(true);
    } else {
      clickHandler();
    }
  };

  // on confirming yes to warning this blcok will be used
  const confirmNavigation = () => {
    clickHandler();
    setShowWarningDialog(false);
  };

  const cancelNavigation = () => {
    setShowWarningDialog(false);
    // hiding warning on cancelNavigation click
  };
  
  const formatHelpNeeded = (rowData) => {
    let className = "";
    let helpNeeded = rowData["Help_Needed"];
    switch (helpNeeded) {
      case "Yes, In Process":
        className = "helpneeded_inprocess";
        helpNeeded = "Yes, In Process";
        rowData["Help_Needed"] = "Yes, In Process";
        return (
          <>
            <span
              className="helpneeded_inprocess"
              onClick={() =>
                handleInfoIcon(`${rowData["Comments"]}`, `${rowData["Help_Needed_Reason"]}`)
              }
            >
              <img
                className="message_logo"
                src={messageIcon}
                alt="message logo"
                onClick={() =>
                  handleInfoIcon(`${rowData["Comments"]}`, `${rowData["Help_Needed_Reason"]}`)
                }
              />
              {rowData?.Help_Needed}
            </span>
          </>
        );
      case "Yes, Done":
        className = "helpneeded_done";
        helpNeeded = "Yes, Done";
        break;
      case "N/A":
        className = "helpneeded_no";
        helpNeeded = "N/A";
        break;
      case "No":
        className = "helpneeded_no";
        helpNeeded = "No";
        break;
      default:
        break;
    }
    return <span className={className}>{helpNeeded}</span>;
  };

  const durationEnabled = (State) => {
    if (State === "In-Progress" || State === "Awaiting") {
      return false;
    } else {
      return true;
    }
  };

  const onDropdownChange = (rowData, { value }, ele) => {
    updateFields(rowData, value, ele);
  };

  const onDDTyped = (rowData, val, ele) => {
    let value = val.value;
    if (typeof value === "string") {
      if (ele === "Role") {
        if (rowData.data["Role"] !== value) {
          rowData.data["Assignee"] = "";
        }
      }
      if (ele === "Owner") {
        rowData.data["Assignee"] = value;
      } else {
        rowData.data[ele] = value;
        if (value === "") setOwnerValue(false)
      }
    }

  };

  const onDurationChange = (rowData, { value }, ele) => {
    const updatedValue = (value === null) ? value : value < 1 ? 1 : value?.toString();
    if (!ele || typeof ele !== "string" || !rowData.data.hasOwnProperty(ele)) {
      return;
    }
    rowData.data[ele] = updatedValue;
    // Cleanup: Remove unintended `ele` key if it exists
    if (rowData.data.hasOwnProperty('ele')) {
      delete rowData.data['ele'];
    }
    const updatedRowData = _.cloneDeep(rowData);
    updateFields(updatedRowData, value, ele);
  };

  const onDate = (rowData, { value }, ele) => {
    rowData.data["Start_Date"] = AcpCommonService.formatDateFromString(value);
    updateFields(rowData, value, ele);
  };

  const getRoleSuggestions = (RolesOwners, field, optionsData) => {
    const roleList = RolesOwners && field === "Role"
      ? RolesOwners?.filter((el) => el.TaskID == optionsData?.Sequence)?.[0]?.RolesList
      : [];
    const roleListFinal = roleList?.map((py) => py?.Role)
    return roleListFinal || [];
  };
  const getOwnerSuggestions = (RolesOwners, field, optionsData) => {
    const ownerList = optionsData && field === "Role"
      ? optionsData?.["RoleOptions"]
      : optionsData && (field === "Owner" || field === "Assignee")
        ? RolesOwners &&
        RolesOwners?.filter((el) => el.TaskID == optionsData?.Sequence)?.[0]?.["RolesList"]?.find(
          (obj) => optionsData?.["Role"] == obj?.Role
        )?.OwnerList
        : [];
    const ownerListFinal = ownerList?.map((py) => py?.Person)
    return ownerListFinal || []
  };

  return (
    <>
      {field === "Task" && (
        <span
          className={`${optionsData?.State === "Awaiting"
            ? ""
            : options?.children?.length === 0
              ? "task-link"
              : "task"
            } ${field === "Task" && "custom-toggler-wrapper"}`}
          style={{ cursor: cursorStyle }}
          onClick={optionsData?.State !== "Awaiting" && typeof handleClickHandler === 'function' ? handleClickHandler : null}
          title={optionsData?.[field]}
        >
          {optionsData[field]}
        </span>
      )}
      {options?.children && options?.children?.length > 0 ? (
        <>
          {(field === "Role" ||
            field === "Owner" ||
            field === "Assignee" ||
            field === "State" ||
            field === "TaskDuration" ||
            field === "StartDate" ||
            field === "TaskID" ||
            field === "Dependency" ||
            field === "Project_TaskID" ||
            field === "Project_PredecessorID" ||
            field === "DaysLeft" ||
            field === "EndDate") && (
              <img
                src={hyphen}
                alt="Hyphen"
                onClick={(e) => {
                  op.current.toggle(e);
                }}
              />
            )}
        </>
      ) : (
        <>
          {field === "Project_TaskID" && (
            <span
              title={optionsData?.["Project_TaskID"]}
              className={`${optionsData?.State === "Awaiting"
                ? ""
                : options?.children?.length === 0
                  ? "task-link"
                  : "task"
                }`}
              style={{ cursor: cursorStyle }}
              onClick={optionsData?.State !== "Awaiting" && typeof handleClickHandler === 'function' ? handleClickHandler : null}
              >
              {optionsData?.["Project_TaskID"]}
            </span>
          )}
          {field === "Role" && (
            <div className="d-flex" ref={dropdownRef}>
              <CustomInput
                filter
                field={field}
                fieldType={field}
                sortData={sortData}
                options={options}
                filterData={filterData}
                onChange={onDropdownChange}
                placeholder={`Select ${field}`}
                type="dropdownWithSearchAndSelect"
                value={options.data[field]}
                dropDownOptions={getRoleSuggestions(RolesOwners, field, optionsData)}
                disabled={
                  optionsData?.GroupName === "Final Art" ||
                  optionsData?.State?.includes?.("Complete") ||
                  optionsData?.["Task_ID"]?.includes("CPC_") ||
                  isDisabled
                }
              />
            </div>
          )}
          {(field === "Owner" || field === "Assignee") && (
            <div className="d-flex" ref={dropdownRef}>
              <CustomInput
                filter
                field={field}
                fieldType={field}
                sortData={sortData}
                options={options}
                filterData={filterData}
                onChange={onDropdownChange}
                placeholder={`Select ${field}`}
                type="dropdownWithSearchAndSelect"
                value={options.data[field === "Owner" ? "Assignee" : field]}
                dropDownOptions={getOwnerSuggestions(RolesOwners, field, optionsData)}
                disabled={
                  optionsData?.GroupName === "Final Art" ||
                  optionsData?.State?.includes?.("Complete") ||
                  optionsData?.["Task_ID"]?.includes("CPC_") ||
                  isDisabled
                }
                dropdownLoading={!RolesOwners?.length>0}
              />
            </div>
          )}
          {field === "State" && dipsalyStateValues(optionsData?.[field])}

          {field === "TaskDuration" && options?.data?.[field] !== "" && (
            <>
              {/* <InputNumber
                className="input-duration"
                inputId="integeronly"
                value={options?.data?.[field]}
                onValueChange={(e) => {
                  if (parseInt(options?.data?.[field]) !== parseInt(e?.target?.value)) {
                    onDurationChange(options, e, field);
                  }
                }}
                disabled={
                  durationEnabled(optionsData?.State) ||
                  optionsData?.IsDurationDisabled === true ||
                  isDisabled
                }
                min={1}
              /> */}
              <CustomInput
                min={1}
                type="Number"
                field={field}
                options={options}
                sortData={sortData}
                inputId="integeronly"
                filterData={filterData}
                fieldType={"TaskDuration"}
                className="input-duration"
                onChange={onDurationChange}
                value={options?.data?.[field]}
                placeholder={"Enter Duration"}
                disabled={
                  durationEnabled(optionsData?.State) ||
                  optionsData?.IsDurationDisabled === true ||
                  isDisabled
                }
              />
            </>
          )}

          {field === "Start_Date" && (
            <div className="projectplan-calender">
              {/* <Calendar
                value={
                  !AcpService.isUBN(optionsData?.["Start_Date"])
                    ? formatIntoDatePicker(optionsData?.["Start_Date"])
                    : null
                }
                onChange={(e) => {
                  onDate(options, e, field);
                }}
                dateFormat="d-M-y"
                showIcon={true}
                minDate={new Date()}
                disabled={
                  optionsData.State !== "Awaiting" ||
                  optionsData?.IsDurationDisabled === true ||
                  isDisabled
                }
                icon={<CalendarIcon />}
              /> */}

              <CustomInput
                type="Date"
                field={field}
                className={""}
                showIcon={true}
                options={options}
                onChange={onDate}
                dateFormat="d-M-y"
                sortData={sortData}
                minDate={new Date()}
                filterData={filterData}
                icon={<CalendarIcon />}
                fieldType={"Start_Date"}
                disabled={
                  optionsData.State !== "Awaiting" ||
                  optionsData?.IsDurationDisabled === true ||
                  isDisabled
                }
                value={
                  !AcpService.isUBN(optionsData?.["Start_Date"])
                    ? formatIntoDatePicker(optionsData?.["Start_Date"])
                    : null
                }
              />
            </div>
          )}

          {field === "End_Date" && (
            <span>
              {optionsData?.["End_Date"]?.length !== undefined ? optionsData?.["End_Date"] : ""}
            </span>
          )}
          {field === "Days_Left" && optionsData?.["TaskDuration"] !== "" && (
            <span style={{ color: daysLeftStyle }}>{optionsData?.["Days_Left"]}</span>
          )}
          {field === "Project_PredecessorID" && (
            <span title={optionsData?.[field]}> {optionsData?.[field]} </span>
          )}
          {field === "Remaining_Work" && optionsData?.["Remaining_Work"]}
          {field === "Buffer" && optionsData?.["Buffer"]}
          {field === "Buffer_To_Work" &&
            options?.[field] !== "" &&
            bufferToWork(optionsData?.["Buffer_To_Work"])}
        </>
      )}

      {field === "Help_Needed" && formatHelpNeeded(optionsData)}

      {field !== "Task" &&
        field !== "Task_ID" &&
        field !== "Role" &&
        field !== "Owner" &&
        field !== "Assignee" &&
        field !== "State" &&
        field !== "Start_Date" &&
        field !== "End_Date" &&
        field !== "TaskDuration" &&
        field !== "Help_Needed" &&
        field !== "Buffer" &&
        field !== "Dependency" &&
        field !== "Project_TaskID" &&
        field !== "Project_PredecessorID" &&
        field !== "Remaining_Work" &&
        field !== "Buffer_To_Work" &&
        field !== "Days_Left" && <>{optionsData[field]}</>}
      {/* this block is being used to show warning on same page header page */}
      <UnsavedChangesDialog
        visible={showWarningDialog}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    </>
  );
};

export default ElementTemplate;
