import pegaJsonData from "../pega.json";


const frozenColData = ["Record ID#", "Timestamp", "Status", "SOP"];

const sortingData = ["Record ID#", "desc"];

const projectPlanAllColumnNames = [
  {
    field_Name: "Task",
    width: 400,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 1,
  },
  {
    field_Name: "Project_TaskID",
    column_Name: "Task ID",
    width: 140,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 2,
  },
  {
    field_Name: "Project_PredecessorID",
    column_Name: "Predecessor",
    width: 140,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 3,
  },
  {
    field_Name: "Role",
    width: 140,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 4,
  },
  {
    field_Name: "Owner",
    width: 140,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 5,
  },
  {
    field_Name: "State",
    width: 100,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 6,
  },
  {
    field_Name: "TaskDuration",
    width: 120,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 7,
  },
  {
    field_Name: "Start_Date",
    width: 140,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 8,
  },
  {
    field_Name: "End_Date",
    width: 100,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 9,
  },
  {
    field_Name: "Days_Left",
    width: 100,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 10,
  },
  {
    field_Name: "Buffer",
    width: 140,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 11,
  },
  {
    field_Name: "Remaining_Work",
    width: 140,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 12,
  },
  {
    field_Name: "Buffer_To_Work",
    width: 140,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 13,
  },
  {
    field_Name: "Help_Needed",
    width: 140,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 14,
  },
];
const reworkAllColumnNames = [
  {
    field_Name: "Task",
    width: 400,
    freeze: false,
    reorder: false,
    sequence: 1,
  },
  {
    field_Name: "Rework",
    width: 140,
    freeze: false,
    reorder: false,
    sequence: 2,
  },
  {
    field_Name: "Task ID",
    width: 140,
    freeze: false,
    reorder: false,
    sequence: 3,
  },
  {
    field_Name: "Predecessor",
    width: 140,
    freeze: false,
    reorder: false,
    sequence: 4,
  },
  {
    field_Name: "Role",
    width: 140,
    freeze: false,
    reorder: false,
    sequence: 5,
  },
  {
    field_Name: "Owner",
    width: 140,
    freeze: false,
    reorder: false,
    sequence: 6,
  },
  {
    field_Name: "State",
    width: 100,
    freeze: false,
    reorder: false,
    sequence: 7,
  },
  {
    field_Name: "TaskDuration",
    width: 120,
    freeze: false,
    reorder: false,
    sequence: 8,
  },
];
const allColumnNames = [
  {
    Field_Name: "DSBP_InitiativeID",
  },
  {
    Field_Name: "DSBP_Initiative_Name",
  },
  {
    Field_Name: "RTA_ProjectID",
  },
  {
    Field_Name: "RTA_Project_Name",
  },
  // {
  //   Field_Name: 'SI.No',
  // },
  {
    Field_Name: "PIMaterialNumber",
  },
  {
    Field_Name: "PIMaterialID",
  },
  {
    Field_Name: "PIMaterialDescription",
  },
  {
    Field_Name: "POANumber",
  },
  {
    Field_Name: "POADescription",
  },
  {
    Field_Name: "BufferToWorkRatio",
    Column_Name: "B:W",
  },
  {
    Field_Name: "BufferToWork",
    Column_Name: "B|W",
  },
  {
    // Field_Name: "POAReleaseDate",
    Field_Name: "ETA",
  },
  {
    // Field_Name: "AWPrinterDate",
    Field_Name: "Req",
  },
  {
    Field_Name: "AWM_GroupPMP",
  },
  {
    Field_Name: "Design",
  },
  {
    Field_Name: "CIC",
  },
  {
    Field_Name: "Copy",
  },
  {
    Field_Name: "FA",
  },
  {
    Field_Name: "RTA_RTAPOAStatus",
    Column_Name:"POA Status"
  },
  {
    Field_Name: "TDState",
  },
  {
    Field_Name: "PMPState",
  },
  {
    Field_Name: "CountryOfSales",
  },
  {
    Field_Name: "FAAssemblyState",
  },
  {
    Field_Name: "FAApproval",
  },
];

const allColumnNamesAllProjects = [
  "Project_ID",
  "Project_Name",
  "Artwork_Category",
  "Artwork_Brand",
  "Artwork_SMO",
  "Project_State",
  "Buffer_To_Work",
  "Estimated_AW_Printer",
  "PM",
  "Artwork_Status",
];

const filterProjectData = [
  { "Record ID#": { frozen: true, sort: "desc", width: 50 } },
  { "Group Name": { frozen: true, width: 100 } },
  { Category: {} },
  { "Group Name": {} },
  { Status: { frozen: true } },
];

const DI = {
  Project_Name: "Paste Mulsaane Oral-B Medical Device Europe",
  Brand: [
    {
      Brand_Name: "Charlie Banana",
      code: "BV0102",
    },
    {
      Brand_Name: "Pampers",
      code: "J29",
    },
    {
      Brand_Name: "All Clean",
      code: "BV0121",
    },
    {
      Brand_Name: "Lines",
      code: "D76",
    },
  ],
  Category: [
    {
      Category_Name: "Baby Care Adjacencies",
      code: "BCA",
    },
    {
      Category_Name: "Baby Wipes",
      code: "BW",
    },
    {
      Category_Name: "Diapers",
      code: "DP",
    },
  ],
  Duration: "15 days",
  Start_Date: "20-mar-2023",
  End_Date: "4-apr-23",
  Consumed_Buffer: "15",
  count: 1,
  Design_Intent_Details: [
    {
      Design_Intent_Name:
        "DI_FAI-214_Fairy_Hand Dish Wash_Paste Mulsaane Oral-B Medical Device Europe_UK_Test",
      AWMTaskId: "DI-1",
      DesignJobid: "1234",
      AWMProjectId: "",
      AgencyReference: "FAI-214",
      Cluster: "UK",
      AdditionalInfo: "Test",
      Select: "true",
      event: "submit",
    },
    {
      Design_Intent_Name: "Abc",
      TaskId: "DI-2",
      AgencyReference: "FAI-215",
      Cluster: "UK",
      AdditionalInfo: "Test",
      Select: "false",
      event: "draft",
      DesignJobid: "1112",
    },
    {
      DesignJobid: "2235",
    },
  ],
};

const myProjectColumnNames = [
  "Project_ID",
  "Project_Name",
  "Artwork_Category",
  "Artwork_Brand",
  "Artwork_SMO",
  "Project_State",
  "Buffer_To_Work",
  "Estimated_AW_Printer",
  "Artwork_Status",
];


const Approve_di = {
  Project_Name: "Paste Mulsaane Oral-B Medical Device Europe",
  brand: "Fairy",
  Category: "Hand Dish Wash",
  Duration: "15 days",
  Start_Date: "20-mar-2023",
  End_Date: "4-apr-23",
  consumed_buffer: "15",
  count: 1,
  DesignIntentList: {
    Design_Intent_Name:
      "DI_FAI-214_Fairy_Hand Dish Wash_Paste Mulsaane Oral-B Medical Device Europe_UK_Test",
    AWMTaskId: "DI-1",
    DesignJobid: "1234",
    AWMProjectId: "",
    AgencyReference: "FAI-214",
    Cluster: "UK",
    AdditionalInfo: "Test",
    Select: "true",
    event: "submit",
    file_name: "DI_FAI-214_Fairy_Hand Dish Wash_Paste Mulsaane",
  },
};

const filesAllColumnNames = [
  {
    field_Name: "Filename",
    // width: 303,
    freeze: false,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 1,
  },
  {
    field_Name: "Action",
    // width: 225,
    freeze: false,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 2,
  },
  {
    field_Name: "Version",
    // width: 156,
    freeze: false,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 3,
  },
  {
    field_Name: "Owner",
    // width: 275,
    freeze: false,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 4,
  },
  {
    field_Name: "Date",
    // width: 238,
    freeze: false,
    sortAtoZ: false,
    sortZtoA: false,
    reorder: false,
    sequence: 5,
  },
];

const bookingColumnNames = [
  "SelectAll",
  "ProjectID",
  "ProjectName",
  "TaskName",
  "Slack",
  "StartDate",
  "EndDate",
  "Status",
  "TaskDuration",
  "Owner",
  "Supplier",
  "ArtPrinterDate",
  "BufferToWork",
  "Buffer",
  "Work",
  "Projected FA FullKit Date",
];

const availablePlanningColumnNames = [
  "SelectAll",
  "AWMProjectID",
  "ProjectName",
  "PM",
  "TaskName",
  "StartDate",
  "Slack",
  "NewStartDate",
  "RemainingBuffer",
  "BufferToWork",
];

const availablePlanningProjectListColumns = [
  "SelectAll",
  "AWMProjectID",
  "ProjectName",
  "PM",
  "ProjectState",
  "EstimatedOfPOAs",
  "EstimatedAWReadiness",
  "NewStartDate",
  "AWMRemainingBuffer",
  "BufferToWork",
];

const dashboardColumnNames = [
  "ProjectName",
  "PmName",
  "AwRedinessDate",
  "Art@PrinterDate"
];

const availableDashboardColumnNames = [
  "SelectAll",
  "ProjectName",
  "PmName",
  "AwRedinessDate",
  "ArtPrinterDate"
];

const dashboardSubColumnNames = [
  "ProjectName",
  "PmName",
  "AwRedinessDate",
  "Art@PrinterDate",
  "Task",
  "Studio",
  "Date",
  "Demand",
  "Capacity",
  "StartDate",
];

const availableDashboardSubColumnNames = [
  // "SelectAll",]
  "Task",
  "Studio",
  "Date",
  "Demand",
  "Capacity",
];

export const ProjectService = {
  getProjectData() {
    return pegaJsonData.ArtworkAgilityProjects;
  },

  getMyProjectData() {
    return myProjectColumnNames;
  },

  getFilterData() {
    return filterProjectData;
  },

  getSortingData() {
    return sortingData;
  },

  getFrozenData() {
    return frozenColData;
  },

  getProjectPlanAllColumnNames() {
    return projectPlanAllColumnNames;
  },
  getreworkAllColumnNames() {
    return reworkAllColumnNames;
  },

  getAllColumnNames() {
    return allColumnNames;
  },

  getDIData() {
    return DI;
  },

  getApproveDI() {
    return Approve_di;
  },

  getAllColumnNamesAllProjects() {
    return allColumnNamesAllProjects;
  },

  getFilesAllColumnNames() {
    return filesAllColumnNames;
  },

  getBookingColumnNames() {
    return [...bookingColumnNames];
  },

  getAvailablePlanningColumnNames() {
    return availablePlanningColumnNames;
  },
  getAvailablePlanningProjectColumnNames() {
    return availablePlanningProjectListColumns;
  },

  getDashboardColumnNames() {
    return [...dashboardColumnNames];
  },

  getAvailableDashboardColumnNames() {
    return [...availableDashboardColumnNames];
  },

  getDashboardSubColumnNames() {
    return [...dashboardSubColumnNames];
  },

  getAvailableSubDashboardColumnNames() {
    return [...availableDashboardSubColumnNames];
  }
};
