import { combineReducers } from "redux";
import * as types from '../types/types';
import ProjectReducer from "./ProjectReducer";
import ProjectPlanReducer from "./ProjectPlanReducer";
import ProjectSetupReducer from "./ProjectSetupReducer";
import TaskReducer from "./TaskReducer";
import UserReducer from "./UserReducer";
import TaskDetailsReducer from "./TaskDetailsReducer";
import azureFileUpload from "./AzureFileReducer";
import delegateReducer from "./DelegateReducer";
import helpNeededReducer from "./HelpNeededReducer";
import DropDownValuesReducer from "./DropDownValuesReducer";
import accessMatrixReducer from "./RoleBasedReducer";
import ssoReducer from "./SSOReducer";
import DSBPDropdownReducer from "./DSBPReducer";
import ArtWorkTabValuesReducer from "./ArtWorkTabValuesReducer";
import DMTabValuesReducer from "./DMTabValuesReducer";
import azureProofscopeFileUpload from "./AzureProofscopeFileReducer";
import azureDownloadFileReducer from "./AzureDownlodReducer";
import AcpBookingReducer from "./AcpBookingReducer";
import AcpPlanningReducer from './AcpPlanningReducer'
import ArtworkStatusReducer from "./ArtworkStatusReducer";
import AuthenticationReducer from "./AuthenticationReducer";
import OwnerListReducer from "./OwnerListReducer";
import ManageDependencyReducer from "./ManageDependencyReducer";
import UserDetailsReducer from "./UserDetailsReducer";
import UserNotificationReducer from "./UserNotificationReducer";
import ApiErrorReducer from "./ApiErrorReducer";
import ReworkMarkupReducer from './ReworkMarkupReducer';

const appReducer = combineReducers({
  myProject: ProjectReducer,
  pmList: ProjectReducer,
  artworkStatus: ArtworkStatusReducer,
  ProjectPlanReducer,
  ProjectSetupReducer,
  TaskReducer,
  UserReducer,
  TaskDetailsReducer,
  uploadedUrl: azureFileUpload,
  delegateReducer,
  helpNeededReducer,
  DropDownValuesReducer,
  accessMatrixReducer,
  ssoReducer,
  DSBPDropdownReducer,
  ArtWorkTabValuesReducer,
  DMTabValuesReducer,
  proofscope: azureProofscopeFileUpload,
  azureDownloadFileReducer,
  AuthenticationReducer,
  AcpBookingReducer,
  AcpPlanningReducer,
  OwnerListReducer,
  ManageDependencyReducer,
  UserDetailsReducer,
  UserNotificationReducer,
  ApiErrorReducer,
  ReworkMarkupReducer
});

export const rootReducer = (state, action) => {
  if (action.type === types.RESET_STORE) {
    state = {};
  }
  return appReducer(state, action);
}
