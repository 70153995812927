import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import LoadingOverlay from "react-loading-overlay-ts";
import { dsbpConstant } from "./constant";
import warning from "../../assets/images/warning.svg";
import "./index.scss";
import { textFieldMaxLength } from "../../constants/index";
import { CharacterLimit } from "../common/CharacterLimit/CharacterLimit";
import CommonAutocomplete from "../../Utils/CommonAutocomplete";
import { shellOwnerMistmach, notLockedPMPMessage, poaTriggeredMessage } from "./utils";
const DsbpActionDialog = ({
  actionHeader,
  actionDialog,
  setActionDialog,
  selected,
  actionNameObject,
  onActionSubmit,
  aiseList,
  assemblyMechanismList,
  rowData,
  headerName,
  CDPTPageData,
  IQData,
  RDTData,
  GABriefData,
  updateDropDownData,
  handleNewGaBrief,
  isSubmitEnable,
  submittedData,
  setSubmittedData,
  pmpData,
  loader,
  projectData,
  ownersList,
  setPageEdited,
  pageEdited,
}) => {
  // const [packageName, setPackageName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [aiseName, setAISEName] = useState("");
  const [assemblyMechanismChange, setAssemblyMechanismChange] = useState("");
  const [ownerChange, setOwnerChange] = useState("");
  const [bioside, setBioside] = useState("");
  const [sellable, setSellable] = useState("");
  const [formData, setFormData] = useState({});
  const [cicMatrixName, setCicMatrixName] = useState("");
  const [CDPT, setCDPT] = useState([]);
  const [IQ, setIQ] = useState([]);
  const [RDT, setRDT] = useState([]);
  const [GABrief, setGABrief] = useState([]);
  const [groupNameError, setGroupNameError] = useState("");
  const [lockedData, setLockedData] = useState([]);
  const [notLockedData, setNotLockedData] = useState([]);
  const [ownerMisMatch, setOwnerMisMatch] = useState(false);
  const [width, setWidth] = useState(0);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [showOwnerErrorPopup, setShowOwnerErrorPopup] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (dropdownRef?.current) {
        setWidth(dropdownRef?.current?.offsetWidth);
      }
    }, 500);
  }, []);

  const optionsList = [
    { name: "Yes", code: "Yes" },
    { name: "No", code: "No" },
  ];

  const { selectedProject } = useSelector((state) => state.ProjectSetupReducer);

  let updatedData = actionNameObject?.filter((data) => data.header === actionHeader);

  let filterTheCICRequestedPMP = pmpData?.filter((ele) => ele.AWM_CIC_Matrix_Requested === "Yes");
  const awmCICMatrixArray =
    filterTheCICRequestedPMP &&
    filterTheCICRequestedPMP?.length &&
    filterTheCICRequestedPMP?.map((item) => item.AWM_CIC_Matrix);
  let uniqueSetOfGroupNamesObject, uniqueSetOfGroupNames;
  if (awmCICMatrixArray && awmCICMatrixArray.length) {
    uniqueSetOfGroupNamesObject = new Set(awmCICMatrixArray);
    uniqueSetOfGroupNames = [...uniqueSetOfGroupNamesObject];
  }

  const handleAiseChange = (e) => {
    setAISEName(e.target.value);
    setFormData({
      ...formData,
      AWM_AISE: e.target.value,
    });
  };

  const handleAssemblyMechanismChange = (e) => {
    setAssemblyMechanismChange(e.target.value);
    setFormData({
      ...formData,
      AWM_AssemblyMechanism: e.target.value,
    });
  };

  const handleOwnerChange = (e) => {
    setOwnerChange(e.target.value);
    if (e.target.value) {
      if (!ownersList?.some((item) => item?.Person?.includes(e?.target?.value?.Person))) {
        setOwnerMisMatch(true);
      } else {
        setOwnerMisMatch(false);
      }
    } else if (e.target.value === "" || e.target.value === null) {
      setOwnerMisMatch(true);
    } else {
      setOwnerMisMatch(false);
    }

    setFormData({
      ...formData,
      Owner: e.target.value,
    });
  };
  const handleBiosideChange = (e) => {
    setBioside(e.target.value);
    setFormData({ ...formData, AWM_Biocide: e.target.value });
  };

  const handleSellableChange = (e) => {
    setSellable(e.target.value);
    setFormData({ ...formData, AWM_Sellable: e.target.value });
  };

  const handleGroupName = (e) => {
    setGroupName(e.target.value);
    setFormData({
      ...formData,
      AWM_GroupPMP: e.target.value,
    });
  };

  // const handlePackageName = (e) => {
  //   setPackageName(e.target.value);
  //   setFormData({
  //     ...formData,
  //     POAPackageName: e.target.value,
  //   });
  // };

  const handleCicMatrixName = (e) => {
    let matrixName = e.target.value;
    setCicMatrixName(matrixName);

    if (!uniqueSetOfGroupNames?.includes(matrixName)) {
      setGroupNameError("");
      setFormData({
        ...formData,
        AWM_CICMatrix: e.target.value,
      });
    } else {
      setFormData({});
      setGroupNameError(
        "CIC Matrix with the same name already exists. Please enter a different name."
      );
    }
  };

  const handleClose = (e) => {
    setActionDialog(false);
    // if (pageEdited) {
    //   setPageEdited(true);
    // }
    // this block will make sure is page is altered then
    // setPageEdited will be done true
    headerName === "Dependency Mapping" && setSubmittedData([]);
  };
  if (headerName !== "Dependency Mapping") {
    if (rowData) {
      selected = [rowData];
    }
    // else if (
    //   (updatedData && updatedData[0]?.value !== "Create POAA") ||
    //   updatedData[0]?.value !== "Add to Project"
    // ) {
    //   selected = selected.filter(
    //     (item) => item.AWM_AddedToProject === "Yes" && item.AWM_AWJStatus !== "Complete"
    //   );
    // }
  } else {
    if (updatedData && updatedData[0]?.value === "Add CIC Matrix") {
      selected = selected?.filter(
        (item) =>
          item.AWM_CIC_Needed === "Yes" &&
          //commented the below code as part of 3319 and 3341 defect
          // (item.AWM_CIC_Matrix_Requested === "" || item.AWM_CIC_Matrix_Requested1 === "") &&
          item.AWM_CIC_Matrix_Requested === "" &&
          item.AWM_CIC_Requested === "" &&
          item.AWM_CIC_Matrix === ""
      );
    } else if (updatedData && updatedData[0]?.value === dsbpConstant.requestCICandCICMatrix) {
      const groupedData = pmpData?.reduce((result, item) => {
        const key = item.AWM_CIC_Matrix;
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(item);
        return result;
      }, {});

      // Grouping same cic matrix
      const groupedArray = Object.keys(groupedData)
        .map((key) => {
          if (key.trim() === "") {
            return null;
          } else {
            return {
              AWM_CIC_Matrix: key,
              items: groupedData[key],
            };
          }
        })
        .filter((item) => item !== null); // Filter out null items if any empty keys were encountered

      const filteredGroupArray = groupedArray.filter((group) => {
        return selected.some(
          (selectedItem) => selectedItem.AWM_CIC_Matrix === group.AWM_CIC_Matrix
        );
      });

      // Merged grouping cic matrix and selected data toghether
      const flattenedArray = filteredGroupArray.reduce((result, group) => {
        const uniqueSet = new Set(result);
        group?.items?.forEach((item) => uniqueSet.add(item));
        selected?.forEach((item) => uniqueSet.add(item));
        result = [...uniqueSet];
        return result;
      }, []);

      const updatedSelectionData = flattenedArray.length > 0 ? flattenedArray : selected;

      selected = updatedSelectionData?.filter(
        (item) =>
          item.AWM_CIC_Needed === "Yes" &&
          item.AWM_CIC_Requested !== "Yes" &&
          //commented as part of 3319 and 3341 defects
          // (item.AWM_CIC_Matrix_Requested === "" || item.AWM_CIC_Matrix_Requested1 === "") === "" &&
          item.AWM_CIC_Matrix_Requested === "" &&
          item.AWM_CIC_Requested === "" &&
          item.AWM_CIC_Matrix_Requested !== "Yes"
      );

      selected = selected?.filter((item) => {
        if (item.FullKit_Condition_Checking_Page?.length > 1) {
          if (
            (item.FullKit_Condition_Checking_Page?.[1]?.CICMatrix_FullKit_Satisfied === "Yes" ||
              item.FullKit_Condition_Checking_Page?.[0]?.CICMatrix_FullKit_Satisfied === "Yes") &&
            (item?.FullKit_Condition_Checking_Page?.[1]?.CIC_FullKit_Satisfied === "Yes" ||
              item?.FullKit_Condition_Checking_Page?.[0]?.CIC_FullKit_Satisfied === "Yes")
          ) {
            return item;
          }
        } else {
          if (
            item.FullKit_Condition_Checking_Page?.[1]?.CICMatrix_FullKit_Satisfied === "Yes" ||
            item.FullKit_Condition_Checking_Page?.[0]?.CICMatrix_FullKit_Satisfied === "Yes" ||
            item?.FullKit_Condition_Checking_Page?.[1]?.CIC_FullKit_Satisfied === "Yes" ||
            item?.FullKit_Condition_Checking_Page?.[0]?.CIC_FullKit_Satisfied === "Yes"
          ) {
            return item;
          }
        }
      });
    }
  }

  const addedToProjectRows = selected?.filter(
    (item) => item.AWM_AddedToProject === "Yes" && item.AWM_AWJStatus !== "Complete"
  );
  useEffect(() => {
    if (actionHeader !== "Dependency Mapping" && updatedData?.length && selected) {
      const firstUpdatedValue = updatedData[0]?.value;
      //cheeck locked and not locked data based on below logic for both poaa creatieon and add to project
      if (firstUpdatedValue === "Create POAA") {
        {
          /* 2952 changes -filtering out to find out owners mismatch */
        }
        const owners = selected?.reduce((acc, item) => {
          acc[item.Owner] = acc[item.Owner] ? [...acc[item.Owner], item] : [item];
          return acc;
        }, {});

        const uniqueOwners = Object.keys(owners);
        const isMultipleOwners = uniqueOwners.length > 1;

        if (isMultipleOwners) {
          setShowOwnerErrorPopup(true);
          return; // Stop execution here if multiple owners are selected
        } else {
          setShowOwnerErrorPopup(false);
        }

        const locked = selected.filter(
          (item) =>
            item.DSBP_PMP_AWReadinessGateStatus === "LOCKED" &&
            item.AWM_AddedToProject === "Yes" &&
            item.AWM_POARequested !== "Yes" &&
            item.RTA_POANumber === "" &&
            (item.POAStatus === "" ||
              item.POAStatus === "Create Failed" ||
              (item.AWM_POARequested === "No" && item.POARejectionReason !== ""))
        );

        const notLocked = selected.filter(
          (item) =>
            item.DSBP_PMP_AWReadinessGateStatus !== "LOCKED" ||
            item.AWM_AddedToProject !== "Yes" ||
            item.AWM_POARequested === "Yes" ||
            item.RTA_POANumber !== "" ||
            (item.POAStatus !== "" &&
              item.POAStatus !== "Create Failed" &&
              (item.AWM_POARequested !== "No" || item.POARejectionReason === ""))
        );

        setLockedData((prevLockedData) =>
          JSON.stringify(prevLockedData) !== JSON.stringify(locked) ? locked : prevLockedData
        );
        setNotLockedData((prevNotLockedData) =>
          JSON.stringify(prevNotLockedData) !== JSON.stringify(notLocked)
            ? notLocked
            : prevNotLockedData
        );
      } else if (firstUpdatedValue === "Add to Project") {
        const locked = selected.filter(
          (item) =>
            item.DSBP_PMP_AWReadinessGateStatus === "LOCKED" && item.AWM_AddedToProject !== "Yes"
        );

        const notLocked = selected.filter(
          (item) =>
            item.DSBP_PMP_AWReadinessGateStatus !== "LOCKED" || item.AWM_AddedToProject === "Yes"
        );

        setLockedData((prevLockedData) =>
          JSON.stringify(prevLockedData) !== JSON.stringify(locked) ? locked : prevLockedData
        );
        setNotLockedData((prevNotLockedData) =>
          JSON.stringify(prevNotLockedData) !== JSON.stringify(notLocked)
            ? notLocked
            : prevNotLockedData
        );
      }
    }
  }, [actionHeader, updatedData, selected]);

  useEffect(() => {
    setIsInputEmpty(
      headerName === "Dependency Mapping"
        ? !cicMatrixName?.trim()
        : updatedData && updatedData[0]?.value === "Mass Update"
        ? ownerMisMatch
        : !groupName?.trim()
    );
  }, [ownerMisMatch, cicMatrixName, groupName, updatedData]);
  const footerContent = (
    <div>
      <Button variant="secondary" onClick={handleClose}>
        {/* 2952 changes -changing the label when owerns mismatch */}
        {showOwnerErrorPopup
          ? "OK"
          : (updatedData && updatedData[0]?.value === "Add to Project") || rowData
          ? "No"
          : "Cancel"}
      </Button>
      {/* 2952 changes -hiding  yes button when owerns mismatch */}
      {!showOwnerErrorPopup && (
        <Button
          //enable the button only for locked data lenght is greater than zero
          disabled={
            (updatedData && updatedData[0]?.value === "Create POAA") ||
            updatedData?.[0]?.value === "Add to Project"
              ? lockedData?.length === 0 // Enable only if lockedData has items
              : rowData
              ? false
              : updatedData && updatedData[0]?.value === dsbpConstant?.requestCICandCICMatrix
              ? selected?.length === 0
              : Object.keys(formData ?? {}).length === 0 || isInputEmpty
          }
          onClick={() =>
            updatedData && updatedData[0]?.value === "Create POAA"
              ? onActionSubmit("CreatePOAA", lockedData)
              : (updatedData && updatedData[0]?.value === "Add to Project") || rowData
              ? // ? onActionSubmit("AddToProject", selected)
                onActionSubmit("AddToProject", lockedData)
              : updatedData && updatedData[0]?.value === dsbpConstant.requestCICandCICMatrix
              ? onActionSubmit("RequestCIC_CICMatrix", selected)
              : onActionSubmit(formData, selected)
          }
        >
          {updatedData && updatedData[0]?.value === "Mass Update"
            ? "Save"
            : (updatedData && updatedData[0]?.value === "Add to Project") || rowData
            ? "Yes"
            : "Save"}
        </Button>
      )}
    </div>
  );

  return (
    <div className="card flex justify-content-center dsbp-action-dialog">
      {/* 2952 changes -hiding the header when owerns mismatch */}
      <Dialog
        header={!showOwnerErrorPopup && actionHeader}
        visible={actionDialog}
        onHide={() => {
          // if (pageEdited) {
          //   setPageEdited(true);
          //   // if page edited then on click of hide again setting setPageEdited
          //   // earlier which we setted as false when click yes,
          //   // but since we are closing with save/submit
          //   // then if we reopen then warning should come again until save clciked
          // }
          setActionDialog(false);
        }}
        footer={footerContent}
        id="actionDialog"
      >
        <LoadingOverlay active={loader} spinner text="">
          <Row>
            {rowData ||
            (updatedData && updatedData[0]?.value === dsbpConstant.requestCICandCICMatrix) ? (
              <Col sm={12}>
                {selected && (
                  <DataTable
                    value={selected}
                    dataKey="id"
                    className="addToProjectTable"
                    emptyMessage={
                      (updatedData && updatedData[0]?.value) === dsbpConstant.requestCICandCICMatrix
                        ? dsbpConstant.fromActionFullkitNotReadyMessage
                        : dsbpConstant.fromActionNoPMPMessage
                    }
                    scrollable
                  >
                    <Column field="DSBP_PMP_PIMaterialNumber" header="PMP "></Column>
                    <Column
                      field="DSBP_PMP_PIMaterialDescription"
                      header="PMP Description"
                    ></Column>
                    {updatedData &&
                      updatedData[0]?.value === dsbpConstant.requestCICandCICMatrix && (
                        <Column field="AWM_CIC_Matrix" header="CIC/CIC Matrix"></Column>
                      )}
                  </DataTable>
                )}
              </Col>
            ) : (
              <>
                {updatedData &&
                  (updatedData[0]?.value === "Create POAA" ||
                    updatedData[0]?.value === "Add to Project") &&
                  actionHeader !== "Dependency Mapping" && (
                    <Col sm={12}>
                      <div className="card">
                        {/* 2952 changes -show hte warnign message when owerns mismatch */}
                        {showOwnerErrorPopup && (
                          // Show error message when multiple owners are selected
                          <div className="invalid-pmp-message-container">
                            <img src={warning} alt="warning icon" />
                            <p className="invalid-pmp-message">{shellOwnerMistmach}</p>
                          </div>
                        )}
                        <>
                          {lockedData?.length > 0 && (
                            <>
                              <DataTable value={lockedData} dataKey="id" scrollable>
                                <Column field="DSBP_PMP_PIMaterialNumber" header="PMP"></Column>
                                <Column
                                  field="DSBP_PMP_PIMaterialDescription"
                                  header="PMP Description"
                                ></Column>
                              </DataTable>
                              <p className="selected-count">{lockedData?.length} Selected </p>
                            </>
                          )}

                          {notLockedData?.length > 0 && (
                            <>
                              {" "}
                              <br />
                              <div className="invalid-pmp-message-container">
                                <img src={warning} alt="warning icon" />
                                <p className="invalid-pmp-message">
                                  {updatedData && updatedData[0]?.value === "Add to Project"
                                    ? notLockedPMPMessage
                                    : poaTriggeredMessage}
                                </p>
                              </div>
                              <DataTable value={notLockedData} dataKey="id" scrollable>
                                <Column field="DSBP_PMP_PIMaterialNumber" header="PMP"></Column>
                                <Column
                                  field="DSBP_PMP_PIMaterialDescription"
                                  header="PMP Description"
                                ></Column>
                              </DataTable>
                              <p className="selected-count">{notLockedData?.length} Selected </p>
                            </>
                          )}
                        </>
                      </div>
                    </Col>
                  )}

                {updatedData &&
                  (updatedData[0]?.value === "Group PMPs" ||
                    updatedData[0]?.value === "Mass Update") &&
                  actionHeader !== "Dependency Mapping" && (
                    <Col sm={7}>
                      {selected && (
                        <DataTable
                          value={selected}
                          dataKey="id"
                          emptyMessage={
                            updatedData &&
                            updatedData[0]?.value !== "Add to Project" &&
                            "Either POA-A is already triggered for the selected PMP or PMP is not added to project"
                          }
                          scrollable
                        >
                          <Column field="DSBP_PMP_PIMaterialNumber" header="PMP "></Column>
                          <Column
                            field="DSBP_PMP_PIMaterialDescription"
                            header="PMP Description"
                          ></Column>
                        </DataTable>
                      )}
                    </Col>
                  )}
                <Col sm={4}>
                  {updatedData &&
                    updatedData[0]?.value === "Mass Update" &&
                    (headerName !== "Dependency Mapping" ? (
                      <Row>
                        <Col sm={12}>
                          <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                            <Form.Label>Assembly Mechanism</Form.Label>
                            <div>
                              <Form.Select
                                value={assemblyMechanismChange}
                                placeholder="Select Assembly Mechanism"
                                onChange={handleAssemblyMechanismChange}
                                disabled={addedToProjectRows.length === 0}
                              >
                                <option value="">Select Assembly Mechanism</option>
                                {assemblyMechanismList.map((aise) => (
                                  <option key={aise.code} value={aise.AWM_AssemblyMechanism}>
                                    {aise.AWM_AssemblyMechanism}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </Form.Group>

                          <Form.Group
                            className={`mb-4`}
                            controlId="groupName.ControlInput1"
                            ref={dropdownRef}
                          >
                            <Form.Label>Shell Creation Owner</Form.Label>
                            {/* <div>
                              <Form.Select
                                value={ownerChange}
                                placeholder="Select Shell Creation Owner"
                                onChange={handleOwnerChange}
                                disabled={ownersList?.length === 0}
                              >
                                {ownerChange ? (
                                  <option value={ownerChange} hidden>
                                    {ownerChange}
                                  </option>
                                ) : (
                                  <option value="" hidden>
                                    Select Shell Creation Owner
                                  </option>
                                )}
                                {ownersList?.map((data) => (
                                  <option key={data?.Person} value={data?.Person}>
                                    {data?.Person}
                                  </option>
                                ))}
                              </Form.Select>
                            </div> */}
                            <CommonAutocomplete
                              width={width}
                              suggestions={ownersList}
                              value={ownerChange}
                              onChange={(e) => handleOwnerChange(e)}
                              placeholder="Select Shell Owner"
                              field="Person"
                              disabled={ownersList?.length === 0}
                              className="massOwnerField"
                            />{" "}
                            {ownerMisMatch && <div> No results found </div>}
                          </Form.Group>
                        </Col>
                        {projectData?.BU === "Home Care" && (
                          <>
                            <Col sm={12}>
                              <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                                <Form.Label>AISE</Form.Label>
                                <Form.Select
                                  value={aiseName}
                                  placeholder="Select AISE"
                                  onChange={handleAiseChange}
                                  disabled={addedToProjectRows.length === 0}
                                >
                                  <option value="">Select AISE</option>
                                  {aiseList.map((aise) => (
                                    <option key={aise.code} value={aise.AWM_AISE}>
                                      {aise.AWM_AISE}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col sm={12}>
                              <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                                <Form.Label>Biocide</Form.Label>
                                <div>
                                  <Form.Select
                                    value={bioside}
                                    placeholder="Select Biocide"
                                    onChange={handleBiosideChange}
                                    disabled={addedToProjectRows.length === 0}
                                  >
                                    <option value="">Select Biocide</option>
                                    {optionsList.map((data) => (
                                      <option key={data.code} value={data.name}>
                                        {data.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col sm={12}>
                              <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                                <Form.Label>Sellable</Form.Label>
                                <div>
                                  <Form.Select
                                    value={sellable}
                                    placeholder="Select Sellable"
                                    onChange={handleSellableChange}
                                    disabled={addedToProjectRows.length === 0}
                                  >
                                    <option value="">Select Sellable</option>
                                    {optionsList.map((data) => (
                                      <option key={data.code} value={data.name}>
                                        {data.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </Form.Group>
                            </Col>
                          </>
                        )}
                      </Row>
                    ) : (
                      <Row>
                        {RDTData && RDTData?.length && (
                          <Col sm={12}>
                            <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                              <Form.Label>Regional Design Template</Form.Label>
                              <div>
                                <MultiSelect
                                  value={RDT}
                                  onChange={(e) => {
                                    updateDropDownData(e.value, "AWM_RDT_Page");
                                    setRDT(e.value);
                                    setFormData({
                                      ...formData,
                                      AWM_RDT_Page: e.target.value,
                                    });
                                  }}
                                  options={
                                    RDTData
                                      ? RDTData.map((obj) => ({
                                          label: obj.AWM_Design_Job_Name,
                                          value: obj.AWM_Design_Job_ID,
                                          disabled:
                                            (RDT.length &&
                                              RDT.includes("DT_DJobN/A") &&
                                              obj.AWM_Design_Job_ID !== "DT_DJobN/A") ||
                                            (RDT.length &&
                                              !RDT.includes("DT_DJobN/A") &&
                                              obj.AWM_Design_Job_ID === "DT_DJobN/A"),
                                        })).filter((option) => option.label !== "")
                                      : []
                                  }
                                  filter
                                  display="chip"
                                  placeholder={`Select`}
                                  maxSelectedLabels={3}
                                  className="p-column-filter"
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        )}
                        {CDPTPageData && CDPTPageData?.length && (
                          <Col sm={12}>
                            <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                              <Form.Label>Color Development and Print Trial</Form.Label>
                              <div>
                                <MultiSelect
                                  value={CDPT}
                                  onChange={(e) => {
                                    updateDropDownData(e.value, "AWM_CDPT_Page");
                                    setCDPT(e.value);
                                    setFormData({
                                      ...formData,
                                      AWM_CDPT_Page: e.target.value,
                                    });
                                  }}
                                  options={
                                    CDPTPageData
                                      ? CDPTPageData.map((obj) => ({
                                          label: obj.AWM_Design_Job_Name,
                                          value: obj.AWM_Design_Job_ID,
                                          disabled:
                                            (CDPT.length &&
                                              CDPT.includes("NPF_DJobN/A") &&
                                              obj.AWM_Design_Job_ID !== "NPF_DJobN/A") ||
                                            (CDPT.length &&
                                              !CDPT.includes("NPF_DJobN/A") &&
                                              obj.AWM_Design_Job_ID === "NPF_DJobN/A"),
                                        })).filter((option) => option.label !== "") // Filter out options with empty labels
                                      : []
                                  }
                                  filter
                                  display="chip"
                                  placeholder={`Select`}
                                  maxSelectedLabels={3}
                                  className="p-column-filter"
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        )}
                        {IQData?.length && (
                          <Col sm={12}>
                            <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                              <Form.Label>Ink Qualification</Form.Label>
                              <div>
                                <MultiSelect
                                  value={IQ}
                                  onChange={(e) => {
                                    updateDropDownData(e.value, "AWM_IQ_Page");
                                    setIQ(e.value);
                                    setFormData({
                                      ...formData,
                                      AWM_IQ_Page: e.target.value,
                                    });
                                  }}
                                  options={
                                    IQData
                                      ? IQData.map((obj) => ({
                                          label: obj.AWM_Design_Job_Name,
                                          value: obj.AWM_Design_Job_ID,
                                          disabled:
                                            (IQ.length &&
                                              IQ.includes("IQ_DJobN/A") &&
                                              obj.AWM_Design_Job_ID !== "IQ_DJobN/A") ||
                                            (IQ.length &&
                                              !IQ.includes("IQ_DJobN/A") &&
                                              obj.AWM_Design_Job_ID === "IQ_DJobN/A"),
                                        })).filter((option) => option.label !== "")
                                      : []
                                  }
                                  filter
                                  display="chip"
                                  placeholder={`Select`}
                                  maxSelectedLabels={3}
                                  className="p-column-filter"
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        )}
                        {GABriefData && projectData?.CICs && (
                          <Col sm={12}>
                            <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                              <Form.Label>GA Brief</Form.Label>
                              <div>
                                <Form.Select
                                  value={GABrief}
                                  placeholder="Select"
                                  onChange={(e) => {
                                    updateDropDownData(e.target.value, "AWM_GA_Brief");
                                    setGABrief(e.target.value);
                                    setFormData({
                                      ...formData,
                                      AWM_GA_Brief: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Select</option>

                                  {GABriefData?.map(
                                    (data, index) =>
                                      data.File_Name !== "Add GA Brief" && (
                                        <option
                                          key={`${data.File_Name}_${index}`}
                                          value={data.File_Name}
                                        >
                                          {data.File_Name}
                                        </option>
                                      )
                                  )}
                                </Form.Select>
                              </div>
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                    ))}
                  {updatedData && updatedData[0]?.value === "Group PMPs" && (
                    <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                      <Form.Label>
                        Group Name<sup>*</sup>
                      </Form.Label>
                      <input
                        type="text"
                        className={
                          groupName?.length === 0
                            ? "form-control"
                            : groupName?.length >= textFieldMaxLength
                            ? "form-control"
                            : "form-control border-success"
                        }
                        placeholder="Enter Group Name"
                        onChange={handleGroupName}
                        maxLength={textFieldMaxLength}
                        value={groupName}
                        disabled={addedToProjectRows.length === 0}
                      />
                      <div className="col-9">
                        <CharacterLimit field={groupName} maxLength={textFieldMaxLength} />
                      </div>
                    </Form.Group>
                  )}
                  {/* {updatedData && updatedData[0]?.value === "Create POAA" && (
                    <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                      <Form.Label>Package Name</Form.Label>
                      <input
                        type="text"
                        maxLength={255}
                        className="form-control"
                        placeholder="Enter Package Name"
                        onChange={handlePackageName}
                        value={packageName}
                        disabled={lockedData.length === 0}
                      />
                    </Form.Group>
                  )} */}
                  {updatedData && updatedData[0]?.value === "Add CIC Matrix" && (
                    <Form.Group className={`mb-4`} controlId="groupName.ControlInput1">
                      <Form.Label>
                        CIC Matrix Name<sup>*</sup>
                      </Form.Label>
                      <input
                        type="text"
                        maxLength={255}
                        className="form-control"
                        placeholder="Enter CIC Matrix Name"
                        onChange={handleCicMatrixName}
                        value={cicMatrixName}
                        disabled={selected.length === 0}
                      />
                      <CharacterLimit field={cicMatrixName} maxLength={255} />
                      {groupNameError && groupNameError.length && (
                        <div className="group-name-error">{groupNameError}</div>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </>
            )}
          </Row>
        </LoadingOverlay>
      </Dialog>
    </div>
  );
};
export default DsbpActionDialog;
