import { ProjectService } from "../service/PegaService";
import { basedOnFreezeLocalStorageUpdate } from "./basedOnFreezeLocalStorageUpdate";

export const acpBookingFrozenColumn = (
  frozenColumns,
  setReorderedColumn,
  setProjectColumnNames,
  setTableRender,
  op,
  projectColumnName,
  pageName,
  selectedFields,
  tab
) => {
  let BookingFreezedColumnClicked = localStorage.getItem("BookingFreezedColumnClicked");
  let customizeViewFieldsBooking = localStorage.getItem("customizeViewFieldsBooking");
  const availableBookingColumnNames = ProjectService.getBookingColumnNames();
  const availableFields =
    customizeViewFieldsBooking &&
    JSON.parse(customizeViewFieldsBooking)?.availableFields?.fieldsData;
  const frozenColumn1 = frozenColumns?.filter((el) => el != "SelectAll");
  frozenColumn1?.push("SelectAll");

  const updatedColumn =
    frozenColumn1?.indexOf(BookingFreezedColumnClicked) === -1
      ? [...availableBookingColumnNames]
      : [...projectColumnName];

  frozenColumn1?.forEach((item) => {
    const index = updatedColumn?.indexOf(item);
    if (index > -1) {
      setReorderedColumn(true);
      updatedColumn?.splice(index, 1);
      updatedColumn?.splice(0, 0, item);
    }
    setTableRender(false);
    // op?.current?.toggle(false);
  });
  let updatedColumnFinal = updatedColumn?.filter(function (objFromA) {
    return !availableFields?.find(function (objFromB) {
      return objFromA === objFromB;
    });
  });
  setProjectColumnNames(updatedColumnFinal);
  basedOnFreezeLocalStorageUpdate(pageName, updatedColumnFinal);
};
