import React from "react";
import { Button } from "react-bootstrap";
import { Cancel } from "../constant";

const AcpFooter = ({
  onSaveAsDraft,
  onSubmit,
  handleCancel,
  formValid,
  checkReadWriteAccess,
  identifier,
  loader,
  saveFlag,
}) => {
  // const location = useLocation();
  // const locationPath = location?.pathname;
  // const url = locationPath?.split("/");
  // const pathName = url[2];
  //   let { TaskID } = useParams();
  //   const pathName = TaskID.split("_")[0];
  // console.log("checkReadWriteAccess in footer", checkReadWriteAccess);
  // console.log("data", data);

  return (
    <div className={`form-buttons acp-footer`}>
      <Button
        variant="secondary"
        onClick={() => handleCancel()}
        disabled={
          identifier === "PlanningPage"
            ? !checkReadWriteAccess || !formValid
            : !checkReadWriteAccess || !formValid || loader
        }
        className={checkReadWriteAccess ? "button-layout" : "btn btn-disabled"}
      >
        {Cancel}
      </Button>
      {identifier === "PlanningPage" && (
        <Button
          className="button-layout"
          type="submit"
          onClick={() => onSaveAsDraft()}
          disabled={!checkReadWriteAccess || !saveFlag || !formValid}
        >
          Save Changes
        </Button>
      )}
      <Button
        type="submit"
        className="button-layout"
        onClick={() => onSubmit()}
        disabled={
          identifier === "PlanningPage"
            ? !checkReadWriteAccess || !formValid
            : !checkReadWriteAccess || !formValid || loader
        }
      >
        Submit Changes
      </Button>
    </div>
  );
};

export default AcpFooter;
