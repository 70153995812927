import React, { useEffect, useRef, useState } from 'react';
import { popUpConst } from '../../../src/constants/reworkPageTasks';
import { getDiffrencyArray, formDataReworkAPI, getAllChildern } from '../../../src/Utils/GridService';
import LoadingOverlay from 'react-loading-overlay-ts';
import './index.scss';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { submitReworkData } from '../../apis/ReworkApi';
import { useParams } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { getEnvironmentFromURL } from '../../utils';
import { reasonForRework } from '../../store/actions/ReworkMarkUpsAction';
import { triggerXfdfUpload } from '../../Utils/ReworkServiceFile';

const SelectionPopUp = ({ selectedNodes, unSelectedNodes, onSubmission, treedata, projectPlanData }) => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  let { page1, page2, pageType } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { reworkTableData } = useSelector((state) => state.ReworkMarkupReducer);
  const { reasonRework } = useSelector((state) => state.ReworkMarkupReducer);

  const { ProjectID } = useParams();
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const env = getEnvironmentFromURL();
  const updatedSelectedList = [];
  let selectedTaskIDList = []
  let deSelectedTaskIDList = []
  let deselectedResList = []
  selectedNodes.forEach((item) => {
    if (item.Task_ID) updatedSelectedList.push(item);
  });

  const updatedDeselectedList = getDiffrencyArray(unSelectedNodes, selectedNodes);
  //get all IDs of selected node
  updatedSelectedList.forEach((item) => {
    selectedTaskIDList.push(item?.Task_ID)
  })

  //get all IDs of De-selected node
  updatedDeselectedList?.forEach((item) => {
    deSelectedTaskIDList.push(item?.Task_ID)
  })
  //push to deselected result only if parent job is selected
  updatedDeselectedList?.forEach((item) => {
    let subsetArray = item?.Dependency?.split(',')
    if (item?.Task_ID) {
      subsetArray?.some((el) => {
        if (selectedTaskIDList.includes(el) || deSelectedTaskIDList.includes(el)) {
          deselectedResList.push(item)
        }
      })
    }
  })
  //remove all childern if JOB is unselected // commented this code to fix AWM-2812
  // updatedDeselectedList?.forEach((item) => {
  //   if (!item?.Task_ID) {
  //     const children = getAllChildern(treedata, item)

  //     const idsSet = new Set(children.map(obj => obj.key));
  //     for (let i = deselectedResList.length - 1; i >= 0; i--) {
  //       if (idsSet.has(deselectedResList[i].key)) {
  //         deselectedResList.splice(i, 1); // Remove object at index i from deselectedResList
  //       }
  //     }
  //   }
  // })


  const createDynamicTable = (nodes) => {
    if (nodes?.length) {
      return (
        <table>
          <thead>
            <tr>
              <th>{popUpConst.taskID}</th>
              <th>{popUpConst.task}</th>
            </tr>
          </thead>
          <tbody>
            {nodes?.map((item) => (
              <tr key={item?.Task_ID}>
                <td>{
                  item["Task ID"] !== "" ? item["Task ID"] : item.Task_ID
                }</td>
                <td><span>{item?.Task}</span></td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  };

  const showToastMesssage = (summary, severity) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      life: 3000,
    });
  };

  const navigateToPP = () => {
    navigate(`/myProjects/projectPlan/${ProjectID}`)
  }

  const groupObjectsByDesignJobID = (objects) => {
    const groupedObjects = {};

    objects.forEach(obj => {
      const designJobID = obj.Design_Job_ID;
      if (!groupedObjects[designJobID]) {
        groupedObjects[designJobID] = [];
      }
      groupedObjects[designJobID].push(obj);
    });

    return groupedObjects;
  }
  const checkToUploadXfdf = () => {
    //using updatedSelectedList filter out the selected ACIC/ACICM tasks and them map on them to use required field for invoking xfdf API in Download Annotation
    const filteredProjectPlanData = [];
    updatedSelectedList?.forEach((item) => {
      //AWM-3059: in case of rework, extract data for Upload tasks instead of approval tasks
      if (item?.State !== "In-Progress" && (item?.Task_ID?.includes('URDT') || item?.Task_ID?.includes('UCIC') || item?.Task_ID?.includes('UCICMatrix'))) {
        projectPlanData?.forEach((obj) => {
          if (item?.Task_ID === obj?.AWM_Task_ID) {
            filteredProjectPlanData.push(obj)
          }
        })
      }
    })
    // Group the objects
    const groupedByDesignJobID = groupObjectsByDesignJobID(filteredProjectPlanData);
    const designJobIDsArray = Object.keys(groupedByDesignJobID);
    designJobIDsArray?.length
      ? designJobIDsArray?.map((id) => {
        setLoading(true);
        triggerXfdfUpload(env, ProjectID, userInformation, groupedByDesignJobID[id], toast, page1, page2, pageType, navigate) //pass groupedByDesignJobID[id]
      }) : setTimeout(navigateToPP, 1000);
  }
  const submitRework = async () => {
    const formattedData = formDataReworkAPI(updatedSelectedList, ProjectID, reworkTableData, reasonRework);
   setLoading(true);
    const response = await submitReworkData(formattedData);
    setLoading(false);
    if (response?.data?.pyMessage === 'Sucess') {
      showToastMesssage(popUpConst.success, 'success');
      dispatch(reasonForRework([]))//making reasonForRework state empty after successful submission AWM-2571
      checkToUploadXfdf();//AWM-3059: check if any Upload task has been reworked and xfdf needs to be uploaded or not
    } else {
      showToastMesssage(response?.data?.pyMessage, 'error');
    }
  };

  return (
    <LoadingOverlay active={loading} spinner text="">
      <div className="selectionpopup">
        {<Toast ref={toast} position="top-center" />}
        <div className="headers">
          <p className="headerText">{popUpConst.headerText}</p>
          <p className="infoText">
            <span className="textVal">{popUpConst.textVal}</span>
          </p>
        </div>
        <span className="preHead">{popUpConst.preHeadSel}</span>
        <div className="selectedtasks">{createDynamicTable(updatedSelectedList)}</div>
        <span className="preHead">{popUpConst.preHeadDeSel}</span>
        <div className="deselectedtasks">
          {createDynamicTable(_.uniqBy(deselectedResList, 'key'))}
        </div>
        <div className="footerbutton">
          <footer>
            <Button variant="secondary" onClick={() => onSubmission()}>
              {popUpConst.cancel}
            </Button>
            <Button onClick={() => submitRework()}>{popUpConst.submit}</Button>
          </footer>

        </div>
      </div>
    </LoadingOverlay>
  );
};
export default SelectionPopUp;
