import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchAccessMatrix } from "./store/actions/RoleBasedActions";
import { ThumbnailViewer } from "./components/ProofScope/ThumbnailViewer";
import { getRoleWithPriority } from "./Utils/GetRolePriority";
import { cloneDeep, isObject } from "lodash";
import TaskName from "./constants/TaskName.js";
import { AcpService } from "./service/ACPService.js";
export const changeDateFormat = (value, format) => {
  let newDate = value
    ? moment(value, "YYYYMMDDTHHmmss.SSS [GMT]").format(
        !AcpService.isUBN(format) ? format : "DD-MMM-YY"
      )
    : value;
  return value?.length > 10 ? newDate : value;
};

export const onSortData = (column, direction, data) => {
  let sortedData = [];
  if (column === "Buffer_To_Work") {
    sortedData = sortColumns(column, direction, data);
  } else {
    sortedData = [...data].sort((a, b) => {
      if (column === "Project_ID") {
        return parseInt(a[column].split("-")[1]) > parseInt(b[column].split("-")[1]) ? 1 : -1;
      } else {
        return a[column] > b[column] ? 1 : -1;
      }
    });

    if (direction === "desc") {
      sortedData.reverse();
    }
  }

  return sortedData;
};
export const checkStatusForDisplay=(designJobStatus)=>{
  const designJobStatusStr=designJobStatus?.replace(/[\s-]/g, '').toLowerCase(); 
  return designJobStatus!=="" && designJobStatusStr!=="awaiting"
}

export const formCustomizeObject = (availableFields, freezedColumns, selectedFeilds) => {
  const customizeViewFieldsUpdated = {
    availableFields: {
      id: "availableFields",
      title: "Available Fields",
      fieldsData: availableFields,
    },
    freezedColumns: {
      id: "freezedColumns",
      title: "Freezed Fields",
      fieldsData: freezedColumns,
    },
    selectedFields: {
      id: "selectedFields",
      title: "Selected Fields",
      fieldsData: selectedFeilds,
    },
  };

  return customizeViewFieldsUpdated;
};
export const sortColumns = (column, direction, data) => {
  let sortedData = [];
  const infiniteValue = direction === "asc" ? Infinity : -Infinity;
  sortedData = data?.sort(function (a, b) {
    const aValue = a[column] === "N/A" || a[column] === "" ? infiniteValue : parseFloat(a[column]);
    const bValue = b[column] === "N/A" || b[column] === "" ? infiniteValue : parseFloat(b[column]);
    const returnVal = direction === "asc" ? aValue - bValue : bValue - aValue;
    return returnVal;
  });
  return sortedData;
};

export const onSort = (column, direction, pegadata, setPegaData, setSortData) => () => {
  const sortedData = [...pegadata].sort((a, b) => {
    return a[column] > b[column] ? 1 : -1;
  });

  if (direction === "desc") {
    sortedData.reverse();
  }
  setPegaData(sortedData);
  setSortData([column, direction]);
};

export const convertCategoryIntoString = (array) => {
  let categoryString = array.map((item) => item.Category_Name).join(",");

  return categoryString;
};

export const convertBrandIntoString = (Brand) => {
  let brandString = Brand.map((item) => item.Brand_Name).join(",");
  return brandString;
};

export const AddNavigation = (breadcrumbLabel) => {
  const location = useLocation();
  const currentUrl = location.pathname;
  let url = currentUrl.split("/")[1];
  let breadcrumbSubLabel = url === "AllTasks" ? "All Tasks" : "My Tasks";
  url = "/" + url;

  const breadcrumb = [{ label: breadcrumbSubLabel, url: url }, { label: breadcrumbLabel }];

  return breadcrumb;
};

/* 
Role based access matrix
*/
export const getAccessDetails = (userLogin, accessMatrix) => {
  const result = {
    pages: [],
  };

  for (const role of accessMatrix) {
    if (role.role === userLogin) {
      for (const page of role.pages) {
        result.pages.push(page);
      }
      break;
    }
  }

  return result;
};

export const getUnAuthoirzedAccess = (role, accessMatrix, pathname) => {
  const roleDetails = accessMatrix.find((item) => item.role === role);
  if (!roleDetails) {
    return null; // Role not found in accessMatrix
  }
  const pageDetails = roleDetails.pages.find((page) => page.path === pathname);
  if (!pageDetails) {
    return null; // Page not found in accessMatrix for the given role and pathname
  }
  const hasAccess = pageDetails.access.length > 0;
  return hasAccess ? pageDetails.access : null;
};

export const CheckReadOnlyAccess = () => {
  const dispatch1 = useDispatch();
  const { accessMatrix } = useSelector((state) => state?.accessMatrixReducer);
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const location = useLocation();
  // if read only access then returns true otherwise return false
  useEffect(() => {
    dispatch1(fetchAccessMatrix());
  }, [dispatch1]);

  const accessDetails = getAccessDetails(userInformation.role, accessMatrix);
  const currentUrl = location.pathname;
  let url;
  if (currentUrl.includes("projectPlan")) {
    url = currentUrl.split("/")[2]; // Extract the project plan ID from URL
  } else {
    url = currentUrl.split("/")[1]; // Extract the URL without project plan
  }
  let checkReadOnlyAccess = true;
  accessDetails.pages.forEach((page, index) => {
    if (page.name === url) {
      let checkAccess = page?.access;
      if (checkAccess && checkAccess.length === 1 && checkAccess.includes("Read"))
        checkReadOnlyAccess = false;
      if (checkAccess.length === 0) checkReadOnlyAccess = false;
    }
  });
  return checkReadOnlyAccess;
};

export const Loading = () => {
  return (
    <div className="align-item-center">
      <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
    </div>
  );
};

const transformTaskName = (value) => {
  for (const { task, transform } of TaskName) {
    if (typeof task === "string" && value?.toString()?.includes(task)) {
      return transform;
    } else if (task instanceof RegExp && task?.test(value)) {
      return transform(value);
    }
  }
  return value; // Return the original value if no transformation matched
};

export const optionList = (data, fieldName) => {
  const uniqueValues = new Set();

  // Define a regular expression pattern to match valid date formats
  const datePattern = /^\d{8}T\d{6}\.\d{3} GMT$/;

  // Iterate over the array and add unique values to the Set
  if (data && data.length) {
    data.forEach((item) => {
      if (item[fieldName] !== undefined && item[fieldName] !== null) {
        const fieldValue = item[fieldName]?.name ? item[fieldName]?.name : item[fieldName];

        // Check if the field value matches the date pattern
        if (
          datePattern.test(fieldValue) &&
          moment(fieldValue).format("DD-MMM-YY") !== "01-Jan-01"
        ) {
          // If it's a valid date and not equal to "01-Jan-01," format it and add to the set
          const dateValue = moment(fieldValue, "YYYYMMDDTHHmmss.SSS [GMT]").format("DD-MMM-YY");
          uniqueValues.add(dateValue);
        } else {
          // If it doesn't match the date pattern, add it directly to the set as text
          if (!item?.["Task"]?.includes("(X")) {
            uniqueValues.add(transformTaskName(fieldValue));
          }
        }
      }
      //Added for empty value filter
      if (Object.keys(item)?.includes(fieldName) && item[fieldName] === "") {
        uniqueValues.add(item[fieldName]);
      }
    });
  }
  // Convert the Set of unique values to an array
  const valueList = Array.from(uniqueValues);
  //Added the empty value to last place
  let emptyIndex = valueList.indexOf("");
  if (emptyIndex !== -1) {
    valueList.splice(emptyIndex, 1);
    valueList.push("");
  }
  if (valueList.length > 0) {
    return sortAlphabetic(valueList);
  }
};

export const generateDropdownOptionList = (data, fieldName, isTreeTable) => {
  const uniqueValues = new Set();

  // Define a regular expression pattern to match valid date formats
  const datePattern = /^\d{8}T\d{6}\.\d{3} GMT$/;

  // Iterate over the array and add unique values to the Set
  if (data && data.length) {
    data?.forEach((item) => {
      if (item?.[fieldName] !== undefined && item?.[fieldName] !== null) {
        const fieldValue = item?.[fieldName]?.name ? item?.[fieldName]?.name : item?.[fieldName];
        // Check if the field value matches the date pattern
        if (
          datePattern?.test(fieldValue) &&
          moment(fieldValue)?.format("DD-MMM-YY") !== "01-Jan-01"
        ) {
          // If it's a valid date and not equal to "01-Jan-01," format it and add to the set
          const dateValue = moment(fieldValue, "YYYYMMDDTHHmmss.SSS [GMT]")?.format("DD-MMM-YY");
          uniqueValues?.add(dateValue);
        } else {
          if (fieldName === "Start_Date" || fieldName === "End_Date") {
            uniqueValues?.add(fieldValue?.length !== undefined ? fieldValue : null);
          } else {
            uniqueValues?.add(fieldValue);
          }
        }
      }
      //Added for empty value filter
      if (Object.keys(item)?.includes(fieldName) && item?.[fieldName] === "") {
        uniqueValues?.add(item?.[fieldName]);
      }
    });
  }
  // Convert the Set of unique values to an array
  const valueList = Array?.from(uniqueValues);
  //Added the empty value to last place
  let emptyIndex = valueList?.indexOf("");
  if (emptyIndex !== -1) {
    valueList?.splice(emptyIndex, 1);
    valueList?.push("");
  }
  if (valueList?.length > 0) {
    return (isTreeTable && fieldName === "Filename") || (isTreeTable && fieldName === "Task")
      ? valueList
      : sortAlphabetic(valueList);
  }
};
export const sortAlphabetic = (values) => {
  const sortedList = values?.sort((a, b) => {
    if (typeof a === "number" && typeof b === "number") {
      return a - b;
    } else {
      return String(a).localeCompare(String(b), undefined, { numeric: true });
    }
  });
  return sortedList;
};
// export const generateUniqueKey = (fieldName) => {
//   const timestamp = new Date().getTime();
//   return `${fieldName}_${timestamp}`;
// };

export const roles = JSON.parse(localStorage.getItem("roles"))?.map(
  (selection) => selection?.UserRole
);

export const BusinessUnit = JSON.parse(localStorage.getItem("roles"))
  ?.ArtworkAgilityPage?.UserGroup?.map((selection) =>
    selection?.UserBU?.map((role) => role?.BU_Name)
  )
  .flat();

export const Regions = JSON.parse(localStorage.getItem("roles"))
  ?.ArtworkAgilityPage?.UserGroup?.map((selection) =>
    selection?.UserRegion?.map((role) => role?.Region_Name)
  )
  .flat();

//get this data from training mode json
const GetPageRoles = () => {
  const url = window.location.pathname;
  const { accessRoles } = useSelector((state) => state?.accessMatrixReducer);
  const UserProfile = useSelector((state) => state.UserReducer);
  const Role = UserProfile?.userProfile;
  const roles = Role?.role || [];
  if (Array.isArray(accessRoles)) {
    const matchingAccessRoles = accessRoles.filter((accessRole) => url?.includes(accessRole?.page));
    // Extract the access roles for the specified roles
    const extractedAccessRoles = matchingAccessRoles.map((accessRole) => {
      const roleAccess = accessRole?.roles?.filter((roleObj) => roles?.includes(roleObj.name));
      return {
        page: accessRole.page,
        path: accessRole.path,
        roles: roleAccess,
      };
    });

    // Convert the access roles to the desired format
    const formattedAccessRoles = extractedAccessRoles.map((accessRole) => ({
      page: accessRole.page,
      path: accessRole.path,
      roles: accessRole?.roles?.map((roleObj) => ({
        name: roleObj.name,
        access: roleObj.access,
      })),
    }));

    return formattedAccessRoles;
  }

  return [];
};

export const hasEmptyAccessForProjectSetup = () => {
  const matchingPageRole = GetPageRoles()?.find((pageRole) => {
    return pageRole.page === "projectPlan" && pageRole.path.includes("ProjectSetup");
  });

  if (matchingPageRole) {
    return matchingPageRole.roles.every((role) => {
      return role?.access?.length === 0;
    });
  }

  return false;
};

export const hasProjectPlanAccess = () => {
  const matchingPageRole = GetPageRoles()?.find((pageRole) => {
    return pageRole.page === "projectPlan" && pageRole.path.includes("ProjectPlan");
  });

  if (matchingPageRole) {
    const hasAllAccess = matchingPageRole?.roles.some((role) => {
      return (
        role.access.includes("Read") &&
        role.access.includes("Write") &&
        role.access.includes("Edit") &&
        role.access.includes("Delete")
      );
    });

    if (hasAllAccess) {
      return true;
    }
  }

  return false;
};

export function hasAllAccess() {
  return GetPageRoles()?.some((pageRole) => {
    return pageRole?.roles?.some((role) => {
      return (
        role.access.includes("Read") &&
        role.access.includes("Write") &&
        role.access.includes("Edit") &&
        role.access.includes("Delete")
      );
    });
  });
}

// const hasReadAccess = (pageRoles) => {
//   return pageRoles.some((pageRole) => {
//     return pageRole.roles.some((role) => {
//       return (
//         role.access.includes("Read") &&
//         !role.access.includes("Write") &&
//         !role.access.includes("Edit") &&
//         !role.access.includes("Delete")
//       );
//     });
//   });
// };

// const hasReadAccessForMyProjects = hasReadAccess(
//   pageRoles.filter((pageRole) => pageRole.page === urls?.split("/")[1])
// );
// // console.log(hasReadAccessForMyProjects); // true
export const generateUniqueKey = (fieldName) => {
  const timestamp = new Date().getTime();
  return `${fieldName}_${timestamp}`;
};

export const addEllipsis = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  const trimmedText = text.substring(0, maxLength);
  return trimmedText + "...";
};

export const selectedDesignItems = (getData, setEnableSubmit) => {
  let allSelectedItemsValid = false;
  const selectedValues = getData.every((item) => {
    if (item.Select) {
      const isValid = item.Agency_Reference !== "" && item.Cluster !== "";
      // If any selected item is invalid, set allSelectedItemsValid to false.
      if (!isValid) {
        allSelectedItemsValid = false;
      }
      return isValid;
    } else {
      // Filter selected items and check if any of them are valid.
      const selectedItems = getData.filter(
        (selectedItem) =>
          selectedItem.Select &&
          selectedItem.Agency_Reference !== "" &&
          selectedItem.Cluster !== "" &&
          selectedItem.Design_Job_Status === ""
      );
      const isValid = selectedItems.length > 0;
      allSelectedItemsValid = isValid;
      return isValid;
    }
  });
  setEnableSubmit(allSelectedItemsValid);
  return selectedValues;
};

export const checkFormValidity = (data, setEnableSubmit) => {
  const validTasks = data?.filter((task) => {
    return (
      task?.Agency_Reference &&
      task?.Cluster &&
      task?.Select &&
      (task.Design_Job_Status === "Awaiting" || task.Design_Job_Status === "")
    );
  });
  const selectedTasks = data?.filter((task) => {
    return task?.Select && (task.Design_Job_Status === "Awaiting" || task.Design_Job_Status === "");
  });

  if (
    validTasks?.length > 0 &&
    selectedTasks?.length > 0 &&
    validTasks?.length === selectedTasks?.length
  ) {
    setEnableSubmit(false);
  } else {
    setEnableSubmit(true);
  }
};

export const getEnvironmentFromURL = () => {
  const url = window.location.href;
  const domainRegex = /https?:\/\/([^/]+)\//; // Regular expression to match the domain part of the URL

  const match = url.match(domainRegex);
  let domain = "";

  if (match && match.length > 1) {
    domain = match[1]; // Extract the matched part
  }

  let env;

  switch (domain) {
    case "awflowdev.pg.com":
      env = "DEV";
      break;
    case "awflowqa.pg.com":
      env = "QA";
      break;
    case "awflowsit.pg.com":
      env = "SIT";
      break;
    case "awflow.pg.com":
      env = "PROD";
      break;
    default:
      env = "localEnv";
  }

  return env;
};

export const checkAllDesignJobsAreDefined = (designJobs) => {
  let checkEmptySatus = designJobs.filter(
    (item) => item.Design_Job_Status === "" || item.Design_Job_Status === "Awaiting"
  );
  if (checkEmptySatus && checkEmptySatus.length) {
    return false;
  } else return true;
};

export const redirectToProjectPlanOrTaskPage = (page1, page2, pageType, ProjectID, navigate) => {
  if (page2 && page2 === "projectPlan") {
    navigate(`/${page1}/${page2}/${ProjectID}`);
  } else if (pageType) {
    navigate(`/${pageType}`);
  }
};

export const redirectUnallocatedLinksToArtworkStatus = (currentUrl, flag, navigate, isRework) => {
  let pathNameParts = currentUrl?.split("/");
  if (pathNameParts?.length) {
    pathNameParts[2] = "artworkStatus";
    pathNameParts[3] = pathNameParts[pathNameParts.length - (isRework ? 2 : 1)];
    let url =
      pathNameParts[0] + "/" + pathNameParts[1] + "/" + pathNameParts[2] + "/" + pathNameParts[3];
    if (flag === "Yes") navigate(url);
  }
};

export const areAllSelectsTrue = (data) => {
  for (const item of data) {
    if (!item.hasOwnProperty("Select") || item.Select !== true) {
      return false;
    }
  }
  return true;
};

export const createDIName = (
  agencyRef,
  clusters,
  additionalInformation,
  Project_Name,
  Brand,
  Category,
  roleName,
  tier
) => {
  let di_name;
  let clubBrandName = Brand?.length && Brand?.map((item) => item.Brand_Name).join(", ");
  let clubCategory = Category?.length && Category?.map((item) => item.Category_Name).join(", ");

  if (clubBrandName === "" || Brand === undefined) clubBrandName = "Brand";

  if (clubCategory === "" || Category === undefined) clubCategory = "Category";

  if (agencyRef || clusters || additionalInformation || tier) {
    di_name = `${roleName}${agencyRef ? "_" + agencyRef : ""}${
      clubBrandName ? "_" + clubBrandName : ""
    }${clubCategory ? "_" + clubCategory : ""}_${Project_Name}${clusters ? "_" + clusters : ""}${
      tier ? "_" + tier : ""
    }${additionalInformation ? "_" + additionalInformation : ""}`;
  }

  return di_name;
};

export const createDesignJobName = (
  printers,
  pantone,
  printingProcess,
  substrate,
  additionalInfo,
  projectName,
  brand,
  category,
  jobName
) => {
  let clubBrandName = brand?.length && brand.map((item) => item.Brand_Name).join(", ");
  let clubCategory = category?.length && category.map((item) => item.Category_Name).join(", ");

  if (clubBrandName === "" || brand === undefined) clubBrandName = "Brand";

  if (clubCategory === "" || category === undefined) clubCategory = "Category";
  let selectedPrinter = [];
  printers?.forEach((val) => {
    selectedPrinter.push(val.Printer);
  });
  let designJobName =
    jobName +
    (jobName === "IQ_" ? (pantone ? pantone + "_" : "") : "") +
    (jobName === "IQ_"
      ? selectedPrinter.length
        ? selectedPrinter.join(", ") + "_"
        : "Printer" + "_"
      : selectedPrinter.length
      ? selectedPrinter.join(", ") + "_"
      : "") +
    (jobName !== "IQ_"
      ? printingProcess
        ? printingProcess + "_"
        : "Printing Process" + "_"
      : "") +
    (jobName !== "IQ_" ? (substrate ? substrate + "_" : "Substrate" + "_") : "") +
    clubBrandName +
    "_" +
    clubCategory +
    "_" +
    projectName +
    (additionalInfo ? "_" + additionalInfo : "");

  return designJobName;
};

export const sanitizeFileName = (fileName) => {
  // Split the fileName into the name and extension parts
  const parts = fileName?.split(".");

  if (parts?.length > 1) {
    // Process the name part (parts[0]) to replace special characters and spaces
    const sanitizedName = parts[0].replace(/[!@#$%^&*()+={}\[\]:;"'`<>,.?/\\| ]/g, (match) => {
      const charCode = match.charCodeAt(0);
      return "%" + charCode.toString(16).toLowerCase();
    });
    // Reconstruct the sanitized fileName by combining the sanitized name and the original extension
    return sanitizedName + "." + parts.slice(1).join(".");
  } else {
    // If there is no extension, process the whole fileName
    return fileName?.replace(/[!@#$%^&*()+={}\[\]:;"'`<>,.?/\\| ]/g, (match) => {
      const charCode = match.charCodeAt(0);
      return "%" + charCode.toString(16).toLowerCase();
    });
  }
};

export const viewThumbnail = async (
  env,
  sanitizedBuName,
  sanitizedTaskFolder,
  fileurl,
  TaskID,
  ProjectID,
  DesignJobID,
  Version
) => {
  try {
    const thumbnail = await ThumbnailViewer(
      `cloudflow://awm/${env}/${sanitizedBuName}/${sanitizedTaskFolder}/${ProjectID}/${DesignJobID}/${Version}/${fileurl}`,
      TaskID
    );
    return thumbnail;
  } catch (error) {
    console.error("API call error:", error);
    return null;
  }
};

export const replaceSpecialChars = (encodedFileName) => {
  if (!encodedFileName) {
    return encodedFileName;
  }

  // Decode the file name to handle percent encoding
  const decodedFileName = decodeURIComponent(encodedFileName);

  return decodedFileName;
};

export const getCookie = (name) => {
  // Split the cookie string into an array of key-value pairs
  var cookies = document.cookie.split("; ");

  // Find the cookie with the specified name
  var foundCookie = cookies.find((cookie) => {
    // Trim any leading whitespace
    cookie = cookie.trim();

    // Check if the cookie starts with the specified name
    return cookie.startsWith(name + "=");
  });

  // If the cookie is found, return its value
  if (foundCookie) {
    // Extract the value part of the cookie
    return foundCookie.split("=")[1];
  }

  // If the cookie is not found, return null
  return null;
};

export const removeCookie = (cookieName) => {
  // Set the expiration date in the past
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const setCookie = (cookieName, cookieValue, expirationDays) => {
  const date = new Date();
  date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/;`;
};

export const bufferToWork = (value) => {
  let color;
  if (value <= 0) {
    color = "#FF0000";
  } else if (value > 0 && value <= 0.3) {
    color = "#E2CC00";
  } else if (value > 0.3) {
    color = "#03A000";
  }
  return <span style={{ color }}>{value}</span>;
};

export const bufferStyle = (value) => {
  let color;
  if (value < 0) {
    color = "#FF0000";
  } else {
    color = "#000000";
  }
  return <span style={{ color }}>{value}</span>;
};

// Sort the data based on Design_Job_ID

export const sortedDataAsPerJobID = (dataArray, key) => {
  return dataArray.slice().sort((a, b) => {
    const valueA = a[key].toUpperCase(); // Ensure case-insensitive sorting
    const valueB = b[key].toUpperCase();

    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
};

export const optionListForArtworkAlignment = (originalData, fieldName) => {
  let data = originalData;
  if (data && data.length) {
    data = originalData.map((ele) => {
      if (ele?.FPCStagingPage) {
        return { ...ele, ...ele.FPCStagingPage[0] };
      }
      return ele;
    });
  }

  const uniqueValues = new Set();

  // Define a regular expression pattern to match valid date formats
  const datePattern = /^\d{8}T\d{6}\.\d{3} GMT$/;

  // Iterate over the array and add unique values to the Set
  if (data && data.length) {
    data.forEach((item) => {
      if (item[fieldName] || item[fieldName] === "") {
        const fieldValue = item[fieldName];

        // Check if the field value matches the date pattern
        if (
          datePattern.test(fieldValue) &&
          moment(fieldValue).format("DD-MMM-YY") !== "01-Jan-01"
        ) {
          // If it's a valid date and not equal to "01-Jan-01," format it and add to the set
          const dateValue = moment(fieldValue, "YYYYMMDDTHHmmss.SSS [GMT]").format("DD-MMM-YY");
          uniqueValues.add(dateValue);
        } else {
          // If it doesn't match the date pattern, add it directly to the set as text
          if (!item?.["Task"]?.includes("(X")) {
            uniqueValues.add(fieldValue);
          }
        }
      }
    });
  }
  // Convert the Set of unique values to an array
  const valueList = Array.from(uniqueValues);
  if (valueList.length > 0) {
    return sortAlphabetic(valueList);
  }
};

export const navigateBasedOnRole = (AccessGroup) => {
  // roleToNavigate
  // 1 = CM
  // 2 = PM
  // 3 = TOI
  // 4 = TOE
  // debugger;
  const roleToNavigate = getRoleWithPriority(AccessGroup);
  switch (roleToNavigate) {
    case 1:
      return "/capacityManagement/dashboard";
    case 2:
      return "/myProjects";
    case 3:
    case 4:
      return "/myTasks";
    default:
      return roleToNavigate;
  }
};

export const multiFilterChange = (
  e,
  colName,
  selectedColumnName,
  selectedFields,
  setselectedFields,
  selectedProdSrchList,
  filterArray,
  selectedColumnList,
  colAllSelected,
  selectedFieldsRef
) => {
  let arr = [];
  const value = e?.value;
  let multiplesFlag = colAllSelected?.current?.includes(selectedColumnName);
  if (value?.length === 0) {
    const deselected = e.selectedOption;
    let newArray = filterArray?.current?.filter((element) => element !== deselected);
    filterArray.current = newArray;
    let tempArr = colAllSelected?.current?.filter((element) => element !== selectedColumnName);
    colAllSelected.current = tempArr;
    let sfrArr =
      selectedFieldsRef?.current?.length > 0 &&
      selectedFieldsRef?.current?.filter(
        (element) => Object.keys(element)[0] !== selectedColumnName
      );
    selectedFieldsRef.current = sfrArr;
  } else {
    filterArray?.current?.push(value?.[0]);
    colAllSelected.current.push(selectedColumnName);
    if (
      selectedFieldsRef.current.length > 0 &&
      selectedFieldsRef.current[0][selectedColumnName] &&
      selectedFieldsRef.current[0][selectedColumnName].length > 0
    ) {
      selectedFieldsRef.current[0][selectedColumnName] = value;
    } else {
      // arr[selectedColumnName] = value;
      arr = {
        [`${selectedColumnName}`]: value,
      };
      if (!Array.isArray(selectedFieldsRef?.current)) {
        const convertedArray = AcpService.convertToObjectArray(selectedFieldsRef?.current);
        selectedFieldsRef.current = [...convertedArray, arr];
      } else {
        selectedFieldsRef?.current?.push(arr);
      }
    }
  }
  let temp = cloneDeep(selectedFields);
  if (temp) {
    temp[colName] = value;
    setselectedFields(temp);
    let allValues = [];
    let keys = Object.keys(temp);
    keys?.forEach((key) => {
      allValues = [...allValues, ...(temp?.[key] || [])];
    });
  }
  const artworkCategories = value;
  if (
    selectedColumnList?.current?.length === 0 ||
    artworkCategories?.length > 1 ||
    value?.length === 0 ||
    multiplesFlag
  ) {
    //selectedColumnList.current = [];
    selectedColumnList.current = selectedProdSrchList;
  }
  return { value, multiplesFlag, temp, artworkCategories, arr };
};

export const designScope = [
  { label: "Design Intent", value: "DI" },
  { label: "Regional Design Template", value: "DT" },
  { label: "Color Development\n / Print Trial", value: "PF" },
  { label: "Ink Qualification", value: "IQ" },
  { label: "Representative Assembly Template", value: "PRA" },
  { label: "CIC/GA", value: "CICs" },
  { label: "Final Art", value: "FA" },
];

export const insertMaxWords = (text, maxWordCount = 6) => {
  if (!text || typeof text !== "string") return text;
  const words = text.split(",");
  let lines = [];
  let currentLine = "";

  for (let i = 0; i < words.length; i++) {
    if (currentLine.length === 0) {
      currentLine = words[i];
    } else {
      currentLine += `,${words[i]}`;
    }

    if ((i + 1) % maxWordCount === 0 || i === words.length - 1) {
      lines.push(currentLine);
      currentLine = "";
    }
  }
  return lines.join("\n");
};

//Below function is to compare two arrays and out of two arrays: one array should an array of objects and other array should be a simple array of values
export const reorderSecondArraySameAsFirstArray = (firstArray, secondArray) => {
  if (firstArray && isObject(firstArray[0])) {
    // Create a mapping from field names to their Sequence values
    const fieldToSequence = {};
    firstArray?.forEach((item) => {
      fieldToSequence[item?.field] = item?.Sequence;
    });

    // Sort the second array based on the Sequence values
    const sortedArray = secondArray?.slice()?.sort((a, b) => {
      return fieldToSequence[a] - fieldToSequence[b];
    });

    return sortedArray !== null ? sortedArray : [];
  } else {
    // Create a mapping from field names to their indices in the firstArray
    const fieldOrder = new Map();
    firstArray?.forEach((field, index) => {
      fieldOrder?.set(field, index);
    });

    // Sort the secondArray based on the order in fieldOrder map
    const sortedArray = secondArray?.slice().sort((a, b) => {
      const orderA = fieldOrder?.get(a?.field);
      const orderB = fieldOrder?.get(b?.field);
      return orderA - orderB;
    });
    sortedArray?.forEach((obj, index) => {
      if (obj?.Sequence) obj.Sequence = index;
    });

    return sortedArray !== null ? sortedArray : [];
  }
};

export const reorderArray = (slectedFeilds, freezedFeilds, arrayOfObjects, field_Name) => {
  const arryToSort =
    freezedFeilds?.length > 0 ? [...freezedFeilds, ...slectedFeilds] : slectedFeilds;
  const arrayToReorder =
    field_Name != ""
      ? arrayOfObjects?.filter((obj) => arryToSort?.includes(obj[field_Name]))
      : arrayOfObjects?.filter((obj) => arryToSort?.includes(obj));
  const reorderedArray = arrayToReorder?.sort((a, b) => {
    if (field_Name != "")
      return arryToSort?.indexOf(a[field_Name]) - arryToSort?.indexOf(b[field_Name]);
    else return arryToSort?.indexOf(a) - arryToSort?.indexOf(b);
  });
  if (freezedFeilds?.length > 0) {
    reorderedArray?.forEach((item) => {
      freezedFeilds?.some((ele) => {
        if (field_Name != "") {
          if (ele === item[field_Name]) item.freeze = true;
        }
      });
    });
  }
  return reorderedArray;
};

export const deepCompare = (obj1, obj2) => {
  // Check if both arguments are objects
  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is different
  if (keys1.length !== keys2.length) {
    return true;
  }

  // Check if there is differnce in keys and values
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) {
      return true;
    }
  }

  return false;
};

export const findObjectWithMostKeys = (arr) => {
  let maxKeys = 0;
  let objectWithMostKeys = null;

  arr?.forEach((obj) => {
    const keyCount = Object.keys(obj)?.length;
    if (keyCount > maxKeys) {
      maxKeys = keyCount;
      objectWithMostKeys = obj;
    }
  });

  return objectWithMostKeys;
};

export const getModifiedProjectHeaderNames = (data) => {
  return data?.map((obj) => {
    switch (obj?.Column_Name) {
      case "Estimated_AW_Printer":
        obj.Column_Name = "Estimated AW@Printer";
        break;
      case "Artwork_Category":
        obj.Column_Name = "Category";
        break;
      case "Project_region":
        obj.Column_Name = "Region";
        break;
      case "Artwork_Brand":
        obj.Column_Name = "Brand";
        break;
      case "Project_Scale":
        obj.Column_Name = "Scale";
        break;
      case "Company":
        obj.Column_Name = "FA Supplier";
        break;
      case "Buffer_To_Work":
        obj.Column_Name = "B:W";
        break;
      case "Artwork_SMO":
        obj.Column_Name = "SMO";
        break;
      default:
        break;
    }
    return obj;
  });
};

export const convertNextLineCharToSpaces = (str) => {
  if (str === "" || str === undefined) {
    return str;
  }
  return str?.replace(/["\n\r\t/]/g, " ");
};

export const isRoleMatch = (accessgroup, roles) => {
  const accessgroups = accessgroup?.map((role) => {
    return role?.AccessGroupNames?.substr(4);
  });
  let isMatchFound = false;
  for (let i = 0; i < accessgroups?.length; i++) {
    for (let j = 0; j < roles?.length; j++) {
      if (accessgroups[i] === roles[j]) {
        isMatchFound = true;
        break;
      }
    }
  }
  return isMatchFound;
};

//AWM-3059: moved here as common function
export const fetchExtractedAnnotations = (xfdfContent) => {
  // Parse the XML response
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xfdfContent, "text/xml");

  // Extract comments from the XML
  const commentNodes = xmlDoc.querySelectorAll("text");
  const squareNodes = xmlDoc.querySelectorAll("square");
  const rectangleNodes = xmlDoc.querySelectorAll("rectangle");
  const inkNodes = xmlDoc.querySelectorAll("ink");
  const extractedAnnotations = [];

  // Extract text annotations
  Array.from(commentNodes)?.forEach((node) => {
    node.querySelector("p").textContent &&
      extractedAnnotations.push({
        markupId: node.getAttribute("name"),
        type: "text",
        creationDate: node.getAttribute("creationdate"),
        content: node.querySelector("p").textContent,
        role: "", // Initialize with the first value from reasonRoles
        reason: "", // Initialize with the first value from reasons
        defectType: "", // Initialize with the first value from defectType
        comment: "",
        taskId: "",
        version: "",
        MarkupTaskID: node.getAttribute("subject").split(".")[0],
      });
  });

  // Extract square annotations
  Array.from(squareNodes)?.forEach((node) => {
    node.querySelector("p").textContent &&
      extractedAnnotations.push({
        markupId: node.getAttribute("name"),
        type: "square",
        creationDate: node.getAttribute("creationdate"),
        content: node.querySelector("p").textContent,
        role: "", // Initialize with the first value from reasonRoles
        reason: "", // Initialize with the first value from reasons
        defectType: "", // Initialize with the first value from defectType
        comment: "",
        taskId: "",
        version: "",
        MarkupTaskID: node.getAttribute("subject").split(".")[0],
        // Extract additional attributes specific to square annotations here
      });
  });

  // Extract rectangle annotations
  Array.from(rectangleNodes).forEach((node) => {
    node.querySelector("p").textContent &&
      extractedAnnotations.push({
        markupId: node.getAttribute("name"),
        type: "rectangle",
        creationDate: node.getAttribute("creationdate"),
        content: node.querySelector("p").textContent,
        role: "", // Initialize with the first value from reasonRoles
        reason: "", // Initialize with the first value from reasons
        defectType: "", // Initialize with the first value from defectType
        comment: "",
        taskId: "",
        version: "",
        MarkupTaskID: node.getAttribute("subject").split(".")[0],
        // Extract additional attributes specific to rectangle annotations here
      });
  });
  // Extract square annotations
  Array.from(inkNodes)?.forEach((node) => {
    node.querySelector("p").textContent &&
      extractedAnnotations.push({
        markupId: node.getAttribute("name"),
        type: "ink",
        creationDate: node.getAttribute("creationdate"),
        content: node.querySelector("p").textContent,
        role: "", // Initialize with the first value from reasonRoles
        reason: "", // Initialize with the first value from reasons
        defectType: "", // Initialize with the first value from defectType
        comment: "",
        taskId: "",
        version: "",
        MarkupTaskID: node.getAttribute("subject").split(".")[0],
        // Extract additional attributes specific to square annotations here
      });
  });

  return extractedAnnotations;
};

export const checkIfOrderSame = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const checkIfObjectOrderSame = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Use the deepCompare function to compare objects
  for (let i = 0; i < arr1.length; i++) {
    if (deepCompare(arr1[i], arr2[i])) {
      return false; // If deepCompare returns true, objects are different
    }
  }

  return true;
};

export const toastMessages = {
  downloading: {
    severity: "warn",
    summary: "Downloading",
    detail: "Please wait, file is downloading...",
  },
  downloadComplete: {
    severity: "success",
    summary: "Download Complete",
    detail: "The file has been successfully downloaded.",
  },
  downloadFailed: {
    severity: "error",
    summary: "Download Failed",
    detail: "An error occurred while downloading the file.",
  },
};

export const showToast = (toastRef, severity, detail, options = {}) => {
  if (toastRef?.current) {
    if (options.clearPrevious) {
      toastRef.current.clear();
    }
    toastRef.current.show({
      severity,
      summary: toastMessages[severity]?.summary || "File Download",
      detail,
      ...options,
    });
  }
};
export const localWidthStorage=(event,localName)=>{
  let jsonColumnWidth = localStorage.getItem(localName);
  let columnWidth = {};
  if (jsonColumnWidth) {
    columnWidth = JSON.parse(jsonColumnWidth);
  }
  columnWidth[event.column.props.field] = event.element.offsetWidth;
  localStorage.setItem(localName, JSON.stringify(columnWidth));
}