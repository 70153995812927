import "../index.scss";
import _, { isEqual, isObject, uniq, isEmpty } from "lodash";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import CustomizeView from "../../common/CustomizeView";
import acpInfo from "../../../assets/images/acpInfo.svg";
import { AcpService } from "../../../service/ACPService";
import acpError from "../../../assets/images/acpError.svg";
import { ProjectService } from "../../../service/PegaService";
import ACPBookingHeaderColumn from "./ACPBookingHeaderColumn";
import React, { useEffect, useState, useCallback } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";
import { acpBookingFrozenColumn } from "../../../Utils/acpBookingFrozenColumn";
import { changeDateFormat, multiFilterChange } from "../../../utils";
import { AcpCommonService } from "../../../service/AcpCommonService";
import CalendarIcon from "../../common/CalendarIcon/CalendarIcon";
import { InputText } from "primereact/inputtext";
import { removeObjectsWithNullStartDate } from "../../../service/DashboardService";
import { getColumnsOrder } from "../../../apis/commonApi";
import { useSelector } from "react-redux";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import { storeACPReorderedColumns, onACPColumnResizeEnd } from "../utils";
import CustomInput from "../../common/CustomInput";
import { localWidthStorage } from "../../../utils";
import { formCustomizeObject } from "../../../utils";

function AcpBookingDatatable(
  {
    reset,
    loader,
    tabName,
    Headers,
    isSearch,
    rowGroups,
    fetchData,
    isBVEloop,
    editedRow,
    expandAll,
    setLoader,
    actionType,
    setHeaders,
    isSearchSet,
    backlogData,
    selectedRows,
    setExpandAll,
    setChartData,
    chartClicked,
    setEditedRow,
    setSuppliers,
    setActionType,
    setActionFlag,
    allEditedRows,
    setBookingData,
    clearAllFilter,
    setSelectedRows,
    setAllEditedRows,
    bookingTableData,
    resetMultiFilter,
    setClearAllFilter,
    setNoOfActiveTasks,
    updatedBookingData,
    ShowCustomizedView,
    bookingTableFilters,
    setResetMultiFilter,
    setBookingTableData,
    customizeViewFields,
    checkReadWriteAccess,
    setShowCustomizedView,
    setCustomizeViewFields,
    onSave,
    resetKey,
    setResetKey,
    setDateChangeValue,
    chartData,
    AllEditedTableDataRows,
    setAllEditedTableDataRows,
  },
  ref
) {
  const dt = useRef(null);
  const op = useRef(null);
  const filterArray = useRef([]);
  const navigate = useNavigate();
  const colAllSelected = useRef([]);
  const selectedFieldsRef = useRef([]);
  const selectedColumnList = useRef([]);
  const [filters, setFilters] = useState([]);
  const [selected, setSelected] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchInfo, setSearchInfo] = useState("");
  const [searchHeader, setSearchHeader] = useState();
  const [expandedRows, setExpandedRows] = useState([]);
  const [tableRender, setTableRender] = useState(false);
  const [groupWiseData, setGroupWiseData] = useState([]);
  const [selectedFields, setSelectedFields] = useState({});
  const [allColumnNames, setAllColumnNames] = useState([]);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [initialPegaData, setInitialPegaData] = useState(null);
  const [InitialTableData, setInitialTableData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [projectColumnName, setProjectColumnNames] = useState([]);
  const [isReorderedColumn, setReorderedColumn] = useState(false);
  const accessSecurityMatrix = checkReadWriteAccess; //usePriorityNew(currentUrlBasePage);
  const [conditionMatched, setConditionMatched] = useState(false);
  const [frozenCoulmns, setFrozenColumn] = useState(["SelectAll"]);
  const [availableColumnNames, setAvailableColumnNames] = useState([]);
  const [selectedColumnName, setSelectedColumnName] = useState("SelectAll");
  const [resizeMode, setResizeMode] = useState("expand");
  const [localRowGroups, setLocalRowGroups] = useState([...rowGroups]);
  const [localPreviousRowGroups, setLocalPreviousRowGroups] = useState([]);
  const baseUrl = "/allProjects/artworkStatus"; //TODO: Need to change to dynamicurl
  const [bookingPageEdited, setBookingPageEdited] = useState(false);
  const [LastEditedUniqueId, setLastEditedUniqueId] = useState("");
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const [showDialog, setShowDialog] = useState(false);
  const [IsEqualRow, setIsEqualRow] = useState(false);
  const [tempUrl, setTempUrl] = useState("");
  const [bu, setBu] = useState("");
  const [region, setRegion] = useState("");
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const { selectedBU } = useSelector((state) => state?.UserDetailsReducer);
  const [pgDefaultColumns, setPGDefaultColumns] = useState({});
  const bookingColumnNames = [...ProjectService.getBookingColumnNames()];
  const [InitAvailableColumns, setInitAvailableColumns] = useState([]);

  const updateCustomizeViewFields = (availableColumns, selectedColumns, frozenColumns) =>{
    const customizeViewData = {
      availableFields: {
        id: "availableFields",
        title: "Available Fields",
        fieldsData: availableColumns,
      },
      selectedFields: {
        id: "selectedFields",
        title: "Selected Fields",
        fieldsData: selectedColumns,
      },
      freezedColumns: {
        id: "freezedColumns",
        title: "Freezed Fields",
        fieldsData: frozenColumns,
      },
    };
    setCustomizeViewFields(JSON.stringify(customizeViewData));
    localStorage.setItem("customizeViewFieldsBooking", JSON.stringify(customizeViewData));
  }

  const updateInObjectFormat = (columns, frozenCoulmns) =>{
    return columns.map((el) => {
      return {
        Sequence: "",
        Field_Name: el,
        reorder: false,
        Column_Name: el,
        sortAtoZ: false,
        sortZtoA: false,
        Attribute_Type: "Free Text",
        freeze: frozenCoulmns.includes(el) ? true : false,
      };
    });
  }

  const updatedFrozenColumn = (frozenArr) =>{
    const fronzenFields = frozenArr?.map((el) => {
      el = el?.replace(/_/g, ' ')
      return el;
    });
    setFrozenColumn(fronzenFields);
  }

  useEffect(() => {
    const resObj = AcpService.getBURegion(selectedBU);
    let bu = "";
    let region = "";
    if (!isEmpty(resObj)) {
      bu = resObj?.bu;
      region = resObj?.region;
      setBu(bu);
      setRegion(region);
    }
    const fetchCustomizeViewColumnsFromPega = async () => {
      let customizeViewColumnFromPega = await getColumnsOrder(
        userInformation?.userid,
        "customizeViewFieldsBooking",
        bu,
        region
      );
      if (customizeViewColumnFromPega !== null && customizeViewColumnFromPega !== "") {
        setCustomizeViewFields(JSON.stringify(customizeViewColumnFromPega));
        localStorage.setItem(
          "customizeViewFieldsBooking",
          JSON.stringify(customizeViewColumnFromPega)
        );
        updatedFrozenColumn(customizeViewColumnFromPega?.freezedColumns?.fieldsData);
      } else {
        const customizeViewFieldsBooking = localStorage.getItem("customizeViewFieldsBooking");
        const customizeViewFieldsParsed = customizeViewFieldsBooking && JSON.parse(customizeViewFields);
        if(customizeViewFieldsParsed){
          setCustomizeViewFields(JSON.stringify(customizeViewFieldsParsed));
          updatedFrozenColumn(customizeViewFieldsParsed?.freezedColumns?.fieldsData);
        }else{
          updateCustomizeViewFields(convertObjToNormalFormat(InitAvailableColumns), bookingColumnNames, frozenCoulmns);
        }
      }
    };

    if (bu && region) {
      fetchCustomizeViewColumnsFromPega();
    }
    const pgDefaultColumns = formCustomizeObject([], [], bookingColumnNames)
    setPGDefaultColumns(pgDefaultColumns);
    setProjectColumnNames(bookingColumnNames);
  }, []);

  const handleSelectAll = (e) => {
    const data = filters?.length ? filters : tableData;
    const updatedData = data?.filter((el) => el?.RequestStatus !== "Awaiting Response");
    if (e.target.checked) {
      updatedData?.map((el) => (el.isSelected = true));
      setSelectAllChecked(true);
      setSelected(updatedData);
    } else {
      updatedData?.map((el) => (el.isSelected = false));
      setSelectAllChecked(false);
      setSelected([]);
    }
  };
  useEffect(() => {
    setSelected([]);
    setSelectAllChecked(false);
  }, [onSave]);

  const handleSelect = useCallback(
    (item) => {
      //fix for AWM-3674
      const index = selected?.findIndex(
        (selectedItem) => selectedItem?.UniqueID === item?.UniqueID
      );
      if (index !== -1) {
        item.isSelected = false;
        setSelectAllChecked(false);
        //fix for AWM-3674
        setSelected((prevSelected) =>
          prevSelected?.filter((selectedItem) => selectedItem?.UniqueID !== item?.UniqueID)
        );
      } else {
        item.isSelected = true;
        if (selected.length === 0) {
          const selectedList = [];
          selectedList.push(item);
          setSelected(selectedList);
        } else {
          const selectedList = [...selected, item];
          setSelectAllChecked(
            (filters?.length ? filters : tableData)?.length === selectedList?.length
          );
          setSelected([...selectedList]);
        }
      }
    },
    [selected]
  );

  useEffect(() => {
    setFilters([]);
    setTableData([]);
    localStorage.removeItem("selectedDataPrselected");
    localStorage.setItem("NewStartDatesSaved", JSON.stringify([]));
  }, []);
  useEffect(()=>{
    let updatedAllColumns = convertObjToNormalFormat(allColumnNames).filter(
      (el) => el !== "SelectAll"
    );
    const updatedHeaders = AcpService.reorderColumns(updatedAllColumns);
    const extraColumnHeaders = updatedAllColumns.filter(function (objFromA) {
      return !updatedHeaders.find(function (objFromB) {
        return objFromA === objFromB;
      });
    });
    const finalHeaders = [...updatedHeaders, ...extraColumnHeaders]
    setHeaders(finalHeaders);
  },[allColumnNames, frozenCoulmns])

  useEffect(() => {
    const storedValue = localStorage.getItem("columnWidthbookingColumn");
    const localBookingColumn = storedValue && JSON.parse(storedValue);
    let allBookingColumnNamesUpdated = [];
    // if customied value is null then take default value
    // else check another condition ->  columnWidthbookingColumn if null then use customied
    // else use columnWidthbookingColumn
    if (
      (customizeViewFields === null ||
        customizeViewFields === undefined ||
        customizeViewFields.length === 0) &&
      isEmpty(localBookingColumn)
    ) {
      allBookingColumnNamesUpdated = updateInObjectFormat(bookingColumnNames, frozenCoulmns);
    } else {
      if (!isEmpty(localBookingColumn) && localBookingColumn !== null) {
        allBookingColumnNamesUpdated = localBookingColumn;
      } else {
        const parsedCustomizedField = JSON?.parse(customizeViewFields);
        const updatedHeaders = parsedCustomizedField?.selectedFields?.fieldsData;
        const latestHeaders = updatedHeaders?.filter((item) => item !== "SelectAll");
        const frozenData = parsedCustomizedField?.freezedColumns?.fieldsData;
        if (!frozenData?.includes("SelectAll")) {
          frozenData?.unshift("SelectAll");
        }
        const finalColumn = [...(frozenData || []), ...(latestHeaders || [])];
        allBookingColumnNamesUpdated = updateInObjectFormat([...finalColumn], frozenCoulmns);
      }
    }
    setAllColumnNames(allBookingColumnNamesUpdated);
    if(tableData?.length){
      const {AWMProjectID, EstimatedAWPrinter, edited, isSelected,Group, GroupOrder, AWMTaskRemainingBuffer, UniqueID, existGroupOrder, Duration, Assignee, AWMTaskRemainingWork, tempStartDate, Projected_FA_FullKit_Date, ...restAllColumns} = tableData?.[0]; 
      let availableFieldsToSelect = Object.keys(restAllColumns).filter(function (objFromA) {
        return !bookingColumnNames.find(function (objFromB) {
          return objFromA === objFromB;
        });
      });
      updateCustomizeViewFields(availableFieldsToSelect, bookingColumnNames, ["SelectAll"]);
      let availableFieldsToSelectInObjFormat = updateInObjectFormat(availableFieldsToSelect, ["SelectAll"])
      setAvailableColumnNames(availableFieldsToSelectInObjFormat);
      setInitAvailableColumns(availableFieldsToSelectInObjFormat);
    }

    if (tableData?.length !== 0 && loader) {
      (async () => {
        try {
          let updatedBookingsData = [];
          // awm-3674, to sync up tableData and Filter Data with ALlEdited Rows
          if (AllEditedTableDataRows?.length) {
            updatedBookingsData = AcpCommonService?.overrideFilteredData(
              AllEditedTableDataRows,
              updatedBookingData,
              "UniqueID"
            );
          }
          if (chartClicked) setTableData(updatedBookingsData);
          let ProjectData = _.cloneDeep(tableData);
          if (ProjectData.length && !clearAllFilter) {
            if (!initialPegaData) {
              setInitialPegaData(_.cloneDeep(ProjectData));
            }
            let filteredPegaDataJson = localStorage.getItem("bookingColumnWiseFilterData");
            let filteredPegaData = JSON.parse(filteredPegaDataJson);
            if (AllEditedTableDataRows?.length) {
              if (ProjectData?.length) {
                ProjectData = AcpCommonService.overrideFilteredData(
                  AllEditedTableDataRows,
                  ProjectData,
                  "UniqueID"
                );
              }
              if (filteredPegaData?.length) {
                ProjectData = AcpCommonService.overrideFilteredData(
                  AllEditedTableDataRows,
                  filteredPegaData,
                  "UniqueID"
                );
              }
            }
            if (filteredPegaData && filteredPegaData.length) {
              setTableData(_.cloneDeep(ProjectData));
              setFilters(filteredPegaData);
              // setSelectedFields(filteredPegaData);
            } else {
              setTableData(_.cloneDeep(ProjectData));
            }
          }

          let columnNamesJson = localStorage.getItem("bookingNamesbookings");
          const columnNames = JSON.parse(columnNamesJson);

          if (columnNames != null && columnNames.length) {
            setProjectColumnNames(columnNames);
          } else {
            if (
              customizeViewFields &&
              JSON.parse(customizeViewFields)?.selectedFields?.fieldsData
            ) {
              const parsedCustomizedField = JSON?.parse(customizeViewFields);
              const updatedHeaders = parsedCustomizedField?.selectedFields?.fieldsData;
              const latestHeaders = updatedHeaders?.filter((item) => item !== "SelectAll");
              const frozenData = parsedCustomizedField?.freezedColumns?.fieldsData;
              if (!frozenData.includes("SelectAll")) {
                frozenData.push("SelectAll");
              }
              const updatedColumn = [...frozenData, ...latestHeaders];
              const finalColumn = updatedColumn?.filter((item) => {
                return item.toLowerCase() !== "selectall";
              });
              const finalColumn1 = [...finalColumn];
              if (!finalColumn.includes("SelectAll")) {
                finalColumn1.unshift("SelectAll");
              }
              // setProjectColumnNames(updatedColumn);
              setProjectFrozen(!ProjectFrozen);
              projectColumnName &&
                acpBookingFrozenColumn(
                  frozenCoulmns,
                  setReorderedColumn,
                  setProjectColumnNames,
                  setTableRender,
                  op,
                  finalColumn1,
                  "booking"
                );
            } else {
              if (!bookingColumnNames.includes("SelectAll")) {
                bookingColumnNames.unshift("SelectAll");
              }
            }
          }

          let jsonSortingData1 = localStorage.getItem("bookingSortingData");
          const sortingData = JSON.parse(jsonSortingData1);

          if (sortingData && sortingData.length) {
            const sortedData = [...ProjectData].sort((a, b) => {
              if (sortingData[1] === "desc") {
                return a[sortingData[0]] > b[sortingData[0]] ? -1 : 1;
              } else {
                return a[sortingData[0]] > b[sortingData[0]] ? 1 : -1;
              }
            });
            onSort(sortingData[0], sortingData[1], _.cloneDeep(ProjectData), true, true);
          }

          let jsonFrozenrData1 = localStorage.getItem("bookingFrozenData");
          const frozenData = JSON.parse(jsonFrozenrData1);
          if (frozenData && frozenData.length) {
            updatedFrozenColumn(frozenData);
          }
        } catch (err) {
          console.log("error", err);
        }
      })();
    }
  }, [tableData]);

  useEffect(() => {
    if (tableData?.length || filters?.length) {
      if (allEditedRows?.length) {
        setAllEditedTableDataRows(allEditedRows);
      }
      //3182
      // setBookingData(filters?.length ? filters : tableData);

      // For 3093
      // The Chart Data state wasn't updated with new filtered data on adding filters. Now we check if filters are present add that or table data to the chart data state.
      if (setBookingData && !chartClicked) {
        setBookingData(filters?.length ? filters : tableData);
        const updatedDataWithoutBacklog = tableData?.filter((el) => el.Group !== "Backlog");
        const updatedDataWithoutBacklogUpdated = _.cloneDeep(updatedDataWithoutBacklog);
        // setChartData(filters?.length ? _.cloneDeep(filters) : updatedDataWithoutBacklogUpdated);
        // Backlog tasks UI
        setChartData(filters?.length ? _.cloneDeep(filters) : _.cloneDeep(tableData));
      }
    }
    const areObjectsEqualData = isEqual(InitialTableData, tableData);
    if (areObjectsEqualData) {
      localStorage.removeItem("NewStartDatesSaved");
    }
  }, [tableData, filters]);

  useEffect(() => {
    let toBeExpanded = [];
    if (expandAll === true) {
      rowGroups?.forEach((grp) => {
        let filteredData = tableData?.filter((data) => {
          return data.Group === grp.id;
        });
        filteredData?.length && toBeExpanded.push(filteredData[0]);
      });
      let _localRowGroups = [...localRowGroups];
      //The function iterates through a collection of row groups (_localRowGroups) to manage their expanded state based on a previously saved state (localPreviousRowGroups) Awm-3247
      _localRowGroups.forEach((el) => {
        const checkPrevSnapState = localPreviousRowGroups?.find((item) => item?.name === el?.name);
        el.expanded = checkPrevSnapState ? checkPrevSnapState?.expanded : true;
        if (!el.expanded) {
          toBeExpanded = toBeExpanded?.filter((item) => item?.Group !== el?.id);
        }
        // changes for Awm-3247
      });
      setLocalRowGroups([..._localRowGroups]);
      // _localRowGroups is an array of objects, with specific properties named expanded, based on the expanded property we are doing expand, and we are using localPreviousRowGroups to manage their expanded state based on a previously saved state
      setExpandedRows(toBeExpanded);
    } else if (expandAll === false) {
      let _localRowGroups = [...localRowGroups];
      _localRowGroups.forEach((el) => {
        el.expanded = false;
      });
      setLocalRowGroups([..._localRowGroups]);
      setExpandedRows([]);
    }
  }, [expandAll, tableData, bookingTableFilters]);

  useEffect(() => {
    const temp = allEditedRows?.filter((row) => {
      return isObject(row) && row.UniqueID !== editedRow.UniqueID;
    });
    if (tabName === "booking") {
      setBookingPageEdited(editedRow?.AWMProjectID ? true : false);
      if (Object.keys(editedRow)?.length && actionType !== "massUpdate") {
        temp.push(editedRow); //don't push if task date is same as filter date
        setAllEditedRows(temp);
      }
    } else if (tabName === "bveLoop") {
      let oldRow = bookingTableData?.filter((row) => {
        return row.TaskName === editedRow.TaskName;
      });
      Object.keys(editedRow)?.length &&
        !AcpService.checkDateIsSameToFilterDate(editedRow.EndDate, oldRow[0]?.EndDate) &&
        temp.push(editedRow); //don't push if task date is same as filter date
    }
    const editedFilterData = _.cloneDeep(filters);
    const editedTableData = _.cloneDeep(tableData);
    let updatedTblDataWithEditedRows = editedTableData;
    let updatedFilterDataWithEditedRows = editedFilterData;
    if (allEditedRows?.length || temp?.length) {
      if (temp?.length && actionType !== "massUpdate") {
        updatedTblDataWithEditedRows = AcpCommonService.overrideFilteredData(
          temp,
          editedTableData,
          "UniqueID"
        );
        if (filters?.length) {
          updatedFilterDataWithEditedRows = AcpCommonService.overrideFilteredData(
            temp,
            editedFilterData,
            "UniqueID"
          );
        }
      } else {
        updatedTblDataWithEditedRows = AcpCommonService.overrideFilteredData(
          allEditedRows,
          editedTableData,
          "UniqueID"
        );
        if (filters?.length) {
          updatedFilterDataWithEditedRows = AcpCommonService.overrideFilteredData(
            allEditedRows,
            editedFilterData,
            "UniqueID"
          );
        }
      }
    }
    if (updatedTblDataWithEditedRows?.length) {
      if (filters?.length) {
        setFilters(updatedFilterDataWithEditedRows);
      }
      setTableData(updatedTblDataWithEditedRows);
    }

    // 3246 - updating the bar chart
    let localChart = [...chartData];
    localChart.forEach((_chart) => {
      if (_chart?.["UniqueID"] === editedRow?.["UniqueID"]) {
        _chart["StartDate"] = AcpService.convertShortDateToGMT(editedRow["StartDate"]);
      }

      // 3385 - updating chart data for multiple edited rows
      allEditedRows.length &&
        allEditedRows.forEach((_editedRow) => {
          if (_chart?.["UniqueID"] === _editedRow?.["UniqueID"]) {
            _chart["StartDate"] = _editedRow["StartDate"];
          }
        });
    });

    setChartData(localChart);
  }, [editedRow]);

  const _selectedDataPrselected =
    localStorage.getItem("selectedDataPrselected") &&
    JSON.parse(localStorage.getItem("selectedDataPrselected"));
  useEffect(() => {
    if (_selectedDataPrselected?.length) {
      const uniqueIds = new Set(_selectedDataPrselected.map((item) => item?.UniqueID));
      let data = tableData?.map((item) => ({
        ...item,
        isSelected: uniqueIds?.has(item.UniqueID) ? false : item?.isSelected,
      }));
      if (AllEditedTableDataRows?.length) {
        data = AcpCommonService.overrideFilteredData(AllEditedTableDataRows, data, "UniqueID");
      }
      setTableData(data);
      localStorage.removeItem("selectedDataPrselected");
    }
  }, [_selectedDataPrselected]);

  useEffect(() => {
    let temp = [];
    tableData?.forEach((data) => {
      temp.push(data.Group);
    });
    const currentRowGroups = _.uniq(temp);
    if (expandedRows?.length < 1 && expandAll) {
      setExpandAll(true);
    } else if (expandedRows?.length < 1) {
      setExpandAll(false);
    } else if (expandedRows?.length === currentRowGroups?.length) {
      setExpandAll(true);
    } else {
      setExpandAll(true);
    }
  }, [expandedRows]);

  useEffect(() => {
    const activeCount = bookingTableData?.filter((item) => item.Status === "In-Progress")?.length;
    setNoOfActiveTasks && setNoOfActiveTasks(activeCount);
    setBookingPageEdited(false);
    setAllEditedRows([]);
    setSelectedRows([]);
    setEditedRow({});
    filterTable();
    setSelected([]);
  }, [reset]);

  useEffect(() => {
    setInitialPegaData(_.cloneDeep(bookingTableData));
    setTableData([]);
    filterTable();
  }, [bookingTableFilters, bookingTableData, backlogData]);

  useEffect(() => {
    setSelectedRows(selected);
    if (setActionFlag) {
      setActionFlag(selected);
    } else {
      setActionFlag(!selected);
    }
  }, [selected]);

  useEffect(() => {
    if (chartClicked === true) {
      let updatedBookingsData = updatedBookingData; // 3708
      if (AllEditedTableDataRows?.length) {
        updatedBookingsData = AcpCommonService.overrideFilteredData(
          AllEditedTableDataRows,
          updatedBookingData,
          "UniqueID"
        );
      }
      setTableData(updatedBookingsData);
    } else {
      filterTable();
    }
  }, [chartClicked, updatedBookingData]);

  //3078
  const convertData = (data) => {
    return data?.map((el, i) => {
      return {
        ...el,
        edited: "",
        isSelected: false,
        existGroupOrder: "",
        Slack: AcpService.formatDuration(el?.Slack),
        Status: el?.Status !== "" ? el?.Status : "",
        Owner: el?.Assignee !== "" ? el?.Assignee : "",
        TaskName: el?.TaskName !== "" ? el?.TaskName : "",
        Supplier: el?.Supplier !== "" ? el?.Supplier : "",
        ProjectName: el?.ProjectName !== "" ? el?.ProjectName : "",
        ProjectID: el?.AWMProjectID !== "" ? el?.AWMProjectID : "",
        BufferToWork: el?.BufferToWork !== "" ? el?.BufferToWork : "",
        EndDate: !AcpService.isUBN(el?.EndDate) ? changeDateFormat(el?.EndDate) : "",
        Work: el?.AWMTaskRemainingWork !== "" ? el?.AWMTaskRemainingWork : "",
        StartDate: !AcpService.isUBN(el?.StartDate) ? changeDateFormat(el?.StartDate) : "",
        tempStartDate: !AcpService.isUBN(el?.StartDate) ? changeDateFormat(el?.StartDate) : "",
        Buffer: el?.AWMTaskRemainingBuffer !== "" ? el?.AWMTaskRemainingBuffer : "",
        ArtPrinterDate: !AcpService.isUBN(el?.EstimatedAWPrinter)
          ? changeDateFormat(el?.EstimatedAWPrinter)
          : "",
        TaskDuration: el?.Duration !== "" ? AcpService.formatDuration(el?.Duration) : "",
        "Projected FA FullKit Date": el?.Projected_FA_FullKit_Date !== "" ? el?.Projected_FA_FullKit_Date : "",
      };
    });
  };

  const filterTable = () => {
    let _backlogData = [];
    let _SupplierValue = [];
    if (backlogData?.length) {
      _SupplierValue = backlogData?.map((el) => {
        let val = el.Supplier.length > 0 ? el.Supplier : "NA";
        return val;
      });
    }
    if (backlogData?.length) {
      _backlogData = backlogData?.filter((data) => {
        if (bookingTableFilters?.FAValue && bookingTableFilters?.FAValue === "FA") {
          return data?.TaskType?.includes("ssembly");
        } else if (bookingTableFilters?.FAValue && bookingTableFilters?.FAValue.includes("CIC")) {
          return data?.TaskType?.includes("CIC");
        } else {
          return data;
        }
      });
      _backlogData = _backlogData?.filter((data) => {
        if (bookingTableFilters?.SupplierValue && bookingTableFilters?.SupplierValue !== "NA") {
          return data?.Supplier === bookingTableFilters?.SupplierValue; //3688
        } else if (bookingTableFilters?.SupplierValue === "NA") {
          return data?.Supplier === ""; //3688
        } else {
          return data;
        }
      });
      _backlogData = _backlogData?.map((el) => {
        return {
          ...el,
          GroupOrder: 3,
          Group: "Backlog",
        };
      });
    }

    if (AcpService.compareDates(bookingTableFilters.Date, bookingTableFilters.endDate)) {
      if (bookingTableFilters?.FAValue === "FA") {
        let uniqueSuppliers = uniq(_SupplierValue);
        setSuppliers(uniqueSuppliers);
        const __backlogData = convertData(_backlogData);
        if (AllEditedTableDataRows?.length) {
          const updatedTblDataWithEditedRows = AcpCommonService.overrideFilteredData(
            AllEditedTableDataRows,
            __backlogData,
            "UniqueID"
          );
          setTableData(updatedTblDataWithEditedRows);
          setChartData(updatedTblDataWithEditedRows);
        } else {
          setTableData(__backlogData);
          setChartData(__backlogData);
        }
        setInitialTableData(__backlogData);
      } else {
        setSuppliers([]);
        setTableData([]);
      }
    } else {
      let tbldata = [];
      tbldata = _.cloneDeep(bookingTableData);
      if (tbldata?.length) {
        const filteredTasks = tbldata?.filter((data) => {
          if (bookingTableFilters?.FAValue && bookingTableFilters?.FAValue === "FA") {
            return data?.TaskType?.includes("ssembly");
          } else if (bookingTableFilters?.FAValue && bookingTableFilters?.FAValue.includes("CIC")) {
            return data?.TaskType?.includes("CIC");
          } else {
            return data;
          }
        });
        //3078
        let filteredSupplier = filteredTasks?.filter((data) => {
          if (bookingTableFilters?.SupplierValue && bookingTableFilters?.SupplierValue !== "NA") {
            return data?.Supplier === bookingTableFilters?.SupplierValue;
          } else if (bookingTableFilters?.SupplierValue === "NA") {
            return data?.Supplier === "";
          } else {
            return data;
          }
        });

        // due to change of 3078
        let SupplierValue = filteredTasks.map((el) => {
          let val = el.Supplier.length > 0 ? el.Supplier : "NA";
          return val;
        });
        let _SupplierValue = [];
        if (backlogData?.length && bookingTableFilters?.FAValue === "FA") {
          _SupplierValue = backlogData?.map((el) => {
            let val = el.Supplier.length > 0 ? el.Supplier : "NA";
            return val;
          });
        }
        const _finalSupplierValue = [...(SupplierValue || []), ...(_SupplierValue || [])];
        let uniqueSuppliers = uniq(_finalSupplierValue);
        //3078
        if (!uniqueSuppliers?.includes(bookingTableFilters?.SupplierValue)) {
          filteredSupplier = filteredTasks;
        }
        if (
          !filteredSupplier?.length &&
          bookingTableFilters?.SupplierValue &&
          !_SupplierValue?.length
        ) {
          setSuppliers([bookingTableFilters?.SupplierValue]);
        } else {
          setSuppliers(uniqueSuppliers);
        }

        let filteredByDate;
        if (isBVEloop) {
          filteredByDate = filteredSupplier;
        } else {
          filteredByDate = AcpService.filterObjectsByDate(
            filteredSupplier,
            AcpService.changeDateFromISTtoUTC(bookingTableFilters?.Date),
            AcpService.changeDateFromISTtoUTC(bookingTableFilters?.endDate)
          );
        }
        let _backlogData = [];
        if (backlogData?.length) {
          _backlogData = backlogData?.filter((data) => {
            if (bookingTableFilters?.FAValue && bookingTableFilters?.FAValue === "FA") {
              return data?.TaskType?.includes("ssembly");
            } else if (
              bookingTableFilters?.FAValue &&
              bookingTableFilters?.FAValue.includes("CIC")
            ) {
              return data?.TaskType?.includes("CIC");
            } else {
              return data;
            }
          });

          _backlogData = _backlogData?.filter((data) => {
            if (bookingTableFilters?.SupplierValue && bookingTableFilters?.SupplierValue !== "NA") {
              return data?.Supplier === bookingTableFilters?.SupplierValue;
            } else if (bookingTableFilters?.SupplierValue === "NA") {
              return data?.Supplier === ""; //3688
            } else {
              return data;
            }
          });
          _backlogData = _backlogData?.map((el) => {
            return {
              ...el,
              GroupOrder: 3,
              Group: "Backlog",
            };
          });
        }
        const finalFilteredData = [...filteredByDate, ..._backlogData];
        let _filteredByDate = convertData(finalFilteredData);

        const newArray = [];
        const sortedData = AcpService.groupBy(_filteredByDate, "Group");
        for (const [key, value] of Object.entries(sortedData)) {
          const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(
            value,
            "StartDate",
            "asc"
          );
          newArray.push(sortedDataSet);
        }
        const activeCount = _filteredByDate?.filter((item) => item.Status === "Active")?.length;
        setNoOfActiveTasks && setNoOfActiveTasks(activeCount);
        const GroupedData = AcpService.countTasksByGroup(_filteredByDate);
        const SortedData = AcpService.addRefinedOrder(GroupedData);
        let updatedData = newArray?.flat();
        setGroupWiseData(SortedData);
        const updatedDataFinal = updatedData?.map((el, i) => {
          return {
            ...el,
            StartDate: el?.StartDate !== "" ? changeDateFormat(el?.StartDate) : null,
          };
        });
        if (allEditedRows && allEditedRows.length > 0) {
          const updatedTableData = updatedDataFinal?.filter((item) => {
            const editedItem = allEditedRows?.find((edit) => edit?.UniqueID === item?.UniqueID);

            if (!AcpService.isUBN(editedItem) && Object.keys(editedItem).length) {
              return { ...item, ...editedItem };
            }
            return item;
          });
          if (AllEditedTableDataRows?.length) {
            const updatedTblDataWithEditedRows = AcpCommonService.overrideFilteredData(
              AllEditedTableDataRows,
              updatedTableData,
              "UniqueID"
            );
            if (sortData?.length) {
              onSort(sortData[0], sortData[1], updatedTblDataWithEditedRows, true);
            } else {
              setTableData(_.cloneDeep(updatedTblDataWithEditedRows));
              setChartData(_.cloneDeep(updatedTblDataWithEditedRows));
            }
          } else {
            if (sortData?.length) {
              onSort(sortData[0], sortData[1], updatedTableData, true);
            } else {
              setTableData(_.cloneDeep(updatedTableData));
              setChartData(_.cloneDeep(updatedTableData));
            }
          }
        } else {
          if (AllEditedTableDataRows?.length) {
            const updatedTblDataWithEditedRows = AcpCommonService.overrideFilteredData(
              AllEditedTableDataRows,
              updatedDataFinal,
              "UniqueID"
            );
            if (sortData?.length) {
              onSort(sortData[0], sortData[1], updatedTblDataWithEditedRows, true);
            } else {
              setTableData(updatedTblDataWithEditedRows);
              setChartData(updatedTblDataWithEditedRows);
            }
          } else {
            if (sortData?.length) {
              onSort(sortData[0], sortData[1], updatedDataFinal, true);
            } else {
              setTableData(_.cloneDeep(updatedDataFinal));
              setChartData(_.cloneDeep(updatedDataFinal));
            }
          }
        }
        setInitialTableData(_.cloneDeep(updatedDataFinal));
        const updatedDataWithoutBacklog = updatedData?.filter((el) => el.Group !== "Backlog");
        setChartData(updatedDataFinal);
        setResetMultiFilter(true);
        const SavedFieldRefs = localStorage.getItem("_selectedFieldsRefCurrentBooking");
        const SavedFieldRefsParsed = SavedFieldRefs && JSON.parse(SavedFieldRefs);
        const currentRef = selectedFieldsRef.current?.length
          ? selectedFieldsRef.current
          : Object.entries(selectedFieldsRef.current);
        if (currentRef?.length) {
          const __selectedFields = selectedFieldsRef.current?.length
            ? selectedFieldsRef.current
            : AcpCommonService.convertArrayOfArrayToArrayOfObjects(currentRef);
          const filterData = AcpService.filterDataByfilters(
            updatedDataFinal,
            __selectedFields,
            setConditionMatched,
            tabName
          );
          let updatedFilterDataWithEditedRows = filterData;
          if (AllEditedTableDataRows?.length) {
            updatedFilterDataWithEditedRows = AcpCommonService.overrideFilteredData(
              AllEditedTableDataRows,
              filterData,
              "UniqueID"
            );
          }
          if (sortData?.length) {
            onSort(sortData[0], sortData[1], updatedFilterDataWithEditedRows);
          } else {
            setFilters(_.cloneDeep(updatedFilterDataWithEditedRows));
            selectedColumnList.current = updatedFilterDataWithEditedRows;
          }
        } else if (SavedFieldRefsParsed?.length) {
          // const filterData = AcpService.filterDataByfilters(
          //   updatedData,
          //   SavedFieldRefsParsed,
          //   setConditionMatched,
          //   tabName
          // );
          // setFilters(filterData);
          // let _selectedFieldsRefCurrentBooking = {};
          // for (const ele of SavedFieldRefsParsed) {
          //   const values = Object.entries(ele)[0];
          //   const key = values[0];
          //   const value = values[1];
          //   _selectedFieldsRefCurrentBooking[key] = value;
          // }
          // selectedColumnList.current = filterData;
          // setSelectedFields(_selectedFieldsRefCurrentBooking);
          // selectedFieldsRef.current = _selectedFieldsRefCurrentBooking;
        }
      }
    }
  };

  const handleToggleExpand = (data, rowGroupdId) => {
    let _localRowGroups = [...localRowGroups];
    _localRowGroups.forEach((el) => {
      if (el.id === rowGroupdId) {
        el.expanded = !AcpService.isUBN(el.expanded) ? !el.expanded : true;
      }
    });
    setLocalRowGroups([..._localRowGroups]);
    let flag = false;
    let _local = [];
    if (expandedRows?.length === 0) {
      setExpandedRows([data]);
    }
    expandedRows?.forEach((el) => {
      if (el.Group === data.Group) {
        _local = expandedRows.filter((el) => el.Group !== data.Group);
        flag = true;
      }
    });
    if (flag) setExpandedRows(_local);
    else setExpandedRows([...expandedRows, data]);

    setLocalPreviousRowGroups(_.cloneDeep(_localRowGroups));
  };

  const headerTemplate = (data) => {
    if (!data) return false;
    const expanded = expandedRows?.length;
    let length = 0;
    const selectAllColumnLength = 1;
    const freezedColumns = customizeViewFields
      ? JSON.parse(customizeViewFields)?.freezedColumns?.fieldsData
      : [];
    const selectedColumns = customizeViewFields
      ? JSON.parse(customizeViewFields)?.selectedFields?.fieldsData
      : [];
    if (freezedColumns?.length) {
      length = (
        selectedColumns?.length +
        selectAllColumnLength +
        freezedColumns?.length
      ).toString();
    } else {
      const isSelectetAllColumnExists = selectedColumns?.includes("SelectAll");
      if (isSelectetAllColumnExists) {
        length = selectedColumns?.length ? (selectedColumns?.length).toString() : "15";
      } else {
        length = selectedColumns?.length
          ? (selectedColumns?.length + selectAllColumnLength).toString()
          : "15";
      }
    }
    document
      .querySelectorAll(`tr > td[colspan="${length}"`)
      .forEach((td) => td.setAttribute("colspan", length));

    const row = document.querySelectorAll("tr.p-rowgroup-header");
    if (row) {
      const finalLength = length - 3;
      for (let i = 0; i < row?.length; i++) {
        let td1 = document.createElement("td");
        td1.setAttribute("style", "border:0");
        td1.setAttribute("id", `row_${i}`);
        td1.setAttribute("colspan", finalLength);
        //need to check with sandeep why it is been added
        //row[i].append(td1);
      }
      // for (let i = 0; i < row?.length; i++) {
      //   window.$(`tr > td[id="row_${i}"]`).not(":first").remove();
      // }
      for (let i = 0; i < row?.length; i++) {
        let cells = document.querySelectorAll(`tr > td[id="row_${i}"]`);
        cells?.forEach((cell, index) => {
          if (index !== 0) {
            cell.remove();
          }
        });
      }
    }
    let rowgrp = rowGroups?.filter((obj) => obj.id === data.Group);
    const iconClassName = `p-treetable-toggler-icon pi pi-fw ${
      localRowGroups[rowgrp[0]?.order - 1]?.expanded ? "pi-angle-up" : "pi-angle-down"
    }`;
    const GroupedData = (filters?.length ? filters : tableData).filter(
      (el) => el.GroupOrder === rowgrp?.[0]?.order
    );
    const position = Object.values(groupWiseData).filter((el) => el.key === data.Group)?.[0]
      ?.refinedOrder;

    return (
      <React.Fragment>
        <div className="group-wrapper">
          <div
            onClick={() => handleToggleExpand(data, rowgrp?.[0]?.id)}
            className={`group-header vertical-align-middle ml-2 font-bold line-height-3 ${rowgrp?.[0]?.name} top-${position}`}
          >
            <span>
              <span>{rowgrp?.[0]?.name}</span>
              <span className="group-length">({GroupedData?.length})</span>
            </span>
            <span className="p-treetable-toggler p-link">
              <span className={iconClassName} aria-hidden="true"></span>
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
      const frozenCoulmns1 = frozenCoulmns.filter((item) => item !== "SelectAll");
      if (!frozenCoulmns1.includes("SelectAll")) {
        frozenCoulmns1.push("SelectAll");
      }
      updatedFrozenColumn(frozenCoulmns1);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = JSON.parse(
        customizeViewFields
      )?.selectedFields?.fieldsData?.filter((el) => el !== "SelectAll");
      updatedFrozenColumn(freezedColumnsNamesData);
      let selectedFieldsNamesData = [...selectedFieldsNames, name];
      updateCustomizeViewFields(convertObjToNormalFormat(InitAvailableColumns), selectedFieldsNamesData, freezedColumnsNamesData);
    }
  };

  const handleDateChange = (options, field, value, isSlack, isEqualData) => {
    if (!options.tempStartDate) {
      options.tempStartDate = options?.StartDate;
    }
    const startDate =
      !AcpService.isUBN(options?.tempStartDate) &&
      !AcpService.checkIfValidDate(options?.tempStartDate)
        ? AcpService.convertToShortDateFormat(options?.tempStartDate, "DD-MMM-YYYY")
        : AcpService.convertToShortDateFormat(new Date(options?.tempStartDate), "DD-MMM-YYYY");
    const endDate = AcpService.convertToShortDateFormat(value, "DD-MMM-YYYY");
    const newStartDateGMT = AcpService.convertToShortDateFormat(value);
    options[field] = newStartDateGMT;
    const dateDifference = AcpService.calculateDateDifference(startDate, endDate);
    const equalIdData = InitialTableData.filter(el=>el.UniqueID === options?.UniqueID);
    const isEqualStartDate = equalIdData?.[0]?.StartDate === options?.StartDate;
    if (!options.existGroupOrder && options.existGroupOrder !== 0 && !isEqualStartDate) {
      const newSlack = parseInt(options.Slack, 10);
      options.existGroupOrder = newSlack;
      const SlackResult = parseInt(options.existGroupOrder, 10) + dateDifference;
      options.Slack = String(SlackResult).padStart(2, "0");
    } else {
      options.existGroupOrder = 0; //changed for 3718
      const SlackResult = parseInt(options.existGroupOrder, 10) + dateDifference;
      options.Slack = !isNaN(SlackResult) ? String(SlackResult).padStart(2, "0") : "00";
    }

    if (value != null && !isEqualStartDate) {
      // options.isSelected = true;
      options.edited = "edited";
    } else {
      options.edited = "";
      // options.isSelected = false;
      options.StartDate = options?.StartDate;
      const allEditedRowsUpdated = allEditedRows?.filter(
        (el) => el?.UniqueID !== options?.UniqueID
      );
      setAllEditedTableDataRows(allEditedRowsUpdated);
      setAllEditedRows(allEditedRowsUpdated);
      setDateChangeValue(allEditedRowsUpdated);
      // if isEqualData=== true, that means, so we are not updating anything,
      // and in that case setEditedRow will be set as setEditedRow({});,
      // and not required to setEditedRow(_options);
      // so it will be reurned because nothing is being changed.
      if (allEditedRowsUpdated?.length === 0) {
        setEditedRow({});
      }
      return;
    }

    let _options = { ...options };

    setEditedRow(_options);
    setIsEqualRow(isEqualStartDate);
    setLastEditedUniqueId(_options?.UniqueID);
    setActionType("inlineUpdate");
  };
  const getMinDate = (options) => {
    const minDate = AcpService.getMinDate(options);
    return minDate;
  };

  const fullKitReadinessBody = (options, rowData) => {
    let field = rowData.field;
    return (
      <>
        {field === "Work" && (
          <>
            {AcpService.isNegative(options[field]) ? (
              <span className="text-danger">{options[field]}</span>
            ) : (
              <span>{options[field]}</span>
            )}
          </>
        )}

        {field === "Buffer" && (
          <>
            {AcpService.isNegative(options[field]) ? (
              <span className="text-danger">{options[field]}</span>
            ) : (
              <span>{options[field]}</span>
            )}
          </>
        )}

        {field === "SelectAll" && (
          <div className="flex align-items-center">
            <input
              type="checkbox"
              checked={
                options?.isSelected
                  ? options.isSelected
                  : selected?.some((selectedItem) => selectedItem.UniqueID === options.UniqueID)
              }
              onChange={() => handleSelect(options)}
              className="p-checkbox-box p-highlight"
              disabled={options?.RequestStatus === "Awaiting Response" || !accessSecurityMatrix}
            />
          </div>
        )}

        {field === "BufferToWork" && (
          <>
            {AcpService.isNegative(options[field]) ? (
              <span className="text-danger">{options[field]}</span>
            ) : (
              <span>{options[field]}</span>
            )}
          </>
        )}

        {field === "Owner" && options[field]}
        {field === "ProjectID" && (
          <span
            className={`task-link`}
            onClick={() => handleProjectIdClick(`${baseUrl}/${options[field]}`)}
          >
            {options[field]}
          </span>
        )}
        {field === "EndDate" && tabName === "bveLoop" && (
          <>
            {options?.RequestStatus === "Awaiting Response" ? (
              <div className="start-date-awaiting">
                {AcpService.formatTableDate(options["UpdatedEndDate"]).toString()}
                <Tooltip target=".infoIcon" mouseTrack mouseTrackLeft={10} />
                <img
                  className="infoIcon"
                  data-pr-tooltip="Request sent to Enovia, awaiting response"
                  src={acpInfo}
                  alt="info"
                />
              </div>
            ) : options?.RequestStatus === "FAIL" ? (
              <>
                <Calendar
                  showIcon={true}
                  dateFormat="d-M-y"
                  disabledDays={[0, 6]}
                  icon={<CalendarIcon />}
                  className="acp-calendar"
                  placeholder="Select End Date"
                  value={AcpService.formatDate(options[field])}
                  onChange={(e) => {
                    // options.RequestStatus = true;
                    // options[field] = convertToGMT(e.target.value);
                    handleDateChange(options, field, e.target.value);
                  }}
                  minDate={AcpService.formatDate(options?.StartDate)}
                  disabled={!accessSecurityMatrix}
                  // maxDate={AcpService.formatDate(options.PlannedRelease)}
                />
                <Tooltip target=".errorIcon" mouseTrack mouseTrackLeft={10} />
                {!options.edited && (
                  <img
                    className="errorIcon"
                    // data-pr-tooltip={options.RTAStatusMessage || "Rejected"}
                    data-pr-tooltip={`Enovia update Failed. Error Code ${options?.StatusCode}.`}
                    src={acpError}
                    alt="error"
                  />
                )}
              </>
            ) : (
              <Calendar
                showIcon={true}
                dateFormat="d-M-y"
                icon={<CalendarIcon />}
                disabledDays={[0, 6]}
                className="acp-calendar"
                placeholder="Select End Date"
                disabled={!accessSecurityMatrix}
                value={AcpService.formatDate(options[field])}
                onChange={(e) => {
                  // options[field] = convertToGMT(e.target.value);
                  handleDateChange(options, field, e.target.value);
                }}
                minDate={AcpService.formatDate(options?.StartDate)}
                // maxDate={AcpService.formatDate(options.PlannedRelease)}
              />
            )}
          </>
        )}
        {field === "StartDate" && tabName !== "bveLoop" && (
          <>
            {options?.RequestStatus === "Awaiting Response" ? (
              <div className="start-date-awaiting">
                {AcpService.formatTableDate(options["UpdatedStartDate"]).toString()}
                <Tooltip target=".infoIcon" mouseTrack mouseTrackLeft={10} />
                <img
                  className="infoIcon"
                  data-pr-tooltip="Request sent to Enovia, awaiting response"
                  src={acpInfo}
                  alt="info"
                />
              </div>
            ) : options?.RequestStatus === "FAIL" ? (
              <div className={!options.edited ? "image-info-icon" : ""}>
                <CustomInput
                  type="Date"
                  field={field}
                  showIcon={true}
                  options={options}
                  dateFormat="d-M-y"
                  sortData={sortData}
                  filterData={filters}
                  disabledDays={[0, 6]}
                  icon={<CalendarIcon />}
                  fieldType={"StartDate"}
                  className={"acp-calendar"}
                  onChange={handleDateChange}
                  minDate={getMinDate(options)}
                  placeholder="Select Start Date"
                  disabled={!accessSecurityMatrix}
                  value={
                    !AcpService.isUBN(options[field]) &&
                    !AcpService.checkIfValidDate(options[field])
                      ? AcpService.formatDate(options[field])
                      : new Date(options[field])
                  }
                />
                <Tooltip target=".errorIcon" mouseTrack mouseTrackLeft={10} />
                {!options.edited && (
                  <img
                    className="errorIcon"
                    // data-pr-tooltip={options.RTAStatusMessage || "Rejected"}
                    data-pr-tooltip={`Enovia update Failed. Error Code ${options?.StatusCode}.`}
                    src={acpError}
                    alt="error"
                  />
                )}
              </div>
            ) : (
              <>
                <CustomInput
                  type="Date"
                  field={field}
                  showIcon={true}
                  slackValue={true}
                  options={options}
                  dateFormat="d-M-y"
                  sortData={sortData}
                  filterData={filters}
                  disabledDays={[0, 6]}
                  icon={<CalendarIcon />}
                  fieldType={"StartDate"}
                  className={"acp-calendar"}
                  onChange={handleDateChange}
                  minDate={getMinDate(options)}
                  placeholder="Select Start Date"
                  disabled={!accessSecurityMatrix}
                  value={
                    !AcpService.isUBN(options[field]) &&
                    !AcpService.checkIfValidDate(options[field])
                      ? AcpService.formatDate(options[field])
                      : !AcpService.isUBN(options[field])
                      ? new Date(options[field])
                      : ""
                  }
                />
              </>
            )}
          </>
        )}

        {field === "BVETaskEndDate" && changeDateFormat(options[field])}
        {/* {field === "ArtPrinterDate" && changeDateFormat(options[field])} */}
        {field === "TaskDuration" && AcpService.formatDuration(options[field])}
        {field === "PlannedRelease" && AcpService.formatTableDate(options[field])}
        {/* {field === "EndDate" && tabName !== "bveLoop" && changeDateFormat(options[field])} */}
        {field === "EndDate" && tabName !== "bveLoop" && <>{options[field]}</>}

        {field === "ArtPrinterDate" && tabName !== "bveLoop" && <>{options[field]}</>}
        {field === "StartDate" && tabName === "bveLoop" && changeDateFormat(options[field])}

        {field !== "ArtPrinterDate" &&
          field !== "BVETaskEndDate" &&
          field !== "PlannedRelease" &&
          field !== "BufferToWork" &&
          field !== "TaskDuration" &&
          field !== "StartDate" &&
          field !== "ProjectID" &&
          field !== "SelectAll" &&
          field !== "EndDate" &&
          field !== "Buffer" &&
          field !== "Owner" &&
          field !== "Work" &&
          options[field]}
      </>
    );
  };

  const handleProjectIdClick = (url) => {
    if (bookingPageEdited) {
      setShowDialog(true);
      setTempUrl(url);
    } else {
      navigate(url);
    }
  };

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  };
  // by doing setShowDialog(false) and will call handleUserNotifications ;
  const confirmNavigation = () => {
    setBookingPageEdited(false);
    navigate(tempUrl);
    setTempUrl("");
    markClean();
    setShowDialog(false);
  };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    let _options = options;
    setSelectedColumnName(_options);
  };

  const onSort = (column, direction, filterData, updateTblData, sortUpdateFalse) => {
    setSearchInfo("");
    const newArray = [];
    const sortedData = AcpService.groupBy(
      filterData?.length ? filterData : filters?.length ? filters : tableData,
      "Group"
    );
    for (const [key, value] of Object.entries(sortedData)) {
      const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(value, column, direction);
      newArray.push(sortedDataSet);
    }
    let updatedData = newArray?.flat();

    if (AllEditedTableDataRows?.length) {
      updatedData = AcpCommonService.overrideFilteredData(
        AllEditedTableDataRows,
        updatedData,
        "UniqueID"
      );
    }
    //3182
    const updatedSortedData = updatedData?.map((el) => {
      return {
        ...el,
        StartDate: AcpCommonService.convertFromGMTtoDDMMMYYDate(el?.StartDate),
      };
    });
    if (!sortUpdateFalse) {
      setSortData([column, direction]);
    }
    localStorage.setItem("sortedColumnBooking", JSON.stringify(true));
    // due to 3076
    if (filters?.length || filterData?.length) {
      if (updateTblData) {
        setTableData(_.cloneDeep(updatedSortedData));
        setChartData(updatedData);
        selectedColumnList.current = updatedSortedData;
      } else {
        setFilters(updatedSortedData);
      }
      //3182
    } else {
      setTableData(updatedSortedData);
      selectedColumnList.current = updatedSortedData;
      //3182
      // updatedData = updatedData?.filter((el) => el.Group !== "Backlog");
      setChartData(updatedData);
    }
    localStorage.setItem("bookingSortingData", JSON.stringify(sortData));
  };

  const saveSettings = () => {
    if (frozenCoulmns?.includes(selectedColumnName)) {
      const availableColumnNamesData = JSON.parse(customizeViewFields)?.availableFields?.fieldsData;
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = JSON.parse(customizeViewFields)
        ?.selectedFields?.fieldsData?.filter((el) => el !== selectedColumnName)
        ?.filter((el) => el !== "SelectAll");
      let selectedFieldsNamesData = selectedFieldsNames?.filter(function (objFromA) {
        return !frozenCoulmns?.find(function (objFromB) {
          return objFromA === objFromB;
        });
      });

      const selectedData = allColumnNames
        ?.map((el) => el.Field_Name)
        ?.filter((el) => el !== selectedColumnName)
        ?.filter((el) => el !== "SelectAll")
        ?.filter(function (objFromA) {
          return !frozenCoulmns?.find(function (objFromB) {
            return objFromA === objFromB;
          });
        });
      updatedFrozenColumn(freezedColumnsNamesData);
      setAvailableColumnNames(availableColumnNames);
      const _availableColumnNames = availableColumnNames?.map((el) => el.Field_Name) || availableColumnNamesData || [];
      updateCustomizeViewFields(_availableColumnNames, (selectedData || selectedFieldsNamesData), freezedColumnsNamesData)
    }
  };

  const clearColumnWiseFilter = () => {
    setSearchInfo("");
    let _selectedColumnName = selectedColumnName;
    const selectedFields2 = _.cloneDeep(selectedFields);
    const _selectedFields = AcpService.emptyProperty(selectedFields2, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    //3182
    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortedColumnBooking");
      localStorage.removeItem("bookingSortingData");
      setSortData([]);
      if (Object.keys(__selectedFields)?.length === 0) {
        setClearAllFilter(true);
      }
    }
    //3078
    let data = [];
    const finalInitialData = _.cloneDeep(InitialTableData);
    if (Object.keys(__selectedFields)?.length) {
      data = [...(finalInitialData || [])];
      Object.keys(__selectedFields).forEach((el) => {
        if (__selectedFields[el]?.length) {
          data = data.filter((ele) => __selectedFields[el].includes(ele[el]));
        }
      });
    }
    if (data?.length && Object.keys(__selectedFields)?.length) {
      if (AllEditedTableDataRows?.length) {
        data = AcpCommonService.overrideFilteredData(AllEditedTableDataRows, data, "UniqueID");
      }
      // updating sort logic for sortData?.[0] !== _selectedColumnName
      const customColumn =
        sortData?.length && sortData?.[0] !== _selectedColumnName ? sortData?.[0] : "StartDate";
      const direction =
        sortData?.length && sortData?.[0] !== _selectedColumnName ? sortData?.[1] : "asc";
      // if there is no sort then default sort  will be StartDate asc,
      // but if there sort then it will go as per sort
      if (filters?.length) {
        // if there is sort and filter both applied on seperate columns,
        // then on clearing filtercolumn wise, we need data should be sorted but
        // with StartDate as it remains on initial page load
        data = AcpService.sortDataOnDemand(data, customColumn, direction);
      }
      setFilters(data);
    } else if (sortData?.length && sortData[0] !== _selectedColumnName) {
      const updatedTblDataWithEditedRows = AcpCommonService.overrideFilteredData(
        AllEditedTableDataRows,
        tableData,
        "UniqueID"
      );
      let finalData = AllEditedTableDataRows?.length ? updatedTblDataWithEditedRows : tableData;
      // here channging the logic as that data should be sorted based on Sort
      finalData = AcpService.sortDataOnDemand(finalData, sortData?.[0], sortData?.[1]);
      setTableData(finalData);
      setChartData(finalData);
      selectedColumnList.current = finalData;
      setFilters([]);
      //3182
    } else {
      const updatedTblDataWithEditedRows = AcpCommonService.overrideFilteredData(
        AllEditedTableDataRows,
        finalInitialData,
        "UniqueID"
      );
      setTableData(
        AllEditedTableDataRows?.length ? updatedTblDataWithEditedRows : finalInitialData
      );
      setChartData(
        AllEditedTableDataRows?.length ? updatedTblDataWithEditedRows : finalInitialData
      );
      selectedColumnList.current = updatedTblDataWithEditedRows;
      setFilters([]);
    }
    setConditionMatched(false);
    //3182
    setSelectedFields(__selectedFields);
    selectedFieldsRef.current = __selectedFields;
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentBooking");
    }
  };
  const storeReorderedColumns = (e) => {
    const projectColumnName = storeACPReorderedColumns(
      e,
      "columnWidthbookingColumn",
      allColumnNames
    );
    setAllColumnNames(projectColumnName);
    let _selectedColumnNames = projectColumnName.map(el=> el?.Field_Name);
    let _availableColumnNames = availableColumnNames.map(el=> el?.Field_Name);
    _selectedColumnNames = _selectedColumnNames.filter(function (objFromA) {
      return !frozenCoulmns?.find(function (objFromB) {
        return objFromA === objFromB;
      });
    });
    updateCustomizeViewFields(_availableColumnNames, _selectedColumnNames, frozenCoulmns )
  };

  const onColumnResizeEnd = (event) => {
    const _allColumns = onACPColumnResizeEnd(event, "columnWidthbookingColumn", allColumnNames);
    localWidthStorage(event, "columnWidthBooking");
    setAllColumnNames(_allColumns);
  };
  const dynamicColumns = () => {
    const localColumnWidth = localStorage.getItem("columnWidthBooking");
    let columnWidth = {};
    if (localColumnWidth) {
      columnWidth = JSON.parse(localColumnWidth);
    }
    const columnWidthKeys = Object.keys(columnWidth);
    const tblData = filters?.length ? filters : tableData;
    const allSelected = selectAllChecked || tblData?.length === selected?.length;

    return allColumnNames?.map((col, i) => {
      const columnWidthValue =
        columnWidthKeys.includes(col?.Field_Name) && columnWidth?.[col?.Field_Name];

      // Check if column is frozen and adjust accordingly
      const isFrozenColumn = col?.freeze;
      const columnClass = isFrozenColumn ? "font-bold" : "";

      // Set the header component props for ACPBookingHeaderColumn
      const headerProps = {
        op,
        col: col?.Field_Name,
        onSort,
        filters,
        sortData,
        tableData,
        setFilters,
        allSelected,
        tableRender,
        setSortData,
        saveSettings,
        ProjectFrozen,
        accessSecurityMatrix,
        frozenCoulmns, // Note: Freeze handling should be customized as per the requirement
        allColumnNames,
        selectedFields,
        setTableRender,
        handleSelectAll,
        setFrozenColumn,
        resetMultiFilter,
        setProjectFrozen,
        addFrozenColumns,
        selectAllChecked,
        projectColumnName,
        isReorderedColumn,
        projectNameOnClick,
        setReorderedColumn,
        selectedColumnName,
        availableColumnNames,
        onGlobalFilterChange,
        setProjectColumnNames,
        setSelectedColumnName,
        clearColumnWiseFilter,
        acpBookingFrozenColumn,
      };

      return (
        <Column
          filter
          key={col?.Field_Name}
          field={col?.Field_Name}
          filterField={col?.Field_Name}
          alignFrozen="left"
          columnKey={col?.Field_Name || i}
          showFilterMenu={false}
          filterPlaceholder={col?.Field_Name}
          filterMatchMode="contains"
          body={fullKitReadinessBody}
          frozen={isFrozenColumn}
          className={columnClass}
          header={
            <>
              <ACPBookingHeaderColumn {...headerProps} />
              {isSearch && col?.Field_Name !== "SelectAll" && (
                <div className="filter-wrapper">
                  <InputText
                    value={searchHeader?.[col?.Field_Name]?.value}
                    onChange={(e) => onGlobalFilterChangeSearch(e, col?.Field_Name)} // Trigger global filter change
                    placeholder={`Search ${col?.Field_Name?.replace(/_/g, " ")}`} // Replace underscores with spaces in placeholder
                    className="p-inputtext p-component p-column-filter"
                  />
                  <div className="clear-search-wrapper">
                    {searchHeader?.[col?.Field_Name]?.value && (
                      <button
                        className="p-column-filter-clear-button p-link clear-search-btn"
                        type="button"
                        aria-label="Clear"
                        onClick={() => clearFilterIndividual(col?.Field_Name)}
                      >
                        <span className="pi pi-filter-slash" aria-hidden="true"></span>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </>
          }
          style={{
            width: isFrozenColumn
              ? col?.Field_Name === "SelectAll"
                ? "50px"
                : col?.Field_Name === "Project Name"
                ? "200px"
                : columnWidthValue
              : columnWidthValue,
            textOverflow: "ellipsis",
            maxWidth: columnWidthValue,
            minWidth: "100px",
          }}
        />
      );
    });
  };

  const onGlobalFilterChange = (e, colName) => {
    setSearchInfo("");
    const { value, multiplesFlag, artworkCategories, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      tableData,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    // putting null array check, when there is all filter cleared
    const _selectedFieldsRef = (selectedFieldsRef?.current || []).map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentBooking");
    }
    if (filterArray.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        let filterData = AcpService.filterDataByfilters(
          tableData,
          _selectedFieldsRefWithUniqueObjects,
          setConditionMatched,
          tabName
        );
        if (filterData?.length && sortData?.length) {
          onSort(sortData[0], sortData[1], filterData);
        } else {
          if (AllEditedTableDataRows?.length) {
            filterData = AcpCommonService.overrideFilteredData(
              AllEditedTableDataRows,
              filterData,
              "UniqueID"
            );
          }
          setFilters(filterData);
          selectedColumnList.current = filterData;
        }
      } else {
        selectedColumnList.current = [];
        setSelectedFields({});
        setFilters([]);
      }
    } else {
      selectedColumnList.current = [];
      setSelectedFields({});
      setFilters([]);
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        clear() {
          clearFilters();
        },
      };
    },
    []
  );

  const clearFilters = () => {
    setFilters([]);
    isSearchSet(false);
    setSelectedFields({});
    setSearchHeader({});
  };

  // on change of clearAllFilter flag clearAllFilters should call and reset the states
  useEffect(() => {
    if (clearAllFilter) {
      clearAppliedFilters();
      filterTable();
    }
  }, [clearAllFilter]);
  // Clear all applied filters and reset states
  const clearAppliedFilters = () => {
    setFilters([]);
    setSelectedFields({});
    setClearAllFilter(false);
    setSelectAllChecked(false);
    setConditionMatched(false);
    selectedFieldsRef.current = {};
    localStorage.removeItem("_selectedFieldsRefCurrentBooking");
  };

  const onGlobalFilterChangeSearch = (e, colName) => {
    const value = e.target.value;
    setSearchInfo(value);
    const searchTerms = value?.split(" ")?.filter(Boolean);
    // Split by spaces and remove empty strings
    setSearchHeader((prevFilters) => {
      return {
        ...prevFilters,
        [colName]: {
          value, // Set the value of the specific column filter
          matchMode: FilterMatchMode.CONTAINS, // Ensure match mode is set
        },
      };
    });
  };

  const clearFilterIndividual = (column) => {
    // copy of the current filters state
    const updatedFilters = { ...searchHeader };
    // Clear filter value for specific column
    if (updatedFilters[column]) {
      updatedFilters[column].value = "";
      setSearchHeader(updatedFilters);
      setSearchInfo("");
    }
  };

  useEffect(() => {
    // awm-3674, this line was already there in below place, but this also required in else part, so moved up updatedValues
    const updatedValues = removeObjectsWithNullStartDate(allEditedRows);
    if ((filters?.length || tableData?.length) && !selectedRows?.length) {
      setSelectedColumnName("SelectAll");
      //check the array if StartDate null skip that value ,Awm-3217
      if (!IsEqualRow && allEditedRows?.length) {
        setDateChangeValue(updatedValues);
      } else {
        if (LastEditedUniqueId && allEditedRows?.length) {
          const updatedRows = allEditedRows.filter((item) => item.UniqueID !== LastEditedUniqueId);
          setDateChangeValue([...updatedRows]);
          // setAllEditedRows([...updatedRows]);
          setLastEditedUniqueId("");
          setIsEqualRow(false);
        }
      }
    } else {
      setDateChangeValue(updatedValues);
    }
    // we need to update setDateChangeValue as blank once allEditedRows?.length === 0
    if (allEditedRows?.length === 0) {
      setDateChangeValue([]);
    }

    const localChart = [...chartData];
    localChart.forEach((_chart) => {
      tableData.forEach((_editedRow) => {
        if (_chart?.["UniqueID"] === _editedRow?.["UniqueID"]) {
          _chart["StartDate"] = _editedRow["StartDate"];
        }
      });
    });

    setChartData(localChart);
  }, [selectedRows, allEditedRows]);

  const convertObjToNormalFormat = (columns) =>{
    return columns?.map(el=>el?.Field_Name)
  }

  
  const resetToPgDefault = () =>{
    const updatedAvailableColumns = convertObjToNormalFormat(InitAvailableColumns);
    updateCustomizeViewFields(updatedAvailableColumns, bookingColumnNames, ["SelectAll"]);
    const finalCols = updateInObjectFormat(bookingColumnNames, ["SelectAll"]);
    setAvailableColumnNames(InitAvailableColumns);
    setFrozenColumn(["SelectAll"]);
    setAllColumnNames(finalCols);
  }


  const handleCustomizeViewFields = (value) =>{
    setLoader(true);
    setTimeout(() => {
      headerTemplate(false);
      setLoader(false);
    }, 1000);
    setTableRender(true);
    if(value === null){
      // setInitialValues of customizeView
      const updatedAvailableColumns = convertObjToNormalFormat(InitAvailableColumns);
      updateCustomizeViewFields(updatedAvailableColumns, bookingColumnNames, ["SelectAll"]);
    }else{
      setCustomizeViewFields(value);
      const updatedHeaders = JSON?.parse(value)?.selectedFields?.fieldsData;
      const latestHeaders = updatedHeaders?.filter((item) => item !== "SelectAll");
      let frozenData = JSON?.parse(value)?.freezedColumns?.fieldsData;
      if (frozenData?.includes("SelectAll")) {
        frozenData = frozenData.filter(el=> el !== "SelectAll");
      }
      if (!frozenData?.includes("SelectAll")) {
        frozenData?.unshift("SelectAll");
      }
      updatedFrozenColumn(frozenData);
      const finalColumn = [...(frozenData || []), ...(latestHeaders || [])];
      setProjectFrozen(!ProjectFrozen);
      projectColumnName &&
        acpBookingFrozenColumn(
          frozenData,
          setReorderedColumn,
          setProjectColumnNames,
          setTableRender,
          op,
          finalColumn,
          "booking"
        );
      setProjectColumnNames(finalColumn);
      const items = _.cloneDeep(finalColumn);
      const finalCols = updateInObjectFormat(items, frozenData);
      let availableFieldsToSelectInObjFormat = updateInObjectFormat(JSON?.parse(value)?.availableFields?.fieldsData, frozenData)
      setAvailableColumnNames(availableFieldsToSelectInObjFormat);
      setAllColumnNames(finalCols);
      const totalColumns = [...(latestHeaders || []), ...(frozenData || [])];
      if (totalColumns.length < 8) {
        setResizeMode("fit");
      } else {
        setResizeMode("expand");
      }
      setResetKey((prev) => prev + 1);
    }
  }

  return (
    <>
      <LoadingOverlay active={loader} spinner text="">
        {ShowCustomizedView && (
          <CustomizeView
            headerName={"ACPBooking"}
            allColumns={allColumnNames}
            setFrozenColumn={setFrozenColumn}
            showTaskDialog={ShowCustomizedView}
            availableFields={availableColumnNames}
            customizeViewFields={customizeViewFields}
            setCustomizeViewFields={handleCustomizeViewFields}
            onClose={() => setShowCustomizedView(!ShowCustomizedView)}
            BU={bu}
            region={region}
            frozenColumnFields={frozenCoulmns}
            pgDefaultColumns={pgDefaultColumns}
            resetToPgDefault={resetToPgDefault}
          />
        )}

        <div className="acp-booking-table data-table-checkbox-column">
          {projectColumnName?.length < 2 ? null : (
            <DataTable
              ref={dt}
              scrollable
              rows={100}
              stripedRows
              showGridlines
              size={"small"}
              resizableColumns
              reorderableColumns
              groupRowsBy="Group"
              expandableRowGroups
              //accordion by group
              dataKey="Project_ID"
              // value={tableData}
              selection={selectedRows}
              rowGroupMode="subheader"
              filters={searchHeader}
              responsiveLayout="scroll"
              columnResizeMode="expand"
              // rowClassName={rowClass}
              className="mt-3 acpBooking"
              expandedRows={expandedRows}
              // selectionMode="checkbox"
              onSelectionChange={(e) => {
                setSelectedRows(e?.value);
              }}
              paginatorPosition="both"
              // onRowToggle={(e) => {
              //   setExpandedRows(e.data);
              // }}
              // filterDisplay={isSearch && "row"}
              onColReorder={storeReorderedColumns}
              rowGroupHeaderTemplate={headerTemplate}
              // key={generateUniqueKey("Project_ID")}
              onColumnResizeEnd={onColumnResizeEnd}
              onResize={(e) => console.log("resize", e)}
              onResizeCapture={(e) => console.log("e", e)}
              // onValueChange={(obj) => {}}
              key={tableRender ? `"Project_ID" + timestamp+ ${resetKey}` : `${resetKey}`}
              tableStyle={{ width: "max-content", minWidth: "100%" }}
              emptyMessage={loader ? " " : "No records found"}
              paginator={tableData?.length || filters?.length ? true : false}
              value={conditionMatched ? [] : filters?.length ? filters : tableData}
              rowClassName={(rowData) =>
                rowData.isSelected || rowData.edited === "edited" ? "highlight-row" : "normal-row"
              }
            >
              {dynamicColumns()}
            </DataTable>
          )}
        </div>
      </LoadingOverlay>
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </>
  );
}

export default forwardRef(AcpBookingDatatable);
