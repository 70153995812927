import {
  mutliDragAwareReorder,
  multiSelectTo as multiSelect,
  replaceSpacesWithUnderscoreInFieldName,
} from "./Utils";
import { DragDropContext } from "@hello-pangea/dnd";
import { dependancyMappingFields } from "../DragAndDrop/Utils";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import entities1 from "./Data";
import Column from "./Column";
import { replaceSpaceWithUnderscore } from "./Utils";
import _, { isEmpty } from "lodash";
import {
  deepCompare,
  reorderSecondArraySameAsFirstArray,
  reorderArray,
  compareObjects,
} from "../../../../utils";
import { setColumnsOrder } from "../../../../apis/commonApi";

const getTasks = (entities, columnId) => {
  return entities?.columns?.[columnId]?.fieldsData?.map((taskId) => entities?.tasks?.[taskId]);
};

const DragAndDrop = (props) => {
  const { bu, region } = useSelector((state) => state.myProject);

  const setDependencyMappingColumnNames = localStorage.getItem("setDependencyMappingColumnNames");
  const parsedSetDependencyMappingColumnNames =
    setDependencyMappingColumnNames && JSON.parse(setDependencyMappingColumnNames);

  const pgDefaultDependencyMappingColumnNames = localStorage.getItem(
    "pgDefaultDependencyMappingColumnNames"
  );
  const parsedPgDefaultDependencyMappingColumnNames =
    pgDefaultDependencyMappingColumnNames && JSON.parse(pgDefaultDependencyMappingColumnNames);

  const customizeViewDependancyFields = localStorage.getItem("customizeViewDependancyFields");
  const parsedCustomizeViewDependancyFields =
    customizeViewDependancyFields && JSON.parse(customizeViewDependancyFields);

  let dependencyColumnNames1 = parsedSetDependencyMappingColumnNames;
  const { selectedProject } = useSelector((state) => state.ProjectSetupReducer);
  const User = useSelector((state) => state?.UserReducer);
  const userInformation = User.userInformation;
  const userId = userInformation?.userid;
  const header = props?.headerName === "Dependency Mapping" ? true : false;
  const [localDestination, setLocalDestination] = useState(null);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [draggingTaskId, setDraggingTaskId] = useState(null);
  const [disable, setDisable] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const [dragInitiated, setDragInitiated] = useState(false);

  const CICs = selectedProject?.CICs;
  const dependencyColumnNames = dependancyMappingFields(
    dependencyColumnNames1,
    props.CDPTPageData,
    props.RDTData,
    props.IQData,
    CICs,
    props?.headerName
  );

  let _availableFields = props?.availableFields || [];
  let _selectedFields = props?.selectedFields || [];

  _availableFields = replaceSpacesWithUnderscoreInFieldName(_availableFields);
  _selectedFields = replaceSpacesWithUnderscoreInFieldName(_selectedFields);
  // reverting my changes
  const [entities, setEntities] = useState(
    entities1(
      dependencyColumnNames && header
        ? replaceSpacesWithUnderscoreInFieldName(dependencyColumnNames)
        : props?.selectedFields !== null
        ? _selectedFields
        : [],
      props?.headerName,
      (props?.headerName === "Artwork Scope Alignment" || props?.headerName === "ACPBooking") &&
        (props?.availableFields !== null ? _availableFields : [])
    )
  );
  // reverting my changes

  useEffect(() => {
    if (
      props?.customizeViewFields &&
      props?.customizeViewFields !== "[]" &&
      props?.customizeViewFields !== null &&
      props?.customizeViewFields?.length !== 0 &&
      !isEmpty(props?.customizeViewFields)
    ) {
      const data =
        props?.customizeViewFields?.availableFields?.fieldsData?.length > 0 ||
        props?.customizeViewFields?.selectedFields?.fieldsData?.length > 0 ||
        props?.customizeViewFields?.freezedColumns?.fieldsData?.length > 0
          ? props?.customizeViewFields
          : JSON.parse(props?.customizeViewFields);
      let updatedData = {
        ...entities,
        columns: data,
      };

      let _availableFields = updatedData?.columns?.availableFields?.fieldsData || [];
      let _selectedFields = updatedData?.columns?.selectedFields?.fieldsData || [];
      // reverted my changes
      if (_selectedFields?.length > 0) {
        _selectedFields = _selectedFields?.map((el) => {
          el = el?.replace(/ /g, "_");
          return el;
        });
      }
      if (_availableFields?.length > 0) {
        _availableFields = _availableFields?.map((el) => {
          el = el?.replace(/ /g, "_");
          return el;
        });
      }
      updatedData.columns.availableFields.fieldsData = _availableFields;
      updatedData.columns.selectedFields.fieldsData = _selectedFields;

      if (updatedData !== undefined) {
        setEntities(updatedData);
        // reverting my changes
      }
    } else {
      // 3283 : replacing spaces with underscore in the object
      let selected =
        props?.selectedFields && props?.selectedFields?.length ? [...props?.selectedFields] : [];
      let available =
        props?.availableFields && props?.availableFields?.length ? [...props?.availableFields] : [];
      selected = replaceSpacesWithUnderscoreInFieldName(selected);
      available = replaceSpacesWithUnderscoreInFieldName(available);
      setEntities(
        entities1(
          header
            ? Array.isArray(dependencyColumnNames) &&
                dependencyColumnNames?.length &&
                replaceSpacesWithUnderscoreInFieldName(dependencyColumnNames)
            : selected,
          props?.headerName,
          (props?.headerName === "Artwork Scope Alignment" || props?.headerName === "ACPBooking") &&
            available
        )
      );
    }
  }, [props.customizeViewFields]);

  useEffect(() => {
    const isDifferent = compareObjects(props?.pgDefaultColumns, entities?.columns);
    setDisable(!isDifferent);
  }, [entities, props?.pgDefaultColumns]);
  useEffect(() => {
    const onWindowClick = (event) => {
      try {
        if (!event.defaultPrevented) {
          unselectAll();
        }
      } catch (error) {
        console.log("something went wrong", error);
      }
    };

    const onWindowKeyDown = (event) => {
      try {
        if (!event.defaultPrevented && event.key === "Escape") {
          unselectAll();
        }
      } catch (error) {
        console.log("something went wrong", error);
      }
    };

    const onWindowTouchEnd = (event) => {
      try {
        if (!event.defaultPrevented) {
          unselectAll();
        }
      } catch (error) {
        console.log("something went wrong", error);
      }
    };

    window.addEventListener("click", onWindowClick);
    window.addEventListener("keydown", onWindowKeyDown);
    window.addEventListener("touchend", onWindowTouchEnd);

    return () => {
      window.removeEventListener("click", onWindowClick);
      window.removeEventListener("keydown", onWindowKeyDown);
      window.removeEventListener("touchend", onWindowTouchEnd);
    };
  }, []);

  const onDragStart = (start) => {
    try {
      const id = start.draggableId;
      const selected = selectedTaskIds?.find((taskId) => taskId === id);
      if (!selected) {
        unselectAll();
      }
      const updatedId = replaceSpaceWithUnderscore(id);
      setDraggingTaskId(updatedId);
    } catch (error) {
      console.log("something went wrong", error);
    }
  };

  const onDragEnd = (result) => {
    try {
      const destination = result.destination;
      const source = result.source;
      const draggableId = result.draggableId;

      if (!destination || result.reason === "CANCEL") {
        setDraggingTaskId(null);
        setLocalDestination(null);
        return;
      }

      const processed = mutliDragAwareReorder({
        draggableId,
        entities,
        selectedTaskIds,
        source,
        destination,
      });
      processed !== undefined && setEntities(processed?.entities);
      setDraggingTaskId(null);
      setLocalDestination(destination);
      setDisable(false);
      setDisableSave(false);
      setDragInitiated(true);
    } catch (error) {
      console.log("something went wrong", error);
    }
  };

  // const onWindowKeyDown = (event) => {
  //   if (event.defaultPrevented) {
  //     return;
  //   }
  //   if (event.key === "Escape") {
  //     unselectAll();
  //   }
  // };

  // const onWindowClick = (event) => {
  //   if (event.defaultPrevented) {
  //     return;
  //   }
  //   unselectAll();
  // };

  // const onWindowTouchEnd = (event) => {
  //   if (event.defaultPrevented) {
  //     return;
  //   }
  //   unselectAll();
  // };

  const toggleSelection = (taskId) => {
    try {
      const wasSelected = selectedTaskIds?.includes(taskId);
      const newTaskIds = (() => {
        if (!wasSelected) {
          return [taskId];
        }
        if (selectedTaskIds?.length > 1) {
          return [taskId];
        }
        return [];
      })();
      setSelectedTaskIds(newTaskIds);
    } catch (error) {
      console.log("something went wrong", error);
    }
  };

  const toggleSelectionInGroup = (taskId) => {
    try {
      const index = selectedTaskIds?.indexOf(taskId);
      if (index === -1) {
        setSelectedTaskIds([...selectedTaskIds, taskId]);
      } else {
        const shallow = [...selectedTaskIds];
        shallow.splice(index, 1);
        setSelectedTaskIds(shallow);
      }
    } catch (error) {
      console.log("something went wrong", error);
    }
  };

  const multiSelectTo = (newTaskId) => {
    try {
      const updated = multiSelect(entities, selectedTaskIds, newTaskId);
      if (updated == null) {
        return;
      }
      setSelectedTaskIds(updated);
    } catch (error) {
      console.log("something went wrong", error);
    }
  };

  const unselectAll = () => {
    setSelectedTaskIds([]);
  };

  const resetToPGDefault = async () => {
    let obj = entities.columns.freezedColumns.fieldsData;
    let selectedObj = entities.columns.selectedFields.fieldsData;
    let data = [];
    let freezeData = [];
    if (obj.length > 0 && selectedObj.length > 0) {
      freezeData = obj.splice(0, obj.length);
      data = selectedObj.splice(0, selectedObj.length);
    } else if (selectedObj.length > 0) {
      data = selectedObj.splice(0, selectedObj.length);
    }

    const updatedData = {
      ...entities,
      columns: {
        ...entities.columns,
        selectedFields: {
          ...entities.columns.selectedFields,
          fieldsData: [...data, ...freezeData, ...entities.columns.selectedFields.fieldsData],
        },
      },
    };
    setEntities(updatedData);

    if (props?.headerName === "My Projects") {
      localStorage.setItem("customizeViewFieldsMyProjects", []);
      props.setCustomizeViewFields([]);
      await setColumnsOrder(userId, "customizeViewFieldsMyProjects", "NA", "NA", true, "");
      await setColumnsOrder(userId, "myProjectsAllColumnNames", "NA", "NA", true, "");
      props.resetToPgDefault();
      props.hideDialog();
    } else if (props?.headerName === "All Projects") {
      localStorage.setItem("customizeViewFieldsAllProjects", []);
      props.setCustomizeViewFields([]);
      await setColumnsOrder(userId, "customizeViewFieldsAllProjects", "NA", "NA", true, "");
      await setColumnsOrder(userId, "allProjectsAllColumnNames", "NA", "NA", true, "");
      props.resetToPgDefault();
      props.hideDialog();
    } else if (props?.headerName === "ACPBooking") {
      props.resetToPgDefault();
      localStorage.removeItem("customizeViewFieldsBooking");
      localStorage.removeItem("columnWidthbookingColumn");
      props.setCustomizeViewFields(null);
      await setColumnsOrder(
        userId,
        "customizeViewFieldsBooking",
        props?.BU,
        props?.region,
        true,
        ""
      );
      await setColumnsOrder(userId, "bookingColumnNames", props?.BU, props?.region, true, "");
      props.hideDialog();
    } else if (props?.headerName === "Project Plan") {
      localStorage.setItem("customizeViewFieldsProjectPlan", []);
      props.setCustomizeViewFields(null);
      //clear columns order from Pega
      await setColumnsOrder(userId, "customizeViewFieldsProjectPlan", "NA", "NA", true, "");
      await setColumnsOrder(userId, "projectPlanAllColumnNames", "NA", "NA", true, "");
      props.resetToPgDefault();
      props.hideDialog();
    } else if (props?.headerName === "Artwork Status") {
      localStorage.setItem("customizeViewArtworkStatusFields", []);
      await setColumnsOrder(
        userId,
        "allColumnNamesArtworkStatus",
        props?.BU,
        props?.region,
        true,
        ""
      );
      await setColumnsOrder(
        userId,
        "customizeViewArtworkStatusFields",
        props?.BU,
        props?.region,
        true,
        ""
      );
      props.setCustomizeViewFields([]);
      props.resetToPgDefault();
      props.hideDialog();
    } else if (props?.headerName === "Dependency Mapping") {
      //clear columns order from Pega
      await setColumnsOrder(userId, "customizeViewDependancyFields", bu, region, true, "");
      await setColumnsOrder(userId, "setDependencyMappingColumnNames", bu, region, true, "");
      props?.resetToPgDefault();
      props.hideDialog();
    } else if (props?.headerName === "Rework") {
      localStorage.setItem("customizeViewFieldsRework", []);
      await setColumnsOrder(userId, "ReworkAllColumnNames", "NA", "NA", true, "");
      await setColumnsOrder(userId, "customizeViewFieldsRework", "NA", "NA", true, "");
      props.setCustomizeViewFields(null);
      props.resetToPgDefault();
      props.hideDialog();
    } else {
      //Artwork Alignment
      await setColumnsOrder(userId, "customizeViewFields", props.BU, props.region, true, "");
      await setColumnsOrder(userId, "artworkAlignmentAllColumns", props.BU, props.region, true, "");
      props?.resetToPgDefault();
      props.setCustomizeViewFields([]);
      props.hideDialog();
    }
    // props.setCustomizeViewFields([]);
    // props.hideDialog();
  };
  const setLocalStorage = async (localStorageName) => {
    localStorage.setItem(localStorageName, JSON.stringify(entities.columns));
    //2805:call an API to store the columns order in DB
    if (props?.headerName === "Dependency Mapping") {
      //add a logic to reorder setDependencyMappingColumnNames same as selected fields of setCustomizeViewFields
      const items = entities?.columns?.freezedColumns?.fieldsData?.length
        ? entities?.columns?.freezedColumns?.fieldsData.concat(
            entities?.columns?.selectedFields?.fieldsData
          )
        : entities?.columns?.selectedFields?.fieldsData;
      const reorderedSecondArray = reorderSecondArraySameAsFirstArray(
        items,
        parsedSetDependencyMappingColumnNames
      );
      localStorage.setItem("setDependencyMappingColumnNames", JSON.stringify(reorderedSecondArray));
      props.setTableRender(!props.tableRender);
      await setColumnsOrder(
        userId,
        "setDependencyMappingColumnNames",
        bu,
        region,
        false,
        JSON.stringify(reorderedSecondArray)
      );
      await setColumnsOrder(
        userId,
        "customizeViewDependancyFields",
        bu,
        region,
        false,
        JSON.stringify(entities.columns)
      );
      await setColumnsOrder(
        userId,
        "pgDefaultDependencyMappingColumnNames",
        bu,
        region,
        false,
        localStorage.getItem("pgDefaultDependencyMappingColumnNames")
      );
      await setColumnsOrder(
        userId,
        "pgDefaultCustomizeViewDependancyFields",
        bu,
        region,
        false,
        localStorage.getItem("pgDefaultCustomizeViewDependancyFields")
      );
    } else if (props?.headerName === "Rework") {
      // order and call setColumnsOrderApi
      props?.reorderColumnsSetColumnOrder(entities);
      props.setCustomizeViewFields(entities.columns);
    } else if (props?.headerName === "Artwork Status") {
      // for correct order of columns
      props?.reorderColumnsSetColumnOrder(entities);
      props.setTableRender(!props.tableRender);
    } else if (props?.headerName === "ACPBooking") {
      //datatable order and freeze is maintained by customize view in the ACPBookingDatatable component
      localStorage.removeItem("columnWidthbookingColumn");
      await setColumnsOrder(
        userId,
        "customizeViewFieldsBooking",
        props?.BU,
        props?.region,
        false,
        JSON.stringify(entities.columns)
      );
    } else if (props?.headerName === "Project Plan") {
      const setprojectPlanAllColumnNames = localStorage.getItem("projectPlanAllColumnNames");
      const parsedprojectPlanColumnNames =
        setprojectPlanAllColumnNames && JSON.parse(setprojectPlanAllColumnNames);
      const reorderedSecondArray = reorderArray(
        entities?.columns?.selectedFields?.fieldsData,
        entities?.columns?.freezedColumns?.fieldsData,
        parsedprojectPlanColumnNames,
        "field_Name"
      );
      await setColumnsOrder(
        userId,
        "projectPlanAllColumnNames",
        "NA",
        "NA",
        false,
        JSON.stringify(reorderedSecondArray)
      );
      await setColumnsOrder(
        userId,
        "customizeViewFieldsProjectPlan",
        "NA",
        "NA",
        false,
        JSON.stringify(entities.columns)
      );
    } else if (props?.headerName === "Artwork Scope Alignment") {
      // const setArtWorkAlignmentColumnNames = localStorage.getItem("artworkAlignmentAllColumns")
      // const parsedArtWorkAlignmentColumnNames = setArtWorkAlignmentColumnNames && JSON.parse(setArtWorkAlignmentColumnNames)

      const setArtWorkAlignmentColumnNames = entities?.columns?.selectedFields?.fieldsData || [];
      const reorderedSecondArray = reorderArray(
        entities?.columns?.selectedFields?.fieldsData,
        entities?.columns?.freezedColumns?.fieldsData,
        setArtWorkAlignmentColumnNames,
        "Field_Name"
      );
      localStorage.setItem("artworkAlignmentAllColumns", JSON.stringify(reorderedSecondArray));
      await setColumnsOrder(
        userId,
        "artworkAlignmentAllColumns",
        props.BU,
        props.region,
        false,
        JSON.stringify(reorderedSecondArray)
      );
      await setColumnsOrder(
        userId,
        "customizeViewFields",
        props.BU,
        props.region,
        false,
        JSON.stringify(entities.columns)
      );
      props?.setResetKey((prev) => prev + 1);
    } else if (props?.headerName === "All Projects") {
      const setAllProjectsAllColumnNames = entities?.columns?.selectedFields?.fieldsData || [];
      localStorage.setItem("allColumnNames", JSON.stringify(setAllProjectsAllColumnNames));
      await setColumnsOrder(
        userId,
        "allProjectsAllColumnNames",
        "NA",
        "NA",
        false,
        JSON.stringify(setAllProjectsAllColumnNames)
      );
      await setColumnsOrder(
        userId,
        "customizeViewFieldsAllProjects",
        "NA",
        "NA",
        false,
        JSON.stringify(entities.columns)
      );
      props?.setResetKey((prev) => prev + 1);
    } else if (props?.headerName === "My Projects") {
      const setMyProjectsAllColumnNames = entities?.columns?.selectedFields?.fieldsData || [];
      localStorage.setItem("allColumnNames", JSON.stringify(setMyProjectsAllColumnNames));
      await setColumnsOrder(
        userId,
        "myProjectsAllColumnNames",
        "NA",
        "NA",
        false,
        JSON.stringify(setMyProjectsAllColumnNames)
      );
      await setColumnsOrder(
        userId,
        "customizeViewFieldsMyProjects",
        "NA",
        "NA",
        false,
        JSON.stringify(entities.columns)
      );
      props?.setResetKey((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (props?.headerName === "Dependency Mapping") {
      if (customizeViewDependancyFields) {
        let temp = _.cloneDeep(entities);
        temp.columns = parsedCustomizeViewDependancyFields;
        setEntities(temp);
      }
    }
  }, [customizeViewDependancyFields]);

  const handleSubmit = async () => {
    switch (props?.headerName) {
      case "Dependency Mapping":
        setLocalStorage("customizeViewDependancyFields");
        break;
      case "Artwork Status":
        setLocalStorage("customizeViewArtworkStatusFields");
        break;
      case "ACPBooking":
        setLocalStorage("customizeViewFieldsBooking");
        break;
      case "All Projects":
        setLocalStorage("customizeViewFieldsAllProjects");
        break;
      case "My Projects":
        setLocalStorage("customizeViewFieldsMyProjects");
        break;
      case "Project Plan":
        setLocalStorage("customizeViewFieldsProjectPlan");
        break;
      case "Rework":
        setLocalStorage("customizeViewFieldsRework");
        break;
      default:
        setLocalStorage("customizeViewFields");
        break;
    }
    props.setCustomizeViewFields(JSON.stringify(entities.columns));
    props.hideDialog();
  };

  const selected = selectedTaskIds;

  useEffect(() => {
    if (!customizeViewDependancyFields && props?.headerName === "Dependency Mapping") {
      localStorage.setItem("customizeViewDependancyFields", JSON.stringify(entities?.columns));
      const reorderedSecondArray = reorderSecondArraySameAsFirstArray(
        parsedPgDefaultDependencyMappingColumnNames,
        entities?.columns?.selectedFields?.fieldsData
      );
      const temp = entities?.columns;
      temp.selectedFields.fieldsData = reorderedSecondArray;
      localStorage.setItem("pgDefaultCustomizeViewDependancyFields", JSON.stringify(temp));
    }
  }, [entities]);

  return (
    <>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div style={{ display: "flex" }}>
          {entities?.columnOrder?.map((columnId) => (
            <Column
              key={columnId}
              entities={entities}
              selectedTaskIds={selected}
              multiSelectTo={multiSelectTo}
              draggingTaskId={draggingTaskId}
              toggleSelection={toggleSelection}
              column={entities?.columns[columnId]}
              tasks={getTasks(entities, columnId)}
              toggleSelectionInGroup={toggleSelectionInGroup}
              droppableId={localDestination ? localDestination.droppableId : null}
            />
          ))}
        </div>
      </DragDropContext>
      <div className="form-buttons dsbp-form-buttons">
        <Button className="button-layout" variant="secondary" onClick={() => props.hideDialog()}>
          Cancel
        </Button>
        <Button
          className="button-layout"
          variant="secondary"
          disabled={disable && !dragInitiated}
          onClick={resetToPGDefault}
        >
          Reset to P&G Default
        </Button>

        <Button
          className="button-layout"
          variant="primary"
          onClick={handleSubmit}
          disabled={(disableSave || disable) && !dragInitiated}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default DragAndDrop;
