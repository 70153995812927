import { AcpService } from "../../service/ACPService";
import _ from "lodash";

export const NumberConversion = (number) => {
  const convertedNumber = String(number).padStart(2, "0");
  return convertedNumber;
};

export const generateTaskName = (Artwork_Brand, Artwork_Category, Project_Name, Artwork_SMO) => {
  let clubBrandName =
    Artwork_Brand?.length && Artwork_Brand.map((item) => item.Brand_Name).join(", ");
  let clubCategory =
    Artwork_Category?.length && Artwork_Category.map((item) => item.Category_Name).join(", ");
  let clubSMO = Artwork_SMO?.length && Artwork_SMO.map((item) => item.SMO_Name).join(", ");
  const task_Name = `${clubBrandName}_${clubCategory}_${Project_Name}${
    clubSMO ? `_${clubSMO}` : ""
  }`;
  return task_Name;
};

// AWM-701 show alerticon based on attributes values

export const checkIfAlertIcon = (item) => {
  return (
    (item && item["AWM_SnapChange_CIC"] === true) ||
    (item && item["AWM_SnapChange_FA"] === true) ||
    (item && item["AWM_SnapChange_POACreate"] === true)
  );
};

export const checkIfRedIcon = (item) => {
  return (
    (item && item["AWM_SnapChange_CIC"] === true) ||
    (item && item["AWM_SnapChange_FA"] === true) ||
    (item && item["AWM_SnapChange_POACreate"] === true)
  );
};

export const checkIfGreyIcon = (item) => {
  return (
    ((item && item["AWM_SnapChange_CIC"] === true) ||
      (item && item["AWM_SnapChange_FA"] === true) ||
      (item && item["AWM_SnapChange_POACreate"] === true)) &&
    item &&
    item["POAStatus"] === "Awaiting Response" &&
    item &&
    item["POARequestType"] === "Update Request"
  );
};

export const getRequestText = (
  cic,
  matrixFullkit,
  CICfullkit,
  matrix,
  requested,
  xmlRequestStatus
) => {
  switch (true) {
    //request already raised
    case cic === "Yes" && matrixFullkit && matrix && requested:
      return { name: "CIC Matrix Requested", type: "text", severity: "info", disabled: false };

    case cic === "Yes" && CICfullkit && !matrix && requested:
      return { name: "CIC Requested", type: "text", severity: "info", disabled: false };

    //request already raised but full kit condition  changed (red color text)
    case cic === "Yes" && !matrixFullkit && matrix && requested:
      return { name: "CIC Matrix Requested", type: "text", severity: "danger", disabled: false };

    case cic === "Yes" && !CICfullkit && !matrix && requested:
      return { name: "CIC Requested", type: "text", severity: "danger", disabled: false };

    //request is not raised and full kit is ready
    case cic === "Yes" && matrixFullkit && matrix && !requested && !xmlRequestStatus:
      return {
        name: "Request CIC Matrix",
        type: "raised",
        severity: "success",
        disabled: false,
        reqId: 1,
      };

    case cic === "Yes" && CICfullkit && !matrix && !requested && !xmlRequestStatus:
      return {
        name: "Request CIC",
        type: "raised",
        severity: "success",
        disabled: false,
        reqId: 2,
      };

    // request is not raised but full kit is not ready
    case cic === "Yes" && !matrixFullkit && matrix && !requested:
      return { name: "Request CIC Matrix", type: "raised", severity: "secondary", disabled: true };

    case cic === "Yes" && !CICfullkit && !matrix && !requested:
      return { name: "Request CIC", type: "raised", severity: "secondary", disabled: true };

    // Copy_XML_Request raised but RTA response not come (disabled button)
    case cic === "Yes" && matrixFullkit && matrix && !requested && xmlRequestStatus:
      return {
        name: "Request CIC Matrix",
        type: "raised",
        severity: "secondary",
        disabled: true,
        reqId: 1,
      };

    case cic === "Yes" && CICfullkit && !matrix && !requested && xmlRequestStatus:
      return {
        name: "Request CIC",
        type: "raised",
        severity: "secondary",
        disabled: true,
        reqId: 2,
      };

    default:
      return { name: "OtherCase" };
  }
};
export const deleteCicPayload = (
  deleteCICFormData,
  ProjectID,
  GABriefData,
  CDPTPageData,
  RDTData,
  IQData
) => {
  let deleteItem = {};
  deleteItem["CICMatrix"] = "";
  deleteItem["AWM_CICMatrix"] = "";
  deleteItem["Action"] = "DELETE";
  deleteItem["Task_Name"] = "Request_CIC_" + deleteCICFormData?.DSBP_PMP_PIMaterialNumber;
  deleteItem["AWM_CICNeeded"] = deleteCICFormData?.AWM_CIC_Needed;
  deleteItem["AWMProjectID"] = ProjectID;
  deleteItem["AWM_GABrief"] = deleteCICFormData?.AWM_GA_Brief;
  const AWM_GABrief_Data = GABriefData.filter(
    (data) => data.File_Name === deleteCICFormData?.AWM_GA_Brief
  );
  deleteItem["AWM_GABrief_ID"] = AWM_GABrief_Data?.[0]?.AWM_Task_ID;
  deleteItem["AWM_SupportingPMPLayout"] = deleteCICFormData.AWM_Supporting_PMP_Layout
    ? deleteCICFormData.AWM_Supporting_PMP_Layout
    : "";
  deleteItem["AWM_SupportingPMPDesign"] = deleteCICFormData.AWM_Supporting_PMP_Design
    ? deleteCICFormData.AWM_Supporting_PMP_Design
    : "";
  deleteItem["AWM_OtherReference"] = deleteCICFormData.AWM_Other_Reference
    ? deleteCICFormData.AWM_Other_Reference
    : "";

  deleteItem["DSBP_PMP_PIMaterialID"] = deleteCICFormData?.DSBP_PMP_PIMaterialID;
  deleteItem["DSBP_PMP_PIMaterialNumber"] = deleteCICFormData?.DSBP_PMP_PIMaterialNumber;
  deleteItem["DSBP_InitiativeID"] = deleteCICFormData?.DSBP_InitiativeID;

  let DSBP_CDPT_Page = [];
  let DSBP_CDPT_Page_data = [];
  if (deleteCICFormData.Color_Development_and_Print_Trial) {
    DSBP_CDPT_Page =
      CDPTPageData?.length &&
      CDPTPageData.filter(
        (cdptData) =>
          deleteCICFormData.Color_Development_and_Print_Trial.includes(
            cdptData.AWM_Design_Job_ID
          ) && cdptData
      );
    DSBP_CDPT_Page_data =
      DSBP_CDPT_Page?.length &&
      DSBP_CDPT_Page.map((item) => ({
        Design_Job_Name: item.AWM_Design_Job_Name,
        Design_Job_ID: item.AWM_Design_Job_ID,
      }));
  }
  deleteItem.DSBP_CDPT_Page = DSBP_CDPT_Page_data || [];
  //rdt
  let DSBP_RDT_Page = [];
  let DSBP_RDT_Page_data = [];
  if (deleteCICFormData.Regional_Design_Template) {
    DSBP_RDT_Page =
      RDTData?.length &&
      RDTData.filter((rdtData) => {
        if (deleteCICFormData.Regional_Design_Template.includes(rdtData.AWM_Design_Job_ID))
          return rdtData;
      });
    DSBP_RDT_Page_data =
      DSBP_RDT_Page?.length &&
      DSBP_RDT_Page.map((item) => ({
        Design_Job_Name: item.AWM_Design_Job_Name,
        Design_Job_ID: item.AWM_Design_Job_ID,
      }));
  }
  deleteItem.DSBP_RDT_Page = DSBP_RDT_Page_data || [];

  //IQ
  let DSBP_IQ_Page = [];
  let DSBP_IQ_Page_data = [];
  if (deleteCICFormData.Ink_Qualification) {
    DSBP_IQ_Page =
      IQData?.length &&
      IQData.filter((iqData) => {
        if (deleteCICFormData.Ink_Qualification.includes(iqData.AWM_Design_Job_ID)) return iqData;
      });
    DSBP_IQ_Page_data =
      DSBP_IQ_Page?.length &&
      DSBP_IQ_Page.map((item) => ({
        Design_Job_Name: item.AWM_Design_Job_Name,
        Design_Job_ID: item.AWM_Design_Job_ID,
      }));
  }
  deleteItem.DSBP_IQ_Page = DSBP_IQ_Page_data || [];

  return [deleteItem];
};

export const createPMPObject = (inputString) => {
  if (AcpService.isUBN(inputString)) {
    return {
      PIMaterialNumber: "",
      PIMaterialID: "",
      DSBP_InitiativeID: "",
    };
  } else {
    const processedArray = inputString?.split("-")?.map((item) => item?.trim());
    return {
      PIMaterialNumber: processedArray[0],
      PIMaterialID: processedArray[1],
      DSBP_InitiativeID: processedArray[2],
    };
  }
};

export const isObjectSame = (oldcols, newCols) => {
  const getfields = oldcols?.map((item) => item?.field);
  const getkeysFromCols = Object?.values(newCols);
  const getkeysFromOldCols = Object?.values(getfields);

  if(isArrayValuesSame(getkeysFromCols, getkeysFromOldCols)) {
    return true;
  } else {
    return false;
  }
}

const isArrayValuesSame = (arr1, arr2) => {
  // Step 1: Quick check - if lengths differ, arrays are not equal
  if (arr1?.length !== arr2?.length) {
      return false;
  }

  // Step 2: Create a frequency map for the first array using Map
  const frequencyMap = new Map();
  for (let item of arr1) {
      frequencyMap.set(item, (frequencyMap.get(item) || 0) + 1);
  }

  // Step 3: Decrease frequency for each element in the second array
  for (let item of arr2) {
      if (!frequencyMap.has(item)) {
          return false; // Item not found in the map
      }
      let count = frequencyMap.get(item);
      if (count === 1) {
          frequencyMap.delete(item); // Remove item if count drops to 0
      } else {
          frequencyMap.set(item, count - 1);
      }
  }

  // If map is empty, arrays were equal
  return frequencyMap.size === 0;
}

export const removeDuplicatesFromArray = (arr1, arr2) => {
  // Step 1: Create a Set from the second array for O(1) lookups
  const set2 = new Set(arr2);
  
  // Step 2: Filter elements from arr1 that do not exist in arr2
  return arr1?.filter(item => !set2.has(item));
}

