import React, { useState, useEffect, useRef, useMemo } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import _ from "lodash";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { createNewProject, editProject } from "../../../apis/projectSetupApi";
import { getProjectValues } from "../../../store/actions/ProjectSetupActions";
import moment from "moment-timezone";
import { Toast } from "primereact/toast";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import LoadingOverlay from "react-loading-overlay-ts";
import { textFieldMaxLength, textAreaMaxLength } from "../../../constants/index";
import { CharacterLimit } from "../../common/CharacterLimit/CharacterLimit";
import "./index.scss";
import { designScope, convertNextLineCharToSpaces } from "../../../utils";
import CalendarIcon from "../../common/CalendarIcon/CalendarIcon";
import { useDispatch, useSelector } from "react-redux";
import AlertDialogBox from "../../../components/ConfirmDialog/AlertDialogBox";
import { onSortData } from "../../../utils";
import { SuggestionsMatch, showBackgroundProcess } from "../../../Utils/helpers";

const defaultCheckedItems = {
  DI: false,
  DT: false,
  PRA: false,
  PF: false,
  IQ: false,
  CICs: false,
};

const defaultTextBoxEnabled = {
  DI: false,
  DT: false,
  PF: false,
  PRA: false,
  IQ: false,
  CICs: false,
};

function AddProject() {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { ProjectID } = useParams();
  const locationPath = location?.pathname;
  const url = locationPath?.split("/");
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const projectSetup = useSelector((state) => state.ProjectSetupReducer);
  const loading = projectSetup?.loading;

  const fetchProjectData = useMemo(() => {
    if (projectSetup?.status === 200) {
      return projectSetup.projectData?.ArtworkAgilityProjects || {};
    }
    return {};
  }, [projectSetup?.status, projectSetup?.projectData?.ArtworkAgilityProjects]);

  const OwnersList = useMemo(() => {
    if (projectSetup?.status === 200) {
      return projectSetup?.projectData?.OwnersList || [];
    }
    return {};
  }, [projectSetup?.status, projectSetup?.projectData?.OwnersList]);

  const [selectedProjectDetails, setSelectedProjectDetails] = useState([]);
  const mode = ProjectID !== undefined ? "edit" : "create";
  let awmProjectId = ProjectID;

  const prePopuSmo = [];
  selectedProjectDetails?.Artwork_SMO?.forEach((obj) => {
    if (obj.code !== "") {
      prePopuSmo.push(obj.code);
    }
  });
  const [loader, setLoader] = useState(false);
  const [spinnerText, setSpinnerText] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [groupName, setGroupName] = useState("");
  const [cluster, setCluster] = useState("");
  const [scale, setScale] = useState("");
  const [selectedCities, setSelectedCities] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [formValidForSaveAsDraft, setFormValidForSaveAsDraft] = useState(false);
  const [formData, setFormData] = useState(null);
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [readinessDate, setReadinessDate] = useState("");
  const [projectStartDate, setProjectStartDate] = useState("");
  const [sopDate, setSOPDate] = useState("");
  const [printerDate, setPrinterDate] = useState("");
  const [sosDate, setSOSDate] = useState("");
  const [region, setRegion] = useState("");
  const [smo, setSmo] = useState([]);
  const [bu, setBu] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [categoriesAlert, setCategoriesAlert] = useState(false);
  const [brand, setBrand] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [productionStrategyList, setProductionStrategyList] = useState([]);
  const [Tier, setTier] = useState("");
  const [ps, setPs] = useState("");
  const [pm, setPm] = useState("");
  const [iL, setIl] = useState("");
  const [comments, setComments] = useState("");
  const [projectType, setProjectType] = useState("");
  const [checkedItems, setCheckedItems] = useState(defaultCheckedItems);
  const [textBoxEnabled, setTextBoxEnabled] = useState(defaultTextBoxEnabled);
  const [POA, setPOA] = useState("1");
  const [projectNameAlert, setProjectNameAlert] = useState(false);
  const [businessUnitAlert, setBusinessUnitAlert] = useState(false);
  const [regionAlert, setRegionAlert] = useState(false);
  const [scaleAlert, setScaleAlert] = useState(false);
  const [faSupplierAlert, setFaSupplierAlert] = useState(false);
  const [PMAlert, setPMAlert] = useState(false);
  const [SMOAlert, setSMOAlert] = useState(false);
  const [brandAlert, setBrandAlert] = useState(false);
  const [readinessDateAlert, setReadinessDateAlert] = useState(false);
  const [projectStartDateAlert, setProjectStartDateAlert] = useState(false);
  const [printerDateAlert, setPrinterDateAlert] = useState(false);
  const [projectSetupPageDropDownValues, setProjectSetupPageDropDownValues] = useState([]);
  const [bUs, setbUs] = useState([]);
  const [brands, setBrands] = useState([]);
  const [regions, setRegions] = useState([]);
  const [smos, setSmos] = useState([]);
  const [scales, setScales] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [projectTypeList, setProjectTypeList] = useState([]);
  const [validateScope, setValidateScope] = useState([]);
  const [visible, setVisible] = useState(false);
  const [designScopes, setDesignScopes] = useState([...designScope]);
  const [projectState, setProjectState] = useState("");
  const [projectActiveState, setProjectActiveState] = useState("");
  const [width, setWidth] = useState(0);
  const [designScopeList, setDesignScopeList] = useState({
    DI: "",
    DT: "",
    PRA: "",
    PF: "",
    IQ: "",
    CICs: "",
    FA: "",
  });
  const [buDropDownMatch, setBuDropDownMatch] = useState(true);
  const [regionDropDownMatch, setRegionDropDownMatch] = useState(true);
  const [scaleDropDownMatch, setScaleDropDownMatch] = useState(true);
  const [pmDropDownMatch, setPmDropDownMatch] = useState(true);
  const [supplierDropDownMatch, setSupplierDropDownMatch] = useState(true);
  const [projectTypeDropDownMatch, setProjectTypeDropDownMatch] = useState(true);
  const [psDropDownMatch, setPsDropDownMatch] = useState(true);
  const [tierDropDownMatch, setTierDropDownMatch] = useState(true);

  const [dropDownMatch, setDropDownMatch] = useState(true);
  const [access, setAccess] = useState([]);
  const [userAccessDisabled, setUserAccessDisabled] = useState(false);
  const [errorDescription, setErrorDescription] = useState(null);

  const userAccessGroup = useSelector(
    (state) => state?.UserDetailsReducer?.userDetails?.AccessGroup
  );

  const dropdownRef = useRef(null);
  const createProjectMessage =
    "Selection of Business Unit and Region is mandatory before you can save the Project as draft.";

  useEffect(() => {
    if (dropdownRef?.current) {
      setWidth(dropdownRef?.current?.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (
      buDropDownMatch &&
      regionDropDownMatch &&
      scaleDropDownMatch &&
      pmDropDownMatch &&
      supplierDropDownMatch &&
      projectTypeDropDownMatch &&
      psDropDownMatch &&
      tierDropDownMatch
    ) {
      setDropDownMatch(true);
    } else {
      setDropDownMatch(false);
    }
  }, [
    buDropDownMatch,
    regionDropDownMatch,
    scaleDropDownMatch,
    pmDropDownMatch,
    supplierDropDownMatch,
    projectTypeDropDownMatch,
    psDropDownMatch,
    tierDropDownMatch,
  ]);

  useEffect(() => {
    if (ProjectID) dispatch(getProjectValues(ProjectID));
  }, [ProjectID]);

  useEffect(() => {
    if (fetchProjectData) {
      const projectDetails = ProjectID ? fetchProjectData?.[0] : [];
      setSelectedProjectDetails(projectDetails);
    }
  }, [fetchProjectData]);

  useEffect(() => {
    let temp = [];
    userAccessGroup?.map((obj) => {
      if (obj?.AccessGroupNames?.includes("ProjectManager")) {
        temp.push("write");
      } else if (obj?.AccessGroupNames.includes("CapacityManager")) {
        if (pm === userInformation?.userid) {
          temp.push("write");
        } else {
          temp.push("read");
        }
      }
    });
    setAccess(temp);
  }, [userAccessGroup, userInformation]);

  useEffect(() => {
    if (access.indexOf("write") === -1) {
      setUserAccessDisabled(true);
    } else {
      setUserAccessDisabled(false);
    }
  }, [access]);

  const { DropDownValuesData } = useSelector((state) => state?.DropDownValuesReducer);

  const user_group = useSelector((state) => state?.UserDetailsReducer?.userDetails?.UserGroup);

  const payLoad = {
    ArtworkAgilityPage: {
      PM: userInformation?.userid,
      UserGroup: user_group,
    },
  };

  const formatDate = (date) => {
    const formattedDate = new Date(
      moment(date, "YYYYMMDD[T]HHmmss.SSS [GMT]")
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
    );
    if (formattedDate.getFullYear() > "1999") {
      return formattedDate;
    } else {
      return "";
    }
  };

  const formatPayloadDate = (date) => {
    if (date) {
      const formattedDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)").format(
        "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
      );
      return formattedDate;
    } else {
      return date;
    }
  };

  useEffect(() => {
    if (DropDownValuesData) {
      setProjectSetupPageDropDownValues(DropDownValuesData?.ArtWorkProjectSetupPage || []);
    }
  }, [DropDownValuesData]);

  useEffect(() => {
    if (
      projectSetupPageDropDownValues !== undefined &&
      projectSetupPageDropDownValues.length !== 0
    ) {
      setbUs(onSortData("BU_Name", "asc", projectSetupPageDropDownValues?.Artwork_BU));
      setBrands(onSortData("Brand_Name", "asc", projectSetupPageDropDownValues?.Artwork_Brand));
      setRegions(onSortData("Region_Name", "asc", projectSetupPageDropDownValues?.Artwork_Region));
      setSmos(onSortData("SMO_Name", "asc", projectSetupPageDropDownValues?.Artwork_SMO));
      setScales(onSortData("Scale_Name", "asc", projectSetupPageDropDownValues?.Artwork_Scale));
      setSupplierList(
        onSortData("Supplier_Name", "asc", projectSetupPageDropDownValues?.Artwork_Supplier)
      );
      setProjectTypeList(
        onSortData("ProjectType_Name", "asc", projectSetupPageDropDownValues?.Artwork_ProjectType)
      );
    }
  }, [projectSetupPageDropDownValues]);

  useEffect(() => {
    bu &&
      bUs?.forEach((obj) => {
        obj?.Artwork_Picklist?.forEach((pickList) => {
          if (bu?.BU_Name === obj?.BU_Name && pickList.Picklist_Name === "SAPCategory") {
            setSubCategoriesOptions(pickList?.Labels);
          }
        });
        let tierList = [];
        let productionStrategyList = [];
        bu?.Artwork_Picklist?.forEach((item) => {
          if (
            item &&
            region &&
            item?.Picklist_Name === "Tier" &&
            (item?.Region?.toLowerCase() === "all" ||
              item?.Region?.toLowerCase() === region?.Region_Name?.toLowerCase())
          ) {
            tierList = item.Labels || [];
          }
          if (
            item &&
            region &&
            item?.Picklist_Name === "PRODSTRAT" &&
            (item?.Region?.toLowerCase() === "all" ||
              item?.Region?.toLowerCase() === region?.Region_Name?.toLowerCase())
          ) {
            productionStrategyList = item.Labels || [];
          }
        });
        setTierList(tierList);
        setProductionStrategyList(productionStrategyList);
      });
  }, [bu, bUs, region]);

  useEffect(() => {
    setRegion(
      (mode !== "create" &&
        selectedProjectDetails &&
        regions.find((r) => r?.Region_Name === selectedProjectDetails?.Project_region)) ||
        ""
    );
  }, [regions]);

  useEffect(() => {
    setBu(
      (mode !== "create" &&
        selectedProjectDetails &&
        bUs.find((b) => b?.BU_Name === selectedProjectDetails?.BU)) ||
        ""
    );
  }, [bUs]);

  useEffect(() => {
    setScale(
      (mode !== "create" &&
        selectedProjectDetails &&
        scales?.find((r) => r?.Scale_Name === selectedProjectDetails?.Project_Scale)) ||
        ""
    );
  }, [scales]);
  useEffect(() => {
    setPs(
      (selectedProjectDetails &&
        productionStrategyList.find(
          (ps) => ps?.Label_Name === selectedProjectDetails?.Production_Strategy
        )) ||
        ""
    );
  }, [productionStrategyList]);

  useEffect(() => {
    setTier(
      (selectedProjectDetails &&
        tierList?.find((r) => r?.Label_Name === selectedProjectDetails?.Tier)) ||
        ""
    );
  }, [tierList]);

  useEffect(() => {
    setProjectType(
      (mode !== "create" &&
        selectedProjectDetails &&
        projectTypeList &&
        projectTypeList?.find(
          (r) => r?.ProjectType_Name === selectedProjectDetails?.Project_Type
        )) ||
        ""
    );
  }, [projectTypeList]);

  useEffect(() => {
    setSupplier(
      (mode !== "create" &&
        selectedProjectDetails &&
        supplierList &&
        supplierList?.find((r) => r.Supplier_Name === selectedProjectDetails?.Company)) ||
        ""
    );
  }, [supplierList]);

  const setToInitialState = () => {
    setProjectName("");
    setGroupName("");
    setProjectDesc("");
    setBu("");
    setSubCategories([]);
    setBrand([]);
    setRegion("");
    setSmo([]);
    setCluster("");
    setScale("");
    setSOSDate("");
    setSOPDate("");
    setPrinterDate("");
    setReadinessDate("");
    setProjectStartDate(minDate);
    setSupplier("");
    setIl("");
    setPm(userInformation?.userid);
    setComments("");
    setProjectType("");

    setDesignScopeList((prevDesignScopeList) => ({
      ...prevDesignScopeList,
      DI: "",
    }));
    handleCheckboxChange({
      target: {
        name: "DI",
        value: "DI",
        checked: false,
      },
    });

    setDesignScopeList((prevDesignScopeList) => ({
      ...prevDesignScopeList,
      DT: "",
    }));
    handleCheckboxChange({
      target: {
        name: "DT",
        value: "DT",
        checked: false,
      },
    });

    setDesignScopeList((prevDesignScopeList) => ({
      ...prevDesignScopeList,
      PF: "",
    }));
    handleCheckboxChange({
      target: {
        name: "PF",
        value: "PF",
        checked: false,
      },
    });

    setDesignScopeList((prevDesignScopeList) => ({
      ...prevDesignScopeList,
      IQ: "",
    }));
    handleCheckboxChange({
      target: {
        name: "IQ",
        value: "IQ",
        checked: false,
      },
    });

    setDesignScopeList((prevDesignScopeList) => ({
      ...prevDesignScopeList,
      PRA: "",
    }));
    handleCheckboxChange({
      target: {
        name: "PRA",
        value: "PRA",
        checked: false,
      },
    });

    setDesignScopeList((prevDesignScopeList) => ({
      ...prevDesignScopeList,
      CICs: "",
    }));
    handleCheckboxChange({
      target: {
        name: "CICs",
        value: "CICs",
        checked: false,
      },
    });
    setDesignScopeList((prevDesignScopeList) => ({
      ...prevDesignScopeList,
      FA: 1,
    }));
    setPs("");
    setTier("");
  };

  useEffect(() => {
    if (mode === "edit" || mode === "design") {
      setProjectState(selectedProjectDetails?.Project_State);
      setProjectActiveState(selectedProjectDetails?.Action);
      setProjectName(selectedProjectDetails?.Project_Name || "");
      setGroupName(selectedProjectDetails?.Initiative_Group_Name || "");
      setProjectDesc(selectedProjectDetails?.Project_Description || "");
      setBu(
        (selectedProjectDetails && bUs.find((b) => b.BU_Name === selectedProjectDetails?.BU)) || ""
      );

      let data = [];
      if (selectedProjectDetails?.Artwork_Category?.length > 0) {
        selectedProjectDetails?.Artwork_Category?.forEach((category) => {
          let temp = {};
          if (category.code !== "") {
            temp.code = category["code"];
            temp.Label_Name = category["Category_Name"];
            data.push(temp);
            setSubCategories(data);
          } else {
            setSubCategories([]);
          }
        });
      } else {
        setSubCategories([]);
      }

      bu &&
        bUs.forEach((obj) => {
          obj.Artwork_Picklist?.forEach((pickList) => {
            if (bu.BU_Name === obj.BU_Name && pickList.Picklist_Name === "SAPCategory") {
              setSubCategoriesOptions(pickList.Labels);
            }
          });
        });

      if (selectedProjectDetails?.Artwork_Brand?.length > 0) {
        selectedProjectDetails?.Artwork_Brand.forEach((brand) => {
          if (brand.code !== "") {
            setBrand(selectedProjectDetails?.Artwork_Brand);
          } else {
            setBrand([]);
          }
        });
      } else {
        setBrand([]);
      }
      setRegion(
        (selectedProjectDetails &&
          regions.find((r) => r.Region_Name === selectedProjectDetails?.Project_region)) ||
          ""
      );
      setSmo(prePopuSmo || []);
      setCluster(selectedProjectDetails?.Cluster || "");
      setScale(
        (selectedProjectDetails &&
          scales?.find((r) => r.Scale_Name === selectedProjectDetails?.Project_Scale)) ||
          ""
      );

      setSOSDate(
        (selectedProjectDetails?.Estimated_SOS &&
          formatDate(selectedProjectDetails?.Estimated_SOS)) ||
          ""
      );
      setSOPDate(
        (selectedProjectDetails?.Estimated_SOP &&
          formatDate(selectedProjectDetails?.Estimated_SOP)) ||
          ""
      );
      setPrinterDate(
        (selectedProjectDetails?.Estimated_AW_Printer &&
          formatDate(selectedProjectDetails?.Estimated_AW_Printer)) ||
          ""
      );
      setReadinessDate(
        (selectedProjectDetails?.Estimated_AW_Readiness &&
          formatDate(selectedProjectDetails?.Estimated_AW_Readiness)) ||
          ""
      );
      setProjectStartDate(
        (selectedProjectDetails?.Project_Start_Date &&
          formatDate(selectedProjectDetails?.Project_Start_Date)) ||
          ""
      );
      setIl(selectedProjectDetails?.IL);
      setPm(selectedProjectDetails?.PM || userInformation.userid);
      setComments(selectedProjectDetails?.Comments || "");

      setSupplier(
        (selectedProjectDetails &&
          supplierList &&
          supplierList?.find((r) => r?.Supplier_Name === selectedProjectDetails?.Company)) ||
          ""
      );

      setProjectType(
        (selectedProjectDetails &&
          projectTypeList &&
          projectTypeList.find(
            (r) => r.ProjectType_Name === selectedProjectDetails?.Project_Type
          )) ||
          ""
      );

      setDesignScopeList((prevDesignScopeList) => ({
        ...prevDesignScopeList,
        DI: parseInt(selectedProjectDetails?.Estimated_No_Of_DI),
      }));
      handleCheckboxChange({
        target: {
          name: "DI",
          value: "DI",
          checked: selectedProjectDetails?.Design_Intent,
        },
      });

      setDesignScopeList((prevDesignScopeList) => ({
        ...prevDesignScopeList,
        DT: parseInt(selectedProjectDetails?.Estimated_No_Of_DT),
      }));
      handleCheckboxChange({
        target: {
          name: "DT",
          value: "DT",
          checked: selectedProjectDetails?.Design_Template,
        },
      });

      setDesignScopeList((prevDesignScopeList) => ({
        ...prevDesignScopeList,
        PF: parseInt(selectedProjectDetails?.Estimated_No_Of_NPF),
      }));
      handleCheckboxChange({
        target: {
          name: "PF",
          value: "PF",
          checked: selectedProjectDetails?.New_Print_Feasibility,
        },
      });

      setDesignScopeList((prevDesignScopeList) => ({
        ...prevDesignScopeList,
        IQ: parseInt(selectedProjectDetails?.Estimated_No_Of_IQ),
      }));
      handleCheckboxChange({
        target: {
          name: "IQ",
          value: "IQ",
          checked: selectedProjectDetails?.Ink_Qualification,
        },
      });

      setDesignScopeList((prevDesignScopeList) => ({
        ...prevDesignScopeList,
        PRA:
          selectedProjectDetails?.Estimated_No_Of_PRA &&
          parseInt(selectedProjectDetails?.Estimated_No_Of_PRA),
      }));
      handleCheckboxChange({
        target: {
          name: "PRA",
          value: "PRA",
          checked: selectedProjectDetails?.Production_Ready_Art,
        },
      });

      setDesignScopeList((prevDesignScopeList) => ({
        ...prevDesignScopeList,
        CICs: parseInt(selectedProjectDetails?.Estimated_No_Of_CICs),
      }));
      handleCheckboxChange({
        target: {
          name: "CICs",
          value: "CICs",
          checked: selectedProjectDetails?.CICs,
        },
      });
      setDesignScopeList((prevDesignScopeList) => ({
        ...prevDesignScopeList,
        FA: parseInt(selectedProjectDetails?.Estimated_No_Of_POAs),
      }));
      setPOA(selectedProjectDetails?.Estimated_No_Of_POAs);

      setPs(
        (selectedProjectDetails &&
          productionStrategyList?.find(
            (ps) => ps?.Label_Name === selectedProjectDetails?.Production_Strategy
          )) ||
          ""
      );

      setTier(
        (selectedProjectDetails &&
          tierList &&
          tierList?.find((r) => r.Label_Name === selectedProjectDetails?.Tier)) ||
          ""
      );
    } else {
      setToInitialState();
    }
  }, [mode, selectedProjectDetails]);

  const getSmoOptions = () => {
    return smos?.map((smo) => ({
      label: smo.SMO_Name,
      value: smo.code,
    }));
  };

  const getProjectSMO = () => {
    const selectedSmoOptions = [];

    smo?.forEach((sm) => {
      let temp = {};
      smoOptions?.forEach((option) => {
        if (option.value === sm) {
          temp.instruction = "APPEND";
          temp.target = "ProjectSMO";
          temp.content = {};
          temp.content.SMO_Name = option.label;
          temp.content.code = option.value;
        }
      });
      selectedSmoOptions.push(temp);
    });
    return selectedSmoOptions;
  };

  const getProjectBrand = () => {
    const selectedBrandOptions = [];

    brand.forEach((obj) => {
      let temp = {};
      temp.instruction = "APPEND";
      temp.target = "ProjectBrand";
      temp.content = obj;
      selectedBrandOptions.push(temp);
    });
    return selectedBrandOptions;
  };

  const getProjectCategory = () => {
    const selectedCategoriesOptions = [];
    subCategories?.forEach((obj) => {
      let temp = {};
      temp.instruction = "APPEND";
      temp.target = "ProjectCategory";
      temp.content = {
        Category_Name: obj["Label_Name"],
        code: obj["code"],
      };
      selectedCategoriesOptions.push(temp);
    });
    return selectedCategoriesOptions;
  };

  const getProjectCode = () => {
    const projName = getValues("projectType");
    let projCode;
    projectTypeList?.forEach((pt) => {
      if (pt.ProjectType_Name === projName) {
        projCode = pt.code;
      }
    });
    return projCode || "";
  };
  const handleScaleChange = (e) => {
    if (!scales?.length) return;
    const selectedScale = scales?.find((r) => r?.Scale_Name === e?.value?.Scale_Name);
    selectedScale ? setScale(selectedScale) : setScale(e?.value);
  };
  const handleProjectTypeChange = (e) => {
    if (!projectTypeList?.length) return;
    const selectedProjectType = projectTypeList?.find(
      (r) => r?.ProjectType_Name === e?.value?.ProjectType_Name
    );
    selectedProjectType ? setProjectType(selectedProjectType) : setProjectType(e?.value);
  };

  const handleSupplierChange = (e) => {
    if (!supplierList?.length) return;
    const selectedSupplier = supplierList?.find(
      (r) => r?.Supplier_Name === e?.value?.Supplier_Name
    );
    selectedSupplier ? setSupplier(selectedSupplier) : setSupplier(e?.value);
  };

  const smoOptions = getSmoOptions();

  const handleSubCategoryChange = (e) => {
    e.stopPropagation();
    e?.value?.length === 0 ? setCategoriesAlert(true) : setCategoriesAlert(false);
    setSubCategories(e.value);
  };

  const form = useForm({ date: null });
  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let prevMonth = month === 0 ? 11 : month - 1;
  let prevYear = prevMonth === 11 ? year - 1 : year;
  let nextMonth = month === 11 ? 0 : month + 1;
  let nextYear = nextMonth === 0 ? year + 1 : year;

  let minDate = new Date();
  minDate.setDate(today.getDate());

  let maxDate = new Date();

  maxDate.setMonth(nextMonth);
  maxDate.setFullYear(nextYear);

  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;
    const isChecked = event.target.checked;

    if (!isChecked) {
      setDesignScopeList((prevDesignScopeList) => ({
        ...prevDesignScopeList,
        [value]: "", // set input value to empty string
      }));
      setTextBoxEnabled((prevTextBoxEnabled) => ({
        ...prevTextBoxEnabled,
        [value]: false, // disable the input textbox
      }));
    }

    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [name]: isChecked ? true : false,
    }));

    // handle inter-dependencies
    switch (name) {
      case "DI":
        // when Design Intent is selected, user must select DT or PRA
        if (isChecked && !checkedItems.DT && !checkedItems.PRA) {
          setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            DT: true,
          }));
          setTextBoxEnabled((prevTextBoxEnabled) => ({
            ...prevTextBoxEnabled,
            DT: true,
          }));
        }
        break;
      case "DT":
        // when Design Template is selected, user cannot select PRA
        if (isChecked) {
          setDesignScopeList((prevDesignScopeList) => ({
            ...prevDesignScopeList,
            PRA: "", // or DT: null
          }));
        }
        if (isChecked && checkedItems.PRA) {
          setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            PRA: false,
          }));
          setTextBoxEnabled((prevTextBoxEnabled) => ({
            ...prevTextBoxEnabled,
            PRA: false,
          }));
        } else if (!isChecked && !checkedItems.PRA && checkedItems.DI) {
          setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            PRA: true,
          }));

          setTextBoxEnabled((prevTextBoxEnabled) => ({
            ...prevTextBoxEnabled,
            PRA: true,
          }));
        }
        break;
      case "PRA":
        // when Production Ready Art is selected, user cannot select DT
        if (isChecked) {
          setDesignScopeList((prevDesignScopeList) => ({
            ...prevDesignScopeList,
            DT: "", // or DT: null
          }));
        }
        if (isChecked && checkedItems.DT) {
          setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            DT: false,
          }));
          setTextBoxEnabled((prevTextBoxEnabled) => ({
            ...prevTextBoxEnabled,
            DT: false,
          }));
        } else if (!isChecked && !checkedItems.DT && checkedItems.DI) {
          setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            DT: true,
          }));
          setTextBoxEnabled((prevTextBoxEnabled) => ({
            ...prevTextBoxEnabled,
            DT: true,
          }));
        }
        break;
      case "PF":
        // when Design Intent is selected, user must select DT or PRA
        if (isChecked) {
          setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            PF: true,
          }));
          setTextBoxEnabled((prevTextBoxEnabled) => ({
            ...prevTextBoxEnabled,
            PF: true,
          }));
        }
        break;
      default:
        break;
    }

    // enable/disable textbox based on checkbox selection
    setTextBoxEnabled((prevTextBoxEnabled) => ({
      ...prevTextBoxEnabled,
      [name]: isChecked,
      // enable both inputs when both DI and DT are selected
      ...(name === "DI" && isChecked && checkedItems.DT
        ? { DT: true }
        : name === "DT" && isChecked && checkedItems.DI
        ? { DI: true }
        : {}),
    }));
  };

  const defaultValues = {
    groupName: "",
    customValue: "",
    kickoffDate: "",
    PM: "",
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const checkFormValidity = () => {
    const valid =
      projectName !== "" &&
      brand?.length > 0 &&
      region &&
      Object.keys(region).length > 0 &&
      bu &&
      Object.keys(bu).length > 0 &&
      readinessDate &&
      printerDate &&
      subCategories?.length > 0 &&
      scale &&
      Object.keys(scale).length > 0 &&
      supplier &&
      Object.keys(supplier).length > 0 &&
      projectStartDate &&
      true;
    return valid;
  };

  const checkFormValidityForSaveAsDraft = () => {
    const valid =
      region && Object.keys(region).length > 0 && bu && Object.keys(bu).length > 0 && true;
    return valid;
  };

  useEffect(() => {
    const valid = checkFormValidityForSaveAsDraft();
    setFormValidForSaveAsDraft(valid);
  });

  useEffect(() => {
    const valid = checkFormValidity();
    setFormValid(valid);
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const collectFormData = (status, mode) => {
    const ProjectSMO = getProjectSMO();
    const ProjectCategory = getProjectCategory();
    const ProjectBrand = getProjectBrand();
    const ProjectCode = getProjectCode();

    const modifiedComments = convertNextLineCharToSpaces(comments);
    const modifiedDesc = convertNextLineCharToSpaces(projectDesc);

    const formData = {
      content: {
        BU: bu?.BU_Name,
        Comments:
          (mode === "edit" || mode === "design") && status === "Active"
            ? modifiedComments
            : comments,
        DesignIntent: checkedItems["DI"].toString(),
        EstimatedNoOfDI:
          checkedItems["DI"] === true && designScopeList.DI !== ""
            ? designScopeList.DI.toString()
            : checkedItems["DI"] === true && designScopeList.DI === ""
            ? "1"
            : designScopeList.DI.toString(),
        DesignTemplate: checkedItems["DT"].toString(),
        EstimatedNoOfDT:
          checkedItems["DT"] === true && designScopeList.DT !== ""
            ? designScopeList.DT.toString()
            : checkedItems["DT"] === true && designScopeList.DT === ""
            ? "1"
            : designScopeList.DT.toString(),
        InkQualification: checkedItems["IQ"].toString(),
        EstimatedNoOfIQ:
          checkedItems["IQ"] === true && designScopeList.IQ !== ""
            ? designScopeList.IQ.toString()
            : checkedItems["IQ"] === true && designScopeList.IQ === ""
            ? "1"
            : designScopeList.IQ.toString(),
        NewPrintFeasibility: checkedItems["PF"].toString(),
        EstimatedNoOfNPF:
          checkedItems["PF"] === true && designScopeList.PF !== ""
            ? designScopeList.PF.toString()
            : checkedItems["PF"] === true && designScopeList.PF === ""
            ? "1"
            : designScopeList.PF.toString(),
        ProductionReadyArt: checkedItems["PRA"].toString(),
        EstimatedNoOfPRA:
          checkedItems["PRA"] === true && designScopeList.PRA !== ""
            ? designScopeList.PRA.toString()
            : checkedItems["PRA"] === true && designScopeList.PRA === ""
            ? "1"
            : designScopeList.PRA.toString(),
        CICs: checkedItems["CICs"].toString(),
        Estimated_ofCICs:
          checkedItems["CICs"] === true && designScopeList.CICs !== ""
            ? designScopeList.CICs.toString()
            : checkedItems["CICs"] === true && designScopeList.CICs === ""
            ? "1"
            : designScopeList.CICs.toString(),

        POAs: "true",
        // Estimated_ofPOAs: POA !== "" ? POA.toString() : "1",
        Estimated_ofPOAs:
          checkedItems["FA"] === true && designScopeList.FA !== ""
            ? designScopeList.FA.toString()
            : checkedItems["FA"] === true && designScopeList.FA === ""
            ? "1"
            : designScopeList.FA.toString(),

        Estimated_SOP: formatPayloadDate(sopDate),
        Estimated_SOS: formatPayloadDate(sosDate),
        Estimated_AW_Printer: formatPayloadDate(printerDate),
        Estimated_AW_Readiness: formatPayloadDate(readinessDate),
        IL: iL,
        Tier: Tier?.Label_Name,
        Project_Start_Date: formatPayloadDate(projectStartDate),
        Company: supplier?.Supplier_Name,
        InitiativeGroupName: groupName,
        PM: pm,
        ProductionStrategy: ps?.Label_Name,
        Project_Scale: scale?.Scale_Name,
        Cluster: cluster,
        ProjectDescription:
          (mode === "edit" || mode === "design") && status === "Active"
            ? modifiedDesc
            : projectDesc,
        ProjectName: projectName,
        Project_region: region?.Region_Name,
        Project_Code: ProjectCode,
        ProjectType: projectType?.ProjectType_Name,
        Project_State: "Draft",
        Buffer_To_Work: "",
        ProjectStatus: status,
      },
      pageInstructions: [...ProjectCategory, ...ProjectBrand, ...ProjectSMO],
    };
    if (mode === "create") {
      formData.caseTypeID = "PG-AAS-Work-ArtworkProject";
    }
    return formData;
  };

  const getArtworkSMO = () => {
    const selectedSmoOptions = [];

    smo?.forEach((sm) => {
      let temp = {};
      smoOptions?.forEach((option) => {
        if (option.value === sm) {
          temp.SMO_Name = option.label;
          temp.code = option.value;
        }
      });
      selectedSmoOptions.push(temp);
    });
    return selectedSmoOptions;
  };

  const getArtworkBrand = () => {
    return brand;
  };

  const getArtworkCategory = () => {
    const selectedCategoriesOptions = [];
    subCategories?.forEach((obj) => {
      let temp = {};
      temp.Category_Name = obj.Label_Name;
      temp.code = obj.code;
      selectedCategoriesOptions.push(temp);
    });
    return selectedCategoriesOptions;
  };
  const collectForm2Data = (action, mode) => {
    const ArtworkSMO = getArtworkSMO();
    const ArtworkCategory = getArtworkCategory();
    const ArtworkBrand = getArtworkBrand();
    const ProjectCode = getProjectCode();

    const modifiedDesc = convertNextLineCharToSpaces(projectDesc);
    const modifiedComments = convertNextLineCharToSpaces(comments);

    const formData = {
      AWM_Project_ID: selectedProjectDetails?.Project_ID,
      // Status: status,
      Action: action,
      Region: region?.Region_Name,
      Project_Name: projectName,
      Initiative_Group_Name: groupName,
      Project_Description:
        (mode === "edit" || mode === "design") && (action === "" || action === "saveasdraft")
          ? modifiedDesc
          : projectDesc,
      BU: bu?.BU_Name,
      Project_region: region?.Region_Name,
      Cluster: cluster,
      Project_Scale: scale?.Scale_Name,
      Tier: Tier?.Label_Name,
      Production_Strategy: ps?.Label_Name,
      Project_Start_Date: formatPayloadDate(projectStartDate),
      Company: supplier?.Supplier_Name,
      Project_State: selectedProjectDetails?.Project_State,
      Project_Type: projectType?.ProjectType_Name,
      IL: iL,
      PM: pm,
      Estimated_SOS: formatPayloadDate(sosDate),
      Estimated_SOP: formatPayloadDate(sopDate),
      Comments:
        (mode === "edit" || mode === "design") && (action === "" || action === "saveasdraft")
          ? modifiedComments
          : comments,
      Buffer_To_Work: "",
      Project_Code: ProjectCode,
      Design_Intent: checkedItems["DI"].toString(),
      Design_Template: checkedItems["DT"].toString(),
      Ink_Qualification: checkedItems["IQ"].toString(),
      New_Print_Feasibility: checkedItems["PF"].toString(),
      Production_Ready_Art: checkedItems["PRA"].toString(),
      CICs: checkedItems["CICs"].toString(),
      POAs: "true",
      Estimated_No_Of_DI:
        checkedItems["DI"] === true && designScopeList.DI !== ""
          ? designScopeList.DI.toString()
          : checkedItems["DI"] === true && designScopeList.DI === ""
          ? "1"
          : designScopeList.DI.toString(),
      Estimated_No_Of_DT:
        checkedItems["DT"] === true && designScopeList.DT !== ""
          ? designScopeList.DT.toString()
          : checkedItems["DT"] === true && designScopeList.DT === ""
          ? "1"
          : designScopeList.DT.toString(),
      Estimated_No_Of_PRA:
        checkedItems["PRA"] === true && designScopeList.PRA !== ""
          ? designScopeList.PRA.toString()
          : checkedItems["PRA"] === true && designScopeList.PRA === ""
          ? "1"
          : designScopeList.PRA.toString(),
      Estimated_No_Of_IQ:
        checkedItems["IQ"] === true && designScopeList.IQ !== ""
          ? designScopeList.IQ.toString()
          : checkedItems["IQ"] === true && designScopeList.IQ === ""
          ? "1"
          : designScopeList.IQ.toString(),
      Estimated_No_Of_NPF:
        checkedItems["PF"] === true && designScopeList.PF !== ""
          ? designScopeList.PF.toString()
          : checkedItems["PF"] === true && designScopeList.PF === ""
          ? "1"
          : designScopeList.PF.toString(),
      Estimated_No_Of_CICs:
        checkedItems["CICs"] === true && designScopeList.CICs !== ""
          ? designScopeList.CICs.toString()
          : checkedItems["CICs"] === true && designScopeList.CICs === ""
          ? "1"
          : designScopeList.CICs.toString(),
      Estimated_No_Of_POAs:
        checkedItems["FA"] === true && designScopeList.FA !== ""
          ? designScopeList.FA.toString()
          : checkedItems["FA"] === true && designScopeList.FA === ""
          ? "1"
          : designScopeList.FA.toString(),
      Estimated_AW_Readiness: formatPayloadDate(readinessDate),
      Estimated_AW_Printer: formatPayloadDate(printerDate),
      Artwork_Brand: ArtworkBrand,
      Artwork_Category: ArtworkCategory,
      Artwork_SMO: ArtworkSMO,
    };

    return formData;
  };

  useEffect(() => {
    designScopes?.map((option) => {
      option.inputValue = designScopeList[option.value];
      option.isError = false;
      if (option.value === "DI") {
        option.minValue = selectedProjectDetails?.DefinedNoOfDI
          ? selectedProjectDetails.DefinedNoOfDI
          : 0;
      }
      if (option.value === "DT") {
        option.minValue = selectedProjectDetails?.DefinedNoOfDT
          ? selectedProjectDetails.DefinedNoOfDT
          : 0;
      }
      if (option.value === "PF") {
        option.minValue = selectedProjectDetails?.DefinedNoOfNPF
          ? selectedProjectDetails?.DefinedNoOfNPF
          : 0;
      }
      if (option.value === "IQ") {
        option.minValue = selectedProjectDetails?.DefinedNoOfIQ
          ? selectedProjectDetails.DefinedNoOfIQ
          : 0;
      }
      if (option.value === "PRA") {
        option.minValue = selectedProjectDetails?.DefinedNoOfPRA
          ? selectedProjectDetails.DefinedNoOfPRA
          : 0;
      }
      if (option.value === "CICs") {
        option.minValue = selectedProjectDetails?.DefinedNoOfCIC
          ? selectedProjectDetails.DefinedNoOfCIC
          : 0;
      }
      if (option.value === "FA") {
        option.minValue = selectedProjectDetails?.DefinedNoOfPOA
          ? selectedProjectDetails.DefinedNoOfPOA
          : 1;
      }
    });
  }, [selectedProjectDetails, designScopeList]);

  useEffect(()=>{
    if(errorDescription){
      setVisible(true)
    }
  },[errorDescription])
  

  const handleHide = (bool) => {
    setVisible(bool)
    setErrorDescription(null)
  }
  const onSubmit = async () => {
    let isError = false;
    let targetPath = `/projectPlan/${awmProjectId}`;
    designScopes?.map((el) => {
      if (el.inputValue < el.minValue) el.isError = true;
      if (el.isError == true) {
        isError = true;
      }
    });

    if (isError) {
      setVisible(true);
      return;
    }

    setSpinnerText("Submitting");
    setLoader(true);
    let updateDetailsRes=null;
    if (mode === "create") {
      const formData = collectFormData("Active", mode);

      setFormData(formData);
      const response = await createNewProject(formData);
      if (response?.data?.ID) {
        awmProjectId = response?.data?.ID.split("PG-AAS-WORK ")[1];
      }
    } else if (
      (mode === "edit" || mode === "design") &&
      selectedProjectDetails?.Action !== "Active" &&
      selectedProjectDetails?.Project_State !== "Complete"
    ) {
      const formData = collectFormData("Active", mode);

      setFormData(formData);
      let id = `PG-AAS-WORK ${awmProjectId}`;
      let method = "PATCH";
      const headers = { key: "If-Match", value: selectedProjectDetails?.Etag };
      await editProject(formData, id, method, headers);
    } else if (
      (mode === "edit" || mode === "design") &&
      selectedProjectDetails?.Action === "Active" &&
      selectedProjectDetails?.Project_State !== "Complete"
    ) {
      const formData = collectForm2Data("", mode);
      setFormData(formData);
      let method = "POST";
      // const headers = { key: "If-Match", value: selectedProjectDetails?.Etag };
      const headers = {};
      //AWM-3138: Error Description comes only for this API's response
      updateDetailsRes = await editProject(formData, awmProjectId, method, headers);
      if(updateDetailsRes?.data?.ErrorDescription && updateDetailsRes?.data?.ErrorDescription!==""){
        setErrorDescription(updateDetailsRes?.data?.ErrorDescription)
      }else{
        setErrorDescription(null)
      }
    }
    setSpinnerText("");
    //worked by Asha 3173- refactored the navigation part
    setLoader(false);
    //AWM-3138: redirection happens only if there is no error returned from API for scope value change
    if (
      (mode === "edit" || mode === "design") &&
      selectedProjectDetails?.Action === "Active" &&
      selectedProjectDetails?.Project_State !== "Complete"
    ){
      if(updateDetailsRes?.data?.ErrorDescription===""){
        showBackgroundProcess(toast);
        setTimeout(()=>{
          navigate(`/${url[1]}${targetPath}`);
        },4000)
      }
    }else{
      if (mode === "create") {
        navigate(`/myProjects/projectPlan/${awmProjectId}`);
      } else {
        navigate(`/${url[1]}${targetPath}`);
      }
    }
    
    // if (url[1] === "projectSetup") {
    //   setLoader(false);
    //   navigate(`/myProjects/projectPlan/${awmProjectId}`);
    // }
    // if (url[1] === "myProjects") {
    //   if (userInformation.userid === pm) {
    //     setLoader(false);
    //     navigate(`/myProjects/projectPlan/${awmProjectId}`);
    //   } else {
    //     setLoader(false);
    //     navigate(`/allProjects/projectPlan/${awmProjectId}`);
    //   }
    // }
    // if (url[1] === "allProjects") {
    //   setLoader(false);
    //   navigate(`/allProjects/projectPlan/${awmProjectId}`);
    // }
  };
  const onSaveAsDraft = async () => {
    setSpinnerText("Saving");
    setLoader(true);
    let updateDetailsRes=null;
    if (mode === "create") {
      let draftFormData = collectFormData("Draft", mode);

      localStorage.setItem("formDraft", JSON.stringify(draftFormData));
      await createNewProject(draftFormData);
    } else if (mode === "edit" || mode === "design") {
      let draftFormData = collectForm2Data("saveasdraft", mode);

      localStorage.setItem("formDraft", JSON.stringify(draftFormData));
      const method = "POST";
      // const headers = { key: "If-Match", value: selectedProjectDetails?.Etag };
      const headers = {};
      //AWM-3138: Error Description comes only for this API's response
      updateDetailsRes = await editProject(draftFormData, awmProjectId, method, headers);
      if(updateDetailsRes?.data?.ErrorDescription && updateDetailsRes?.data?.ErrorDescription!==""){
        setErrorDescription(updateDetailsRes?.data?.ErrorDescription)
      }else{
        setErrorDescription(null)
      }
    }
    setSpinnerText("");
    setLoader(false);
    //check1: Navigation is based on whether the PM is part of the logged-in user and if it's the first time creating a project else by defaut it should go to previous page
    //worked by Asha 3173- refactored the navigation part
    //AWM-3138: redirection happens only if there is no error returned from API for scope value change
    if (mode === "edit" || mode === "design"){
      if(updateDetailsRes?.data?.ErrorDescription===""){
        if (userInformation.userid === pm) {
          navigate("/myProjects");
        } else {
          navigate(`/${url[1]}`);
        }
      }
    } else {
      if (mode === "create" || userInformation.userid === pm) {
        navigate("/myProjects");
      } else {
        navigate(`/${url[1]}`);
      }
    }
  };

  // this function is to change the date format maybe we will change when pega integration is done.
  const changeDateFormat = (value) => {
    const options = { timeZone: "Asia/Kolkata", hour12: false };
    const outputDateString = value?.toLocaleString("en-US", options);
    const outputDate = new Date(outputDateString);
    return outputDate;
  };
  const handleTierChange = (e) => {
    const selectedTier = tierList?.find((r) => r?.Label_Name === e?.target?.value?.Label_Name);

    selectedTier ? setTier(selectedTier) : setTier(e?.value);
  };
  const handlePsChange = (e) => {
    const selectedPs = productionStrategyList?.find(
      (r) => r?.Label_Name === e?.target?.value?.Label_Name
    );

    selectedPs ? setPs(selectedPs) : setPs(e?.value);
  };
  const handlePM = (e) => {
    e?.target?.value.length === 0 ? setPMAlert(true) : setPMAlert(false);
    setPm(e?.target?.value);
  };
  const handleIL = (e) => {
    setIl(e?.target?.value);
  };
  useEffect(() => {
    const selectedData = JSON.parse(localStorage.getItem("formDraft"));
    if (selectedData && selectedData?.selectedCities) {
      setSelectedCities(selectedData?.selectedCities);
    }
  }, []);

  const headerName = "Project Setup";
  const breadcrumb = (
    <div>
      <nav className="p-breadcrumb p-component ProjectPlanBreadCrum" aria-label="Breadcrumb">
        <ul>
          <li className="">
            <NavLink to={`/${url[1]}`} className="p-menuitem-link">
              <span className="p-menuitem-text">{_.startCase(url[1])}</span>
            </NavLink>
          </li>
          <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"></li>
          <li className="">
            <a href="#" className="p-menuitem-link">
              <span className="p-menuitem-text">{headerName}</span>
            </a>
          </li>
          <li>
            {mode !== "create" && (
              <div className="project-name">{!loader && selectedProjectDetails?.Project_Name}</div>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
  const onCancel = () => {
    if (mode === "create") {
      setToInitialState(); //if we are creating project for 1st time and click of cancel, feilds should reset to initial state.
    } else {
      navigate(`/${url[1]}`);
    }
  };
  return (
    <LoadingOverlay active={loading || loader} spinner text="">
      <div className="actions">
        <div className="breadCrumbParent">{breadcrumb}</div>
      </div>
      <div className="tabular-add-project">
        <Toast ref={toast} />
        <Form onSubmit={handleSubmit(onSubmit)} className="project-setup-add-project">
          <Row disabled={userAccessDisabled || projectState === "Complete"}>
            <Col>
              <Row>
                <Form.Group
                  className={`mb-2 ${projectNameAlert ? "error-text" : ""}`}
                  controlId="projectName.ControlInput1"
                >
                  <Form.Label>
                    Project Name <sup>*</sup>
                  </Form.Label>
                  <input
                    type="text"
                    maxLength={textFieldMaxLength}
                    style={{ fontSize: "10px" }}
                    className={
                      projectName.length === 0
                        ? "form-control"
                        : projectName.length >= textFieldMaxLength
                        ? "form-control"
                        : "form-control"
                    }
                    placeholder="Enter Project Name"
                    onChange={(e) => {
                      const value = e.target.value;
                      setProjectName(value);
                      if (value.trim() === "") {
                        setProjectNameAlert(true);
                      } else if (value.trim() !== "") {
                        setProjectNameAlert(false);
                      }
                    }}
                    value={projectName}
                  />
                  <CharacterLimit field={projectName} maxLength={textFieldMaxLength} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className={`mb-2`} controlId="groupName.ControlInput1">
                  <Form.Label>Initiative Group Name</Form.Label>
                  <input
                    type="text"
                    maxLength={textFieldMaxLength}
                    className={
                      groupName?.length === 0
                        ? "form-control"
                        : groupName?.length >= textFieldMaxLength
                        ? "form-control"
                        : "form-control"
                    }
                    placeholder="Enter Group Name"
                    onChange={(e) => setGroupName(e?.target?.value)}
                    value={groupName}
                  />
                  <CharacterLimit field={groupName} maxLength={textFieldMaxLength} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-2" controlId="projectDescription.ControlInput1">
                  <Form.Label>Project Description</Form.Label>
                  <textarea
                    maxLength={textAreaMaxLength}
                    type="text"
                    // className={projectDesc.length === maxLength ? 'form-control border-danger' : 'form-control'}
                    className={
                      projectDesc?.length === 0
                        ? "form-control"
                        : projectDesc?.length >= textAreaMaxLength
                        ? "form-control"
                        : "form-control"
                    }
                    placeholder="Enter Project Description"
                    onChange={(e) => setProjectDesc(e?.target?.value)}
                    value={projectDesc}
                    style={{ minHeight: "75px" }}
                  />
                  <CharacterLimit field={projectDesc} maxLength={textAreaMaxLength} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  className={`mb-2 ${businessUnitAlert ? "error-text" : ""}`}
                  controlId="bu.SelectMultiple"
                >
                  <Form.Label>
                    Business Unit<sup>*</sup>
                  </Form.Label>
                  <div ref={dropdownRef}>
                    <CommonAutocomplete
                      width={width}
                      disabled={
                        projectState === "Draft" ||
                        projectState === "Active" ||
                        projectState === "Complete"
                      }
                      suggestions={bUs}
                      value={bu}
                      onChange={(e) => {
                        e?.value?.length === 0
                          ? setBusinessUnitAlert(true)
                          : setBusinessUnitAlert(false);
                        setBu(e?.value);
                        setSubCategories([]);
                      }}
                      placeholder="Select BU"
                      field="BU_Name"
                      setDropDownMatch={setBuDropDownMatch}
                    />
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  controlId="scale.category"
                  className={`mb-2 ${categoriesAlert ? "error-text" : ""}`}
                  ref={dropdownRef}
                >
                  <Form.Label>
                    Category <sup>*</sup>
                  </Form.Label>
                  <MultiSelect
                    disabled={projectState === "Complete"}
                    value={subCategories}
                    onChange={handleSubCategoryChange}
                    options={subCategoriesOptions}
                    optionLabel="Label_Name"
                    filter
                    placeholder="Select Categories"
                    className="w-full md:w-20rem"
                    panelStyle={{ width: width, borderRadius: "4px" }}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  className={brandAlert ? "error-text" : ""}
                  controlId="brand.SelectMultiple"
                >
                  <Form.Label>
                    Brand <sup>*</sup>
                  </Form.Label>
                  <div ref={dropdownRef}>
                    <MultiSelect
                      disabled={projectState === "Complete"}
                      value={brand}
                      onChange={(e) => {
                        e.stopPropagation();
                        e?.target?.value?.length === 0 ? setBrandAlert(true) : setBrandAlert(false);
                        setBrand(e?.value);
                      }}
                      options={brands}
                      optionLabel="Brand_Name"
                      filter
                      placeholder="Select Brand"
                      className="w-full md:w-20rem"
                      required
                      panelStyle={{ width: width, borderRadius: "4px" }}
                    />
                  </div>
                </Form.Group>
              </Row>
            </Col>
            <Col>
              <Row>
                <Form.Group
                  className={`mb-2 ${regionAlert ? "error-text" : ""}`}
                  controlId="reg.SelectMultiple"
                >
                  <Form.Label>
                    Region <sup>*</sup>
                  </Form.Label>
                  <div ref={dropdownRef}>
                    <CommonAutocomplete
                      width={width}
                      disabled={
                        projectState === "Draft" ||
                        projectState === "Active" ||
                        projectState === "Complete"
                      }
                      suggestions={regions}
                      value={region}
                      onChange={(e) => {
                        e.value ? setRegionAlert(false) : setRegionAlert(true);
                        setRegion(e?.value);
                      }}
                      placeholder="Select Region"
                      field="Region_Name"
                      setDropDownMatch={setRegionDropDownMatch}
                    />
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className={`mb-2`} controlId="smo.SelectMultiple">
                  <Form.Label>SMO</Form.Label>
                  <div ref={dropdownRef}>
                    <MultiSelect
                      disabled={projectState === "Complete"}
                      value={smo}
                      onChange={(e) => {
                        e.stopPropagation();
                        e?.target?.value?.length === 0 ? setSMOAlert(true) : setSMOAlert(false);
                        setSmo(e?.value);
                      }}
                      options={smoOptions}
                      optionLabel="label"
                      filter
                      placeholder="Select SMO"
                      className="w-full md:w-20rem"
                      panelStyle={{ width: width, borderRadius: "4px" }}
                    />
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-2" controlId="cluster.SelectMultiple">
                  <Form.Label>Cluster</Form.Label>
                  <div>
                    <Form.Control
                      className={
                        cluster?.length === 0
                          ? "form-control"
                          : cluster?.length >= textFieldMaxLength
                          ? "form-control"
                          : "form-control"
                      }
                      placeholder="Enter Cluster"
                      maxLength={textFieldMaxLength}
                      value={cluster}
                      onChange={(e) => setCluster(e?.target?.value)}
                      autocomplete="off"
                    ></Form.Control>
                  </div>
                  <CharacterLimit field={cluster} maxLength={textFieldMaxLength} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  className={`mb-2 ${scaleAlert ? "error-text" : ""}`}
                  controlId="bve.SelectMultiple"
                >
                  <Form.Label>
                    Scale <sup>*</sup>
                  </Form.Label>
                  <div ref={dropdownRef}>
                    <CommonAutocomplete
                      width={width}
                      disabled={projectState === "Complete"}
                      suggestions={scales}
                      value={scale}
                      onChange={(e) => {
                        e?.value ? setScaleAlert(false) : setScaleAlert(true);
                        handleScaleChange(e);
                      }}
                      placeholder="Select Scale"
                      field="Scale_Name"
                      setDropDownMatch={setScaleDropDownMatch}
                    />
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="il.SelectMultiple">
                  <Form.Label>Scope & Estimated</Form.Label>
                  <div className="design-scope">
                    {designScope?.map((option, index) => (
                      <span key={index} style={{ display: "flex" }}>
                        {option?.value !== "FA" && (
                          <>
                            <Form.Check
                              label={
                                <div id="scopeLabel">
                                  {option.label.split("\n").map((text, idx) => (
                                    <React.Fragment key={idx}>
                                      {text}
                                      {idx !== option?.label?.split("\n")?.length - 1 && <br />}
                                    </React.Fragment>
                                  ))}
                                </div>
                              }
                              name={option?.value}
                              type="checkbox"
                              value={option?.value}
                              onChange={handleCheckboxChange}
                              checked={checkedItems[option?.value]}
                              style={{
                                width: 160,
                              }}
                            />

                            <Form.Control
                              type="number"
                              key={index}
                              value={designScopeList[option?.value]}
                              min={0}
                              autocomplete="off"
                              onInput={(e) => {
                                let { value } = e.target;
                                value = !!value && Math.abs(value) >= 0 ? Math.abs(value) : null;
                                setDesignScopeList((prevDesignScopeList) => ({
                                  ...prevDesignScopeList,
                                  [option.value]: parseInt(value),
                                }));
                              }}
                              onChange={(e) => {
                                const { value } = e.target;
                                if (designScopes[index] !== undefined) {
                                  designScopes[index]["inputValue"] =
                                    value !== "" ? parseInt(value) : 0;
                                  designScopes[index]["isError"] = value < option.minValue;
                                  setDesignScopes(designScopes);
                                }
                              }}
                              disabled={!textBoxEnabled[option.value]}
                              style={{
                                width: 40,
                                height: 27,
                                paddingLeft: "5px",
                                paddingRight: 0,
                                fontSize: "10px",
                                marginRight: "10px",
                                ...(!textBoxEnabled[option.value]
                                  ? { backgroundColor: "#e9ecef" }
                                  : {}),
                              }}
                            />
                          </>
                        )}
                        {option.value === "FA" && (
                          <>
                            <Form.Check
                              label="Final Art *"
                              name="POA"
                              type="checkbox"
                              value="POA"
                              onChange={handleCheckboxChange}
                              checked
                              style={{ width: 160 }}
                            />
                            <Form.Control
                              type="number"
                              value={designScopeList[option.value]}
                              required
                              min={0}
                              onInput={(e) => {
                                let { value } = e.target;
                                value = !!value && Math.abs(value) >= 0 ? Math.abs(value) : null;
                                setDesignScopeList((prevDesignScopeList) => ({
                                  ...prevDesignScopeList,
                                  [option.value]: parseInt(value),
                                }));
                              }}
                              onChange={(e) => {
                                const { value } = e.target;
                                if (designScopes[index] != undefined) {
                                  designScopes[index]["inputValue"] =
                                    value !== "" ? parseInt(value) : 0;
                                  designScopes[index]["isError"] = value < option.minValue;
                                  setDesignScopes(designScopes);
                                }
                              }}
                              style={{
                                width: 40,
                                height: 27,
                                paddingLeft: "5px",
                                paddingRight: 0,
                                fontSize: "10px",
                                marginRight: "10px",
                              }}
                            />
                          </>
                        )}
                      </span>
                    ))}
                  </div>
                </Form.Group>
              </Row>
            </Col>
            <Col>
              <Row>
                <Form.Group
                  className={`mb-2 ${projectStartDateAlert ? "error-text" : ""}`}
                  controlId="sop.readiness"
                >
                  <Form.Label>
                    Estimated Project Start Date <sup>*</sup>
                  </Form.Label>
                  <Controller
                    name="date"
                    control={form.control}
                    rules={{ required: "Date is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <Calendar
                          placeholder="Select Estimated Project Start Date"
                          inputId={field.name}
                          value={projectStartDate}
                          disabled={projectState === "Active" || projectState === "Complete"}
                          onChange={(e) => {
                            !e.target.value
                              ? setProjectStartDateAlert(true)
                              : setProjectStartDateAlert(false);
                            setProjectStartDate(e.target.value);
                          }}
                          dateFormat="d-M-y"
                          showIcon={true}
                          minDate={minDate}
                          maxDate={
                            readinessDate !== "" && readinessDate !== null
                              ? readinessDate
                              : printerDate !== "" && printerDate !== null
                              ? printerDate
                              : sopDate !== "" && sopDate !== null
                              ? sopDate
                              : sosDate
                          }
                          className={classNames({
                            "p-invalid": fieldState.error,
                          })}
                          icon={<CalendarIcon />}
                        />
                      </>
                    )}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group
                  className={`mb-2 ${readinessDateAlert ? "error-text" : ""}`}
                  controlId="sop.readiness"
                >
                  <Form.Label>
                    Estimated AW Readiness <sup>*</sup>
                  </Form.Label>
                  <Controller
                    name="date"
                    control={form.control}
                    rules={{ required: "Date is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <Calendar
                          placeholder="Select Estimated AW Readiness"
                          inputId={field.name}
                          value={readinessDate}
                          disabled={projectState === "Complete"}
                          onChange={(e) => {
                            !e.target.value
                              ? setReadinessDateAlert(true)
                              : setReadinessDateAlert(false);
                            setReadinessDate(e.target.value);
                          }}
                          dateFormat="d-M-y"
                          showIcon={true}
                          minDate={
                            projectStartDate !== "" && projectStartDate !== null
                              ? projectStartDate
                              : minDate
                          }
                          maxDate={
                            printerDate !== "" && printerDate !== null
                              ? printerDate
                              : sopDate !== "" && sopDate !== null
                              ? sopDate
                              : sosDate
                          }
                          className={classNames({
                            "p-invalid": fieldState.error,
                          })}
                          icon={<CalendarIcon />}
                        />
                      </>
                    )}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  className={`mb-2 ${printerDateAlert ? "error-text" : ""}`}
                  controlId="sop.readiness"
                >
                  <Form.Label>
                    Estimated AW@Printer<sup>*</sup>{" "}
                  </Form.Label>

                  <Controller
                    name="date"
                    control={form.control}
                    rules={{ required: "Date is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <Calendar
                          placeholder="Select Estimated AW@Printer"
                          inputId={field.name}
                          value={printerDate}
                          disabled={projectState === "Complete"}
                          onChange={(e) => {
                            !e.target.value
                              ? setPrinterDateAlert(true)
                              : setPrinterDateAlert(false);
                            setPrinterDate(e.target.value);
                          }}
                          dateFormat="d-M-y"
                          showIcon={true}
                          minDate={
                            readinessDate !== "" && readinessDate !== null
                              ? readinessDate
                              : projectStartDate !== "" && projectStartDate !== null
                              ? projectStartDate
                              : minDate
                          }
                          maxDate={sopDate !== "" && sopDate !== null ? sopDate : sosDate}
                          className={classNames({
                            "p-invalid": fieldState.error,
                          })}
                          icon={<CalendarIcon />}
                        />
                      </>
                    )}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className={`mb-2`} controlId="sop.readiness">
                  <Form.Label>Estimated SOP</Form.Label>
                  <Controller
                    name="date"
                    control={form.control}
                    rules={{ required: "Date is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <Calendar
                          placeholder="Select Estimated SOP"
                          inputId={field.name}
                          value={sopDate}
                          disabled={projectState === "Complete"}
                          onChange={(e) => setSOPDate(e.target.value)}
                          dateFormat="d-M-y"
                          showIcon={true}
                          minDate={
                            printerDate !== "" && printerDate !== null
                              ? printerDate
                              : readinessDate !== "" && readinessDate !== null
                              ? readinessDate
                              : projectStartDate !== "" && projectStartDate !== null
                              ? projectStartDate
                              : minDate
                          }
                          maxDate={sosDate}
                          className={classNames({
                            "p-invalid": fieldState.error,
                          })}
                          icon={<CalendarIcon />}
                          style={{
                            fontSize: "12px",
                            fontWeight: 1500,
                          }}
                        />
                      </>
                    )}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-2" controlId="sop.readiness">
                  <Form.Label>Estimated SOS</Form.Label>

                  <Controller
                    name="date"
                    control={form.control}
                    rules={{ required: "Date is required." }}
                    render={({ field, fieldState }) => (
                      <>
                        <Calendar
                          placeholder="Select Estimated SOS"
                          inputId={field.name}
                          value={sosDate}
                          disabled={projectState === "Complete"}
                          onChange={(e) => setSOSDate(e.target.value)}
                          dateFormat="d-M-y"
                          showIcon={true}
                          minDate={
                            sopDate !== "" && sopDate !== null
                              ? sopDate
                              : printerDate !== "" && printerDate !== null
                              ? printerDate
                              : readinessDate !== "" && readinessDate !== null
                              ? readinessDate
                              : projectStartDate !== "" && projectStartDate !== null
                              ? projectStartDate
                              : minDate
                          }
                          // style={{
                          //   width: 208,
                          //   fontSize: "12px",
                          //   fontWeight: 1500,
                          // }}
                          className={classNames({
                            "p-invalid": fieldState.error,
                          })}
                          icon={<CalendarIcon />}
                        />
                      </>
                    )}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-2" controlId="il.SelectMultiple">
                  <Form.Label>Initiative Leader</Form.Label>
                  <div>
                    <Form.Control
                      className={
                        iL?.length === 0
                          ? "form-control"
                          : iL?.length >= textFieldMaxLength
                          ? "form-control"
                          : "form-control"
                      }
                      placeholder="Enter IL"
                      maxLength={textFieldMaxLength}
                      value={iL}
                      onChange={handleIL}
                      autocomplete="off"
                    ></Form.Control>
                  </div>
                  <CharacterLimit field={iL} maxLength={textFieldMaxLength} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className={PMAlert ? "error-text" : ""}>
                  <Form.Label className={PMAlert ? "error-text" : ""}>
                    Project Manager <sup>*</sup>
                  </Form.Label>
                  <div ref={dropdownRef}>
                    {mode === "create" ? (
                      <Form.Select
                        value={pm}
                        onChange={handlePM}
                        disabled={mode === "create" && true}
                      >
                        <option value="">Select PM</option>
                        <option key={userInformation?.userid} value={userInformation?.userid}>
                          {userInformation?.userid}
                        </option>
                      </Form.Select>
                    ) : (
                      <>
                        <CommonAutocomplete
                          width={width}
                          suggestions={OwnersList}
                          value={pm}
                          onChange={(e) => {
                            setPm(e.value?.Owner_Name || pm);
                          }}
                          placeholder="Select PM"
                          field="Owner_Name"
                          disabled={projectState === "Complete"}
                          setDropDownMatch={setPmDropDownMatch}
                        />
                      </>
                    )}
                  </div>
                </Form.Group>
              </Row>
            </Col>
            <Col>
              <Row className={`${faSupplierAlert ? "error-text" : ""}`}>
                <Form.Group className={`mb-2`} controlId="projectType.SelectMultiple">
                  <Form.Label>
                    FA Supplier <sup>*</sup>
                  </Form.Label>
                  <div ref={dropdownRef}>
                    <CommonAutocomplete
                      width={width}
                      suggestions={supplierList}
                      value={supplier}
                      disabled={projectState === "Complete"}
                      onChange={(e) => {
                        e?.value ? setFaSupplierAlert(false) : setFaSupplierAlert(true);
                        handleSupplierChange(e);
                      }}
                      placeholder="Select Supplier Name"
                      field="Supplier_Name"
                      setDropDownMatch={setSupplierDropDownMatch}
                    />
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-2" controlId="comments.ControlInput1">
                  <Form.Label>Comments</Form.Label>
                  <textarea
                    type="text"
                    maxLength={textAreaMaxLength} //{255}
                    className={
                      comments?.length === 0
                        ? "form-control"
                        : comments?.length >= textAreaMaxLength
                        ? "form-control"
                        : "form-control"
                    }
                    placeholder="Add Comments"
                    onChange={(e) => setComments(e.target.value)}
                    value={comments}
                  />
                  <CharacterLimit field={comments} maxLength={textAreaMaxLength} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-2" controlId="projectType.SelectMultiple">
                  <Form.Label>Project Type</Form.Label>
                  <div ref={dropdownRef}>
                    <CommonAutocomplete
                      width={width}
                      suggestions={projectTypeList}
                      value={projectType}
                      disabled={projectState === "Complete"}
                      onChange={(e) => handleProjectTypeChange(e)}
                      placeholder="Select Project Type"
                      field="ProjectType_Name"
                      setDropDownMatch={setProjectTypeDropDownMatch}
                    />
                  </div>
                </Form.Group>
              </Row>
              {bu && tierList?.length > 0 && (
                <Row>
                  <Form.Group className="mb-2" controlId="bve.SelectMultiple">
                    <Form.Label>Tier</Form.Label>
                    <div ref={dropdownRef}>
                      <CommonAutocomplete
                        width={width}
                        suggestions={tierList}
                        value={Tier}
                        disabled={projectState === "Complete"}
                        onChange={(e) => handleTierChange(e)}
                        placeholder="Select Tier"
                        field="Label_Name"
                        setDropDownMatch={setTierDropDownMatch}
                      />
                    </div>
                  </Form.Group>
                </Row>
              )}
              {bu && productionStrategyList?.length > 0 && (
                <Row>
                  <Form.Group className="mb-2" controlId="bve.SelectMultiple">
                    <Form.Label>Production Strategy</Form.Label>
                    <div ref={dropdownRef}>
                      <CommonAutocomplete
                        width={width}
                        suggestions={productionStrategyList}
                        value={ps}
                        disabled={projectState === "Complete"}
                        onChange={(e) => handlePsChange(e)}
                        placeholder="Select PS"
                        field="Label_Name"
                        setDropDownMatch={setPsDropDownMatch}
                      />
                    </div>
                  </Form.Group>
                </Row>
              )}
            </Col>
          </Row>
          <div className="form-buttons footer-form-buttons">
            <div className="creation-message">
              <p>{createProjectMessage}</p>
            </div>
            <Button
              className="button-layout"
              variant="secondary"
              // onClick={() => {
              //   url[1] === "projectSetup" || userInformation.userid === pm
              //     ? navigate("/myProjects")
              //     : navigate(`/${url[1]}`);
              // }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className={
                userAccessDisabled ||
                projectState === "Complete" ||
                projectState === "Active" ||
                projectActiveState === "Active" ||
                !formValidForSaveAsDraft ||
                !dropDownMatch
                  ? "button-layout btn btn-disabled"
                  : "button-layout"
              }
              variant="secondary"
              onClick={onSaveAsDraft}
              disabled={
                userAccessDisabled ||
                projectState === "Complete" ||
                projectState === "Active" ||
                projectActiveState === "Active" ||
                !formValidForSaveAsDraft ||
                !dropDownMatch
              }
            >
              Save as draft
            </Button>
            <Button
              className={
                !formValid || userAccessDisabled || !dropDownMatch
                  ? "button-layout btn btn-disabled"
                  : "button-layout"
              }
              type="submit"
              disabled={!formValid || userAccessDisabled || !dropDownMatch}
            >
              Next
            </Button>
          </div>
        </Form>
        {visible && (
          <AlertDialogBox
            visible={visible}
            onHide={handleHide}
            message={
              errorDescription !==null ? <p>{errorDescription}</p> :
              <>
                {designScopes?.map((el, inx) => {
                  if (el.isError) {
                    return (
                      <p>
                        Number of {el.label} cannot be below {el.minValue} that are already defined.
                        Please verify and try again.
                      </p>
                    );
                  }
                })}
              </>
            }
            icon="pi"
            handleContinue={() => handleHide(false)}
          />
        )}
      </div>
    </LoadingOverlay>
  );
}
export default AddProject;
